import { useCollection } from 'react-firebase-hooks/firestore';
import { BookingData } from '../firebase/subscription/subscription';
import { collection } from 'firebase/firestore';
import { initializeFirebase } from '../firebase/configValues';

const { firestore } = initializeFirebase();

export const useUserBookings = (userID: string) => {
  function getFourWeeksFutureSchedule(b: BookingData): Array<BookingData> {
    let weekInMili = 1000 * 60 * 60 * 24 * 7;
    return [
      { ...b, startMili: b.startMili + weekInMili },
      { ...b, startMili: b.startMili + weekInMili * 2 },
      { ...b, startMili: b.startMili + weekInMili * 3 },
      { ...b, startMili: b.startMili + weekInMili * 4 },
    ];
  }

  const [data, loading, error] = useCollection(
    collection(firestore, `users/${userID}/bookings`),
  );

  let newBookings: Array<BookingData> = [];
  data?.forEach((d: any) => {
    const docData: BookingData = d.data();
    docData.id = d.id;
    newBookings.push(docData);
  });

  newBookings.forEach((b: BookingData) => {
    if (b.recurring)
      newBookings = newBookings.concat(getFourWeeksFutureSchedule(b));
  });
  newBookings = newBookings.filter((b: BookingData) => {
    return (
      b.startMili + b.durationMili > new Date().valueOf() &&
      !b.canceled &&
      !b.skipTime?.includes(b.startMili)
    );
  });
  newBookings = newBookings.sort((a: BookingData, b: BookingData) => {
    return a.startMili - b.startMili;
  });

  if (error) throw error;
  return {
    bookings: newBookings,
    bookingsLoading: loading,
    bookingsError: error,
  };
};
