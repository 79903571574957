interface ProgressBarStep {
  name: string;
  page: number;
}

interface ProgressBarStepsProps {
  steps: Array<ProgressBarStep>;
  currentProgress: number;
  hideLabels?: boolean;
}

export const ProgressBarStepsDynamic = ({
  steps,
  currentProgress,
  hideLabels,
}: ProgressBarStepsProps) => {
  return (
    <div>
      <h4 className="sr-only">Status</h4>
      <div className="mt-6" aria-hidden="true">
        {!hideLabels ? (
          <div className="mb-6 text-2xl font-medium text-gray-600 text-center">
            <div className="text-blue-immigo">
              {
                steps[
                  Math.min(
                    Math.floor(steps.length * (currentProgress / 100)),
                    steps.length - 1,
                  )
                ].name
              }
            </div>
          </div>
        ) : null}
        <div className="overflow-hidden rounded-full bg-gray-200">
          <div
            className="transition-all transform duration-300 h-2 rounded-full bg-blue-immigo"
            style={{
              width: `${currentProgress.toFixed(2)}%`,
            }}
          />
        </div>
      </div>
    </div>
  );
};
