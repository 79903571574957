import mixpanel from '../../../analytics/mixpanel';

type Events =
  | 'Buy more credit modal shown'
  | 'Buy more credit modal: Upgrade plan'
  | 'Buy more credit modal: Buy credit'
  | 'Buy more credit modal: I dont need credit'
  | 'Buy more credit modal: Closed'
  | 'Buy more credit modal: Upgrade requested';

type TrackBuyMoreCreditModalData = {
  userID?: string;
  plan?: string;
};

export const trackBuyMoreCreditModal = (
  event: Events,
  data?: TrackBuyMoreCreditModalData,
) => {
  mixpanel.track(event, data);
};
