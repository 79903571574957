import { collection, doc } from 'firebase/firestore';
import { useEffect } from 'react';
import Confetti from 'react-confetti';
import {
  useCollectionData,
  useCollectionDataOnce,
} from 'react-firebase-hooks/firestore';
import { useWindowSize } from 'react-use';
import VerticalMessageModal from '../../../components/modals/verticalMessageModal/verticalMessageModal';
import {
  getUserSubAchievements,
  setAchievementNotified,
} from '../../../firebase/achievements/achievements';
import { initializeFirebase } from '../../../firebase/configValues';
import { trackAchievementEvent } from '../analytics';
import { AcheivementTypes, AchievementMap } from '../config/type';

const { firestore } = initializeFirebase();

type AchievemnetNotifierProps = {
  userID: string;
};

const useUserAchievements = (userID: string) => {
  const [data, loading, error] = useCollectionData(
    collection(firestore, `users/${userID}/achievements`),
  );

  const [wildfire_data, wildfire_loading, wildfire_error] = useCollectionData(
    collection(
      firestore,
      `users/${userID}/achievements/wildfire/subAchievements`,
    ),
  );

  const local = [...(data ?? []), ...(wildfire_data ?? [])];

  const unnotified = local.filter((a) => {
    return a && !a.notified && !a.subAchievements;
  }); // Only show the ones that were not notified

  unnotified?.sort((a: any, b: any) => {
    return b.createdAt - a.createdAt;
  }); // Sort by most recent acheivement

  return {
    achievements: unnotified,
    loading,
    error,
  };
};

export const AchievemnetNotifier = ({ userID }: AchievemnetNotifierProps) => {
  const { achievements, loading, error } = useUserAchievements(userID);

  const { width, height } = useWindowSize();

  function getAchievementModals() {
    const modals = achievements?.map((achievement) => {
      const achID: AcheivementTypes = !achievement?.parentAchievement
        ? achievement?.id
        : achievement?.parentAchievement;
      trackAchievementEvent('Achievement notification modal opened', {
        userID: userID,
        achievementID: achievement.id,
      });
      return (
        <VerticalMessageModal
          mainMessage="Achievement Unlocked!"
          subMessage=""
          theme="success"
          buttonText="Continue"
          key={achievement?.id}
          onButtonClick={() => {
            setAchievementNotified(
              userID,
              !achievement?.parentAchievement
                ? achID
                : `${achID}/subAchievements/${achievement?.id}`,
            );
            trackAchievementEvent(
              'Achievement notification modal continue button clicked',
              {
                userID: userID,
                achievementID: achievement.id,
              },
            );
          }}
          onCloseModal={() => {
            setAchievementNotified(
              userID,
              !achievement?.parentAchievement
                ? achID
                : `${achID}/subAchievements/${achievement?.id}`,
            );
            trackAchievementEvent('Achievement notification modal closed', {
              userID: userID,
              achievementID: achievement.id,
            });
          }}
          isOpen
        >
          <div className="w-64">
            <img
              className="w-24 h-24 mx-auto mb-4"
              src={AchievementMap.achievements[achID]?.badge}
              alt={achievement.id}
            />
            <div className="text-blue-immigo text-2xl mb-4">
              {achievement.name}
            </div>
            <div className="text-md text-gray-600">
              {achievement.description}
            </div>
          </div>
        </VerticalMessageModal>
      );
    });
    return modals;
  }

  return (
    <>
      {achievements?.length ? (
        <div className="z-50">
          <Confetti
            width={width}
            height={height}
            numberOfPieces={320}
            recycle={false}
          />
        </div>
      ) : null}
      {getAchievementModals()}
    </>
  );
};
