import { useEffect, useState } from 'react';
import moment from 'moment';
import { useCollection } from 'react-firebase-hooks/firestore';
import { collection } from 'firebase/firestore';
import { initializeFirebase } from '../../../firebase/configValues';
import NoBorderTabs from '../../../components/tabs/noBorderTabs/NoBorderTabs';
import { ClassBookingData, Classes } from './classes/Classes';
import { Topics } from './topics/Topics';
import { checkIsAdmin } from '../../../util/admin';
import { useSelector } from 'react-redux';
import { UserData } from '../../../types/user';
import { getAllClasses } from '../../../firebase/subscription/classes/classes';
import { returnMiliAfterCheckingIfClassHasPassed } from '../../../util/dates';
import { getClassWeekTopic } from '../../../firebase/subscription/subscription';
import { Slides } from './slides/Slides';
import { LoadingOverlay } from '../../../components/loading';
import { getAllCoaches } from '../../../firebase/subscription/coaches/coaches';
import { getAllTopics } from '../../../firebase/subscription/topics/topics';

const { firestore } = initializeFirebase();

function useClasses() {
  const [classList, classListLoading, classListError] = useCollection(
    collection(firestore, 'classes'),
  );

  const classListData: Array<ClassBookingData> = [];

  classList?.forEach((c) => {
    const data: any = c.data();
    data.id = c.id;
    classListData.push(data as ClassBookingData);
  });

  classListData.sort((a, b) => {
    return a.startMili - b.startMili;
  });

  return {
    classList: classListData as Array<ClassBookingData>,
    classListLoading,
    classListError,
  };
}

export default function ClassEdit() {
  const [selectedPage, setSelectedPage] = useState(0);
  const [topicsMap, setTopicsMap] = useState();
  const [coaches, setCoaches] = useState<any>();
  const [processedClassList, setProcessedClassList] = useState<
    Array<ClassBookingData>
  >([]);

  const { classList } = useClasses();
  const [loading, setLoading] = useState(false);

  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );

  const isAdmin = checkIsAdmin(userData?.email);

  useEffect(() => {
    intializeData();
    processClassList();
  }, []);

  async function intializeData() {
    const coaches = await getAllCoaches();
    const topicsList = await getAllTopics();
    const topicsMap: any = {};

    topicsList?.forEach((topic) => {
      topicsMap[topic.id] = topic;
    });

    setTopicsMap(topicsMap);
    setCoaches(coaches);
  }

  async function processClassList() {
    const classes = await getAllClasses();
    const filteredClasses = classes.filter((c: ClassBookingData) => {
      return c.recurring || c.startMili > new Date().valueOf();
    });
    let processedClasses: Array<ClassBookingData> = await Promise.all(
      filteredClasses?.map(async (c: ClassBookingData) => {
        c.startMili = returnMiliAfterCheckingIfClassHasPassed(
          c.startMili,
          c.durationMili,
        );

        let localMomentObject = moment(c.startMili)?.local();
        const date = localMomentObject.format('MMMM Do, YYYY [at] hh:mm A');
        if (c.id) {
          const topic = await getClassWeekTopic(c.id, c.startMili);
          c.title = topic;
        }

        c.dateString = date;
        c.recurringTime =
          localMomentObject.format('[Every] dddd hh:mm A') +
          localMomentObject.add(c.durationMili)?.format('- hh:mm A');
        localMomentObject.subtract(1, 'h'); // Subtract an hour again to make it normal.
        return c;
      }),
    );

    processedClasses.sort((a: any, b: any) => {
      return a.startMili - b.startMili;
    });

    setProcessedClassList(processedClasses);
  }

  return (
    <div className="">
      <LoadingOverlay enabled={loading} />
      {isAdmin ? (
        <>
          <NoBorderTabs
            tabs={['Classes', 'Topics']}
            selectedIndex={selectedPage}
            onTabClicked={(index: number) => {
              setSelectedPage(index);
            }}
          />
          {
            [
              <Classes
                classes={processedClassList}
                coaches={coaches}
                topicsMap={topicsMap}
                onUpdateFinished={async () => {
                  await processClassList();
                  setLoading(false);
                }}
                onUpdateStarted={() => {
                  setLoading(true);
                }}
              />,
              <Topics
                topics={topicsMap ?? []}
                setLoading={setLoading}
                onUpdateFinished={async () => {
                  await intializeData();
                  await processClassList();
                  setLoading(false);
                }}
                onUpdateStarted={() => {
                  setLoading(true);
                }}
              />,
            ][selectedPage]
          }
        </>
      ) : null}
    </div>
  );
}
