import mixpanel from '../../../analytics/mixpanel';

type Events =
  | 'Clicked share button on ranking container at home'
  | 'Clicked tab on the ranking container'
  | 'Clicked user profile bubble on ranking container';

type RankingContainerData = {
  clickedTab?: string;
  id?: string;
};

export const trackHomeRankingContainer = (
  event: Events,
  data: RankingContainerData,
) => {
  mixpanel.track(event, data);
};
