import { XMarkIcon } from '@heroicons/react/24/outline';
import cx from 'classix';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import closeIcon from '../../images/icons/close.svg';

const Modal = ({ isOpen, closeModal, children, className }: any) => {
  // useEffect(() => {
  //   try {
  //     document.body.style.setProperty('overflow', isOpen ? 'hidden' : 'unset');
  //     // @ts-ignore
  //     (document.querySelector('#root') as HTMLElement)?.style?.setProperty(
  //       'overflow',
  //       isOpen ? 'hidden' : 'unset',
  //     );
  //   } catch (e) {}
  // }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={cx(
        'z-40 fixed w-full h-full bg-black-light bg-opacity-40 flex justify-center items-center top-0',
        'backdrop-blur-sm py-4',
      )}
    >
      <div
        className={cx(
          'fixed bg-white shadow-2xl z-50 p-4 pt-10 rounded-md',
          'max-h-full overflow-auto',
          'max-w-[90vw] md:max-w-[75vw] lg:max-w-[50vw]',
          className,
        )}
      >
        <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => {
              closeModal?.();
            }}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className={cx('max-w-full')}>{children}</div>
      </div>
    </div>,
    document.getElementById('modal-root')!,
  );
};

export default Modal;
