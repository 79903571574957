export const SecondaryButton = (props: { text: string; onClick: Function }) => {
  const { text, onClick } = props;
  return (
    <button
      className="inline-flex w-full justify-center rounded-md border border-transparent
      px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 sm:text-sm"
      onClick={() => {
        onClick();
      }}
    >
      {text}
    </button>
  );
};
