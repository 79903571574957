import { SecondaryButton } from '../../buttons/secondary';
import { SecondaryRedButton } from '../../buttons/secondaryRedButton/secondaryRedButton';
import { SparkleButton } from '../../buttons/sparkle/sparkleButton';
import VerticalMessageModal from '../verticalMessageModal/verticalMessageModal';

export const CancelSubscriptionAreYouSureModal = (props: {
  onCancelAnyways: Function;
  onGetFree: Function;
  onClose: Function;
  isOpen: boolean;
  loading?: boolean;
}) => {
  const { onClose, onGetFree, onCancelAnyways, isOpen, loading } = props;
  return (
    <VerticalMessageModal
      mainMessage="Stick around"
      subMessage="Stay with us and get next 4 weeks of classes for free! You can cancel at any point if you still decide to leave."
      icon="exclamation"
      theme="info"
      buttonText=""
      onCloseModal={onClose}
      isOpen={isOpen}
    >
      <div className="mt-6">
        <SparkleButton
          text="Get next 4 weeks free"
          onClick={onGetFree}
          loading={loading}
        />
        <SecondaryRedButton text="Cancel anyways" onClick={onCancelAnyways} />
      </div>
    </VerticalMessageModal>
  );
};
