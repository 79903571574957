import { CSSProperties, ComponentProps, FC, useMemo, memo } from 'react';
import cx from 'classix';

import { useBrowserInfo } from '../../../hooks/useBrowserInfo';

import './select.css';
import { FieldError } from '../field-error';

export interface SelectOption {
  value: string;
  text: string;
}

interface BaseProps {
  id: string;
  label?: string;
  name?: string;
  hideLabel?: boolean;
  touched?: boolean;
  error?: string | null;
  description?: string;

  containerClassName?: string;
  containerStyle?: CSSProperties;

  labelClassName?: string;
  labelStyle?: CSSProperties;

  descriptionClassName?: string;
  descriptionStyle?: CSSProperties;

  errorClassName?: string;
  errorStyle?: CSSProperties;

  hide?: boolean;
  disabled?: boolean;
  options?: Array<SelectOption>;
  withSearch?: boolean;
  defaultOptionText?: boolean;
  optional?: boolean;
}

type SelectProps = { withSearch?: false } & ComponentProps<'select'> &
  BaseProps;
type DataListProps = { withSearch?: true } & ComponentProps<'input'> &
  BaseProps;

interface FinalSelectProps
  extends Omit<SelectProps | DataListProps, 'onChange'> {
  onChange?: (value: string, index: number) => void;
}

const SelectComponent: FC<FinalSelectProps> = (props) => {
  const browserInfo = useBrowserInfo();

  const {
    id,
    name,
    options = [],
    hideLabel,
    label,
    labelClassName,
    labelStyle,
    description,
    descriptionClassName,
    descriptionStyle,
    touched,
    error,
    errorClassName,
    errorStyle,
    hide,
    disabled,
    value,
    withSearch: propWithSearch,
    defaultOptionText,
    className,
    style,
    onChange,
    required = true,
    optional = !required,
    containerClassName,
    containerStyle,
    ...otherProps
  } = props;

  const withSearch = useMemo<boolean>(() => {
    return Boolean(propWithSearch) && browserInfo?.name !== 'Edge';
  }, [propWithSearch, browserInfo]);

  const validValue = useMemo<boolean>(() => {
    return typeof value !== 'undefined' && value !== null && Boolean(value);
  }, [value]);

  const Component = withSearch ? 'datalist' : 'select';

  if (!options || !options.length) return null;
  return (
    <div
      className={cx(
        'flex flex-col',
        containerClassName,
        hide && 'hidden select-none pointer-events-none',
      )}
      style={containerStyle}
    >
      <label
        htmlFor={id || name}
        className={cx(
          'block text-sm font-medium leading-6 text-gray-900',
          hideLabel && 'hidden select-none pointer-events-none',
          labelClassName,
        )}
        style={labelStyle}
      >
        {label}
        {!required || optional ? (
          <>
            {' '}
            <span className={cx('opacity-75 select-none')}>(optional)</span>
          </>
        ) : null}
      </label>
      {description ? (
        <p
          className={cx('opacity-70', descriptionClassName)}
          style={descriptionStyle}
        >
          {description}
        </p>
      ) : null}
      {withSearch ? (
        <>
          {/* @ts-ignore */}
          <input
            id={id || name}
            name={id || name}
            list={`${id || name}-list`}
            className={cx(
              'w-full px-4 py-1 border rounded-md focus:shadow',
              className,
            )}
            disabled={disabled || hide}
            value={value || ''}
            onChange={(e) => {
              props.onChange?.(e.target.value, -1);
            }}
            required={required || !optional}
            {...otherProps}
          />
        </>
      ) : null}
      {/* @ts-ignore */}
      <Component
        id={`${id || name}${withSearch ? '-list' : ''}`}
        name={`${id || name}${withSearch ? '-list' : ''}`}
        className={cx(
          'bg-white mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-immigo sm:text-sm sm:leading-6',
          !validValue || !props.value ? 'text-opacity-80' : '',
          className,
        )}
        disabled={disabled || hide}
        onChange={(e) => {
          props.onChange?.(
            e.target.value,
            options.findIndex((opt) => {
              return opt.value === e.target.value;
            }),
          );
        }}
        value={value || ''}
        required={required || !optional}
        {...otherProps}
      >
        <option value={''} disabled>
          {defaultOptionText || 'Choose an option…'}
        </option>
        {(options || []).map((option) => {
          return (
            <option
              key={option.value}
              value={withSearch ? option.text : option.value}
            >
              {withSearch ? null : option.text}
            </option>
          );
        })}
      </Component>
      <FieldError
        touched={touched || !validValue}
        error={error}
        className={errorClassName}
        style={errorStyle}
      />
    </div>
  );
};

export const Select = memo(SelectComponent);
