import { useEffect, useState } from 'react';
import { UserData } from '../../../types/user';
import { returnUserByUID } from '../../../firebase/configuration';
import { capitalizeEveryFirstLetter } from '../../../util/standardization';
import { getFirstNameFromFullName } from '../../../util/helper';
import { getUserBookingsAttended } from '../../../firebase/subscription/bookings/bookings';
import { SparklesIcon } from '@heroicons/react/20/solid';
import { flattenDeep } from 'lodash';
import { trackProfileOverviewEventts } from './analytics';
import VerticalMessageModal from '../../../components/modals/verticalMessageModal/verticalMessageModal';
import {
  addPointHistoryToUser,
  redeemPointsToGetClass,
} from '../../../firebase/points/points';
import { useSelector } from 'react-redux';
import { Avatar } from '../../../components/avatar/avatar';
import ProgressBar from '@ramonak/react-progress-bar';
import { LoadingOverlay } from '../../../components/loading';
import { LevelProgress } from '../../../components/progress/levelProgress';
import {
  cefrToColorStyle,
  getCurrentCefrBasedOnAttendance,
  getCurrentCefrLevelAndLevelPair,
  getCurrentLevelIndexBasedOnAttendance,
  levelToStyle,
} from '../../../util/cefr';
import cx from 'classix';
import { CefrLevelPair, CEFRLevel } from '../../../types/level';

export const ProfileOverview = (props: {
  uid: string;
  disableLevel?: boolean;
}) => {
  const { uid, disableLevel } = props;
  const [user, setUser] = useState<UserData | undefined>();
  const [currentCefrLevelPair, setCurrentCefrLevelPair] = useState<
    CefrLevelPair | undefined
  >();
  const [openHowToEarnModal, sertOpenHowToEarnModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );

  async function onLoad() {
    const bookings = await getUserBookingsAttended(uid);
    const phrases = flattenDeep(bookings.map((b) => b.phrases));

    if (userData) {
      if (bookings) userData.attendedBookings = bookings;
      userData.phrasesLearned = phrases;
    }
    setUser(userData);
  }

  useEffect(() => {
    if (uid) onLoad();
  }, [uid]);

  async function onHowToEarnPointsClick() {
    trackProfileOverviewEventts('Clicked what are immigots profile overview');
    sertOpenHowToEarnModal(true);
  }

  async function claimFreeClass() {
    setLoading(true);
    trackProfileOverviewEventts(
      'Clicked claim free class button profile overview',
    );
    const history = await redeemPointsToGetClass(uid);
    setLoading(false);
  }

  useEffect(() => {
    if (user?.cefrLevel && user?.attendedBookings) {
      const newLevel = getCurrentCefrLevelAndLevelPair(
        user.cefrLevel as CEFRLevel,
        user.attendedBookings?.length ?? 0,
      );
      setCurrentCefrLevelPair(newLevel);
    }
  }, [user]);

  return (
    <div
      id="profile-overview-container"
      className="rounded-lg shadow w-full py-6 px-4 relative lg:mb-2 bg-gradient-to-r from-indigo-400 to-cyan-400 text-white"
    >
      <LoadingOverlay enabled={loading} />
      <VerticalMessageModal
        mainMessage="⭐ Introducing Immigots"
        subMessage=""
        isOpen={openHowToEarnModal}
        theme="info"
        icon="star"
        buttonText="Got it!"
        onCloseModal={() => {
          sertOpenHowToEarnModal(false);
        }}
      >
        <div>
          <div className="text-gray-700">
            ⭐ Immigots are basically like points you can redeem to get free
            classes. Here's how you'd be able to earn them!
          </div>
          <div className="mt-4 text-gray-600">
            <div>1. Attended classes</div>
            <div>2. Do your preview, review, and warmup on the platform</div>
            <div>3. Buy extra classes</div>
            <div>4. Many more ways to earn Immigots coming soon!</div>
          </div>
        </div>
      </VerticalMessageModal>
      {user ? (
        <div
          className={cx(
            'flex pb-6 items-center xl:flex-row flex-col',
            !disableLevel ? 'border-b' : '',
          )}
        >
          <div className="flex flex-col items-center">
            <Avatar
              photoUrl={user?.profilePictureLink}
              className="xl:w-32 xl:h-32 w-16 h-16 xl:mb-4 mb-2"
            />
            {currentCefrLevelPair && !disableLevel ? (
              <div className="flex items-center w-full justify-center mt-2">
                <div
                  className={cx(
                    'mr-1 px-2 py-1 text-xs font-medium inline-flex items-center rounded-md ',
                    cefrToColorStyle[currentCefrLevelPair.cefr],
                  )}
                >
                  {currentCefrLevelPair.cefr}
                </div>
                <div
                  className={cx(
                    'px-2 py-1 text-xs font-medium inline-flex items-center rounded-md',
                    levelToStyle[currentCefrLevelPair.level],
                  )}
                >
                  {`Level ${currentCefrLevelPair.level}`}
                </div>
              </div>
            ) : null}
          </div>
          <div className="ml-4">
            {/* <div className="text-2xl font-semibold">
              {user
                ? `Hi, ${capitalizeEveryFirstLetter(
                    getFirstNameFromFullName(user?.name),
                  )}! 👋 `
                : null}
            </div> */}
            <div className="text-4xl font-semibold md:text-left text-center">
              Welcome,{' '}
              {capitalizeEveryFirstLetter(getFirstNameFromFullName(user?.name))}
              !
            </div>
            {disableLevel ? (
              <div>
                <div className="mt-4 w-full md:text-md text-sm">
                  {/* <div className="">{`Classes attended: ${user?.bookings?.length}`}</div>
                <div className="">
                  {`Phrases learned: ${user?.phrasesLearned?.length}`}
                </div> */}
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={onHowToEarnPointsClick}
                  >
                    {`Earned Immigots: ${userData?.points || 0}P`}
                    <div className="bg-purple-400 font-semibold md:ml-4 ml-auto rounded-md p-1 text-xs flex items-center hover:bg-purple-300">
                      <SparklesIcon className="w-3 h-3 text-white mr-2" />
                      What are Immigots?
                    </div>
                  </div>
                </div>

                <div className="mt-4 flex items-center">
                  <ProgressBar
                    completed={((userData?.points ?? 0) / 100) * 100}
                    barContainerClassName="rounded-md bg-gray-200 w-36"
                    labelAlignment={'left'}
                    width="300"
                    bgColor="#FF6700"
                    labelClassName="hidden"
                  />
                  {(userData?.points ?? 0) < 100 ? (
                    <span className="ml-2 text-white text-sm">{`${
                      userData?.points ?? 0
                    }/${100} until free class`}</span>
                  ) : (
                    <div
                      className="ml-2 bg-yellow-400 hover:bg-yellow-300 rounded-xl p-1 cursor-pointer"
                      onClick={claimFreeClass}
                    >
                      Claim free class
                    </div>
                  )}
                </div>
              </div>
            ) : null}
            {user.cefrLevel && user.attendedBookings && !disableLevel ? (
              <div className="pl-1">
                <LevelProgress
                  cefrLevel={user.cefrLevel as CEFRLevel}
                  completedLessons={user.attendedBookings?.length ?? 0}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {!disableLevel ? (
        <div>
          <div className="mt-4 w-full md:text-md text-sm">
            {/* <div className="">{`Classes attended: ${user?.bookings?.length}`}</div>
        <div className="">
          {`Phrases learned: ${user?.phrasesLearned?.length}`}
        </div> */}
            <div
              className="flex items-center cursor-pointer"
              onClick={onHowToEarnPointsClick}
            >
              {`Earned Immigots: ${userData?.points || 0}P`}
              <div className="bg-purple-400 font-semibold md:ml-4 ml-auto rounded-md p-1 text-xs flex items-center hover:bg-purple-300">
                <SparklesIcon className="w-3 h-3 text-white mr-2" />
                What are Immigots?
              </div>
            </div>
          </div>

          <div className="mt-4 flex items-center">
            <ProgressBar
              completed={((userData?.points ?? 0) / 100) * 100}
              barContainerClassName="rounded-md bg-gray-200 w-36"
              labelAlignment={'left'}
              width="300"
              bgColor="#FF6700"
              labelClassName="hidden"
            />
            {(userData?.points ?? 0) < 100 ? (
              <span className="ml-2 text-white text-sm">{`${
                userData?.points ?? 0
              }/${100} until free class`}</span>
            ) : (
              <div
                className="ml-2 bg-yellow-400 hover:bg-yellow-300 rounded-xl p-1 cursor-pointer"
                onClick={claimFreeClass}
              >
                Claim free class
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};
