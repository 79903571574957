import { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import CardPaymentForm from '../../components/forms/payment/card';
import LoginRegisterBox from '../../components/forms/loginregister/index';
import { LoadingOverlay } from '../../components/loading';
import { SubscriptionInformation } from '../../components/profile/subscriptioninformation';
import { UserData } from '../../types/user';

export const Subscription = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const userData = useSelector(
    (state: any) => (state?.sessionState?.userData ?? {}) as UserData,
  );

  const priceID = window.location.pathname.split('/')[2];

  function onLogInSuccess() {
    window.location.reload();
  }

  function onLogInFail() {
    setSubmitting(false);
  }

  function onSignInClick() {
    setSubmitting(true);
  }

  function onRegisterClick() {
    setSubmitting(true);
  }

  return (
    <div className="min-h-screen">
      <LoadingOverlay enabled={submitting} />
      <div className="r">
        {userData ? (
          <SubscriptionInformation
            onAction={() => {
              setSubmitting(true);
            }}
            onActionComplete={() => {
              setSubmitting(false);
            }}
            userData={userData}
          />
        ) : (
          <LoginRegisterBox onLoginSuccess={onLogInSuccess} defaultRegister />
        )}
      </div>
    </div>
  );
};
