import { useEffect, useState } from 'react';
import VerticalMessageModal from '../../../../components/modals/verticalMessageModal/verticalMessageModal';
import { useSelector } from 'react-redux';
import { UserData } from '../../../../types/user';
import { CheckIcon } from '@heroicons/react/24/outline';
import { LoadingCircle } from '../../../../components/icons/loadingCircle';
import { createUserReferralCode } from '../../../../api/social';
import { trackReferralContainer } from './analytics';
import { getReferredUsers } from '../../../../firebase/social/social';

export const ReferFriendContainer = (props: {}) => {
  const [learnMore, setLearnMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [user, setUser] = useState<undefined | UserData>();
  const [referred, setReferred] = useState<Array<any>>([]);

  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );

  async function onUserLoaded(newUserData: UserData) {
    if (newUserData.referralCode) {
      const referredUsers = await getReferredUsers(newUserData.referralCode);
      setReferred(referredUsers);
    }
    setUser(newUserData);
  }

  function onCopy(link: string) {
    trackReferralContainer('Copied referral code');
    navigator.clipboard.writeText(link);
    setCopied(true);
  }

  async function onGenerate(uid: string) {
    setLoading(true);
    const referralCode = await createUserReferralCode(uid);
    trackReferralContainer('Generated referral code');
    if (user) {
      const newUserData: UserData = { ...user } as UserData;
      newUserData.referralCode = referralCode;
      onUserLoaded(newUserData);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (userData) onUserLoaded(userData);
  }, [userData]);

  return (
    <>
      <VerticalMessageModal
        icon="star"
        theme="default"
        mainMessage="Immigo Referral Program"
        subMessage=""
        isOpen={learnMore}
        onCloseModal={() => {
          setLearnMore(false);
        }}
      >
        <div>
          <div>
            1. Every friend you refer will get{' '}
            <span className="text-blue-immigo">⭐300</span> Immigots
          </div>
          <div>
            2. You will also get <span className="text-blue-immigo">⭐300</span>{' '}
            Immigots for every friend you refer
          </div>
          <div className="mt-4 mb-2 font-semibold">More rewards:</div>
          <div>
            - Refer 3 friends to get extra{' '}
            <span className="text-blue-immigo">⭐1000</span> Immigots
          </div>
          <div>
            - Refer 5 friends to get extra{' '}
            <span className="text-blue-immigo">⭐3000</span> Immigots
          </div>
          <div>
            - Refer 10 friends to get{' '}
            <span className="text-blue-immigo">1 year</span> of Immigo
            Subscription for free.
          </div>
        </div>
      </VerticalMessageModal>
      <div
        onClick={() => {
          trackReferralContainer('Clicked inside referral container');
        }}
        className="bg-gradient-to-r from-amber-200 to-yellow-500 rounded-md p-4 text-center mb-2 mx-1"
      >
        <div className="font-semibold text-4xl text-white max-w-[300px] mx-auto">
          Refer your friend Get FREE classes
        </div>
        <div className="text-white my-6">
          Click{' '}
          <span
            className="text-blue-immigo cursor-pointer hover:text-blue-immigo-lighter"
            onClick={() => {
              trackReferralContainer('Clicked see more in referral container');
              setLearnMore(true);
            }}
          >
            here
          </span>{' '}
          to learn more.
        </div>
        {user ? (
          user?.referralCode ? (
            <div>
              <div className="flex justify-center">
                <div
                  className="bg-gradient-to-r from-slate-500 to-slate-800
                        px-4 py-2 rounded-l-md"
                >
                  <span
                    className="
                            bg-gradient-to-r from-amber-200 to-yellow-500 
                            inline-block text-transparent bg-clip-text"
                  >
                    {`immigo.io?ref=${user.referralCode}`}
                  </span>
                </div>
                <div
                  className="rounded-r-md flex bg-blue-immigo 
                        text-black hover:bg-blue-immigo-lighter cursor-pointer justify-center items-center px-2 text-white"
                  onClick={() => {
                    onCopy(`immigo.io?ref=${user.referralCode}`);
                  }}
                >
                  {copied ? (
                    <span className="flex items-center">
                      <CheckIcon className="w-5 h-5 mr-1" /> Copied!
                    </span>
                  ) : (
                    <span>Copy link</span>
                  )}
                </div>
              </div>
              <div className="text-white mt-2">{`My referral count: ${referred.length}`}</div>
            </div>
          ) : (
            <div className="w-full flex items-center justify-center my-6">
              <div
                className="text-md
                    bg-gradient-to-r from-slate-500 to-slate-800
                    text-white inline px-8 py-4 rounded-md border border-yellow-500 cursor-pointer hover:from-slate-400 hover:to-slate-700"
              >
                <span
                  className="
                        bg-gradient-to-r from-amber-200 to-yellow-500 
                        inline-block text-transparent bg-clip-text flex items-center"
                >
                  {loading ? (
                    <>
                      <LoadingCircle className="w-5 h-5 text-white mr-2" />
                      <div>Generating referral link</div>
                    </>
                  ) : (
                    <div
                      onClick={async () => {
                        if (!loading && !userData?.referralCode)
                          await onGenerate(user.id);
                      }}
                    >
                      Generate referral link
                    </div>
                  )}
                </span>
              </div>
            </div>
          )
        ) : (
          'Loading'
        )}
      </div>
    </>
  );
};
