import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useEffect, useState } from 'react';
import {
  registerWithGoogle,
  sendPasswordResetEmailHelper,
} from '../../../../firebase/auth/auth';
import { validateEmail } from '../../../../util/validation';
import GreenAlert from '../../../alerts/greenAlert';
import RedAlert from '../../../alerts/redAlert';
import { PrimaryButton } from '../../../buttons/primary';

interface ForgotPasswordProps {
  hideHeader?: boolean;
  onLogIn: Function;
}

export default function ForgotPassword({
  onLogIn,
  hideHeader,
}: ForgotPasswordProps) {
  const [email, setEmail] = useState('');
  const [errorText, setErrorText] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setErrorText('');
  }, [email]);

  async function onSendPWDResetInstruction() {
    if (!validateEmail(email)) {
      setErrorText('Please enter a valid email');
    } else {
      let error = await sendPasswordResetEmailHelper(email);
      console.log(error);
      if (error) {
        setErrorText(error);
      } else {
        setSuccess(true);
      }
    }
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center sm:px-6 lg:px-8">
        {!hideHeader ? (
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="md:text-center md:text-2xl text-xl font-bold leading-9 tracking-tight text-gray-900">
              Reset your password
            </h2>
          </div>
        ) : null}
        <div className="md:mt-10 mt-5 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            {success ? (
              <div className="mb-4">
                <GreenAlert
                  text={`Your password reset instruction was sent to ${email}. Please allow at least 10 minutes for the email to arrive and remember to check your spam!`}
                />
              </div>
            ) : null}
            {errorText.length > 0 ? (
              <div className="mb-4">
                <RedAlert text={errorText} />
              </div>
            ) : null}
            <form
              className="space-y-6"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-immigo sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <PrimaryButton
                  text="Send reset instructions"
                  onClick={async () => {
                    await onSendPWDResetInstruction();
                  }}
                />
                <div
                  className="text-center text-gray-500 text-sm mt-4 flex mx-auto w-fit hover:text-gray-400 cursor-pointer"
                  onClick={() => {
                    onLogIn();
                  }}
                >
                  <ArrowLeftIcon className="w-5 h-5 mr-2" />
                  <span>Back to log in</span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
