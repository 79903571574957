import ContentLoader from 'react-content-loader';

export const UpcomingClassContainerLoader = () => {
  return (
    <ContentLoader viewBox="0 0 380 230">
      <rect x="0" y="2" rx="4" ry="4" width="300" height="20" />
      <rect x="0" y="30" rx="3" ry="3" width="250" height="10" />
      <rect x="0" y="50" rx="3" ry="3" width="250" height="10" />
      <rect x="0" y="82" rx="4" ry="4" width="300" height="20" />
      <rect x="0" y="110" rx="3" ry="3" width="250" height="10" />
      <rect x="0" y="130" rx="3" ry="3" width="250" height="10" />
      <rect x="0" y="162" rx="4" ry="4" width="300" height="20" />
      <rect x="0" y="190" rx="3" ry="3" width="250" height="10" />
      <rect x="0" y="210" rx="3" ry="3" width="250" height="10" />
    </ContentLoader>
  );
};
