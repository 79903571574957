import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  updateDoc,
} from 'firebase/firestore';
import { initializeFirebase } from '../configValues';
import { SurveyObject, SurveyObjectEntries } from '../../types/survey';
import { removeUndefinedProperties } from '../../util/data';

const { firestore: db } = initializeFirebase();

export async function addSurveySubmittedToUser(
  userID: string,
  surveyID: string,
) {
  const userRef = doc(db, 'users', userID);
  const res = await updateDoc(userRef, {
    surveys: arrayUnion(surveyID),
  });
  return res;
}

export async function createSurveyDocument(
  submittedBy: string,
  data: SurveyObjectEntries,
) {
  const payload: SurveyObject = {
    createdAt: new Date().valueOf(),
    submittedBy,
    ...data,
  };
  const survey = await addDoc(
    collection(db, 'survey'),
    removeUndefinedProperties(payload as any),
  );
  await addSurveySubmittedToUser(submittedBy, survey.id);
  return survey.id;
}
