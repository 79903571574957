import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import { initializeFirebase } from '../configValues';
const { firestore: db } = initializeFirebase();

export const getUserHooks = async (userID: string) => {
  try {
    const hooksRef = await collection(db, 'users', userID, 'hooks');
    const hooksDocs = await getDocs(hooksRef);
    const hooks: any[] = [];
    hooksDocs.forEach((b) => {
      const data = b.data();
      data.id = b.id;
      hooks.push(data);
    });
    return hooks;
  } catch (err) {
    console.log(err);
  }
};

export const getPendingHooks = async (userID: string, type?: string) => {
  try {
    const hooks = (await getUserHooks(userID))?.filter(
      (h) => h.status === 'pending',
    );

    return type ? hooks?.filter((h) => h.type === type) : hooks;
  } catch (err) {
    console.log(err);
  }
};

export const getNextPendingHook = async (userID: string, type?: string) => {
  try {
    const hooks = (await getPendingHooks(userID, type))?.sort(
      (h1, h2) => h1.postAt - h2.postAt,
    );
    return hooks?.[0];
  } catch (err) {
    console.log(err);
  }
};
