import { SparklesIcon } from '@heroicons/react/20/solid';
import { LoadingCircle } from '../../icons/loadingCircle';

export const SparkleButton = (props: {
  text: string;
  onClick: Function;
  loading?: boolean;
}) => {
  const { text, onClick, loading } = props;
  return (
    <button
      className="flex shadow justify-center w-full rounded-md hover:bg-[#b18def] bg-[#772ff4] px-3 py-2 text-white font-semibold text-sm"
      onClick={() => {
        onClick();
      }}
      disabled={loading}
    >
      <SparklesIcon className="w-5 h-5 mr-2" />
      {loading ? <LoadingCircle className="mx-2" /> : null}
      {text}
    </button>
  );
};
