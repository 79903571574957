export const oneDollarPerClass = {
  currency: ['usd', 'cop', 'brl', 'mxn'],
  // Monthly
  price_1OXXP0IJPWWhjYyo8rfijEnr: {
    coupon: 'KCfiHQ77',
    amount: {
      usd: 55,
      cop: 216011.5,
      brl: 268.4,
      mxn: 933.29,
    },
  }, // 4 class prod
  price_1OXYMRIJPWWhjYyobpbeOrFT: {
    coupon: 'VlBYUQBG',
    amount: {
      usd: 55,
      cop: 216011.5,
      brl: 268.4,
      mxn: 933.29,
    },
  }, // 4 class dev
  price_1OXvvFIJPWWhjYyotHNqtU7M: {
    coupon: 'gVU1AtoS',
    amount: {
      usd: 81,
      cop: 318082,
      brl: 395.72,
      mxn: 1374.42,
    },
  }, // 8 class prod
  price_1OXYqTIJPWWhjYyoQbqLP0yo: {
    coupon: 'NHaLqEwy',
    amount: {
      usd: 81,
      cop: 318082,
      brl: 395.72,
      mxn: 1374.42,
    },
  }, // 8 class dev
  price_1OXvxpIJPWWhjYyoLdVq4Wap: {
    coupon: 'ZmLM24uu',
    amount: {
      usd: 107,
      cop: 420152,
      brl: 522.25,
      mxn: 1815.54,
    },
  }, // 12 class prod
  price_1OXYyoIJPWWhjYyoqZ6iMJSR: {
    coupon: 'YJF2FBZ3',
    amount: {
      usd: 107,
      cop: 420152,
      brl: 522.25,
      mxn: 1815.54,
    },
  }, // 12 class dev

  // Yearly
  price_1OXvtMIJPWWhjYyofgItQ62U: {
    coupon: 'tLE5twSv',
    amount: {
      usd: 337.9,
      cop: 1326602.1,
      brl: 1648.78,
      mxn: 5726.74,
    },
  }, // 4 prod
  price_1OXYwGIJPWWhjYyouUdR9snX: {
    coupon: 'zpaYUoCl',
    amount: {
      usd: 337.9,
      cop: 1326602.1,
      brl: 1648.78,
      mxn: 5726.74,
    },
  }, // 4 dev
  price_1OXvwlIJPWWhjYyolZtLq4TV: {
    coupon: 'hqMBWAWV',
    amount: {
      usd: 483.9,
      cop: 1899228.1,
      brl: 2361.79,
      mxn: 8200.91,
    },
  }, // 8 prod
  price_1OXYuXIJPWWhjYyoGjtn1gps: {
    coupon: 'wEe04smq',
    amount: {
      usd: 483.9,
      cop: 1899228.1,
      brl: 2361.79,
      mxn: 8200.91,
    },
  }, // 8 dev
  price_1OXvz2IJPWWhjYyo3gqZqFJZ: {
    coupon: 'SGS4Vj55',
    amount: {
      usd: 626.5,
      cop: 2458595.5,
      brl: 3058.21,
      mxn: 10619.44,
    },
  }, // 12 prod
  price_1OXYzpIJPWWhjYyoPp2S5J9f: {
    coupon: 'CK0LSUQq',
    amount: {
      usd: 626.5,
      cop: 2458596.5,
      brl: 3058.21,
      mxn: 10619.0,
    },
  }, // 12 dev
};

export const threeDollarPerClass = {
  currency: ['usd', 'brl', 'mxn'],

  // Monthly
  price_1OXXP0IJPWWhjYyo8rfijEnr: {
    coupon: 'M8MIGxow',
    amount: {
      usd: 47,
      brl: 229.19,
      mxn: 797.29,
    },
  }, // 4 class prod
  price_1OXYMRIJPWWhjYyobpbeOrFT: {
    coupon: 'a7RJOePt',
    amount: {
      usd: 47,
      brl: 229.19,
      mxn: 797.29,
    },
  }, // 4 class dev
  price_1OXvvFIJPWWhjYyotHNqtU7M: {
    coupon: 'ZiLvd2iY',
    amount: {
      usd: 65,
      brl: 316.72,
      mxn: 1102.41,
    },
  }, // 8 class prod
  price_1OXYqTIJPWWhjYyoQbqLP0yo: {
    coupon: '5x6tloKH',
    amount: {
      usd: 65,
      brl: 316.72,
      mxn: 1102.41,
    },
  }, // 8 class dev
  price_1OXvxpIJPWWhjYyoLdVq4Wap: {
    coupon: 'xWWZ7foT',
    amount: {
      usd: 83,
      brl: 405.25,
      mxn: 1407.54,
    },
  }, // 12 class prod
  price_1OXYyoIJPWWhjYyoqZ6iMJSR: {
    coupon: 'mb1gPLlN',
    amount: {
      usd: 83,
      brl: 405.25,
      mxn: 1407.54,
    },
  }, // 12 class dev

  // Yearly
  price_1OXvtMIJPWWhjYyofgItQ62U: {
    coupon: 'rF7xsXUD',
    amount: {
      usd: 235.91,
      brl: 1180.9,
      mxn: 4099.04,
    },
  }, // 4 prod
  price_1OXYwGIJPWWhjYyouUdR9snX: {
    coupon: '4sUpNDPL',
    amount: {
      usd: 235.91,
      brl: 1180.9,
      mxn: 4099.04,
    },
  }, // 4 dev
  price_1OXvwlIJPWWhjYyolZtLq4TV: {
    coupon: '3FaWnQVZ',
    amount: {
      usd: 295.91,
      brl: 1530.47,
      mxn: 5312.38,
    },
  }, // 8 prod
  price_1OXYuXIJPWWhjYyoGjtn1gps: {
    coupon: 'xLTYioer',
    amount: {
      usd: 295.91,
      brl: 1530.47,
      mxn: 5312.38,
    },
  }, // 8 dev
  price_1OXvz2IJPWWhjYyo3gqZqFJZ: {
    coupon: 'vkVJ71Nu',
    amount: {
      usd: 375.51,
      brl: 1965.44,
      mxn: 6838.01,
    },
  }, // 12 prod
  price_1OXYzpIJPWWhjYyoPp2S5J9f: {
    coupon: 'wedPeAg8',
    amount: {
      usd: 375.51,
      brl: 1965.44,
      mxn: 6838.01,
    },
  }, // 12 dev
};

// 2 dollar really $1.99
export const threeDollarPerClassAnnualTwoDollars = {
  currency: ['usd', 'brl', 'mxn'],

  // Monthly
  price_1OXXP0IJPWWhjYyo8rfijEnr: {
    coupon: 'M8MIGxow',
    amount: {
      usd: 47,
      brl: 229.19,
      mxn: 797.29,
    },
  }, // 4 class prod
  price_1OXYMRIJPWWhjYyobpbeOrFT: {
    coupon: 'a7RJOePt',
    amount: {
      usd: 47,
      brl: 229.19,
      mxn: 797.29,
    },
  }, // 4 class dev
  price_1OXvvFIJPWWhjYyotHNqtU7M: {
    coupon: 'ZiLvd2iY',
    amount: {
      usd: 65,
      brl: 316.72,
      mxn: 1102.41,
    },
  }, // 8 class prod
  price_1OXYqTIJPWWhjYyoQbqLP0yo: {
    coupon: '5x6tloKH',
    amount: {
      usd: 65,
      brl: 316.72,
      mxn: 1102.41,
    },
  }, // 8 class dev
  price_1OXvxpIJPWWhjYyoLdVq4Wap: {
    coupon: 'xWWZ7foT',
    amount: {
      usd: 83,
      brl: 405.25,
      mxn: 1407.54,
    },
  }, // 12 class prod
  price_1OXYyoIJPWWhjYyoqZ6iMJSR: {
    coupon: 'mb1gPLlN',
    amount: {
      usd: 83,
      brl: 405.25,
      mxn: 1407.54,
    },
  }, // 12 class dev

  price_1POp2mIJPWWhjYyoCD6YCm2J: {
    coupon: 'mfQ8HKFR',
    amount: {
      usd: 101,
      brl: 492.79,
      mxn: 1712.66,
    },
  }, // 16 class prod
  price_1POmoxIJPWWhjYyoVPRFoPxs: {
    coupon: 'PIPIStKO',
    amount: {
      usd: 101,
      brl: 492.79,
      mxn: 1712.66,
    },
  }, // 16 class dev

  // Yearly
  price_1OXvtMIJPWWhjYyofgItQ62U: {
    coupon: '6sikE3Xv',
    amount: {
      usd: 288.41,
      brl: 1412.9,
      mxn: 4912.04,
    },
  }, // 4 prod
  price_1OXYwGIJPWWhjYyouUdR9snX: {
    coupon: 'WdOVzwWP',
    amount: {
      usd: 288.41,
      brl: 1412.9,
      mxn: 4912.04,
    },
  }, // 4 dev
  price_1OXvwlIJPWWhjYyolZtLq4TV: {
    coupon: 'ZNIAUieV',
    amount: {
      usd: 386.91,
      brl: 1892.47,
      mxn: 6572.38,
    },
  }, // 8 prod
  price_1OXYuXIJPWWhjYyoGjtn1gps: {
    coupon: 'KhlbD5lh',
    amount: {
      usd: 386.91,
      brl: 1892.47,
      mxn: 6572.38,
    },
  }, // 8 dev
  price_1OXvz2IJPWWhjYyo3gqZqFJZ: {
    coupon: 'cLBDWhR2',
    amount: {
      usd: 481.01,
      brl: 2354.44,
      mxn: 8179.01,
    },
  }, // 12 prod
  price_1OXYzpIJPWWhjYyoPp2S5J9f: {
    coupon: '6SrWhPsv',
    amount: {
      usd: 481.01,
      brl: 2354.44,
      mxn: 8179.01,
    },
  }, // 12 dev

  price_1POp2mIJPWWhjYyor5xXau8X: {
    coupon: 'gzdNueIu',
    amount: {
      usd: 483.91,
      brl: 2417.79,
      mxn: 8432.62,
    },
  }, // 16 prod
  price_1POn6LIJPWWhjYyoivqS2TEd: {
    coupon: 'KMgH1Gbo',
    amount: {
      usd: 483.91,
      brl: 2417.79,
      mxn: 8432.62,
    },
  }, // 16 dev
};

export const fiveDollarPerClassAnnualThreeDollars = {
  currency: ['usd', 'brl', 'mxn'],

  // Monthly
  price_1OXXP0IJPWWhjYyo8rfijEnr: {
    coupon: 'HpFtF65B',
    amount: {
      usd: 39,
      brl: 176.19,
      mxn: 376.29,
    },
  }, // 4 class prod
  price_1OXYMRIJPWWhjYyobpbeOrFT: {
    coupon: 'phGvkIFe',
    amount: {
      usd: 39,
      brl: 176.19,
      mxn: 376.29,
    },
  }, // 4 class dev
  price_1OXvvFIJPWWhjYyotHNqtU7M: {
    coupon: '4iVO27i1',
    amount: {
      usd: 49,
      brl: 209.72,
      mxn: 757.42,
    },
  }, // 8 class prod
  price_1OXYqTIJPWWhjYyoQbqLP0yo: {
    coupon: 'JQYF9PZf',
    amount: {
      usd: 49,
      brl: 209.72,
      mxn: 757.42,
    },
  }, // 8 class dev
  price_1OXvxpIJPWWhjYyoLdVq4Wap: {
    coupon: 'U8buusg7',
    amount: {
      usd: 59,
      brl: 244.25,
      mxn: 891.49,
    },
  }, // 12 class prod
  price_1OXYyoIJPWWhjYyoqZ6iMJSR: {
    coupon: 'WEaAWZ9n',
    amount: {
      usd: 59,
      brl: 244.25,
      mxn: 891.49,
    },
  }, // 12 class dev

  price_1POp2mIJPWWhjYyoCD6YCm2J: {
    coupon: 'L61ReEhq',
    amount: {
      usd: 69,
      brl: 278.79,
      mxn: 1023.65,
    },
  }, // 16 class prod
  price_1POmoxIJPWWhjYyoVPRFoPxs: {
    coupon: '1Y1fidcp',
    amount: {
      usd: 69,
      brl: 278.79,
      mxn: 1023.65,
    },
  }, // 16 class dev

  // Yearly
  price_1OXvtMIJPWWhjYyofgItQ62U: {
    coupon: 'AtFCbAEU',
    amount: {
      usd: 235,
      brl: 999.78,
      mxn: 3629.24,
    },
  }, // 4 prod
  price_1OXYwGIJPWWhjYyouUdR9snX: {
    coupon: 'uyH3KqES',
    amount: {
      usd: 235,
      brl: 999.78,
      mxn: 3629.24,
    },
  }, // 4 dev
  price_1OXvwlIJPWWhjYyolZtLq4TV: {
    coupon: 'wHNMs8gh',
    amount: {
      usd: 278,
      brl: 1063.79,
      mxn: 4005.91,
    },
  }, // 8 prod
  price_1OXYuXIJPWWhjYyoGjtn1gps: {
    coupon: 'oVShS4EB',
    amount: {
      usd: 278,
      brl: 1063.79,
      mxn: 4005.91,
    },
  }, // 8 dev
  price_1OXvz2IJPWWhjYyo3gqZqFJZ: {
    coupon: 'ChjmzzYs',
    amount: {
      usd: 318,
      brl: 1111.21,
      mxn: 4324.94,
    },
  }, // 12 prod
  price_1OXYzpIJPWWhjYyoPp2S5J9f: {
    coupon: 'SwPG5y5q',
    amount: {
      usd: 318,
      brl: 1111.21,
      mxn: 4324.94,
    },
  }, // 12 dev

  price_1POp2mIJPWWhjYyor5xXau8X: {
    coupon: 'Dq75EIK7',
    amount: {
      usd: 277,
      brl: 763.79,
      mxn: 3286.62,
    },
  }, // 16 prod
  price_1POn6LIJPWWhjYyoivqS2TEd: {
    coupon: 'Dw1plVtk',
    amount: {
      usd: 277,
      brl: 763.79,
      mxn: 3286.62,
    },
  }, // 16 dev
};

// Master list
export const masterCouponList: any = [
  oneDollarPerClass,
  threeDollarPerClass,
  threeDollarPerClassAnnualTwoDollars,
];

export function findDiscountAmountWithID(couponID: string, currency: string) {
  let amount = 0;
  masterCouponList.forEach((p: any) => {
    Object.keys(p).forEach((c: any) => {
      if (p[c].coupon === couponID) amount = p[c].amount?.[currency];
    });
  });
  return amount;
}
