import { CSSProperties, FC } from 'react';

interface TableContainerProps {
  className?: string;
  style?: CSSProperties;
}

export const TableContainer: FC<TableContainerProps> = ({
  className,
  style,
  children,
}) => {
  return (
    <div
      className={'overflow-auto overflow-y-hidden max-w-full'}
      style={{
        WebkitOverflowScrolling: 'touch',
      }}
    >
      <table
        className={`border border-collapse ${className || ''}`}
        style={style}
      >
        {children}
      </table>
    </div>
  );
};
