import { useState } from 'react';
import { CircularAddButton } from '../../buttons/CircularAdd/CircularAdd';

type SimpleAddFormProps = {
  placeholder: string;
  label: string;
  onAdd: Function;
  initialValue?: string;
  onChange?: Function;
};

export function SimpleAddForm({
  placeholder,
  label,
  onAdd,
  initialValue,
  onChange,
}: SimpleAddFormProps) {
  const [value, setValue] = useState(initialValue ?? '');
  const [error, setError] = useState(false);

  function onClickAdd() {
    if (value.length === 0) {
      setError(true);
    } else {
      onAdd(value);
      setError(false);
      setValue('');
    }
  }

  function _handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      onClickAdd();
    }
  }

  return (
    <div>
      <div className="flex my-1 h-8 items-center">
        <CircularAddButton
          onClick={() => {
            onClickAdd();
          }}
        />
        <div className="ml-2">
          <label htmlFor={label} className="sr-only">
            {label}
          </label>
          <input
            type="text"
            name={label}
            id={label}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => _handleKeyDown(e)}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-immigo focus:ring-blue-immigo sm:text-sm"
            placeholder={placeholder}
          />
        </div>
      </div>
      {error ? (
        <div className="text-red-warning">Please enter a value</div>
      ) : null}
    </div>
  );
}
