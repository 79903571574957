import { ClockIcon, DocumentIcon } from '@heroicons/react/24/outline';
import moment from 'moment-timezone';
import { Avatar } from '../avatar/avatar';
import { getFirstNameFromFullName } from '../../util/helper';
import { capitalizeFirstLetter } from '../../util/standardization';

export const ClassInfoTablePreview = (props: {
  slideUrl: string;
  startMili: number;
  instructorPhoto?: string;
  instructorName?: string;
}) => {
  const { slideUrl, startMili, instructorPhoto, instructorName } = props;
  return (
    <div className="my-4 p-4 rounded-md border border-gray-200 my-4">
      {instructorPhoto && instructorName ? (
        <div className="flex items-center mb-4">
          <Avatar className="mr-2 w-24 h-24" photoUrl={instructorPhoto} />
          <div className="text-xl">
            {`Led by coach ${capitalizeFirstLetter(
              getFirstNameFromFullName(instructorName),
            )}`}
          </div>
        </div>
      ) : null}
      <div className="flex items-center mb-2">
        <DocumentIcon className="w-5 h-5 mr-2" />
        <div>
          <a
            target="_blank"
            href={slideUrl}
            className="text-blue-immigo"
            rel="noreferrer"
          >
            Check out the class material
          </a>
        </div>
      </div>
      <div className="flex items-center">
        <ClockIcon className="w-5 h-5 mr-2" />
        <div className="text-gray-500">
          {`This class starts ${moment(startMili)
            .local()
            .format('MMMM DD, YYYY hh:mm A')}`}
        </div>
      </div>
    </div>
  );
};
