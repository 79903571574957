// Capitalize every first letter of the string.
export const capitalizeEveryFirstLetter = (str: string): string => {
  let splitStr: string[] = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.substring(1);
};

// Return obscured name with last name with only showing the first letter
export const getObscuredName = (str: string): string => {
  let splitStr: string[] = str.trim().toLowerCase().split(' ');
  let obscuredName =
    splitStr[0] +
    (splitStr.length > 1 ? ' ' + splitStr[splitStr.length - 1][0] : '');

  return capitalizeEveryFirstLetter(obscuredName);
};

// Given 2 time offeset from UTC in minutes, returns the correct time offset between 2 timezone offsets.
export const getAbsoluteTimeZoneDifferance = (
  time1: number,
  time2: number,
): number => {
  let diff = Math.abs(time1 - time2);

  // If the difference is greater than 720 miunutes / 12 hours, the difference should be wrapped around
  if (diff >= 720) diff = 1440 - diff;

  return diff;
};

export const timeOffsetToUTCString = (offset: number): string => {
  const hours = -offset / 60; // Time offset is opposite of UTC representation (eg. -540 = UTC+9)
  let finalString = 'UTC';

  if (hours >= 0) finalString += '+';

  return finalString + hours;
};

export const flattenStudentPodRoster = (studentPodRoster: any): any => {
  const studentList = [];
  for (let i = 0; i < studentPodRoster.length; i++) {
    for (let j = 0; j < studentPodRoster[i].length; j++) {
      const student = studentPodRoster[i][j];
      student.podNumber = i + 1;
      studentList.push(student);
    }
  }

  return studentList;
};
