import React from 'react';

import './textbox.css';

const TextBox = (props: any) => {
  return (
    <div
      className="textbox-container"
      style={{
        width: props.width,
      }}
    >
      <label className="textbox-label">
        {props.title}
        {props.required ? <span className="textbox-required"> *</span> : null}
      </label>
      <div className="textbox-description">{props.description}</div>
      <textarea
        style={{ resize: props.resize }}
        className={`textbox ${props.className}`}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(e.target.value)}
        value={props.value ? props.value : ''}
        onBlur={props.onBlur}
      />
      {props.error ? (
        <div className="textbox-error">{props.errorMessage}</div>
      ) : null}
    </div>
  );
};

export default TextBox;
