import { ClockIcon, DocumentIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { BookingData } from '../../firebase/subscription/subscription';

export const InsightHeaderContainer = (props: { booking: BookingData }) => {
  const { booking } = props;

  return (
    <div className="my-2 p-4 rounded-md border border-gray-200 my-4">
      <div className="flex items-center mb-2">
        <DocumentIcon className="w-5 h-5 mr-2" />
        <div>
          <a
            target="_blank"
            href={booking?.slide ?? booking?.cleanSlide}
            className="text-blue-immigo"
            rel="noreferrer"
          >
            Review the class material
          </a>
        </div>
      </div>
      <div className="flex items-center">
        <ClockIcon className="w-5 h-5 mr-2" />
        <div className="text-gray-500">
          {`You took this class ${moment(booking?.startMili)
            .local()
            .format('MMMM DD, YYYY hh:mm A')}`}
        </div>
      </div>
    </div>
  );
};
