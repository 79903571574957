import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  FacebookShareCount,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
} from 'react-share';
import { analyticsProfilePagesShare } from '../../../analytics';

export const SocialShare = ({ url, userID }: any) => {
  const someNetworkStyle = 'inline-block align-top mr-2 text-center';
  const [copied, setCopied] = useState(false);

  return (
    <div>
      {copied ? (
        <span className="text-gray-400 absolute pt-8 pl-12">
          Profile URL copied!
        </span>
      ) : null}
      <div>
        <div className={someNetworkStyle}>
          <CopyToClipboard
            text={url}
            onCopy={() => {
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 5000);
              analyticsProfilePagesShare();
            }}
          >
            <button>
              <svg
                className="w-8 h-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  strokeWidth="2"
                  d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                ></path>
              </svg>
            </button>
          </CopyToClipboard>
        </div>

        <div className={someNetworkStyle}>
          <FacebookShareButton
            url={url}
            onClick={() => {
              analyticsProfilePagesShare();
            }}
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <div>
            <FacebookShareCount url={url}>
              {(count) => count}
            </FacebookShareCount>
          </div>
        </div>

        <div className={someNetworkStyle}>
          <TwitterShareButton
            url={url}
            onClick={() => {
              analyticsProfilePagesShare();
            }}
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <div>&nbsp;</div>
        </div>

        <div className={someNetworkStyle}>
          <TelegramShareButton
            url={url}
            onClick={() => {
              analyticsProfilePagesShare();
            }}
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>
          <div>&nbsp;</div>
        </div>

        <div className={someNetworkStyle}>
          <WhatsappShareButton
            url={url}
            onClick={() => {
              analyticsProfilePagesShare();
            }}
            separator=":: "
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          <div>&nbsp;</div>
        </div>

        <div className={someNetworkStyle}>
          <LinkedinShareButton
            url={url}
            onClick={() => {
              analyticsProfilePagesShare();
            }}
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>
      </div>
    </div>
  );
};
