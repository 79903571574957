import { UserData } from '../../../types/user';
import { getObscuredName } from '../../../util/standardization';
import { Avatar } from '../../avatar/avatar';
import { cx } from 'classix';

export default function PeopleReactionOption(props: {
  userList: Array<Partial<UserData>>;
  title?: string;
  reactionMap?: any;
  onReactionChange: Function;
  reactionList: Array<string>;
}) {
  const { userList, title, onReactionChange, reactionList, reactionMap } =
    props;

  function ratingChangeHandler(uid: string, reaction: string) {
    onReactionChange?.(uid, reaction);
  }

  return (
    <fieldset>
      <legend className="text-base font-semibold leading-6 text-gray-900">
        {title}
      </legend>
      <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200 max-h-[500px] overflow-scroll px-2">
        {userList.map((person, personIdx) => (
          <div key={personIdx} className="relative flex items-start py-4">
            <div className="min-w-0 flex-1 text-sm leading-6 flex items-center">
              <Avatar photoUrl={person.profilePictureLink} />
              <label
                htmlFor={`person-${person.id}`}
                className="select-none font-medium text-gray-900 ml-2"
              >
                {getObscuredName(person.name ?? '')}
              </label>
            </div>
            <div className="ml-3 flex h-6 items-center">
              {reactionList.map((r: string) => {
                return (
                  <span
                    key={r}
                    className={cx(
                      'cursor-pointer mx-1 w-7 h-7 text-center flex justify-center items-center',
                      reactionMap?.[person?.id ?? ''] === r
                        ? 'bg-blue-100 rounded-full'
                        : '',
                    )}
                    onClick={() => {
                      if (person?.id) ratingChangeHandler(person?.id, r);
                    }}
                  >
                    {r}
                  </span>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
}
