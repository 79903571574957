import { saveAs } from 'file-saver';
import moment from 'moment';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import {
  capitalizeFirstLetter,
  capitalizeEveryFirstLetter,
} from './standardization';

export function downloadPDF(pdf: string, title: string) {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement('a');
  const fileName = title + '.pdf';

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

const wrapText = (text: string, width: number, font: any, fontSize: number) => {
  const words = text.split(' ');
  let line = '';
  let result = '';
  let lineCount = 1;
  for (let n = 0; n < words.length; n++) {
    const testLine = line + words[n] + ' ';
    const testWidth = font.widthOfTextAtSize(testLine, fontSize);
    if (testWidth > width) {
      result += line + '\n';
      line = words[n] + ' ';
      lineCount += 1;
    } else {
      line = testLine;
    }
  }
  result += line;
  console.log(result);
  return { result, lineCount };
};

function drawName(page: any, name: string, font: any) {
  let fontSize = 30;
  let nameYOffset = 5;
  if (name.length <= 16) {
    fontSize = 40;
  } else if (name.length <= 25) {
    fontSize = 30;
    nameYOffset = 5;
  } else {
    fontSize = 20;
    nameYOffset = 5;
  }

  page.drawText(name, {
    x: 375,
    y: 385 + nameYOffset,
    size: fontSize,
    font: font,
    color: rgb(1, 1, 0),
  });
}

function drawCourseTitle(page: any, title: string, font: any) {
  let fontSize = 30;
  let nameYOffset = 5;
  if (title.length <= 20) {
    fontSize = 40;
  } else if (title.length <= 25) {
    fontSize = 30;
    nameYOffset = 5;
  } else {
    fontSize = 20;
    nameYOffset = 5;
  }

  let { result, lineCount } = wrapText(title, 460, font, fontSize);

  page.drawText(result, {
    x: 380,
    y: 285 + nameYOffset,
    size: fontSize,
    font: font,
    color: rgb(0, 0, 0),
  });
}

function drawDate(page: any, date: string, font: any) {
  let fontSize = 14;
  if (date.length <= 20) {
    fontSize = 17;
  }

  page.drawText(date, {
    x: 385,
    y: 475,
    size: fontSize,
    font: font,
    color: rgb(0, 0, 0),
  });
}

function drawGoal(page: any, goal: string, font: any) {
  let fontSize = 18;
  page.drawText('Speaking club goal: ', {
    x: 25,
    y: 280,
    size: fontSize,
    font: font,
    color: rgb(1, 1, 0),
  });

  page.drawText(goal, {
    x: 25,
    y: 280,
    size: fontSize,
    font: font,
    color: rgb(1, 1, 1),
  });
}

function drawAcheivedThrough(
  page: any,
  achievedThrough: Array<string>,
  font: any,
  verticalAlign?: number,
) {
  let fontSize = 18;

  page.drawText('We achieved through: ', {
    x: 25,
    y: 250 + (verticalAlign ? verticalAlign : 0),
    size: fontSize,
    font: font,
    color: rgb(1, 1, 0),
  });

  let totalLines = 0;

  achievedThrough.forEach((method: string) => {
    let { result, lineCount } = wrapText('- ' + method, 320, font, fontSize);
    page.drawText(result, {
      x: 25,
      y: 225 + (verticalAlign ? verticalAlign : 0) - totalLines * 25,
      size: fontSize,
      font: font,
      color: rgb(1, 1, 1),
    });
    totalLines += lineCount;
  });

  return totalLines + 1;
}

function drawSkills(
  page: any,
  goal: string,
  font: any,
  verticalAlign?: number,
) {
  let fontSize = 18;
  page.drawText('Speaking skills we practiced: ', {
    x: 25,
    y: 150 + (verticalAlign ? verticalAlign : 0),
    size: fontSize,
    font: font,
    color: rgb(1, 1, 0),
  });

  page.drawText(goal, {
    x: 25,
    y: 150 + (verticalAlign ? verticalAlign : 0),
    size: fontSize,
    font: font,
    color: rgb(1, 1, 1),
  });
}

export async function generateCompletionCertificate(
  name: string,
  courseName: string,
  startDateMili: number,
  endDateMili: number,
  goal?: string,
  practicedSkills?: string,
  achievedThrough?: Array<string>,
) {
  const url =
    'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/certificates%2Fcomplete-cert-template.pdf?alt=media&token=66d7f404-8272-4190-94fc-f46827ab9605';
  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  const { width, height } = firstPage.getSize();

  const startText = moment(startDateMili).format('MMMM DD, YYYY');
  const endText = moment(endDateMili).format('MMMM DD, YYYY');
  const dateText = startText + ' - ' + endText;

  console.log(courseName);
  drawName(firstPage, capitalizeEveryFirstLetter(name), helveticaFont);
  drawCourseTitle(firstPage, courseName, helveticaFont);
  drawDate(firstPage, dateText, helveticaFont);

  if (goal && achievedThrough && practicedSkills) {
    let { result, lineCount } = wrapText(
      ' '.repeat(32) + capitalizeFirstLetter(goal),
      320,
      helveticaFont,
      18,
    );
    drawGoal(firstPage, result, helveticaFont);
    const lineCountAchievement = drawAcheivedThrough(
      firstPage,
      achievedThrough,
      helveticaFont,
      -lineCount * 18,
    );
    let skillsWrap = wrapText(
      ' '.repeat(46) + capitalizeFirstLetter(practicedSkills),
      320,
      helveticaFont,
      18,
    );

    drawSkills(
      firstPage,
      skillsWrap.result,
      helveticaFont,
      -lineCountAchievement * 18,
    );
  }

  const pdfBytes = await pdfDoc.save();
  const fileBolb = new Blob([pdfBytes], { type: 'application/pdf' });

  return fileBolb;
}
