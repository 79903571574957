import { useEffect, useState } from 'react';
import { LoadingOverlay } from '../../components/loading';
import { connect } from 'react-redux';
import { BookingEventObject } from '../../features/Booking/bookingEventContainer';
import VerticalMessageModal from '../../components/modals/verticalMessageModal/verticalMessageModal';
import { addAvailableClassesToUser } from '../../firebase/subscription/subscription';
import { trackBookingAnalytics } from '../../features/Booking/analytics';
import LoginRegisterBox from '../../components/forms/loginregister';
import { BookingPage } from './Booking/Booking';
import { ChoicePage } from './Choice/ChoicePage';
import { CheckOutPage } from './CheckOut/CheckOutPage';
import { getAllClasses } from '../../firebase/subscription/classes/classes';
import { createBookingDocument } from '../../firebase/subscription/bookings/bookings';
import { getFirstNameFromFullName } from '../../util/helper';
import { PrimaryButton } from '../../components/buttons/primary';
import { getUserWholeProfileByUID } from '../../firebase/configuration';
import { ProgressBarSteps } from '../../components/steps/progressBarSteps';
import PhoneInput from 'react-phone-number-input';
import RedAlert from '../../components/alerts/redAlert';
import { trackPricingPageEvents } from '../../features/PricingPage/analytics';
import { MotivationPage } from './MotivationPage/MotivationPage';
import { EnglishLevel } from './EnglishLevel/EnglishLevel';
import { Nationality } from './Nationality/Nationality';
import { BasedCountry } from './BasedCountry/BasedCountry';
import { Loading } from './Loading/Loading';
import { WhatsappIcon } from 'react-share';
import { analyticsIdentifyUser } from '../../analytics';
import { stringsPricingPage } from '../../util/localization';
import { DropDownFloat } from '../../components/forms/dropDownFloat/dropDownFloat';
import { getGeoLocation } from '../../api/location';
import { KakaoAuthButton } from '../../components/forms/loginregister/authProviders/startWithKakaoButton';

export interface FrequencyPricng {
  value: string;
  label: string;
  priceSuffix: string;
}

export const frequenciesPricing: Array<FrequencyPricng> = [
  {
    value: 'monthly',
    label: stringsPricingPage.monthly,
    priceSuffix: '/month',
  },
  {
    value: 'annually',
    label: stringsPricingPage.annually,
    priceSuffix: '/year',
  },
];

export function PricingPageKoreaComponent(props: {
  userData: any;
  onSetUserData: Function;
}) {
  const search = new URLSearchParams(window.location.search);
  const via = search.get('via');
  const lang = search.get('lang');
  const currency_ = search.get('currency');

  const [page, setPage] = useState(via ? 3 : 0);
  const [subOption, setSubOption] = useState<undefined | number>(undefined);
  const [frequency, setFrequency] = useState(frequenciesPricing[0]);
  const [loading, setLoading] = useState(true);
  const [wantRecur, setWantRecur] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [classList, setClassList] = useState<
    Array<BookingEventObject> | undefined
  >();
  const [chosenSchedule, setChosenSchedule] = useState<
    Array<BookingEventObject>
  >([]);
  const [phoneNumberModalOpen, setPhoneNumberModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  // Profile customization questions
  const [motivation, setMotivation] = useState<string | undefined>(undefined);
  const [cefrLevel, setCefrLevel] = useState<string | undefined>(undefined);
  const [nationality, setNationality] = useState<string | undefined>(undefined);
  const [basedCountry, setBasedCountry] = useState<string | undefined>(
    undefined,
  );
  const [language, setLanguage] = useState(lang ?? 'en');
  const [wantOtherAuth, setWantOtherAuth] = useState(false);

  const currency = 'krw';

  const { userData } = props;

  const type = window.location.pathname.split('/')[2];

  const steps = via
    ? [
        { name: 'English Level', page: 4 },
        { name: 'Learning Plan', page: 5 },
        { name: 'Book Your Class', page: 6 },
        { name: 'Create Account', page: 7 },
        { name: 'Payment', page: 8 },
      ]
    : !userData
    ? [
        { name: 'Motivation', page: 0 },
        { name: 'Nationality', page: 1 },
        { name: 'Based Country', page: 2 },
        { name: 'English Level', page: 3 },
        { name: 'Loading', page: 4 },
        { name: 'Learning Plan', page: 5 },
        { name: 'Book Your Class', page: 6 },
        { name: 'Create Account', page: 7 },
        { name: 'Payment', page: 8 },
      ]
    : [
        { name: 'Motivation', page: 0 },
        { name: 'Nationality', page: 1 },
        { name: 'Based Country', page: 2 },
        { name: 'English Level', page: 3 },
        { name: 'Loading', page: 4 },
        { name: 'Learning Plan', page: 5 },
        { name: 'Book Your Class', page: 6 },
        { name: 'Payment', page: 8 },
      ];

  useEffect(() => {
    stringsPricingPage.setLanguage(language);
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);
  useEffect(() => {
    setChosenSchedule([]); // Reset schedule
  }, [subOption, wantRecur]);

  useEffect(() => {
    setLoading(false);
    if (
      userData?.cefrLevel &&
      userData?.nationality &&
      userData?.basedCountry &&
      userData?.livingAbroad &&
      subOption === undefined
    ) {
      setPage(4); // If the user already went through the on boarding then skip the questions
    }
  }, [userData]);

  useEffect(() => {
    updateClasses();
    analyticsIdentifyUser();
    trackBookingAnalytics('Visited pricing page', {
      userID: userData?.id,
      trial: type === 'trial',
    });
  }, []);

  useEffect(() => {
    if (page === 7 && userData) setPage(8);
  }, [page, userData]);

  useEffect(() => {
    if (phoneNumberError) setPhoneNumberError(false);
  }, [phoneNumber]);

  async function updateClasses() {
    const classes = await getAllClasses();
    setClassList(classes);
  }

  async function onLogInSuccess(uid: string) {
    if (uid) {
      const user = await getUserWholeProfileByUID(uid);
      if (user) props.onSetUserData(user);
    }
    console.log(uid);
    setPage(8);
    setLoading(false);
  }

  function renderPage() {
    const pages = [
      <MotivationPage
        onNextClick={() => {
          setPage(1);
        }}
        setValue={(v: string) => {
          trackPricingPageEvents('Selected motivation on pricing page', {
            enteredValue: v,
          });
          setMotivation(v);
        }}
      />,
      <Nationality
        onNextClick={() => {
          setPage(2);
        }}
        setValue={(v: string) => {
          trackPricingPageEvents('Selected nationality on pricing page', {
            enteredValue: v,
          });
          setNationality(v);
        }}
      />,
      <BasedCountry
        onNextClick={() => {
          setPage(3);
          trackPricingPageEvents('Entered loading page on pricing page');
        }}
        setValue={(v: string) => {
          trackPricingPageEvents('Selected based country on pricing page', {
            enteredValue: v,
          });
          setBasedCountry(v);
        }}
      />,
      <EnglishLevel
        onNextClick={() => {
          setPage(4);
        }}
        setValue={(v: string) => {
          trackPricingPageEvents('Selected CEFR Level on pricing page', {
            enteredValue: v,
          });
          setCefrLevel(v);
        }}
      />,
      <Loading
        onNextClick={() => {
          setPage(5);
        }}
      />,
      <ChoicePage
        setFrequency={setFrequency}
        frequency={frequency}
        userData={userData}
        page={page}
        setPage={setPage}
        setSubOption={setSubOption}
        currency={currency}
      />,
      <BookingPage
        userData={userData}
        subOption={subOption}
        setPage={setPage}
        classes={classList}
        setChosenSchedule={setChosenSchedule}
        onBookingSubmit={() => {
          if (!userData) {
            trackPricingPageEvents(
              'Phone number modal shown on the pricing page',
            );
            setPhoneNumberModalOpen(true);
          }
        }}
        chosenSchedule={chosenSchedule}
        setWantRecur={setWantRecur}
        wantRecur={wantRecur}
        oneClassCap={type === 'trial'}
        setLoading={setLoading}
      />,
      <>
        <div className="mx-auto flex flex-col">
          {!wantOtherAuth ? (
            <div className="min-w-[300px] grid gap-4">
              <div className="text-4xl sm:text-d4 mb-[32px] font-bold text-center">
                Sign up
              </div>
              <KakaoAuthButton />
              <PrimaryButton
                text="이메일로 시작"
                onClick={() => {
                  setWantOtherAuth(true);
                }}
              />
            </div>
          ) : (
            <div>
              <div className="mb-6">
                <KakaoAuthButton />
              </div>
              <LoginRegisterBox
                defaultRegister
                onLoginSuccess={onLogInSuccess}
                userDataOverride={{
                  whyLearnEnglish: motivation,
                  phoneNumber: phoneNumber,
                  cefrLevel: cefrLevel,
                  nationality: nationality,
                  basedCountry: basedCountry,
                  livingAbroad: nationality !== basedCountry,
                }}
              />
            </div>
          )}
        </div>
      </>,
      <CheckOutPage
        trial={type === 'trial'}
        page={page}
        setPage={setPage}
        subOption={subOption}
        setLoading={setLoading}
        userData={userData}
        frequency={frequency}
        currency={currency}
        onSuccess={async (onComplete: Function) => {
          let allBookings: any[] = [];
          trackBookingAnalytics('Paid for the class subscription', {
            userID: userData?.id,
            subOption: subOption,
            trial: type === 'trial',
          });
          await Promise.all(
            chosenSchedule.map(async (booking) => {
              if (booking?.id && userData?.id) {
                const bookings = await createBookingDocument(
                  booking?.id,
                  booking?.startMili,
                  booking?.durationMili,
                  userData.id,
                  wantRecur ? 4 : 1,
                );
                allBookings = allBookings.concat(bookings);
                return bookings;
              }
            }),
          );
          await addAvailableClassesToUser(userData.id, -allBookings.length); // Subtract the booked courses
          onComplete();
          setMessageModalOpen(true);
        }}
      />,
    ];
    return pages[page];
  }

  return (
    <div translate="no">
      <LoadingOverlay enabled={loading} />
      <VerticalMessageModal
        mainMessage={stringsPricingPage.stayOnTopOfYourClasses}
        subMessage={stringsPricingPage.weHelpStayOnTop}
        theme="success"
        icon="phone"
        buttonText={stringsPricingPage.submit}
        isOpen={phoneNumberModalOpen}
        onCloseModal={() => {
          trackPricingPageEvents(
            'Phone number modal closed on the pricing page',
          );
          setPhoneNumberModalOpen(false);
        }}
        onButtonClick={() => {
          if (phoneNumber.length > 6) {
            trackPricingPageEvents(
              'Submitted phone number on phone number modal in pricing page',
            );
            setPhoneNumberModalOpen(false); // Shortest # is 7 at the moment St Helena
          } else setPhoneNumberError(true);
        }}
        stayOpenOnPrimaryClick
      >
        <div className="my-10 max-w-[400px] mx-auto">
          <div className="block text-sm font-medium leading-6 text-gray-900 text-left mb-2 flex items-center">
            <WhatsappIcon size={20} round className="mr-2" />{' '}
            {stringsPricingPage.whatsAppNumber}
          </div>
          <PhoneInput
            value={phoneNumber}
            onChange={(n: string) => {
              setPhoneNumber(n);
            }}
          />
        </div>
        {phoneNumberError ? (
          <RedAlert text="Please enter a valid phone number with your country code!" />
        ) : null}
      </VerticalMessageModal>
      <VerticalMessageModal
        mainMessage="You are in!"
        subMessage="We officially have you in our exclusive Immigo subscription program. Now let's complete your profile for your customized experience."
        theme={'success'}
        buttonText={'Continue'}
        onButtonClick={() => {
          trackBookingAnalytics(
            'Clicked continue on pricing page paid confirmation',
            {
              userID: userData?.id,
              subOption: subOption,
            },
          );
          setMessageModalOpen(false);
          window.location.href = '/get-started';
        }}
        onCloseModal={() => {
          trackBookingAnalytics('Closed pricing page paid confirmation modal', {
            userID: userData?.id,
            subOption: subOption,
          });
          setMessageModalOpen(false);
          window.location.href = '/get-started';
        }}
        isOpen={messageModalOpen}
      />

      {!userData?.subProductID ? (
        <>
          <div className="flex flex-shrink-0 items-center md:px-4">
            <img
              className="md:h-12 h-10 w-auto"
              src="https://immigoimages.s3.amazonaws.com/Immigo_new_logo.png"
              alt="Immigo logo"
            />
          </div>
          <div className="mb-8 md:mt-8 mt-0 max-w-[500px] mx-auto">
            <ProgressBarSteps steps={steps} currentStep={page} hideLabels />
            {/* <HorizontalArrowSteps steps={steps} chosenPage={page} /> */}
          </div>
          <div className="items-center justify-center md:mt-16 mt-2 pb-10">
            {renderPage()}
          </div>
        </>
      ) : (
        <div
          className="shadow-lg w-full border-2 p-3
          font-medium bg-white
          rounded-lg
          mb-10 max-w-[500px]"
        >
          <img
            className="md:h-12 h-12 w-auto mb-5"
            src="https://immigoimages.s3.amazonaws.com/Immigo_new_logo.png"
            alt="Immigo logo"
          />
          <span className="block mb-4">
            Hi {getFirstNameFromFullName(userData.name)}!
          </span>
          Seems like you are already enrolled in our subscription plan. If you
          wish to change your learning plan, please contact our team at
          <a href="mailto: ricardo@immigo.io" className="text-blue-immigo">
            {' '}
            ricardo@immigo.io
          </a>
          .
          <div className="my-5">
            <div>Learning plan options:</div>
            <div>
              <span className="text-blue-immigo">4 classes /4 weeks: </span>
              $14.8 per class ($59
              <span className="line-through text-gray-400">$79</span> total)
            </div>
            <div>
              <span className="text-blue-immigo">8 classes /4 weeks: </span>
              $11.1 per class ($79
              <span className="line-through text-gray-400">$109</span> total)
            </div>
            <div>
              <span className="text-blue-immigo">12 classes /4 weeks </span>$9.9
              per class ($119
              <span className="line-through text-gray-400">$139</span> total)
            </div>
          </div>
          <PrimaryButton
            className="mt-10"
            text="View my learning plan"
            onClick={() => {
              window.location.href = '/subscription';
            }}
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  userData: state.sessionState.userData,
});

const mapDispatchToProps = (dispatch: any) => ({
  onSetUserData: (userData: any) =>
    dispatch({ type: 'USER_DATA_SET', userData }),
});

export const PricingPageKorea = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PricingPageKoreaComponent);
