import mixpanel from '../../analytics/mixpanel';

type Events =
  | 'Visit all courses Report Card page'
  | 'Visit course Report Card page'
  | 'Navigate to Report Card from top Navigation'
  | 'Navigate to Report Card from Profile Popup'
  | 'Navigate to Report Card from Profile page'
  | 'Click confetti button';

type UserData = {
  userID?: string;
  userName?: string;
  courseId?: string;
};

export const trackReportCardEvent = (event: Events, data: UserData = {}) => {
  mixpanel.track(event, data);
};
