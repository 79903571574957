import moment from 'moment-timezone';
import { BookingEventObject } from '../../features/Booking/bookingEventContainer';
import { minuteInMili } from '../../usefuldata/mili';
import { TeacherData } from '../../firebase/subscription/subscription';
import { ClassType } from '../../types/class';
import { CEFRLevel } from '../../types/level';

export const coaches: TeacherData[] = [
  {
    id: 'oBFXnnz4o4VOI3g7ywmzereys5g1',
    name: 'Matthew Fockler',
    profilePictureLink:
      'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FoBFXnnz4o4VOI3g7ywmzereys5g1_profile_picture?alt=media&token=dad7446e-f7b3-4d9b-8587-f9ea50be5b70',
  },
  {
    id: 'AGzmCyvq9qay5V40EykRJ9f9LTp1',
    name: 'Andrea',
    profilePictureLink:
      'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FAGzmCyvq9qay5V40EykRJ9f9LTp1_profile_picture?alt=media&token=98a3ac1b-0dba-4cfd-ab75-db7073d124ab',
  },
  {
    id: '6gBeRoW9jDVMGIQpr4UOjSwtFc52',
    name: 'meg r. osnes',
    profilePictureLink:
      'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2F6gBeRoW9jDVMGIQpr4UOjSwtFc52_profile_picture?alt=media&token=56e6901e-4992-44ee-8dd7-dfbb126fb4b4',
  },
];

export const topicBase: string[] = [
  'Grammar',
  'Vocabulary',
  'Idioms',
  'Pronunciation',
];

export const tags: ClassType[] = ['grammar', 'vocab', 'idiom', 'pronunciation'];

export function generateMockClasses(
  startHour: number,
  endHour: number,
  cefrLevel: CEFRLevel,
  onClick: Function,
) {
  let currentHour = startHour;
  let currentDay = 0;
  const bookings = [];

  while (currentDay < 7) {
    while (currentHour < endHour) {
      const classTime = moment()
        .set('hour', currentHour)
        .set('minute', 0)
        .set('second', 0)
        .set('day', currentDay);
      const topicIndex = Math.round(Math.random() * 3);
      const booking: BookingEventObject = {
        id: '',
        classID: '',
        startMili: classTime.valueOf(),
        durationMili: minuteInMili * 50,
        createdAt: new Date().valueOf(),
        title: `(${cefrLevel}) ${topicBase[topicIndex]}`,
        tag: tags[topicIndex],
        recurring: true,
        recLevel: cefrLevel,
        bookerID: '',
        waitlist: true,
        teacher: coaches[Math.round(Math.random() * 2)],
        onClick: () => {
          onClick(booking);
        },
      };
      bookings.push(booking);
      currentHour++;
    }
    currentHour = startHour;
    currentDay++;
  }

  return bookings;
}
