interface ProgressBarStep {
  name: string;
  page: number;
}

interface ProgressBarStepsProps {
  steps: Array<ProgressBarStep>;
  currentStep: number;
  hideLabels?: boolean;
}

export const ProgressBarSteps = ({
  steps,
  currentStep,
  hideLabels,
}: ProgressBarStepsProps) => {
  console.log(
    Math.max(((currentStep - steps[0].page) / steps.length) * 100, 5),
  );
  return (
    <div>
      <h4 className="sr-only">Status</h4>
      <div className="mt-6" aria-hidden="true">
        <div className="overflow-hidden rounded-full bg-gray-200">
          <div
            className="transition-all transform duration-300 h-2 rounded-full bg-blue-immigo"
            style={{
              width: `${Math.max(
                ((currentStep - steps[0].page) / steps.length) * 100,
                5,
              ).toFixed(2)}%`,
            }}
          />
        </div>
        {!hideLabels ? (
          <div className="mt-6 hidden grid-cols-4 text-sm font-medium text-gray-600 sm:grid">
            {steps.map((step: ProgressBarStep) => {
              return (
                <div
                  className={
                    currentStep >= step.page
                      ? 'text-blue-immigo'
                      : 'text-gray-600'
                  }
                >
                  {step.name}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};
