import { ClockIcon, DocumentIcon } from '@heroicons/react/24/outline';
import { BookingData } from '../../../firebase/subscription/subscription';
import { IdiomAndExplanationContainerProp } from '../Idioms/idiomContainer';
import { PrimaryButton } from '../../../components/buttons/primary';
import { SecondaryBlueButton } from '../../../components/buttons/secondaryBlue';
import moment from 'moment';
import { IdiomExplanationContainerLarge } from '../Idioms/idiomContainerLarge';
import { SimpleCarouselWithBottomArrows } from '../../../components/carousels/simpleCarouselWithBottomArrows';
import { ReactChild, useEffect, useState } from 'react';
import BlueAlert from '../../../components/alerts/blueAlert';
import { PreviewQuestionContainerLarger } from '../resource/questionContainerLarger';

export const ClassPreviewPreviewPage = (props: {
  booking?: BookingData;
  idioms: Array<IdiomAndExplanationContainerProp>;
  questionToAnswerMap?: any;
  onBackClick?: Function;
  onNextClick?: Function;
  onlyPreview?: boolean;
}) => {
  const {
    booking,
    idioms,
    onBackClick,
    onNextClick,
    onlyPreview,
    questionToAnswerMap,
  } = props;
  const [idiomBoxList, setIdiomBoxList] = useState<Array<ReactChild>>([]);
  const [questionBoxList, setQuestionBoxList] = useState<Array<ReactChild>>([]);

  useEffect(() => {
    if (idioms) {
      const newIdiomBoxList = idioms.map(
        (idiom: IdiomAndExplanationContainerProp, index: number) => {
          return (
            <div key={idiom.phrase}>
              <IdiomExplanationContainerLarge
                key={idiom.phrase}
                phrase={idiom.phrase}
                definition={idiom.definition}
                example={idiom.example}
              />
            </div>
          );
        },
      );
      setIdiomBoxList(newIdiomBoxList);
    }
  }, [idioms]);

  useEffect(() => {
    if (booking?.questions) {
      const newQuestionList = booking?.questions.map(
        (question: string, index: number) => {
          return (
            <PreviewQuestionContainerLarger
              key={index}
              question={question}
              answer={questionToAnswerMap?.[question]}
            />
          );
        },
      );
      setQuestionBoxList(newQuestionList);
    }
  }, [booking?.questions]);

  return (
    <div>
      <div className="mt-2 text-md text-gray-500">
        <BlueAlert
          text={`🤓 Now that you reviewed your last class, take 5 minutes to get familiar with what you will learn in the class!`}
        />
      </div>
      <div className="my-4 p-4 rounded-md border border-gray-200 my-4">
        <div className="flex items-center mb-2">
          <DocumentIcon className="w-5 h-5 mr-2" />
          <div>
            <a
              target="_blank"
              href={booking?.cleanSlide}
              className="text-blue-immigo"
              rel="noreferrer"
            >
              Preview the class material
            </a>
          </div>
        </div>
        <div className="flex items-center">
          <ClockIcon className="w-5 h-5 mr-2" />
          <div className="text-gray-500">
            {`This class will start ${moment(booking?.startMili)
              .local()
              .format('MMMM DD, YYYY hh:mm A')}`}
          </div>
        </div>
      </div>
      {idiomBoxList.length > 0 ? (
        <>
          <div className="text-xl text-gray-600 mt-2">Idiom/Phrase list</div>
          <SimpleCarouselWithBottomArrows items={idiomBoxList} />
        </>
      ) : null}

      <div className="mb-8">
        {questionBoxList.length > 0 ? (
          <>
            <div className="text-xl text-gray-600 mt-2">Questions</div>
            <SimpleCarouselWithBottomArrows items={questionBoxList} />
          </>
        ) : null}
      </div>
      <div className="mb-2">
        <PrimaryButton
          text="I'm done previewing!"
          onClick={() => {
            onNextClick?.();
          }}
        />
      </div>
      {!onlyPreview && onBackClick ? (
        <SecondaryBlueButton
          text="Back"
          onClick={() => {
            onBackClick?.();
          }}
        />
      ) : null}
    </div>
  );
};
