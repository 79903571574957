import moment from 'moment';
import {
  cancelOneMeetingFromRecurring,
  cancelUserBooking,
} from '../../../../firebase/subscription/bookings/bookings';
import {
  addAvailableClassesToUser,
  BookingData,
} from '../../../../firebase/subscription/subscription';
import { capitalizeEveryFirstLetter } from '../../../../util/standardization';
import HorizontalMessageModal from '../../horizontalMessageModal/horizontalMessageModal';
import { CancelBookingConfirmationModalLoader } from './cancelBookingConfirmationLoader';

export const CancelBookingConfirmationModal = (props: {
  cancelBooking: BookingData | undefined;
  onNotTodayClick: Function;
  onCancelCompleted: Function;
  onCancelClick: Function;
  onCloseModal: Function;
}) => {
  const {
    cancelBooking,
    onNotTodayClick,
    onCancelCompleted,
    onCancelClick,
    onCloseModal,
  } = props;

  let dayInMili = 1000 * 60 * 60 * 24;
  const cancelBookingAfter24HourRule: boolean =
    cancelBooking === undefined ||
    moment(cancelBooking.startMili).valueOf() - new Date().valueOf() <
      dayInMili;
  return (
    <HorizontalMessageModal
      mainMessage={
        cancelBooking
          ? !cancelBookingAfter24HourRule
            ? 'Cancel your class'
            : 'Your class starts within 24 hours'
          : ''
      }
      subMessage={''}
      buttonText="Cancel class"
      secondaryButtonText="Not today"
      secondaryButtonOnClick={onNotTodayClick}
      theme="warning-red"
      onCloseModal={onCloseModal}
      onButtonClick={async () => {
        await onCancelClick();
        if (cancelBooking?.id) {
          if (cancelBooking.recurring)
            await cancelOneMeetingFromRecurring(
              cancelBooking.id,
              cancelBooking.startMili,
            );
          else await cancelUserBooking(cancelBooking.id);
          if (!cancelBookingAfter24HourRule && cancelBooking.bookerID)
            await addAvailableClassesToUser(cancelBooking.bookerID, 1); // Add a class since it's canceled only if within 24 hour rule
        }
        await onCancelCompleted();
      }}
      isOpen={cancelBooking !== undefined}
    >
      {cancelBooking ? (
        <div className="max-w-[400px] text-md text-gray-600 my-2">
          You are about to cancel{' '}
          <span className="font-semibold">
            {capitalizeEveryFirstLetter(cancelBooking?.title ?? '') + ' '}
          </span>
          starting at{' '}
          <span className="font-semibold">
            {moment(cancelBooking?.startMili)
              .local()
              .format('MMMM Do, YYYY hh:mm A') + ' '}
          </span>
          {cancelBookingAfter24HourRule ? (
            <span className="underline text-red-400">
              You will lose this class credit as the 24 hours cancelation period
              has passed.{' '}
            </span>
          ) : (
            ''
          )}{' '}
          Are you sure?
        </div>
      ) : (
        <CancelBookingConfirmationModalLoader />
      )}
    </HorizontalMessageModal>
  );
};
