const ADMIN_EMAILS = [
  'info@immigo.io',
  'admin@immigo.io',
  'meg.r.osnes@gmail.com',
  'andrea.suchyta@gmail.com',
  'matthewafockler@gmail.com',
];

const ADMIN_IDS = ['55GfqR1eKVQmzqe11XFdYvpjWyg2'];

export const checkIsAdmin = (userEmailorID: string | undefined | null) => {
  /**
   * The users of this function rely heavily on optional chaining, which makes me think that it's possible that the value
   * is not present all the time. That's why it has the type of `string | undefined | null`.
   *
   */
  if (!userEmailorID) {
    return false;
  }

  return (
    ADMIN_EMAILS.includes(userEmailorID) || ADMIN_IDS.includes(userEmailorID)
  );
};
