import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { PartialUserData } from '..';
import {
  registerWithFacebook,
  registerWithGoogle,
  signInUserWithEmailAndPassword,
} from '../../../../firebase/auth/auth';
import { validateEmail } from '../../../../util/validation';
import RedAlert from '../../../alerts/redAlert';
import { PrimaryButton } from '../../../buttons/primary';
import { AuthProvider } from '../authProviders/authProviders';
import { stringsPricingPage } from '../../../../util/localization';

interface LoginProps {
  onForgotPassword: Function;
  onCreateAccount: Function;
  onLoginSuccess: Function;
  hideHeader?: boolean;
  userDataOverride?: PartialUserData;
}

export default function Login({
  onForgotPassword,
  onCreateAccount,
  onLoginSuccess,
  hideHeader,
  userDataOverride,
}: LoginProps) {
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signInErrorText, setSignInErrorText] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEmailError(false);
    setSignInErrorText('');
  }, [email]);

  useEffect(() => {
    setPasswordError(false);
    setSignInErrorText('');
  }, [password]);

  async function signInUser() {
    const { userData, error } = await signInUserWithEmailAndPassword(
      email,
      password,
    );

    if (userData && !error) {
      onLoginSuccess();
    } else {
      if (error) setSignInErrorText(error);
    }
  }

  async function onSubmit() {
    setLoading(true);
    const emailValid = !(!email || !validateEmail(email));
    const passwordValid = !(!password || password.length === 0);
    if (!emailValid) setEmailError(true);
    if (!passwordValid) setPasswordError(true);
    if (passwordValid && emailValid) {
      await signInUser();
    }
    setLoading(false);
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center sm:px-6 lg:px-8">
        {!hideHeader ? (
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="md:text-center md:text-2xl text-xl leading-9 tracking-tight text-gray-900">
              {stringsPricingPage.signInWithYourAccount}
            </h2>
          </div>
        ) : null}
        <div className="md:mt-4 mt-2 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            {signInErrorText?.length > 0 ? (
              <div className="mb-4">
                <RedAlert text={signInErrorText} />
              </div>
            ) : null}
            <form
              className="space-y-6"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {stringsPricingPage.emailAddress}
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-immigo sm:text-sm sm:leading-6"
                  />
                </div>
                {emailError ? (
                  <div className="text-sm text-red-warning">
                    {stringsPricingPage.pleaseEnterValidEmail}
                  </div>
                ) : null}
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {stringsPricingPage.password}
                  </label>
                  <div className="text-sm leading-6">
                    <div
                      onClick={() => {
                        onForgotPassword();
                      }}
                      className="font-semibold text-blue-immigo hover:text-blue-lighter cursor-pointer"
                    >
                      {stringsPricingPage.forgotPassword}
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-immigo sm:text-sm sm:leading-6"
                  />
                </div>
                {passwordError ? (
                  <div className="text-sm text-red-warning">
                    {stringsPricingPage.pleaseEnterPassword}
                  </div>
                ) : null}
              </div>

              {/* <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                    />
                    <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">
                      Remember me
                    </label>
                  </div>
              </div> */}

              <div>
                <PrimaryButton
                  className="mt-1"
                  text={stringsPricingPage.signInWithYourAccount}
                  onClick={() => {
                    onSubmit();
                  }}
                  loading={loading}
                />
              </div>
              <div
                className="text-center text-gray-500 text-sm mt-4 flex mx-auto w-fit hover:text-gray-400 cursor-pointer"
                onClick={() => {
                  onCreateAccount();
                }}
              >
                <span>{stringsPricingPage.createYourAccount}</span>
                <ArrowRightIcon className="w-5 h-5 ml-2" />
              </div>
            </form>

            <div>
              <div className="relative mt-10">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="bg-white px-6 text-gray-900">
                    {stringsPricingPage.orContinueWith}
                  </span>
                </div>
              </div>
            </div>
            <AuthProvider
              onAction={() => {
                setLoading(true);
              }}
              onError={async (error: string) => {
                setLoading(false);
                setSignInErrorText(error);
              }}
              onSuccess={async (uid: string) => {
                await onLoginSuccess(uid);
                setLoading(false);
              }}
              userDataOverride={userDataOverride}
              isLoading={loading}
            />
          </div>

          {/* <p className="mt-10 text-center text-sm text-gray-500">
              Not a member?{' '}
              <a href="#" className="font-semibold leading-6 text-blue-600 hover:text-blue-500">
                Start a 14 day free trial
              </a>
            </p> */}
        </div>
      </div>
    </>
  );
}
