import StackedCardRadio from '../../../../components/forms/stackedCardRadio/stackedCardRadio';
import { WhatHaveYouTriedData, validationSchema } from './data';
import { useForm } from '../../../../components/forms/hooks';

import {
  BookOpenIcon,
  DocumentIcon,
  DocumentTextIcon,
  FilmIcon,
  UserGroupIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/20/solid';
import { SimpleBackAndNext } from '../../../../components/buttons/simpleBackAndNext/simpleBackAndNext';
import { OnBoardingPageProps } from '../../OnBoard';
import { useDispatch, useSelector } from 'react-redux';
import { UserData } from '../../../../types/user';
import { useEffect, useState } from 'react';
import { updateUserData } from '../../../../firebase/users/users';
import HorizontalMessageModal from '../../../../components/modals/horizontalMessageModal/horizontalMessageModal';
import TextInput from '../../../../components/forms/textInput';
import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/solid';

export const WhatHaveYouTried = ({
  userID,
  onNextClick,
  onBackClick,
}: OnBoardingPageProps) => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [options, setOptions] = useState<Array<string>>([]);
  const [otherOption, setOtherOption] = useState<string | undefined>();

  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );
  const dispatch = useDispatch();

  function removeOrAddOption(val: string) {
    const index = options.indexOf(val);
    if (index >= 0) {
      options.splice(index, 1);
      setOptions([...options]);
    } else setOptions([...options, val]);
  }

  async function onSubmit() {
    setLoading(true);
    if (otherOption) options.push(otherOption.toLowerCase());
    dispatch({
      type: 'USER_DATA_SET',
      userData: { ...userData, whatHaveYouTried: options },
    });
    await updateUserData(userID, {
      whatHaveYouTried: options,
    });
    await onNextClick();
    setLoading(false);
  }

  const hearAboutChoices = [
    {
      name: 'Private class (1:1 class)',
      icon: <UserIcon className="text-blue-500" />,
      chosen: options.includes('private-class'),
      onClick: () => {
        removeOrAddOption('private-class');
      },
    },
    {
      name: 'Group class',
      icon: <UserGroupIcon className="text-blue-500" />,
      chosen: options.includes('group-class'),
      onClick: () => {
        removeOrAddOption('group-class');
      },
    },
    {
      name: 'Self-learning online course',
      icon: <DocumentTextIcon className="text-blue-500" />,
      chosen: options.includes('course'),
      onClick: () => {
        removeOrAddOption('course');
      },
    },
    {
      name: 'Self-learning apps',
      icon: <DocumentTextIcon className="text-blue-500" />,
      chosen: options.includes('app'),
      onClick: () => {
        removeOrAddOption('app');
      },
    },
    {
      name: 'Speaking partners / Penpals',
      icon: <UsersIcon className="text-blue-500" />,
      chosen: options.includes('partners'),
      onClick: () => {
        removeOrAddOption('partners');
      },
    },
    {
      name: 'Books',
      icon: <BookOpenIcon className="text-blue-500" />,
      chosen: options.includes('books'),
      onClick: () => {
        removeOrAddOption('books');
      },
    },
    {
      name: 'Movies',
      icon: <FilmIcon className="text-blue-500" />,
      chosen: options.includes('movies'),
      onClick: () => {
        removeOrAddOption('movies');
      },
    },
    {
      name: otherOption ?? 'Other',
      chosen: otherOption !== undefined,
      icon: <ChatBubbleOvalLeftEllipsisIcon className="text-blue-500" />,
      onClick: () => {
        setOpenModal(true);
      },
    },
  ];

  useEffect(() => {
    if (userData?.whatHaveYouTried) setOptions(userData.whatHaveYouTried);
  }, [userData]);

  return (
    <div>
      <HorizontalMessageModal
        mainMessage="What have you tried before?"
        subMessage="Please type below what you've tried before!"
        theme="default"
        icon="check-mark"
        buttonText="Submit"
        onButtonClick={async () => {
          setOpenModal(false);
        }}
        onCloseModal={() => {
          setOpenModal(false);
        }}
        loading={loading}
        isOpen={openModal}
      >
        <div className="mt-6">
          <TextInput
            placeholder="ex) LinkedIn, Podcast"
            value={otherOption}
            onChange={(v: string) => {
              setOtherOption(v);
            }}
          />
        </div>
      </HorizontalMessageModal>
      <div className="text-2xl text-center">What have you tried before?</div>
      <div className="mt-6 text-center text-sm text-gray-500 mb-8">
        Before we get started, help us out by telling us what other methods you
        used to study English :). Select all that applies.
      </div>
      <div>
        <StackedCardRadio choices={hearAboutChoices} error={''} />
      </div>
      <SimpleBackAndNext
        onBack={() => {
          onBackClick?.();
        }}
        onNext={async () => {
          setLoading(true);
          await onSubmit();
          setLoading(false);
        }}
        nextLoading={loading}
      />
    </div>
  );
};
