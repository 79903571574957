import cx from 'classix';
import { collection } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useCollection } from 'react-firebase-hooks/firestore';
import { onAuthStateChanged } from 'firebase/auth';

import { getUserByUID } from '../../firebase/configuration';
import { useForm } from '../../components/forms/hooks';
import { Input } from '../../components/forms/input';
import { LoadingOverlay } from '../../components/loading';
import { TableContainer } from '../../components/table-container/TableContainer';
import { initializeFirebase } from '../../firebase/configValues';
import { DiscountCodesData, validationSchema } from './data';

const { firestore: db, auth } = initializeFirebase();

const functions = getFunctions();
const createDiscountCode = httpsCallable(functions, 'createDiscountCode');

interface FunctionResponse {
  success: boolean;
  error?: string;
  id?: string;
}

const DiscountCodesComponent = ({ userData, onSetUserData }: any) => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const [discountCodesList, collectionLoading] = useCollection(
    collection(db, 'affiliates'),
  );

  const formik = useForm<DiscountCodesData>({
    initialValues: { isPercentage: false },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setError(undefined);
        setLoading(true);
        const func = await createDiscountCode(values);
        const response = func.data as FunctionResponse;
        setError(!response?.success ? response?.error : undefined);
        setLoading(false);
        if (response.success) {
          formik.resetForm();
        }
      } catch (e) {
        console.error(e);
      }
    },
    enableReinitialize: true,
  });

  function onLoad() {
    if (userData && onSetUserData) {
      // Update with new user data
      getUserByUID(userData.id, (data: any) => {
        onSetUserData(data);
        userDataLoaded(data);
      });
    }

    onAuthStateChanged(auth, (user) => {
      if (!user) history.push('/login');
    });
  }

  function userDataLoaded(user: any) {
    if (user) {
      if (!user.email.endsWith('@immigo.io')) history.push('/login');
    }
  }

  useEffect(onLoad, []);
  useEffect(() => {
    userDataLoaded(userData);
  }, [userData]);

  return (
    <div className={cx('flex flex-col gap-16')}>
      <section id={'create-discount-code'}>
        <LoadingOverlay
          enabled={loading || collectionLoading || formik.isSubmitting}
        />
        <form className={cx('flex flex-col gap-8')}>
          <div className={cx('flex flex-col gap-4')}>
            <h4 className={cx('font-bold text-xl')}>Discount Information</h4>
            <div
              className={cx('flex flex-wrap items-start justify-evenly gap-4')}
            >
              <Input
                label={'Code'}
                containerClassName={'flex-1'}
                {...formik.getFieldProps('code')}
              />
              <Input
                label={'Value'}
                type={'number'}
                containerClassName={'flex-1'}
                min={0}
                {...formik.getFieldProps('value')}
              />
              <Input
                label={'Is Percentage?'}
                type={'checkbox'}
                {...formik.getFieldProps('isPercentage')}
              />
            </div>
          </div>
          <div className={cx('flex flex-col gap-4')}>
            <h4 className={cx('font-bold text-xl')}>Creator Information</h4>
            <div
              className={cx('flex flex-wrap items-start justify-evenly gap-4')}
            >
              <Input
                label={'First name'}
                containerClassName={'flex-1'}
                {...formik.getFieldProps('creatorFirstName')}
              />
              <Input
                label={'Last name'}
                containerClassName={'flex-1'}
                {...formik.getFieldProps('creatorLastName')}
              />
            </div>
            <Input
              label={'Email'}
              type={'email'}
              {...formik.getFieldProps('creatorEmail')}
            />
          </div>
          <button
            type={'button'}
            disabled={formik.isSubmitting || formik.isValidating}
            className={cx(
              'self-end bg-blue-immigo px-4 py-3',
              'text-center text-white rounded font-semibold',
              'hover:shadow hover:-translate-y-0.5 transform transition-all ease-in-out',
              'disabled:opacity-80 disabled:cursor-not-allowed disabled:transform-none',
            )}
            onClick={() => {
              formik.submitForm();
            }}
          >
            Create code
          </button>
          {Boolean(error) && (
            <p
              className={cx(
                'text-red-warning border border-red-warning border-opacity-30 border-dashed',
                'px-2 py-1 rounded-sm mt-1 self-start font-semibold',
              )}
            >
              <span role={'img'} aria-label={'emergency light emoji'}>
                🚨
              </span>{' '}
              {error}
            </p>
          )}
        </form>
      </section>
      <section className={cx('flex flex-col gap-4')}>
        <h4 className={cx('font-bold text-2xl')}>List of Discount Codes</h4>
        {Boolean(discountCodesList) && (
          <TableContainer>
            <thead>
              <tr>
                <th className={'border px-2 py-1 text-left min-w-[192px]'}>
                  Code
                </th>
                <th className={'border px-2 py-1 text-left min-w-[192px]'}>
                  Discount
                </th>
                <th className={'border px-2 py-1 text-left min-w-[192px]'}>
                  Creator
                </th>
                <th className={'border px-2 py-1 text-left min-w-[192px]'}>
                  Creator email
                </th>
              </tr>
            </thead>
            <tbody>
              {discountCodesList?.docs?.map((doc) => {
                const { value, isPercentage, creator } = doc.data();
                return (
                  <tr>
                    <td className={'border px-2 py-1'}>
                      {/* eslint-disable-next-line react/jsx-no-target-blank */}
                      <a
                        href={`https://immigo.io?via=${doc.id.toLowerCase()}`}
                        target={'_blank'}
                        className={cx(
                          'text-lightBlue-600 underline cursor-pointer',
                        )}
                      >
                        {doc.id.toLowerCase()}
                      </a>
                    </td>
                    <td className={'border px-2 py-1'}>{`${
                      isPercentage ? '' : '$'
                    }${value}${isPercentage ? '%' : ''}`}</td>
                    <td className={'border px-2 py-1'}>
                      {creator.firstName} {creator.lastName}
                    </td>
                    <td className={'border px-2 py-1'}>{creator.email}</td>
                  </tr>
                );
              })}
            </tbody>
          </TableContainer>
        )}
      </section>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  onSetUserData: (userData: any) =>
    dispatch({ type: 'USER_DATA_SET', userData }),
});

const mapStateToProps = (state: any) => ({
  userData: state.sessionState.userData,
});

export const DiscountCodes = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscountCodesComponent);
