export const supportedCurrencies = ['usd', 'brl', 'mxn']; // COP not supported default when it exceeds 1M COP

export const usdToXFixedRateMap: any = {
  usd: 1,
  cop: 3929.0,
  brl: 4.8844,
  mxn: 16.9708,
  eur: 0.92,
  krw: 1364.31,
};

export const currencySymbols: any = {
  usd: '$',
  cop: '$',
  mxn: '$',
  brl: 'R$',
  krw: '₩',
};

export function usdToX(amount: number, currency: string) {
  return usdToXFixedRateMap[currency] * amount;
}

export function getUsdToXMap(amount: number, currency: string) {
  const map: any = {};
  Object.keys(usdToXFixedRateMap).forEach((c: string) => {
    map[c] = usdToX(amount, currency);
  });
  return map;
}
