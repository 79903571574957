import moment from 'moment-timezone';
import { LoadingCircle } from '../../icons/loadingCircle';
import { useEffect, useRef, useState } from 'react';
import cx from 'classix';
import { SpeakerWaveIcon } from '@heroicons/react/24/solid';

export const AudioPlayerButton = (props: {
  audioRecordingURL?: string;
  hideTime?: boolean;
  audioID: string;
  stopAudio?: boolean;
  onPlayClick?: Function;
}) => {
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [audioTimeLeft, setAudioTimeLeft] = useState(0);
  const [audioLoading, setAudioLoading] = useState(false);
  const { audioRecordingURL, hideTime, onPlayClick, stopAudio, audioID } =
    props;
  const audioRef = useRef<HTMLAudioElement>();

  useEffect(() => {
    const audioObj: HTMLAudioElement = document.getElementById(
      audioID,
    ) as HTMLAudioElement;
    if (audioObj) {
      audioRef.current = audioObj;
      audioObj.currentTime = 0; // buffer
      audioObj.addEventListener('loadedmetadata', function () {
        setAudioTimeLeft(this.duration);
      });
      audioObj.addEventListener('timeupdate', function () {
        setAudioTimeLeft(this.duration - this.currentTime);
      });
      audioObj.addEventListener('ended', function () {
        setAudioTimeLeft(this.duration);
      });
      audioObj.addEventListener('loadstart', () => {
        setAudioLoading(true);
      });
      audioObj.addEventListener('loadeddata', () => {
        setAudioLoading(false);
      });
      audioObj.addEventListener('playing', () => {
        setAudioPlaying(true);
      });
      audioObj.addEventListener('pause', () => {
        setAudioPlaying(false);
      });
    }
  }, []);

  useEffect(() => {
    const audioObj: HTMLAudioElement = document.getElementById(
      audioID,
    ) as HTMLAudioElement;
    if (stopAudio) {
      if (!audioObj.paused) {
        audioObj.currentTime = 0; // buffer
        audioObj.pause();
      }
    }
  }, [stopAudio]);

  return (
    <>
      {audioRecordingURL ? (
        <audio id={audioID} src={audioRecordingURL} />
      ) : null}
      {audioRecordingURL ? (
        <span className="flex items-center text-left">
          <SpeakerWaveIcon
            className={cx(
              audioLoading ? 'text-gray-400' : 'cursor-pointer',
              'h-10 ml-2 flex-shrink-0 text-blue-300 hover:text-blue-500',
              audioPlaying ? 'text-blue-immigo animate-pulse' : 'text-black',
            )}
            onClick={() => {
              onPlayClick?.();
              const audioObj: HTMLAudioElement = document.getElementById(
                audioID,
              ) as HTMLAudioElement;

              if (audioObj.paused) {
                audioObj.currentTime = 0; // buffer
                audioObj.play();
              } else {
                audioObj.pause();
              }
            }}
          />
          {audioLoading ? <LoadingCircle className="text-gray-400" /> : null}
          {!hideTime ? (
            <span
              className={cx(
                'ml-2',
                audioPlaying ? 'text-blue-immigo' : 'text-blue-400',
              )}
            >
              {`${moment
                .duration(audioTimeLeft * 1000)
                .minutes()
                .toString()
                .padStart(2, '0')}:${moment
                .duration(audioTimeLeft * 1000)
                .seconds()
                .toString()
                .padStart(2, '0')}`}
            </span>
          ) : null}
        </span>
      ) : null}
    </>
  );
};
