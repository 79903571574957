import { DocumentIcon } from '@heroicons/react/24/outline';
import { BookingData } from '../../../firebase/subscription/subscription';
import {
  IdiomAndExplanationContainerProp,
  IdiomExplanationContainer,
} from '../Idioms/idiomContainer';
import { PrimaryButton } from '../../../components/buttons/primary';
import { SecondaryBlueButton } from '../../../components/buttons/secondaryBlue';
import { IdiomExplanationContainerLarge } from '../Idioms/idiomContainerLarge';
import { SimpleCarouselWithBottomArrows } from '../../../components/carousels/simpleCarouselWithBottomArrows';
import { ReactChild, useEffect, useState } from 'react';
import BlueAlert from '../../../components/alerts/blueAlert';

export interface ClassPreviewWarmUpQuestionAnswerItem {
  phrase: string;
  question: string;
  answer: string;
}

export const ClassPreviewWarmUpPage = (props: {
  booking?: BookingData;
  qaList: Array<ClassPreviewWarmUpQuestionAnswerItem>;
  onBackClick?: Function;
  onNextClick?: Function;
}) => {
  const { booking, qaList, onBackClick, onNextClick } = props;
  const [qaBoxList, setQABoxList] = useState<Array<ReactChild>>([]);

  useEffect(() => {
    if (qaList) {
      const newIdiomBoxList = qaList.map((h: any, i: number) => {
        return (
          <div>
            <IdiomExplanationContainerLarge
              key={i}
              phrase={h.phrase}
              definition={h.question}
              example={h.answer}
            />
          </div>
        );
      });
      setQABoxList(newIdiomBoxList);
    }
  }, [qaList]);
  return (
    <div>
      <div className="mt-2 text-md text-gray-500">
        <BlueAlert
          text={`🤓 Here are some suggestions how to answer your upcoming discussion questions with idioms/phrases you learned in your last class.`}
        />
      </div>
      <div className="mb-8">
        {qaBoxList.length > 0 ? (
          <SimpleCarouselWithBottomArrows items={qaBoxList} />
        ) : null}
      </div>
      <div className="mb-2">
        <PrimaryButton
          className="my-2"
          text="I feel ready for the class!"
          onClick={() => {
            onNextClick?.();
          }}
        />
      </div>
      <SecondaryBlueButton
        text="Back"
        onClick={() => {
          onBackClick?.();
        }}
      />
    </div>
  );
};
