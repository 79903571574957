import { useEffect, useState } from 'react';
import Modal from '../../modal/index';
import { returnMeetingSessionDocByUID } from '../../../firebase/configuration';
import { getWordFrequency, findAllSubstringOcc } from '../../../util/data';
import { trackMeetingNotifierEvent } from '../../../features/MeetingNotifier/analytics';

let fillers = ['SO', 'UM', 'UH', 'LIKE', 'YOU KNOW'];

export const MeetingSessionSummaryModal = (props: {
  isOpen: boolean;
  onClose: Function;
  sessionID: string | null;
  userData: any;
}) => {
  const [wordFreqMap, setWordFreqMap] = useState<any>(undefined);
  const [topUsageFiller, setTopUsageFiller] = useState<any>(undefined);
  const { isOpen, onClose, sessionID, userData } = props;
  const [meetingSessionDocument, setMeetingSessionDocument] =
    useState<any>(null);

  useEffect(() => {
    async function updateMeetingSessionDocument(sessionID: string) {
      const meetingSession = await returnMeetingSessionDocByUID(sessionID);
      console.log(meetingSession);
      if (meetingSession?.script?.[userData.id]) {
        const map = getWordFrequency(meetingSession.script[userData.id]);
        map['YOU KNOW'] = findAllSubstringOcc(
          meetingSession.script[userData.id],
          'YOU KNOW',
        );
        const sortable = [];
        for (var word in map) {
          if (fillers.includes(word)) sortable.push([word, map[word]]);
        }
        sortable.sort(function (a, b) {
          return b[1] - a[1];
        });
        if (sortable.length > 0) setTopUsageFiller(sortable[0][0]);
        setWordFreqMap(map);
      }
      setMeetingSessionDocument(meetingSession);
    }
    if (sessionID && userData) updateMeetingSessionDocument(sessionID);
  }, [sessionID, userData]);

  useEffect(() => {
    if (userData && isOpen && sessionID) {
      trackMeetingNotifierEvent('Showed meeting summary to user', {
        userID: userData.id,
        userName: userData.name,
        sessionID: sessionID,
      });
    }
  }, [userData, isOpen, sessionID]);

  function onCloseModal() {
    if (sessionID) {
      onClose();
      trackMeetingNotifierEvent('Closed meeting summary', {
        userID: userData.id,
        userName: userData.name,
        sessionID: sessionID,
      });
    }
  }

  return (
    <>
      {userData &&
      meetingSessionDocument?.userTimeData?.[userData.id] !== undefined ? (
        <Modal isOpen={isOpen} closeModal={onCloseModal}>
          <div>
            <div className="text-blue-immigo text-xl font-bold w-64 mb-6">
              📈 Meeting Summary
            </div>
            <div>
              <span className="text-blue-immigo">You've spoken: </span>
              {'' +
                meetingSessionDocument.userTimeData[userData.id] +
                ' minutes'}
            </div>
            {meetingSessionDocument.script &&
            meetingSessionDocument.script[userData.id] ? (
              <div>
                <span className="text-blue-immigo">You've spoken: </span>
                {'' +
                  meetingSessionDocument.script[userData.id].split(' ').length +
                  ' words'}
              </div>
            ) : null}
            {/* {wordFreqMap ? (
              <div>
                <span className="text-blue-immigo">
                  Your filler word usage:
                </span>
                <div>
                  {'Um: ' + (wordFreqMap['UM'] ? wordFreqMap['UM'] : 0)}
                </div>
                <div>
                  {'So: ' + (wordFreqMap['SO'] ? wordFreqMap['SO'] : 0)}
                </div>
                <div>
                  {'Like: ' + (wordFreqMap['LIKE'] ? wordFreqMap['LIKE'] : 0)}
                </div>
                <div>
                  {'Uh: ' + (wordFreqMap['UH'] ? wordFreqMap['UH'] : 0)}
                </div>
                <div>
                  {'You know: ' +
                    (wordFreqMap['YOU KNOW'] ? wordFreqMap['YOU KNOW'] : 0)}
                </div>
              </div>
            ) : null} */}
            {topUsageFiller ? (
              <div className="mt-10 w-64">
                <span className="text-blue-immigo font-bold">💡 Tip: </span>
                Try using "{topUsageFiller.toLowerCase()}" less in your next
                meeting. Using less filler words will make you sound more
                fluent!
              </div>
            ) : null}
          </div>
        </Modal>
      ) : null}
    </>
  );
};
