import {
  CalendarIcon,
  ClockIcon,
  DocumentTextIcon,
  MapPinIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';
import DropDownFancy from '../../../../components/forms/dropDownFancy/dropDownFancy';
import {
  BookingData,
  TeacherData,
  updateClassRecurringCoachForAllClassSessions,
  updateClassRecurringUrl,
  updateClassRecurringUrlForAllClassSessions,
  updateClassTopicID,
  updateTopicIDForAllClassSessions,
} from '../../../../firebase/subscription/subscription';
import { capitalizeEveryFirstLetter } from '../../../../util/standardization';
import { updateClass } from '../../../../firebase/subscription/classes/classes';

export interface ClassBookingData extends BookingData {
  dateString?: string;
  recurringTime?: string;
}

export const Classes = (props: {
  classes: Array<ClassBookingData>;
  topicsMap: any;
  onUpdateStarted: Function;
  onUpdateFinished: Function;
  coaches?: Array<TeacherData>;
}) => {
  const [linkToBeEditedID, setLinkToBeEditedID] = useState<
    string | undefined
  >();
  const [classTopicToBeEditedID, setClassTopicToBeEdited] = useState<
    string | undefined
  >();
  const [classCoachToBeEditedID, setClassCoachToBeEdited] = useState<
    string | undefined
  >();
  const { classes, topicsMap, coaches, onUpdateFinished, onUpdateStarted } =
    props;

  return (
    <div className="lg:grid lg:grid-cols-6 lg:gap-x-12">
      <ol className="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
        {classes.map((c: ClassBookingData) => (
          <li
            key={c.id}
            className="relative flex space-x-6 py-6 xl:static bg-white p-4 rounded-md my-4"
          >
            <div className="flex-auto">
              <h3 className="pr-10 font-semibold text-gray-900 xl:pr-0">
                {c.title}
              </h3>
              <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row text-xs">
                <div className="flex items-start space-x-3">
                  <dt className="mt-0.5">
                    <span className="sr-only">Date</span>
                    <CalendarIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd>
                    <time dateTime={c.dateString}>{c.dateString}</time>
                  </dd>
                </div>
                <div className="mt-2 flex items-start space-x-3 xl:mt-0 xl:ml-3.5 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                  <dt className="mt-0.5">
                    <span className="sr-only">Location</span>
                    <ClockIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd>{c.recurringTime}</dd>
                </div>
                <div className="mt-2 flex items-start space-x-3 xl:mt-0 xl:ml-3.5 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                  <dt className="mt-0.5">
                    <span className="sr-only">Topic Index</span>
                    <DocumentTextIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  {classTopicToBeEditedID === c.id ? (
                    <dd>
                      <DropDownFancy
                        title=""
                        options={Object.keys(topicsMap).map((id) => {
                          const topic = topicsMap[id];
                          return {
                            id: topic.id,
                            name: capitalizeEveryFirstLetter(topic.topicTitle),
                          };
                        })}
                        noDefaultValue
                        onOptionSelected={async (v: any) => {
                          await onUpdateStarted();
                          if (c.id) {
                            await updateTopicIDForAllClassSessions(c.id, v.id);
                            setClassTopicToBeEdited(undefined);
                          }
                          await onUpdateFinished();
                        }}
                      />
                    </dd>
                  ) : (
                    <dd className="hover:text-blue-immigo cursor-pointer">
                      {c.topicID
                        ? capitalizeEveryFirstLetter(
                            topicsMap[c.topicID]?.topicTitle ?? '',
                          )
                        : 'Add Topic'}
                    </dd>
                  )}
                  <div
                    className="hover:text-blue-immigo cursor-pointer"
                    onClick={() => {
                      console.log(c.id);
                      setClassTopicToBeEdited(c.id);
                    }}
                  >
                    Edit
                  </div>
                </div>

                {/** Recurring link edit */}
                <div className="mt-2 flex items-start space-x-3 xl:mt-0 xl:ml-3.5 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                  <dt className="mt-0.5">
                    <span className="sr-only">Location</span>
                    <MapPinIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  {linkToBeEditedID === c.id ? (
                    <dd>
                      <div>
                        <label htmlFor="email" className="sr-only">
                          Link
                        </label>
                        <input
                          type="link"
                          name="link"
                          id="link"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-immigo sm:text-sm sm:leading-6"
                          placeholder=""
                          defaultValue={c?.recurringMeetingUrl ?? ''}
                          onBlur={async (e) => {
                            await onUpdateStarted();
                            if (c.id && e?.target?.value)
                              await updateClassRecurringUrlForAllClassSessions(
                                c.id,
                                e.target.value,
                              );
                            setLinkToBeEditedID(undefined);
                            await onUpdateFinished();
                          }}
                        />
                      </div>
                    </dd>
                  ) : (
                    <dd className="hover:text-blue-immigo cursor-pointer">
                      {c.recurringMeetingUrl ? (
                        <a href={c.recurringMeetingUrl}>
                          {c.recurringMeetingUrl}
                        </a>
                      ) : (
                        'Add Link'
                      )}
                    </dd>
                  )}
                  <div
                    className="hover:text-blue-immigo cursor-pointer"
                    onClick={() => {
                      console.log(c.id);
                      setLinkToBeEditedID(c.id);
                    }}
                  >
                    Edit
                  </div>
                </div>

                {/** Recurring coach edit */}
                <div className="mt-2 flex items-start space-x-3 xl:mt-0 xl:ml-3.5 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                  <dt className="mt-0.5">
                    <span className="sr-only">Topic Index</span>
                    <DocumentTextIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  {classCoachToBeEditedID === c.id ? (
                    <dd>
                      <DropDownFancy
                        title=""
                        options={
                          coaches?.map((coach) => {
                            return {
                              id: coach.id,
                              name: capitalizeEveryFirstLetter(coach.name),
                            };
                          }) ?? []
                        }
                        noDefaultValue
                        onOptionSelected={async (v: any) => {
                          console.log(v);
                          await onUpdateStarted();
                          if (c.id) {
                            await updateClassRecurringCoachForAllClassSessions(
                              c.id,
                              v.id,
                            );
                            setClassCoachToBeEdited(undefined);
                          }
                          await onUpdateFinished();
                        }}
                      />
                    </dd>
                  ) : (
                    <dd className="hover:text-blue-immigo cursor-pointer">
                      {c.teacher
                        ? capitalizeEveryFirstLetter(c.teacher.name ?? '')
                        : 'Add Coach'}
                    </dd>
                  )}
                  <div
                    className="hover:text-blue-immigo cursor-pointer"
                    onClick={() => {
                      console.log(c.id);
                      setClassCoachToBeEdited(c.id);
                    }}
                  >
                    Edit
                  </div>
                </div>
                <div className="mt-2 flex items-start space-x-3 xl:mt-0 xl:ml-3.5 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                  <dd>
                    <div
                      className="hover:text-blue-immigo cursor-pointer"
                      onClick={async () => {
                        await onUpdateStarted();
                        if (c.id)
                          await updateClass(c.id, {
                            canceled: !(c.canceled !== undefined && c.canceled),
                          });
                        await onUpdateFinished();
                      }}
                    >
                      {c.canceled ? 'Uncancel' : 'Cancel'}
                    </div>
                  </dd>
                  <dd>
                    <div
                      className="hover:text-blue-immigo cursor-pointer"
                      onClick={async () => {
                        await onUpdateStarted();
                        if (c.id)
                          await updateClass(c.id, {
                            recurring: !(
                              c.recurring !== undefined && c.recurring
                            ),
                          });
                        await onUpdateFinished();
                      }}
                    >
                      {!c.recurring ? 'Recur' : 'Unrecur'}
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
};
