import axios from 'axios';
import { StripeLineItem } from '../types/stripe';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com'
    : 'http://localhost:8080'; // Dev env

export async function createCustomer(
  email: string,
  fullName: string,
  userID: string,
) {
  const customerData = await axios.post(endPoint + '/payment/createCustomer', {
    email,
    fullName,
    userID,
  });
  return { ...customerData?.data?.customer };
}

export async function attachPaymentMethod(
  paymentMethodID: string,
  customerID: string,
) {
  const data = await axios.post(
    endPoint + '/payment/updateCustomerPaymentMethod',
    {
      paymentMethodID,
      customerID,
    },
  );
  return data.data;
}

export async function createCharge(
  amount: number,
  currency: string,
  customerID: string,
  description: string,
) {
  const data = await axios.post(endPoint + '/payment/createCharge', {
    amount,
    currency,
    customerID,
    description,
  });
  return data?.data;
}

export async function resetBillingCycle(subID: string) {
  const data = await axios.post(
    endPoint + '/payment/resetSubscriptionBillingCycle',
    {
      subID,
    },
  );
  return data?.data;
}

export async function awardStickAroundCredit(
  customerID: string,
  currency: string,
  amount: number,
  reason: string,
  userID: string,
) {
  const data = await axios.post(endPoint + '/payment/addBalanceToAccount', {
    customerID,
    currency,
    amount: -amount,
    reason,
    userID,
  });
  return data?.data;
}

export async function getStripeCustomer(customerID: string) {
  const data = await axios.get(endPoint + `/payment/customer/${customerID}`);
  return data?.data?.customer;
}

export async function updateCustomerDefaultPaymentMethod(
  customerId: string,
  paymentMethodId: string,
) {
  const data = await axios.post(
    endPoint + '/payment/updateCustomerDefaultPaymentMethod',
    {
      customerId,
      paymentMethodId,
    },
  );
  return data?.data;
}

export async function detachPaymentMethod(paymentMethodId: string) {
  const data = await axios.post(endPoint + '/payment/detachPaymentMethod', {
    id: paymentMethodId,
  });
  return data?.data;
}

export async function getSetUpIntent(setiId: string) {
  const data = await axios.get(`${endPoint}/payment/setupIntent/${setiId}`);
  return data?.data?.setupIntent;
}

export async function createSetUpIntent(
  customerId: string,
  items: Array<StripeLineItem>,
  mode: string,
) {
  const data = await axios.post(`${endPoint}/payment/setupIntent`, {
    successUrl: `${window.location.origin}`,
    customerId,
    items,
    mode,
  });
  return data?.data?.setupIntent;
}

export async function changePlan(
  subscriptionID: string,
  subscriptionItemID: string,
  newPriceID: string,
  coupon?: string,
  billEndOfCycle?: boolean,
  prorate?: boolean,
) {
  const data = await axios.post(`${endPoint}/payment/changePlan`, {
    subscriptionID,
    subscriptionItemID,
    newPriceID,
    coupon,
  });
  return data?.data;
}
