import { ReactElement, useEffect, useState } from 'react';
import { returnUserByUID } from '../../firebase/configuration';
import { UserData } from '../../types/user';
import { PeerReactionContainer } from './peerReactionContainer';

type PeerReactionCollectionObject = {
  [key: string]: string;
};

export const PeerReactionCollectionContainer = (props: {
  reactionMap: PeerReactionCollectionObject;
}) => {
  const { reactionMap } = props;
  const [reactionComponents, setReactionComponents] = useState<ReactElement[]>(
    [],
  );

  async function renderReactions() {
    const rcs = await Promise.all(
      Object.keys(reactionMap).map(async (uid: string) => {
        const user: UserData = (await returnUserByUID(uid)) as UserData;
        const reaction = reactionMap[uid];
        const { profilePictureLink, name } = user;
        return (
          <PeerReactionContainer
            reactorPictureUrl={profilePictureLink}
            reactorName={name}
            reaction={reaction}
          />
        );
      }),
    );
    setReactionComponents(rcs);
  }

  useEffect(() => {
    renderReactions();
  }, []);

  return (
    <div className="overflow-scroll rounded-lg bg-white px-4 py-5 shadow sm:p-3 max-h-[100px]">
      {reactionComponents}
    </div>
  );
};
