import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { initializeFirebase } from '../../firebase/configValues';
const { auth } = initializeFirebase();

const LandingComponent = (props: any) => {
  const history = useHistory();

  useEffect(onLoad, [history]);

  function onLoad() {
    onAuthStateChanged(auth, (user) => {
      if (user) history.push('/home');
    });
  }

  return (
    <div className="flex text-center justify-center max-w-screen-xl m-auto px-4max-w-screen-xl m-auto px-4">
      <div>
        <img
          className="m-auto"
          src="https://immigoimages.s3.amazonaws.com/Immigo_new_logo.png"
          alt="immigo"
        />
        <div className="text-gray-dark text-xl md:w-3/5 m-auto p-4">
          Sign-in to Immigo to get started on your courses.
        </div>
        <button
          className="text-white font-bold mb-4 bg-blue-immigo w-52 h-12 px-5 rounded-md"
          onClick={() => history.push('/login')}
        >
          Log in
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userData: state.sessionState.userData,
});

export const LandingPage = connect(mapStateToProps)(LandingComponent);
