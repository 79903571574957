import { trackAuthEvents } from '../analytics';

export const KakaoAuthButton = () => {
  const onLoginWithKakao = () => {
    trackAuthEvents('Clicked start with Kakao');
    window.open(`${window.location.origin}/auth/kakao`, '_blank');
  };
  // https://developers.kakao.com/docs/latest/ko/kakaologin/design-guide
  return (
    <button
      style={{
        minWidth: '100%',
        minHeight: '56px',
        borderRadius: ' 5px',
        backgroundColor: 'rgb(255, 233, 0)',
      }}
      className="text-h5 font-medium leading-[20px]  flex cursor-pointer items-center justify-center"
      onClick={onLoginWithKakao}
    >
      <img
        className="w-5 h-5"
        src="https://d38emex6h5e12i.cloudfront.net/logo/ico-kakaotalk.svg"
      ></img>
      <div className="text-black text-h5 ml-[6px] font-medium  leading-[20px]">
        카카오로 5초만에 시작
      </div>
    </button>
  );
};
