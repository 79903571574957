import ContentLoader from 'react-content-loader';

export const ClassInsightLoader = () => {
  return (
    <div>
      <ContentLoader viewBox="0 0 380 140">
        <rect x="0" y="0" rx="5" ry="5" width="180" height="120" />
        <rect x="200" y="0" rx="5" ry="5" width="180" height="120" />
      </ContentLoader>
    </div>
  );
};
