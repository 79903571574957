import PeopleTableUpDownSame from '../../../../../../components/forms/peopleTableUpDownSameOption/peopleTableUpDownSame';
import { BookerData } from '../../../../../../types/bookings';

export const TheirEnglishLevel = (props: {
  chosenMembers: Array<BookerData>;
  onRatingChange: Function;
  ratingMap: any;
}) => {
  const { chosenMembers, onRatingChange, ratingMap } = props;
  return (
    <div>
      <div className="text-gray-500 mb-2">
        Please tell us a little about their English levels so that we can match
        you with more compatible students. Your response will be completely
        anonymous and will only be used to improve your experience internally.
      </div>
      <PeopleTableUpDownSame
        userList={chosenMembers}
        ratingMap={ratingMap}
        title="Were their English levels higher, lower, or the same as yours?"
        onRatingChange={onRatingChange}
      />
    </div>
  );
};
