import {
  DocumentData,
  QueryDocumentSnapshot,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { initializeFirebase } from '../configValues';
import { dayInMili } from '../../usefuldata/mili';
import _ from 'lodash';
import { returnUserByUID } from '../configuration';
import { UserData } from '../../types/user';

const { firestore: db } = initializeFirebase();

export const getUserFollowings = async (uid: string) => {
  const followingSnapshot = collection(db, 'following', uid, 'userFollowing');
  const followingDocs = await getDocs(followingSnapshot);
  return await Promise.all(
    followingDocs.docs.map(async (d: QueryDocumentSnapshot<DocumentData>) => {
      const data = d.data();
      data.id = d.id;
      return data;
    }),
  );
};

export const getUserFollowed = async (uid: string) => {
  const followingSnapshot = collection(db, 'following', uid, 'userFollowed');
  const followingDocs = await getDocs(followingSnapshot);
  return await Promise.all(
    followingDocs.docs.map(async (d: QueryDocumentSnapshot<DocumentData>) => {
      const data = d.data();
      data.id = d.id;
      return data;
    }),
  );
};

export const getAllUserActivities = async (uid: string) => {
  const activitySnapshot = collection(db, 'activities', uid, 'userActivities');
  const activitiesDocs = await getDocs(activitySnapshot);
  return activitiesDocs.docs.map((d: QueryDocumentSnapshot<DocumentData>) => {
    const data = d.data();
    data.id = d.id;
    return data;
  });
};

export const getPast7DaysUserActivities = async (uid: string) => {
  const activitySnapshot = collection(db, 'activities', uid, 'userActivities');
  const activityQuery = query(
    activitySnapshot,
    where('createdAt', '>=', new Date().valueOf() - dayInMili * 7),
  );
  const userData: UserData = (await returnUserByUID(uid)) as UserData;
  const activitiesDocs = await getDocs(activityQuery);
  return activitiesDocs.docs.map((d: QueryDocumentSnapshot<DocumentData>) => {
    const data = d.data();
    data.id = d.id;
    data.uid = uid;
    if (userData) {
      data.name = userData.name;
      data.email = userData.email;
      data.profilePictureLink = userData.profilePictureLink;
    }
    return data;
  });
};

export const getPast7DaysUserActivityFeed = async (uid: string) => {
  const followings = await getUserFollowings(uid);
  let activities: any[] = await Promise.all(
    followings.map(async (f: any) => {
      const uid = f.id;
      const userActivities = await getPast7DaysUserActivities(uid);
      return userActivities;
    }),
  );
  activities = _.flatten(activities).sort((a, b) => b.createdAt - a.createdAt); // Flatten array

  return activities;
};

export const getReferredUsers = async (code: string) => {
  const referredSnapshot = collection(db, 'referralCodes', code, 'referred');
  const referredDocs = await getDocs(referredSnapshot);
  return await Promise.all(
    referredDocs.docs.map(async (d: QueryDocumentSnapshot<DocumentData>) => {
      const data = d.data();
      data.id = d.id;
      return data;
    }),
  );
};
