import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { initializeFirebase } from '../../configValues';
import {
  addBookingDataToClassSession,
  BookingData,
  BookingDataUpdateMap,
  createClassSessionDocument,
  getClass,
  getClassWeekCleanSlide,
  getClassWeekPhrases,
  getClassWeekQuestions,
  getClassWeekTheme,
  getClassWeekTopic,
  getSessionOnClass,
} from '../subscription';
import momentTimezone from 'moment-timezone';
import { range } from 'lodash';
import { findUserByEmail } from '../../users/users';
import { BookingLog, BookingLogType } from '../../../types/bookings';

const { firestore: db } = initializeFirebase();

const weekMili = 1000 * 7 * 24 * 60 * 60;

export const getBookingDocument = async (bookingID: string) => {
  const bookingRef = await doc(db, 'bookings', bookingID);
  const bookingDoc = await getDoc(bookingRef);
  const bookingData = bookingDoc.data();
  return bookingData;
};

export const updateBookingDetail = async (
  bookingID: string,
  updateField: Partial<BookingDataUpdateMap>,
) => {
  const bookingRef = await doc(db, 'bookings', bookingID);
  const bookingDoc = await getDoc(bookingRef);
  const bookingData = bookingDoc.data();

  if (bookingData) {
    // If the data exists
    const bookingsRefUser = await doc(
      db,
      'users',
      bookingData.bookerID,
      'bookings',
      bookingID,
    );
    const bookingsRefClass = await doc(
      db,
      'classes',
      bookingData.classID,
      'bookings',
      bookingID,
    );
    const bookingsRefClassSession = await doc(
      db,
      'classSessions',
      bookingData.classSessionID,
      'bookings',
      bookingID,
    );
    const bookingsRefClassSessionBooking = await doc(
      db,
      'classes',
      bookingData.classID,
      'classSessions',
      `${bookingData.startMili}`,
      'bookings',
      bookingID,
    );

    const bruDoc = await getDoc(bookingsRefUser);
    const brcDoc = await getDoc(bookingsRefClass);
    const brcsDoc = await getDoc(bookingsRefClassSession);
    const brcbDoc = await getDoc(bookingsRefClassSessionBooking);

    try {
      if (bookingsRefUser && bruDoc.exists())
        await updateDoc(bookingsRefUser, updateField);
      else {
        console.log(bruDoc.metadata);
      }
      if (bookingRef && bookingDoc.exists())
        await updateDoc(bookingRef, updateField);
      else {
        console.log(bookingDoc.metadata);
      }
      if (bookingsRefClass && brcDoc.exists())
        await updateDoc(bookingsRefClass, updateField);
      else {
        console.log(brcDoc.metadata);
      }
      if (bookingsRefClassSession && brcsDoc.exists())
        await updateDoc(bookingsRefClassSession, updateField);
      else {
        console.log(brcsDoc.metadata);
      }
      if (bookingsRefClassSessionBooking && brcbDoc.exists())
        await updateDoc(bookingsRefClassSessionBooking, updateField);
      else {
        console.log(brcbDoc.metadata);
      }
    } catch (err) {
      console.log(err);
    }
    // Update all the bookingRef to be canceled
  }

  return bookingData;
};

export const adminCancelUserBooking = async (bookingID: string) => {
  await updateBookingDetail(bookingID, {
    canceledReason: 'moved-admin',
    canceled: true,
  });
};

export const getUserBookings = async (userID: string) => {
  const bookingsRef = await collection(db, 'users', userID, 'bookings');
  const bookingDocs = await getDocs(bookingsRef);
  const bookings: any[] = [];
  bookingDocs.forEach((c: any) => {
    const data = c.data();
    data.id = c.id;
    bookings.push(data);
  });
  return bookings;
};

export const getUserFutureBookings = async (userID: string) => {
  const bookings = await getUserBookings(userID);
  const futureBookings = bookings.filter((b: BookingData) => {
    return !b.canceled && b.startMili >= new Date().valueOf();
  });
  return futureBookings;
};

export const getUserBookingsAttended = async (userID: string) => {
  const bookings = await getUserBookings(userID);
  return bookings.filter((b) => b.attended);
};

export const getMostRecentClass = async (userID: string) => {
  const bookingsRef = await collection(db, 'users', userID, 'bookings');
  const bookingDocs = await getDocs(bookingsRef);
  const bookings: any[] = [];
  bookingDocs.forEach((c: any) => {
    const data = c.data();
    data.id = c.id;
    bookings.push(data);
  });
  return bookings;
};

export const cancelUserBooking = async (bookingID: string) => {
  await updateBookingDetail(bookingID, {
    canceled: true,
    canceledAt: new Date().valueOf(),
  });
};

export const checkBookingAttended = async (bookingID: string) => {
  await updateBookingDetail(bookingID, {
    attended: true,
    attendedTime: new Date().valueOf(),
  });
};

export const updateBookingAttendance = async (
  bookingID: string,
  attendend: boolean,
) => {
  await updateBookingDetail(bookingID, {
    attended: attendend,
  });
};

export const cancelOneMeetingFromRecurring = async (
  bookingID: string,
  startMili: number,
) => {
  await updateBookingDetail(bookingID, {
    skipTime: arrayUnion(startMili),
  });
};

export const addBookingDocumentByEmail = async (
  classID: string,
  startMili: number,
  durationMili: number,
  bookerEmail: string,
  reucrringWeeks: number,
  addedBy?: string,
) => {
  let user;
  let bookingIDs;
  try {
    const foundUsersList = await findUserByEmail(bookerEmail);

    if (foundUsersList?.[0]) {
      user = foundUsersList[0];
      const id = user.id;
      console.log(id);
      console.log({
        classID,
        startMili,
        durationMili,
        id,
        reucrringWeeks,
      });
      const res = await createBookingDocument(
        classID,
        startMili,
        durationMili,
        id,
        reucrringWeeks,
        addedBy,
      );

      bookingIDs = res.map((r) => r.id);
    } else {
      console.log(
        `addBookingDocumentByEmail : user with ${bookerEmail} does not exist`,
      );
    }
  } catch (err) {
    console.log(err);
  }
  return { user, bookingIDs };
};

export const addBookingLog = async (
  bookingID: string,
  log: string,
  logType: BookingLogType,
) => {
  const logData: BookingLog = {
    log,
    tag: logType,
    bookingID,
    createdAt: new Date().valueOf(),
  };
  const logDoc = await addDoc(
    collection(db, 'bookings', bookingID, 'logs'),
    logData,
  ); // Create a new booking document
  return logDoc;
};

export const createBookingDocument = async (
  classID: string,
  startMili: number,
  durationMili: number,
  bookerID: string,
  reucrringWeeks: number,
  addedBy?: string,
) => {
  const classData = await getClass(classID);
  const title = await getClassWeekTopic(classID, startMili);
  const phrases = await getClassWeekPhrases(classID, startMili);
  const questions = await getClassWeekQuestions(classID, startMili);
  const cleanSlide = await getClassWeekCleanSlide(classID, startMili);
  const theme = await getClassWeekTheme(classID, startMili);

  console.log(classData);
  if (classData?.reschedule?.[startMili]) {
    // Class has been rescheduled
    startMili = classData?.reschedule?.[startMili];
  }

  const bookingData: BookingData = {
    classID,
    startMili,
    durationMili,
    recurring: reucrringWeeks === 4,
    title,
    bookerID,
    createdAt: new Date().valueOf(),
    timeZone: momentTimezone.tz.guess(), // Guess user booking timezone
  };

  if (addedBy) bookingData.addedBy = 'admin';
  if (phrases) bookingData.phrases = phrases;
  if (cleanSlide) bookingData.cleanSlide = cleanSlide;
  if (questions) bookingData.questions = questions;
  if (theme) bookingData.theme = theme;
  if (classData?.recurringMeetingUrl)
    bookingData.recurringMeetingUrl = classData.recurringMeetingUrl;
  if (classData?.tag) bookingData.tag = classData.tag; // Assign class tag
  if (classData?.recLevel) bookingData.recLevel = classData.recLevel; // assign rec level

  const bookings = await Promise.all(
    range(reucrringWeeks).map(async (w: number) => {
      const newBooking = {
        ...bookingData,
        startMili: bookingData.startMili + w * weekMili,
      };

      const classSession = await getSessionOnClass(
        classID,
        newBooking.startMili,
      );
      let classSessionID = classSession?.id;
      let classTeacher = classSession?.teacher;
      if (classSessionID) newBooking.classSessionID = classSessionID;
      if (classTeacher) newBooking.teacher = classTeacher;

      const booking = await addDoc(collection(db, 'bookings'), newBooking); // Create a new booking document
      newBooking.id = booking.id;
      await setDoc(
        doc(db, 'users', bookerID, 'bookings', booking.id),
        newBooking,
      ); // Add booking to user
      await setDoc(
        doc(db, 'classes', classID, 'bookings', booking.id),
        newBooking,
      ); // Add booking to the class
      if (!classSession) {
        const seshref = await createClassSessionDocument(
          // Create class session if doesn't exist
          classID,
          newBooking,
        );
        classSessionID = seshref.id;
      }

      // Populate booking data to class sesh
      await addBookingDataToClassSession(classSessionID, newBooking);

      return booking;
    }),
  );

  return bookings;
};
