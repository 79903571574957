import mixpanel from '../../analytics/mixpanel';

type Events =
  | 'Class Detail Page: Visited class detail page'
  | 'Class Detail Page: Followed user'
  | 'Class Detail Page: Unfollowed user';

type ClassDetailPageData = {
  sessionID?: string;
};

export const trackClassDetailPage = (
  event: Events,
  data?: ClassDetailPageData,
) => {
  mixpanel.track(event, data);
};
