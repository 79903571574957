import mixpanel from '../../analytics/mixpanel';
import { UserPointHistoryReasonType } from '../../types/user';

type Events =
  | 'Immigots notifier opened award modal'
  | 'Immigots notifier closed award modal';

type ImmigotsNotifierData = {
  amount?: number;
  reason?: UserPointHistoryReasonType;
};

export const trackImmigotsNotifierEvents = (
  event: Events,
  data?: ImmigotsNotifierData,
) => {
  mixpanel.track(event, data);
};
