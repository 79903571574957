import mixpanel from '../../analytics/mixpanel';

type Events = 'Clicked certificate container' | 'Visited certificate page';

type PageData = {
  certificateName?: string;
};

export const trackCertificateData = (event: Events, data: PageData = {}) => {
  mixpanel.track(event, data);
};
