import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  enrollStudentInCourse,
  findCourseWithReadableID,
  getUserByUID,
  getUserWholeProfileByUID,
  returnAllCourses,
  returnCourseByUID,
} from '../../firebase/configuration';
import moment from 'moment';
import Modal from '../../components/modal';
import MessageModal from '../../components/modals/messageModal';
import { LoadingOverlay } from '../../components/loading';
import { checkIsAdmin } from '../../util/admin';
import { onAuthStateChanged } from 'firebase/auth';
import { initializeFirebase } from '../../firebase/configValues';
import { MeetingNotifier } from '../../notifier/meetingNotifier/meetingNotifier';
import { UserData } from '../../types/user';
import { IncompleteFormBanner } from './IncompleteFormBanner';
import { hasEnoughAvailabilitiesSet } from '../../util/availability-overlaps';
import VerticalMessageModal from '../../components/modals/verticalMessageModal/verticalMessageModal';

const { auth } = initializeFirebase();

export const CourseList = () => {
  const dispatch = useDispatch();
  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );
  const onSetUserData = (userData: any) =>
    dispatch({ type: 'USER_DATA_SET', userData });
  const [courses, setCourses] = useState<Array<any>>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [messageModalOpen, setMessageModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [courseId, setCourseID] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');
  const enoughAvailabilities = hasEnoughAvailabilitiesSet(
    userData?.availability,
  );

  const history = useHistory();
  useEffect(onLoad, []);
  useEffect(() => {
    userDataLoaded(userData);
  }, [userData]);

  function filteredCourses(courseList: Array<any>, onGoing: boolean) {
    return courseList
      .filter((course: any) => {
        return (
          (!onGoing && course.coursePeriod.end < new Date().valueOf()) ||
          (onGoing && course.coursePeriod.end >= new Date().valueOf())
        );
      })
      .sort((a, b) => b.coursePeriod.end - a.coursePeriod.end);
  }

  function onLoad() {
    setLoading(true);
    if (userData && onSetUserData) {
      // Update with new user data
      getUserWholeProfileByUID(userData.id, (data: any) => {
        onSetUserData(data);
        userDataLoaded(data);
      });
    }

    onAuthStateChanged(auth, (user) => {
      if (!user) history.push('/login');
    });
  }

  function userDataLoaded(user: any) {
    if (user && user.courses) {
      setLoading(true);
      updateCourses(user.courses);
    } else {
      console.log('User is missing!');
    }
  }

  async function updateCourses(courses: Array<string>) {
    let courseList: Array<any> = [];
    if (checkIsAdmin(userData?.email)) {
      courseList = await returnAllCourses(); // get all courses for admin
    } else {
      courseList = await Promise.all(
        courses.map(async (courseID: string, courseIndex: number) => {
          const course: any = await returnCourseByUID(courseID);
          return course;
        }),
      );
    }

    setCourses(courseList); // Flatten 2 dimensional array before updating the student list
    setLoading(false);
  }

  function onCourseClick(id: string, course: any) {
    if (
      course.students &&
      userData &&
      !course.students.includes(userData.id) &&
      !checkIsAdmin(userData.email)
    ) {
      // If the student is not enrolled and not admin
      if (
        !userData.age ||
        userData.podImportance === undefined ||
        userData.kindOfWork === undefined ||
        userData.perfectPodForMe === undefined
      ) {
        history.push('/additional-info');
      } else {
        setLoading(true);
        enrollStudentInCourse(id, userData.id, () => {
          setLoading(false);
          onLoad();
        });
      }
    } else {
      if (
        !userData.age ||
        userData.podImportance === undefined ||
        userData.kindOfWork === undefined ||
        userData.perfectPodForMe === undefined
      )
        history.push('/additional-info');
      else history.push('/dashboard/' + id);
    }
  }

  function onCourseRegister(code: string) {
    if (code && code.length > 0) {
      setLoading(true);
      findCourseWithReadableID(
        code,
        (courseID: any) => {
          const id = courseID.docID;
          if (userData.courses?.includes(id)) {
            setErrorText('You are already enrolled in this course');
            setLoading(false);
          } else {
            enrollStudentInCourse(id, userData.id, () => {
              setModalOpen(false); // Close registration modal
              setLoading(false);
              onLoad();
            });
          }
        },
        () => {
          setErrorText('No course associated with this course ID was found.');
          setLoading(false);
        },
      );
    } else {
      setErrorText('Please enter a valid course ID.');
    }
  }

  function renderCourseList(courses: Array<any>) {
    const courseList: Array<any> = courses.map((course: any, index) => {
      const startString =
        course.coursePeriodString && course.coursePeriodString.start
          ? course.coursePeriodString.start
          : moment(course.coursePeriod.start).format('MMMM Do YYYY');
      const endString =
        course.coursePeriodString && course.coursePeriodString.end
          ? course.coursePeriodString.end
          : moment(course.coursePeriod.end).format('MMMM Do YYYY');
      if (
        userData &&
        course.students &&
        !course.students.includes(userData.id)
      ) {
        return (
          <button
            key={index}
            type={'button'}
            onClick={() => onCourseClick(course.id, course)}
            className="flex flex-col items-start border-2 border-red-warning cursor-pointer p-5 w-full shadow-md bg-white rounded-md"
          >
            <div className="font-bold text-red-warning">
              {
                '(Action required) Click here to complete your course enrollment.'
              }
            </div>
            <div className="font-bold">{course.courseTitle}</div>
            {!course?.hideCoursePeriod ? (
              <>
                <div>{'Start Date: ' + startString}</div>
                <div>{'End Date: ' + endString}</div>
              </>
            ) : (
              <div>Course period coming soon!</div>
            )}
          </button>
        );
      } else {
        return (
          <button
            key={index}
            type={'button'}
            onClick={() => onCourseClick(course.id, course)}
            className="flex flex-col items-start cursor-pointer p-5 w-full shadow-md bg-white border rounded-md"
          >
            <div className="font-bold">{course.courseTitle}</div>
            {!course?.hideCoursePeriod ? (
              <>
                <div>{'Start Date: ' + startString}</div>
                <div>{'End Date: ' + endString}</div>
              </>
            ) : (
              <div>Course period coming soon!</div>
            )}
          </button>
        );
      }
    });
    return courseList;
  }

  return (
    <div className="flex w-full justify-center h-full">
      <MeetingNotifier userData={userData} />
      <Modal isOpen={modalOpen} closeModal={() => setModalOpen(false)}>
        <div className="mb-1">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Course ID
          </label>
          <div className="mb-4 text-gray-500">
            Please enter the course ID of the course you'd like to enroll in.
          </div>
          <input
            className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="courseid"
            type="name"
            placeholder="Course ID"
            onChange={(e) => {
              setCourseID(e.target.value);
              setErrorText('');
            }}
            value={courseId}
          />
          <button
            className="bg-blue-immigo hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            value="Sign In"
            type="button"
            onClick={() => onCourseRegister(courseId)}
          >
            Enroll
          </button>
          <div className="mb-4 text-red-warning h-4">{errorText}</div>
        </div>
      </Modal>
      <VerticalMessageModal
        isOpen={messageModalOpen}
        onCloseModal={() => {
          history.go(0); // Refresh
          setMessageModalOpen(false);
        }}
        mainMessage="Thank you!"
        subMessage="At the end of the course, we will be selecting
                students who are most engaged (e.g. 100% attendance) and may invite
                you to an exclusive group of other students where you get to know
                the founders and the other great students."
        buttonText="Awesome!"
        onButtonClick={() => {
          history.go(0);
          setMessageModalOpen(false);
        }}
        theme={'default'}
      />
      <LoadingOverlay enabled={loading} />
      <div className="w-full flex flex-col mx-auto gap-4 h-full">
        <button
          type={'button'}
          onClick={() => {
            if (
              userData &&
              (userData.podImportance === undefined ||
                userData.perfectPodForMe === undefined ||
                userData.kindOfWork === undefined)
            ) {
              history.push('/additional-info');
            } else {
              if (userData) setModalOpen(true);
            }
          }}
          className="cursor-pointer text-right text-lg text-blue-immigo self-end"
        >
          + Register a new course
        </button>
        {Boolean(
          userData &&
            (userData.podImportance === undefined ||
              userData.perfectPodForMe === undefined ||
              userData.kindOfWork === undefined),
        ) && <IncompleteFormBanner name={userData.name} userID={userData.id} />}
        {enoughAvailabilities !== 'ok' && (
          <div className="border border-red-warm px-3 py-4 rounded border-dashed bg-white">
            <strong className={'text-orange-600'}>
              <span
                role="img"
                aria-label="emergency light emoji"
                className="inline-block mr-1"
              >
                ⚠️
              </span>{' '}
              {enoughAvailabilities === 'missing' ? 'Missing' : 'Insufficient'}{' '}
              availability
            </strong>
            {enoughAvailabilities === 'missing' ? (
              <p>
                Hey, we noticed you haven’t filled out your availabilities!
                Please fill this out before your course starts to have your
                optimal course experience.
              </p>
            ) : (
              <p>
                Please fill out at least 2 different days of availability and
                allow at least 4 hours of total availability for us to find you
                the best matching pod.
              </p>
            )}
            <a
              href="/availability/"
              className="font-semibold underline cursor-pointer mt-4 inline-block"
            >
              Fill out availability
            </a>
          </div>
        )}

        <section className={'flex flex-col gap-6 w-full mt-6'}>
          <h3 className="font-bold w-2/3">Ongoing courses</h3>
          {renderCourseList(filteredCourses(courses, true))}
        </section>
        <section className={'flex flex-col gap-6 w-full mt-6 pb-24'}>
          <h3 className="font-bold w-2/3">Past courses</h3>
          {renderCourseList(filteredCourses(courses, false))}
        </section>
      </div>
    </div>
  );
};
