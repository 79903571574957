import { useEffect, useState } from 'react';
import { returnAllReportCards } from '../../../firebase/configuration';
import moment from 'moment';
export const ReportCardView = () => {
  const [reportCards, setReportCards] = useState<any>([]);

  useEffect(() => {
    getReportCards();
  }, []);

  async function getReportCards() {
    console.log('hold');
    const reportCards = await returnAllReportCards();

    reportCards.sort((a, b) => b.totalPhraseUsage - a.totalPhraseUsage);

    if (reportCards) setReportCards(reportCards);
  }

  function renderReportCards() {
    return reportCards.map((reportCard: any) => {
      console.log(reportCard);
      return (
        <div>
          {reportCard.userName} {reportCard.totalPhraseUsage}{' '}
          {reportCard.phraseUsageList
            .map(
              (phrase: any) =>
                phrase.phrase +
                ' ' +
                (phrase.lastUsed
                  ? moment(phrase.lastUsed).format('MM/DD/YY HH:mm')
                  : ''),
            )
            .join(', ')}
        </div>
      );
    });
  }

  return <div>{renderReportCards()}</div>;
};
