import { RocketLaunchIcon } from '@heroicons/react/24/outline';

export const TrialInfo = () => {
  return (
    <div className="rounded-md bg-sky-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <div className="mx-auto flex h-8 w-8 items-center justify-center rounded-full bg-sky-100">
            <RocketLaunchIcon
              className="h-5 w-5 text-blue-immigo"
              aria-hidden="true"
            />
          </div>
        </div>
        <div className="ml-3">
          <h3 className="text-xl font-medium text-sky-800">
            Try us out first and pay later with your first workshop on us.
          </h3>
          <div className="mt-2 text-md text-sky-700">
            <p>
              You will have until 24 hours after your first workshop to decide
              to withdraw from the course. Once you withdraw from the course,
              you will not be charged.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
