import axios from 'axios';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com/class'
    : 'http://localhost:8080/class'; // Dev env

export async function cancelClassAtTime(classID: string, startMili: number) {
  const data = await axios.post(`${endPoint}/rescheduleClass`, {
    classID,
    fromMili: startMili,
    toMili: 0,
  });
  return data?.data;
}

export async function resetClassAtTime(classID: string, startMili: number) {
  const data = await axios.post(`${endPoint}/rescheduleClass`, {
    classID,
    fromMili: startMili,
    toMili: startMili,
  });
  return data?.data;
}
