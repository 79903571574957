import { useEffect } from 'react';
import { useState } from 'react';
import { initializeFirebase } from '../../../firebase/configValues';
import Login from './login/login';
import Register from './register/register';
import ForgotPassword from './forgotPassword/forgotPassword';
import { UserData } from '../../../types/user';

export interface PartialUserData extends Partial<UserData> {}

interface LoginRegisterBoxProps {
  onLoginSuccess: Function;
  hideHeader?: boolean;
  defaultRegister?: boolean;
  userDataOverride?: PartialUserData;
}

const LoginRegisterBox = ({
  onLoginSuccess,
  defaultRegister,
  hideHeader,
  userDataOverride,
}: LoginRegisterBoxProps) => {
  const [registerMode, setRegisterMode] = useState(
    defaultRegister ? true : false,
  );

  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);

  return (
    <div className="w-full md:min-w-[500px]">
      {!forgotPasswordMode && !registerMode ? (
        <Login
          onLoginSuccess={(uid: string) => {
            onLoginSuccess(uid);
          }}
          onForgotPassword={() => {
            setForgotPasswordMode(true);
          }}
          onCreateAccount={() => {
            setRegisterMode(true);
          }}
          userDataOverride={userDataOverride}
          hideHeader={hideHeader}
        />
      ) : !forgotPasswordMode && registerMode ? (
        <Register
          onRegistrationSuccess={(uid: string) => {
            onLoginSuccess(uid);
          }}
          onLoginClicked={() => {
            setRegisterMode(false);
          }}
          userDataOverride={userDataOverride}
          hideHeader={hideHeader}
        />
      ) : (
        <ForgotPassword
          onLogIn={() => {
            setForgotPasswordMode(false);
          }}
          hideHeader={hideHeader}
        />
      )}
    </div>
  );
};

export default LoginRegisterBox;
