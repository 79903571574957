import {
  HandThumbDownIcon,
  HandThumbUpIcon,
  SpeakerWaveIcon,
} from '@heroicons/react/20/solid';
import {
  CheckIcon,
  LightBulbIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import moment from 'moment-timezone';
import { Correction } from '../../../types/feedback';
import { generateCorrectionPairWithStyle } from '../../../util/feedback';
import { cx } from 'classix';
import { useEffect, useRef, useState } from 'react';
import { LoadingCircle } from '../../../components/icons/loadingCircle';

export const CorrectionContainer = (props: {
  correction: Correction;
  audioRecordingURL?: string;
  onRecordingPlayClicked?: Function;
  onCorrectionRating?: Function;
  useFullDate?: boolean;
}) => {
  const {
    audioRecordingURL,
    correction,
    onRecordingPlayClicked,
    onCorrectionRating,
    useFullDate,
  } = props;

  const correctionPair =
    correction?.original && correction?.fixed
      ? generateCorrectionPairWithStyle(correction.original, correction.fixed)
      : undefined;
  const correctedAt = correction?.correctedAt;

  const startTime = correction?.startSeconds || 0;
  const endTime = correction?.endSeconds || 0;
  const speaker = correction?.speakerTag;
  const audioID = `${startTime}-${endTime}-${correction?.original}`;

  const [audioPlaying, setAudioPlaying] = useState(false);
  const [audioTimeLeft, setAudioTimeLeft] = useState(endTime - startTime);
  const [audioLoading, setAudioLoading] = useState(false);
  const [thumbsUpLoading, setThumbsUpLoading] = useState(false);
  const [thumbsDownLoading, setThumbsDownLoading] = useState(false);
  const audioRef = useRef<HTMLAudioElement>();

  useEffect(() => {
    const audioObj: HTMLAudioElement = document.getElementById(
      audioID,
    ) as HTMLAudioElement;
    if (audioObj) {
      audioRef.current = audioObj;
      audioObj.currentTime = startTime - 1; // buffer
      audioObj.addEventListener('timeupdate', function () {
        setAudioTimeLeft(endTime - this.currentTime);
        if (this.currentTime >= endTime) {
          this.pause();
          audioObj.currentTime = startTime - 1;
        }
      });
      audioObj.addEventListener('loadstart', () => {
        setAudioLoading(true);
      });
      audioObj.addEventListener('loadeddata', () => {
        setAudioLoading(false);
      });
      audioObj.addEventListener('playing', () => {
        setAudioPlaying(true);
      });
      audioObj.addEventListener('pause', () => {
        setAudioPlaying(false);
      });
    }
  }, []);

  return (
    <div className="bg-white rounded-md my-3 shadow">
      {correctedAt ? (
        <div className="text-gray-500 p-2">
          {useFullDate
            ? `${moment(correctedAt).format('MMMM DD, YYYY HH:MM:SS')}`
            : `${speaker ? speaker : ''} [${moment(correctedAt).format(
                'HH:mm:ss',
              )}]`}
        </div>
      ) : null}
      <div className="flex items-center px-2 pt-2">
        {audioRecordingURL ? (
          <audio id={audioID} src={audioRecordingURL} />
        ) : null}
        <XMarkIcon className="w-5 h-5 text-red-500 mr-4 flex-shrink-0" />
        <span
          dangerouslySetInnerHTML={{
            __html: correctionPair?.original ?? '',
          }}
        />

        {audioRecordingURL && audioTimeLeft > 0 ? (
          <span className="flex items-center text-center">
            <SpeakerWaveIcon
              className={cx(
                audioLoading ? 'text-gray-400' : 'cursor-pointer',
                'w-5 h-5 ml-2 flex-shrink-0',
                audioPlaying ? 'text-blue-immigo animate-pulse' : 'text-black',
              )}
              onClick={() => {
                const audioObj: HTMLAudioElement = document.getElementById(
                  audioID,
                ) as HTMLAudioElement;

                if (!audioPlaying) {
                  audioObj.currentTime = startTime - 1; // buffer
                  audioObj.play();
                } else {
                  audioObj.pause();
                }
              }}
            />
            {audioLoading ? <LoadingCircle className="text-gray-400" /> : null}
            <span
              className={cx(
                'ml-2',
                audioPlaying ? 'text-blue-immigo' : 'text-black',
              )}
            >
              {`${moment
                .duration(audioTimeLeft * 1000)
                .minutes()
                .toString()
                .padStart(2, '0')}:${moment
                .duration(audioTimeLeft * 1000)
                .seconds()
                .toString()
                .padStart(2, '0')}`}
            </span>
          </span>
        ) : null}
      </div>
      <div className="flex items-center px-2 py-2">
        <CheckIcon className="w-5 h-5 text-green-500 mr-4 flex-shrink-0" />
        <span
          dangerouslySetInnerHTML={{ __html: correctionPair?.fixed ?? '' }}
        />
      </div>
      {correction?.explanation ? (
        <div className=" flex items-center border-t border-gray-300 p-2">
          <LightBulbIcon className="w-5 h-5 text-yellow-300 mr-4 flex-shrink-0" />
          {correction?.explanation}
          {onCorrectionRating ? (
            <div className="ml-auto">
              {!thumbsUpLoading ? (
                <HandThumbUpIcon
                  className={cx(
                    'w-5 h-5 my-1 cursor-pointer hover:text-green-400',
                    correction?.rating === 'thumb-up'
                      ? 'text-green-500'
                      : 'text-gray-300',
                  )}
                  onClick={async () => {
                    setThumbsUpLoading(true);
                    correction.rating = 'thumb-up';
                    await onCorrectionRating('thumb-up', correction);
                    setThumbsUpLoading(false);
                  }}
                />
              ) : (
                <LoadingCircle className="text-gray-300" />
              )}
              {!thumbsDownLoading ? (
                <HandThumbDownIcon
                  className={cx(
                    'w-5 h-5 my-1 cursor-pointer hover:text-red-400',
                    correction?.rating === 'thumb-down'
                      ? 'text-red-500'
                      : 'text-gray-300',
                  )}
                  onClick={async () => {
                    setThumbsDownLoading(true);
                    correction.rating = 'thumb-down';
                    await onCorrectionRating('thumb-down', correction);
                    setThumbsDownLoading(false);
                  }}
                />
              ) : (
                <LoadingCircle className="text-gray-300" />
              )}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
