import { cx } from 'classix';
import { PartialUserData } from '..';
import {
  registerWithFacebook,
  registerWithGoogle,
} from '../../../../firebase/auth/auth';
import { trackAuthEvents } from '../analytics';

interface AuthProviderProps {
  onAction: Function;
  onSuccess: Function;
  onError: Function;
  isLoading: boolean;
  userDataOverride?: PartialUserData;
}

export const AuthProvider = ({
  onAction,
  onSuccess,
  onError,
  isLoading,
  userDataOverride,
}: AuthProviderProps) => {
  return (
    <div className="mt-6 grid grid-cols-2 gap-4">
      <button
        onClick={async () => {
          onAction();
          trackAuthEvents('Clicked authenticate with Google');
          const { user, error } = await registerWithGoogle(userDataOverride);
          console.log(user);
          if (!error && user) await onSuccess(user?.id);
          else await onError(error);
        }}
        disabled={isLoading}
        className={cx(
          isLoading ? 'bg-gray-300' : 'bg-white cursor-pointer',
          'text-gray-500 border border-gray-400 flex',
          'w-full items-center hover:text-gray-400 justify-center gap-3 rounded-md px-3 py-1.5 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]',
        )}
      >
        <svg className="h-5 w-5" viewBox="0 0 186.69 190.5">
          <g transform="translate(1184.583 765.171)">
            <path
              clipPath="none"
              mask="none"
              d="M-1089.333-687.239v36.888h51.262c-2.251 11.863-9.006 21.908-19.137 28.662l30.913 23.986c18.011-16.625 28.402-41.044 28.402-70.052 0-6.754-.606-13.249-1.732-19.483z"
              fill="#4285f4"
            />
            <path
              clipPath="none"
              mask="none"
              d="M-1142.714-651.791l-6.972 5.337-24.679 19.223h0c15.673 31.086 47.796 52.561 85.03 52.561 25.717 0 47.278-8.486 63.038-23.033l-30.913-23.986c-8.486 5.715-19.31 9.179-32.125 9.179-24.765 0-45.806-16.712-53.34-39.226z"
              fill="#34a853"
            />
            <path
              clipPath="none"
              mask="none"
              d="M-1174.365-712.61c-6.494 12.815-10.217 27.276-10.217 42.689s3.723 29.874 10.217 42.689c0 .086 31.693-24.592 31.693-24.592-1.905-5.715-3.031-11.776-3.031-18.098s1.126-12.383 3.031-18.098z"
              fill="#fbbc05"
            />
            <path
              d="M-1089.333-727.244c14.028 0 26.497 4.849 36.455 14.201l27.276-27.276c-16.539-15.413-38.013-24.852-63.731-24.852-37.234 0-69.359 21.388-85.032 52.561l31.692 24.592c7.533-22.514 28.575-39.226 53.34-39.226z"
              fill="#ea4335"
              clipPath="none"
              mask="none"
            />
          </g>
        </svg>
        <span className="text-sm font-semibold leading-6">Google</span>
      </button>

      <button
        onClick={async () => {
          onAction();
          trackAuthEvents('Clicked authenticate with Facebook');
          const { user, error } = await registerWithFacebook(userDataOverride);
          if (!error && user) await onSuccess(user?.id);
          else await onError(error);
        }}
        disabled={isLoading}
        className={cx(
          isLoading ? 'bg-gray-300' : 'bg-white cursor-pointer',
          'text-gray-500 border border-gray-400 flex',
          'w-full items-center hover:text-gray-400 justify-center gap-3 rounded-md px-3 py-1.5 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]',
        )}
      >
        <svg className="h-5 w-5" viewBox="0 0 14222 14222">
          <circle cx="7111" cy="7112" r="7111" fill="#1977f3" />
          <path
            d="M9879 9168l315-2056H8222V5778c0-562 275-1111 1159-1111h897V2917s-814-139-1592-139c-1624 0-2686 984-2686 2767v1567H4194v2056h1806v4969c362 57 733 86 1111 86s749-30 1111-86V9168z"
            fill="#fff"
          />
        </svg>
        <span className="text-sm font-semibold leading-6">Facebook</span>
      </button>
    </div>
  );
};
