import { useState } from 'react';
import Highlighter from 'react-highlight-words';

export const ClassInsightWordUsageList = (props: {
  word: string;
  sentences: Array<string>;
  onExpandCollapseClick?: Function;
}) => {
  const [expanded, setExpanded] = useState(false);
  const { word, sentences, onExpandCollapseClick } = props;

  return (
    <div className="bg-gray-100 rounded-md p-2 text-gray-700 mt-1">
      <div className="flex justify-between">
        {expanded ? (
          <div>
            {sentences.map((s: string) => {
              return (
                <div className="max-w-[550px] mb-1">
                  <Highlighter
                    searchWords={[word]}
                    textToHighlight={s}
                    highlightClassName="text-green-600 bg-gray-100"
                    autoEscape
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <span>
            <Highlighter
              searchWords={[word]}
              textToHighlight={sentences[0]}
              highlightClassName="text-green-600 bg-gray-100"
              autoEscape
            />
          </span>
        )}
        <span
          className="min-w-[80px] text-gray-400 cursor-pointer break-keep mt-auto"
          onClick={() => {
            onExpandCollapseClick?.(expanded);
            setExpanded(!expanded);
          }}
        >
          {expanded ? 'Collapse' : 'See more..'}
        </span>
      </div>
    </div>
  );
};
