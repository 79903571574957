import { ArrowDownOnSquareIcon } from '@heroicons/react/24/outline';
import { takeScreenShotAndOpen } from '../../../util/canvas';

export const ScreenShotButton = (props: { onClick?: Function }) => {
  const { onClick } = props;
  function onClickHandler() {
    takeScreenShotAndOpen();
    onClick?.();
  }
  return (
    <div
      className="flex items-center justify-center text-white cursor-pointer my-4 text-xl"
      onClick={onClickHandler}
      data-html2canvas-ignore="true"
    >
      <ArrowDownOnSquareIcon className="w-5 h-5 mr-2" /> Share this on your
      socials!
    </div>
  );
};
