import ProgressBar from '@ramonak/react-progress-bar';
import { ReactNode } from 'react';

export const BarStatBox = (props: {
  title: string;
  completed: number;
  description?: string;
  customBottomChild?: ReactNode;
}) => {
  const { title, description, completed, customBottomChild } = props;
  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
      <div className="truncate md:text-xl font-medium text-gray-500">
        {title}
      </div>
      {description ? (
        <div className="text-sm mb-2 text-gray-500">{description}</div>
      ) : null}
      <div>
        <div className="overflow-hidden rounded-full bg-gray-200 w-full">
          <div
            className="h-2 rounded-full bg-blue-immigo"
            style={{ width: `${completed.toFixed(2)}%` }}
          />
        </div>
        {customBottomChild}
      </div>
    </div>
  );
};
