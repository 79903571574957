import { useState, useEffect, useMemo } from 'react';

type BrowserName =
  | 'Netscape'
  | 'Opera'
  | 'Internet Explorer'
  | 'Edge'
  | 'Chrome'
  | 'Safari'
  | 'Firefox'
  | string;

interface BrowserInfo {
  name: BrowserName;
  version: string;
  majorVersion: number;
  navigator: {
    appName: string;
    userAgent: string;
  };
}

// Code from: https://stackoverflow.com/a/22401895/4864104
const getBrowserInfo = (navigator: any): BrowserInfo => {
  let nAgt: string = navigator.userAgent;
  let browserName: BrowserName = navigator.appName;
  let fullVersion = '' + parseFloat(navigator.appVersion);
  let majorVersion = parseInt(navigator.appVersion, 10);
  let nameOffset, verOffset, ix;

  // In Opera, the true version is after "Opera" or after "Version"
  if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
    browserName = 'Opera';
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf('Version')) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
    browserName = 'Internet Explorer';
    fullVersion = nAgt.substring(verOffset + 5);
  }
  // In Edge, user agent includes the text 'Edg' or 'Edge'
  else if (nAgt.toLowerCase().includes('edg')) {
    const browserData = nAgt.substring(nAgt.lastIndexOf(' ') + 1);
    if (browserData.startsWith('Edg')) {
      browserName = 'Edge';
      fullVersion = browserData.substring(browserData.lastIndexOf('/') + 1);
    }
  }
  // In Chrome, the true version is after "Chrome"
  else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
    browserName = 'Chrome';
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
    browserName = 'Safari';
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf('Version')) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In Firefox, the true version is after "Firefox"
  else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
    browserName = 'Firefox';
    fullVersion = nAgt.substring(verOffset + 8);
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else if (
    (nameOffset = nAgt.lastIndexOf(' ') + 1) <
    (verOffset = nAgt.lastIndexOf('/'))
  ) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }

  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(';')) !== -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(' ')) !== -1)
    fullVersion = fullVersion.substring(0, ix);

  majorVersion = parseInt('' + fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = '' + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }

  return {
    name: browserName,
    version: fullVersion,
    majorVersion,
    navigator: {
      appName: navigator.appName,
      userAgent: navigator.userAgent,
    },
  };
};

export const useBrowserInfo = (): BrowserInfo | undefined => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const browserInfo = useMemo<BrowserInfo | undefined>(() => {
    try {
      if (!mounted) return undefined;
      if (typeof window === 'undefined') return undefined;
      return getBrowserInfo(window.navigator);
    } catch (e) {
      return undefined;
    }
  }, [mounted]);

  return browserInfo;
};
