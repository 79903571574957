import emptyAvatar from '../../images/empty-avatar.webp';

interface AvatarProps {
  photoUrl: string | undefined;
  className?: string;
}

export const Avatar = ({ photoUrl, className }: AvatarProps) => {
  return (
    <div className="flex flex-shrink-0">
      <img
        className={`${
          className ? className : 'h-8 w-8'
        } flex flex-shrink-0 rounded-full ring-1 ring-gray-200 object-cover object-center bg-white`}
        src={photoUrl ?? emptyAvatar}
        alt={photoUrl ?? 'avatar'}
      />
    </div>
  );
};
