import { CEFRLevel } from '../../types/level';
import {
  getCurrentCefrBasedOnAttendance,
  getCurrentCefrLevelAndLevelPair,
  getLevelProgressPercentage,
  getNextCefrLevel,
  getNextCefrLevelPair,
  getRemainingClassUntilNextLevel,
} from '../../util/cefr';
import ProgressSteps from './progressSteps';

const steps = [
  { name: 'Level 1', status: 'upcoming' },
  { name: 'Level 2', status: 'upcoming' },
  { name: 'Level 3', status: 'upcoming' },
  { name: 'Level 4', status: 'upcoming' },
];

export const LevelProgress = (props: {
  cefrLevel: CEFRLevel;
  completedLessons: number;
}) => {
  const { cefrLevel, completedLessons } = props;

  const nextCefrPair = getNextCefrLevelPair(cefrLevel, completedLessons);
  const currentCerfPair = getCurrentCefrLevelAndLevelPair(
    cefrLevel,
    completedLessons,
  );
  const maxLevel =
    currentCerfPair?.cefr === 'C2' && currentCerfPair?.level === 4;

  console.log(nextCefrPair);

  function getNextStageString() {
    const remaining = getRemainingClassUntilNextLevel(completedLessons);

    return maxLevel
      ? 'You reached the max level! 🎉'
      : `${remaining} more classes to reach ${nextCefrPair.cefr} Level ${nextCefrPair.level}`;
  }

  return (
    <div className="my-2">
      <div className="mb-2">{`${getNextStageString()}`}</div>
      <div className="overflow-hidden rounded-full bg-gray-200">
        <div
          className="h-4 rounded-full bg-blue-immigo"
          style={{
            width: `${
              maxLevel
                ? 100
                : getLevelProgressPercentage(completedLessons).toFixed(2)
            }%`,
          }}
        />
      </div>
      <div className="mt-4 w-full">
        <ProgressSteps
          steps={steps.map((step, index) => {
            if (maxLevel) {
              step.status = 'complete';
              return step;
            }
            let nextLevelIndex = nextCefrPair.level - 1;
            let currentLevel = getCurrentCefrBasedOnAttendance(
              cefrLevel,
              completedLessons,
            );
            if (nextLevelIndex === 0) nextLevelIndex = 3;
            if (index === nextLevelIndex) step.status = 'current';
            else if (index > nextLevelIndex) step.status = 'upcoming';
            else step.status = 'complete';

            if (index === steps.length - 1 && currentLevel !== 'C2')
              step.name = `${getNextCefrLevel(
                getCurrentCefrBasedOnAttendance(cefrLevel, completedLessons),
              )} Level 1`;

            return step;
          })}
        />
      </div>
    </div>
  );
};
