import { useEffect, useState } from 'react';
import { PrimaryButton } from '../../buttons/primary';
import { SecondaryButton } from '../../buttons/secondary';
import { SecondaryBlueButton } from '../../buttons/secondaryBlue';
import VerticalMessageModal from '../verticalMessageModal/verticalMessageModal';
import { trackBuyMoreCreditModal } from './analytics';
import { slackCustomerNotifier } from '../../../api/slack';
import { createSetUpIntent } from '../../../api/payment';
import { fiveDollarextraClassCredit } from '../../../config/stripe';
import { confirmPaymentIntent, getClientSecret } from '../../../api';
import { addAvailableClassesToUser } from '../../../firebase/subscription/subscription';

export const BuyMoreCreditModal = (props: {
  isOpen: boolean;
  onClose: Function;
  customerId: string;
  userId: string;
  userEmail?: string;
  userPhone?: string;
}) => {
  const { isOpen, onClose, userEmail, userId, userPhone, customerId } = props;

  const [upgradeLoading, setUpgradeLoading] = useState(false);
  const [buyCreditLoading, setBuyCreditLoading] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openPlanModal, setOpenPlanModal] = useState(false);

  useEffect(() => {
    if (isOpen) {
      trackBuyMoreCreditModal('Buy more credit modal shown');
    }
  }, [isOpen]);

  async function onUpgradePlan(plan: string) {
    setUpgradeLoading(true);
    trackBuyMoreCreditModal('Buy more credit modal: Upgrade requested', {
      plan: plan,
    });
    const res = await slackCustomerNotifier(
      `${userEmail} wants: I upgrade plan ${plan} -- WA Link : https://wa.me/${userPhone}`,
    );
    setUpgradeLoading(false);
    setOpenSuccessModal(true);
  }

  async function onBuyCredit() {
    setBuyCreditLoading(true);
    trackBuyMoreCreditModal('Buy more credit modal: Buy credit');

    const res = await getClientSecret(
      4.99 * 100,
      'usd',
      'card',
      userEmail ?? '',
      '',
      '',
      customerId,
    );
    if (res?.data?.paymentIntent?.id) {
      const confirmRes = await confirmPaymentIntent(
        res.data?.paymentIntent?.id,
      );

      console.log(confirmRes);
      if (confirmRes?.data?.data?.status === 'succeeded') {
        setUpgradeLoading(false);
        setOpenSuccessModal(true);
        await addAvailableClassesToUser(userId, 1);
      }
    }
    setBuyCreditLoading(false);
  }

  async function onNoNeedCredit() {
    trackBuyMoreCreditModal('Buy more credit modal: I dont need credit');
    onClose();
  }

  return (
    <VerticalMessageModal
      mainMessage="🧑‍🎓 Do you want to learn more?"
      subMessage="You can either upgrade your plan or buy a class credit to take more classes. Remember to use your credit before your next billing cycle as your credit will reset."
      theme="default"
      icon="exclamation"
      isOpen={isOpen}
      onCloseModal={() => {
        trackBuyMoreCreditModal('Buy more credit modal: Closed');
        onClose();
      }}
    >
      <VerticalMessageModal
        mainMessage="Success!"
        theme="success"
        subMessage="Your credit will be updated within 12 hours! This process will become instant in the future, so bear with us!"
        buttonText="Yay!"
        isOpen={openSuccessModal}
        onButtonClick={() => {
          setOpenSuccessModal(false);
          setOpenPlanModal(false);
          onClose();
        }}
      />

      <VerticalMessageModal
        mainMessage="Which plan do you want to upgrade to?"
        theme="default"
        subMessage="Choose the plan you want to change to"
        icon="pencil"
        isOpen={openPlanModal}
        onCloseModal={() => {
          setOpenPlanModal(false);
        }}
        secondaryButtonText="Not now"
        onSecondaryButtonClick={() => {
          setOpenPlanModal(false);
        }}
      >
        <div className="my-6">
          <div className="my-2">
            <PrimaryButton
              text="8 classes / 4 weeks"
              onClick={async () => {
                await onUpgradePlan('8 classes');
              }}
            />
          </div>
          <div className="my-2">
            <PrimaryButton
              text="12 classes / 4 weeks"
              onClick={async () => {
                await onUpgradePlan('12 classes');
              }}
            />
          </div>
        </div>
      </VerticalMessageModal>
      <div className="my-6">
        <div className="my-2">
          <PrimaryButton
            text="Upgrade plan (Save $2 a class)"
            onClick={() => {
              setOpenPlanModal(true);
            }}
          />
        </div>
        <div className="my-2">
          <SecondaryBlueButton
            loading={buyCreditLoading}
            text="Buy 1 credit ($4.99)"
            onClick={onBuyCredit}
          />
        </div>
        <div className="my-2">
          <SecondaryButton
            text="I don't need more credit!"
            onClick={onNoNeedCredit}
          />
        </div>
      </div>
    </VerticalMessageModal>
  );
};
