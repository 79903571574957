import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { cx } from 'classix';

interface SimpleBackAndNextProps {
  onNext: Function;
  onBack?: Function;
  color?: string;
  hoverColor?: string;
  nextLoading?: boolean;
}

export const SimpleBackAndNext = ({
  onNext,
  onBack,
  color,
  nextLoading,
}: SimpleBackAndNextProps) => {
  return (
    <div
      className={cx(
        'flex justify-between my-8',
        color ? color : 'text-gray-500',
      )}
    >
      {onBack ? (
        <div
          className={cx(
            'flex cursor-pointer item-center mr-2',
            `hover:text-blue-200`,
          )}
          onClick={() => onBack?.()}
        >
          <ArrowLeftIcon className="w-5 h-5 my-auto mr-1" /> Back
        </div>
      ) : null}
      <div
        className={cx('flex cursor-pointer ml-2', `hover:text-blue-200`)}
        onClick={() => (!nextLoading ? onNext() : {})}
      >
        {nextLoading ? (
          <svg
            className="text-gray-400 animate-spin h-5 w-5 m-auto"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        ) : (
          'Next'
        )}{' '}
        <ArrowRightIcon className="w-5 h-5 my-auto ml-1" />
      </div>
    </div>
  );
};
