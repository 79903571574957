import { useDispatch, useSelector } from 'react-redux';
import StackedCardRadio from '../../../components/forms/stackedCardRadio/stackedCardRadio';
import { updateUserData } from '../../../firebase/users/users';
import { UserData } from '../../../types/user';
import { useState } from 'react';
import { stringsPricingPage } from '../../../util/localization';

export const EnglishLevel = (props: {
  onNextClick: Function;
  setValue: Function;
}) => {
  const { onNextClick, setValue } = props;
  const [error, setError] = useState('');

  const choices = [
    {
      name: `🥚 A1 (${stringsPricingPage.beginner})`,
      onClick: async () => {
        setValue('A1');
        setError(stringsPricingPage.levelNotFit);
      },
    },
    {
      name: `🐣 A2 (${stringsPricingPage.elementary})`,
      onClick: async () => {
        setValue('A2');
        onNextClick();
      },
    },
    {
      name: `📈 B1 (${stringsPricingPage.preIntermediate})`,
      onClick: async () => {
        setValue('B1');
        onNextClick();
      },
    },
    {
      name: `🧑‍🎓️ B2 (${stringsPricingPage.intermediate})`,
      onClick: async () => {
        setValue('B2');
        onNextClick();
      },
    },
    {
      name: `🤓 C1 (${stringsPricingPage.upperIntermediate})`,
      onClick: async () => {
        setValue('C1');
        onNextClick();
      },
    },
    {
      name: `🚀 C2 (${stringsPricingPage.advanced})`,
      onClick: async () => {
        setValue('C2');
        onNextClick();
      },
    },
  ];
  return (
    <div>
      <div className="text-2xl text-center">
        {stringsPricingPage.yourEnglishJourney}
      </div>
      <div className="mt-6 text-center">
        {stringsPricingPage.tellUsYourEnglishLevel}
      </div>
      <div className="mt-6">
        <StackedCardRadio choices={choices} error={error} />
      </div>
    </div>
  );
};
