import CountUp from 'react-countup';
import {
  topOccurenceMap,
  topOccurenceMapWithoutCommonWords,
} from '../../../util/data';
import { Fade } from 'react-awesome-reveal';
import { SimpleBackAndNext } from '../../../components/buttons/simpleBackAndNext/simpleBackAndNext';
import { capitalizeFirstLetter } from '../../../util/standardization';
import { ImmigoWhiteLogo } from '../../../components/icons/immigoWhite';
import { ScreenShotButton } from '../../../components/buttons/screenShot/screenShotButton';
import { trackYearlyInsight } from '../../../features/YearlyInsight/analytics';
import { useEffect } from 'react';

export const YearlyInsightWords = (props: {
  numberOfWords?: number;
  wordMap?: any;
  idioms?: Array<string>;
  onNext: Function;
  onBack: Function;
}) => {
  const { numberOfWords, wordMap, idioms, onNext, onBack } = props;
  useEffect(() => {
    trackYearlyInsight('Visited wrapped words page');
  });

  return (
    <div className="text-center flex flex-col items-center justify-center py-24 bg-gradient-to-r from-cyan-500 to-blue-500 h-full md:px-0 px-12">
      <div className="w-48 flex">
        <ImmigoWhiteLogo />
      </div>
      <ScreenShotButton
        onClick={() => {
          trackYearlyInsight('Wrapped share with friends clicked', {
            page: 'words',
          });
        }}
      />
      <div className="font-bold text-3xl md:text-6xl mb-10 text-white max-w-[700px]">
        This year you{' '}
        {(numberOfWords ?? 0) > 100 ? (
          <span>
            spoke{' '}
            <span className="text-pink-300">
              <CountUp end={numberOfWords ?? 0} />{' '}
            </span>
            English words and
          </span>
        ) : null}{' '}
        learned{' '}
        <span className="text-pink-300">
          <CountUp end={idioms?.length ?? 0} />
        </span>{' '}
        phrases at Immigo.
      </div>
      {idioms ? (
        <div className="mb-8">
          <div className="text-white text-xl md:text-3xl font-semibold mb-2">
            🧑‍🎓️ Some of the phrases you learned at Immigo..
          </div>
          {idioms.slice(0, 5).map((i: string, index: number) => {
            return (
              <div className="text-white text-md md:text-2xl">
                {capitalizeFirstLetter(i)}
              </div>
            );
          })}
          {idioms.length - idioms.slice(0, 5).length > 0 ? (
            <div className="text-white text-md md:text-2xl">
              {`...and ${idioms.length - idioms.slice(0, 5).length} more`}
            </div>
          ) : null}
        </div>
      ) : null}
      <div data-html2canvas-ignore="true">
        <SimpleBackAndNext onNext={onNext} onBack={onBack} color="text-white" />
      </div>
    </div>
  );
};
