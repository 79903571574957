import { UserGroupIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropDownFancy from '../../../components/forms/dropDownFancy/dropDownFancy';
import StackedCardRadio from '../../../components/forms/stackedCardRadio/stackedCardRadio';
import HorizontalMessageModal from '../../../components/modals/horizontalMessageModal/horizontalMessageModal';
import { updateUserData } from '../../../firebase/users/users';
import { UserData } from '../../../types/user';
import {
  countryNameToCode,
  nationalities,
  nationalitiesOptions,
} from '../../../util/forms';
import { trackPricingPageEvents } from '../../../features/PricingPage/analytics';
import { stringsPricingPage } from '../../../util/localization';

export const BasedCountry = (props: {
  onNextClick: Function;
  setValue: Function;
}) => {
  const { onNextClick, setValue } = props;
  const [openNationalityModal, setOpenNationalityModal] = useState(false);

  const basedChoices = [
    {
      name: '🇺🇸 United States',
      onClick: () => {
        setValue('US');
        onNextClick();
      },
      userPhotos: [
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FO39r83Ik72MDYys6mCiv9u90hVM2_profile_picture?alt=media&token=9913a15d-b185-438b-8142-438a2cd69041',
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FBKRy7pNX7KS9IRADaQxp3acSUU43_profile_picture?alt=media&token=501de254-21e0-4cc4-b97e-d1b98161341d',
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FCOrTQvG3LtP2xEunVATBFpfmln22_profile_picture?alt=media&token=f372cd2f-f5f5-4ed1-9de2-7a7f9362c44f',
      ],
    },
    {
      name: '🇨🇦 Canada',
      onClick: () => {
        setValue('CA');
        onNextClick();
      },
      userPhotos: [
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FtMTlVSBRYub0K6xD4MLTGyp0UOZ2_profile_picture?alt=media&token=1225fb1b-9fc7-4cce-957f-100ecc7752ee',
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FgqDv9cdeQfYrjojxYhPhHEK5r9o2_profile_picture?alt=media&token=47bd6146-a61d-4d31-a694-5c689ba83be9',
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FEofnNvqRtGeh1PG9iDSd37tjcCl1_profile_picture?alt=media&token=e8dddb0d-29e2-437a-9959-38047314007c',
      ],
    },
    {
      name: '🇬🇧 United Kingdom',
      onClick: () => {
        setValue('UK');
        onNextClick();
      },
      userPhotos: [
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FFiVTwU2LaUZ7Yszg8AISjgcckkt1_profile_picture?alt=media&token=b320d2e9-c1f2-4f01-bdaa-7287281c6d85',
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FvaglHW4bmRWj75UbG3yj8Uz0uft1_profile_picture?alt=media&token=973223df-a8a9-4df9-b645-d35bbf22424a',
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FPtktGU2rd6Tuvn67igRdi99GynD3_profile_picture?alt=media&token=1ef78c87-a412-4e81-8166-da7d033c211c',
      ],
    },
    {
      name: `💭 ${stringsPricingPage.other}`,
      onClick: () => {
        trackPricingPageEvents('Opened based country modal on pricing page');
        setOpenNationalityModal(true);
      },
    },
  ];
  return (
    <div>
      <HorizontalMessageModal
        mainMessage={stringsPricingPage.letsFindYourCountry}
        buttonText={stringsPricingPage.submit}
        subMessage={stringsPricingPage.chooseYourCountryPressSubmit}
        onButtonClick={() => {
          setOpenNationalityModal(false);
          onNextClick();
        }}
        onCloseModal={() => {
          trackPricingPageEvents('Closed based country modal on pricing page');
          setOpenNationalityModal(false);
        }}
        theme="default"
        icon="flag"
        isOpen={openNationalityModal}
      >
        <div className="mt-6">
          <DropDownFancy
            title=""
            onOptionSelected={(selected: any) => {
              const code = countryNameToCode(selected?.name);
              setValue(code);
            }}
            options={nationalities.map((n: string) => {
              return { name: n, id: n };
            })}
          />
        </div>
      </HorizontalMessageModal>
      <div className="text-2xl text-center">
        {stringsPricingPage.whichCountryDoYouLiveIn}
      </div>
      <div className="mt-6 text-center max-w-[500px]">
        {stringsPricingPage.manyAreImmigrants}
      </div>
      <div className="mt-6">
        <StackedCardRadio choices={basedChoices} />
      </div>
    </div>
  );
};
