import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { initializeFirebase } from '../../configValues';
const { firestore: db } = initializeFirebase();

export const getAllCoaches = async () => {
  const coachesReference = await collection(db, 'coaches');
  const coaches = await getDocs(coachesReference);
  const coachesData: any[] = [];
  coaches.forEach((c: any) => {
    const data = c.data();
    data.id = c.id;
    coachesData.push(data);
  });
  return coachesData;
};

export const getCoachDocument = async (coachID: string) => {
  const coachRef = await doc(db, 'coaches', coachID);
  const coachDoc = await getDoc(coachRef);
  const coachData = coachDoc.data();
  if (coachData) coachData.id = coachID;
  return coachData;
};
