import { map, sum, filter, flowRight, orderBy } from 'lodash';
import { initializeFirebase } from '../../firebase/configValues';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
const { firestore: db } = initializeFirebase();

export type TotalTimeInMeetings = {
  totalTime: number;
  weeklyTime: number[];
};

export type CourseData = {
  id: string;
  courseTitle: string;
  attendanceRate: string;
  attendedWeeks: number;
  coursePeriodWeeks: number;
  missedWeeks: number;
};

type AttendanceRate = {
  id?: string;
  attendanceRate: string;
  attendedWeeks: number;
  coursePeriodWeeks: number;
  courseTitle: string;
  endDate: number;
  missedWeeks: number;
  startDate: number;
};

type AttendanceRateCollection = {
  [key: string]: AttendanceRate;
};

export async function getTotalTimeInMeetingsData(
  userId: string,
  courseId: string,
): Promise<TotalTimeInMeetings> {
  const minutesPerWeek: number[] = [];
  const meetingSessionRef = query(
    collection(db, 'meetingSessions'),
    where('attendants', 'array-contains', userId),
    where('courseID', '==', courseId),
  );
  const meetingSessionDocs = await getDocs(meetingSessionRef);

  meetingSessionDocs.forEach((meetingSessionDoc) => {
    const meetingSession = meetingSessionDoc.data();

    /**
     * The `else` clause here is being used as a safety net. It could be possible that a `meetingSession.userTimeData`
     * doesn't have a user in it. If that happens, we add 0 to the array.
     *
     * This is going to be used for the data visualization.
     *
     * I (@alejandro) couldn't find an example where a user didn't show up for a podMeeting and that we have a value
     * like `userID: 0`. Once we have a `reportCard` collection, we can get rid of this because that collection
     * will have all the data available for the client.
     */
    if (meetingSession?.userTimeData?.hasOwnProperty(userId)) {
      minutesPerWeek.push(meetingSession?.userTimeData[userId]);
    } else {
      minutesPerWeek.push(0);
    }
  });

  const totalTimeInPodMeetings = sum(minutesPerWeek);

  return {
    totalTime: totalTimeInPodMeetings,
    weeklyTime: minutesPerWeek,
  };
}

export async function getAllCoursesAttendanceForUser(
  userId: string,
): Promise<CourseData[]> {
  const userRef = await getDoc(doc(db, 'users', userId));

  if (!userRef.exists()) {
    return [];
  }

  const document = userRef.data();

  const result = flowRight(
    mapCollectionToResponseShape,
    transformArrayToMapById,
    removeAttendanceWithNullValues,
    attachIdToAttendanceObject,
  )(document?.attendancePerCourse);

  return result;
}

const removeAttendanceWithNullValues = (collection: AttendanceRateCollection) =>
  filter(collection, Boolean);

const attachIdToAttendanceObject = (collection: AttendanceRateCollection) =>
  map(collection, (attendance, courseId) => {
    if (!attendance) {
      return null;
    }

    return {
      ...attendance,
      id: courseId,
    };
  });

const mapCollectionToResponseShape = (collection: AttendanceRateCollection) =>
  map(collection, (attendance) => ({
    id: attendance.id,
    courseTitle: attendance.courseTitle,
    attendanceRate: attendance.attendanceRate,
    attendedWeeks: attendance.attendedWeeks,
    coursePeriodWeeks: attendance.coursePeriodWeeks,
    missedWeeks: attendance.missedWeeks,
  }));

const transformArrayToMapById = (collection: AttendanceRateCollection) =>
  orderBy(collection, 'id');
