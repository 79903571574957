import { useMemo } from 'react';
import {
  doc,
  getDoc,
  updateDoc,
  type DocumentData,
  type DocumentSnapshot,
  type FirestoreError,
} from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { initializeFirebase } from '../configValues';
import { SubTier } from '../../config/stripe';
const { firestore: db } = initializeFirebase();

export interface AffiliateDiscount {
  creator: {
    firstName: string;
    lastName: string;
    email: string;
  };
  value: number;
  isPercentage: boolean;
  uses?: Array<{
    userId: string;
    courseId: string;
    date: string;
  }>;
}

export const referralCodeExists = async (referralCode?: string) => {
  if (!referralCode) return undefined;
  try {
    const ref = await getDoc(doc(db, 'affiliates', referralCode));
    return ref.exists();
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getAffiliateReference = (referralCode?: string) => {
  if (!referralCode) return undefined;
  try {
    return doc(db, 'affiliates', referralCode);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

interface HookProps {
  discount: AffiliateDiscount | undefined;
  loading: boolean;
  error?: FirestoreError;
  snapshot?: DocumentSnapshot<DocumentData>;
  additionalInfo?: { usedByUser: boolean; invalid: boolean };
  registerDiscountUsageByUser?: (
    courseId?: string,
    subOption?: SubTier,
    trialDays?: number,
  ) => Promise<boolean>;
}

export const useAffiliateDiscountFromFirestore = (
  referralCode?: string,
  userId?: string,
): HookProps => {
  const documentReference = getAffiliateReference(referralCode);

  if (referralCode) referralCode = referralCode?.toLowerCase(); // standardize
  const [value, loading, error, snapshot] = useDocumentData(documentReference);

  const discount = useMemo<AffiliateDiscount | undefined>(() => {
    return value && !loading ? ({ ...value } as AffiliateDiscount) : undefined;
  }, [value, loading]);

  const hasBeenUsedByUser = useMemo<boolean>(() => {
    if (!userId) return false; // If there's no user info, consider it as not used
    if (!discount) return true; // If there's no existing discount, consider it as used
    const { uses } = discount;
    // If some of the code uses includes this userId, return true (used)
    if (uses && uses.length) return uses.some((use) => use.userId === userId);
    return false;
  }, [discount, userId]);

  const registerDiscountUsageByUser = async (
    courseId?: string,
    subOption?: SubTier,
    trialDays?: number,
  ): Promise<boolean> => {
    if (!documentReference) return false;
    try {
      const usageDoc: any = { userId, date: new Date().getTime() };
      if (courseId) usageDoc.courseId = courseId;
      if (subOption) usageDoc.subOption = subOption;
      if (trialDays) usageDoc.trialDays = trialDays;
      updateDoc(documentReference, {
        uses: [...(discount?.uses || []), usageDoc],
      });
      return true;
    } catch (e) {
      return false;
    }
  };

  return {
    discount:
      // If the code has been used by the given user, then we return no discount
      hasBeenUsedByUser ? undefined : discount,
    loading,
    error,
    snapshot,
    additionalInfo: {
      usedByUser: hasBeenUsedByUser,
      invalid: !loading && (Boolean(error) || !Boolean(value)),
    },
    registerDiscountUsageByUser,
  };
};
