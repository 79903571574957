import { Fade, Slide } from 'react-awesome-reveal';
import CountUp from 'react-countup';
import { SimpleBackAndNext } from '../../../components/buttons/simpleBackAndNext/simpleBackAndNext';
import { ArrowDownOnSquareIcon } from '@heroicons/react/24/outline';
import { ImmigoWhiteLogo } from '../../../components/icons/immigoWhite';
import { takeScreenShotAndOpen } from '../../../util/canvas';
import { ScreenShotButton } from '../../../components/buttons/screenShot/screenShotButton';
import { useEffect } from 'react';
import { trackYearlyInsight } from '../../../features/YearlyInsight/analytics';

export const YearlyInsightClassInsight = (props: {
  numberOfClasses?: number;
  classTime?: number;
  onNext: Function;
  onBack: Function;
}) => {
  const { numberOfClasses, classTime, onNext, onBack } = props;

  useEffect(() => {
    trackYearlyInsight('Visited wrapped class insight page');
  });

  return (
    <div className="text-center flex flex-col justify-center items-center py-24 bg-gradient-to-r from-pink-400 to-blue-500 h-full">
      <div className="w-48 flex">
        <ImmigoWhiteLogo />
      </div>
      <ScreenShotButton
        onClick={() => {
          trackYearlyInsight('Wrapped share with friends clicked', {
            page: 'class',
          });
        }}
      />
      <div className="font-bold text-4xl md:px-0 px-12 md:text-6xl mb-10 text-white max-w-[800px] break-normal">
        You've taken
        <span className="text-blue-300">
          {' '}
          <CountUp end={numberOfClasses ?? 0} duration={2} />{' '}
        </span>
        classes this year, that is over
        <span className="text-blue-300">
          {' '}
          <CountUp end={classTime ?? 0} duration={7} />{' '}
        </span>
        minutes of learning English at Immigo!
      </div>
      <div data-html2canvas-ignore="true">
        <SimpleBackAndNext color="text-white" onNext={onNext} onBack={onBack} />
      </div>
    </div>
  );
};
