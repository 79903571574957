import AliPayForm from '../../../forms/payment/alipay/index';
// import WeChatPaymentForm from '../../../forms/payment/wechat/index';
import CardPaymentForm from '../../../forms/payment/card/index';

export type PaymentMethod = 'card' | 'wechat' | 'alipay';

export const PaymentMethods = (props: {
  price: number | string; // could be price_id from Stripe
  isSubscribing: boolean;
  activePaymentMethod: PaymentMethod;
  userData: any;
  onPaymentMethodSelected: (selectedMethod: PaymentMethod) => void;
  onSubmit: Function;
  onSuccess: Function;
  onFailure: Function;
  subOption?: number;
  planName?: string;
  subscriptionPrice?: number;
  disabled?: boolean;
  trial?: boolean;
}) => {
  const {
    price,
    userData,
    onSubmit,
    onSuccess,
    onFailure,
    onPaymentMethodSelected,
    isSubscribing,
    activePaymentMethod,
    subOption,
    planName,
    subscriptionPrice,
    disabled,
    trial,
  } = props;
  function renderPaymentOption() {
    if (activePaymentMethod === 'card')
      return (
        <CardPaymentForm
          disabled={disabled}
          price={price} // Check if we are using credit : Meaning we don't need to subscribe so just pay the diff
          subscription={isSubscribing} // User opted into sub & has sub plans for course
          userData={userData}
          onSubmit={() => {
            onSubmit();
          }}
          onFailure={() => {
            onFailure();
          }}
          onSuccess={onSuccess}
          trial={trial}
        />
      );
    // if (activePaymentMethod === 'wechat')
    // return <WeChatPaymentForm
    //     price={price} // Check if we are using credit : Meaning we don't need to subscribe so just pay the diff
    //     subscription={isSubscribing} // User opted into sub & has sub plans for course
    //     userData={userData}
    //     onSubmit={() => {
    //         onSubmit();
    //     }}
    //     onFailure={() => {
    //         onFailure();
    //     }}
    //     onSuccess={onSuccess}
    // />, // Disable wechat for now
    if (activePaymentMethod === 'alipay')
      return (
        <AliPayForm
          price={price} // Check if we are using credit : Meaning we don't need to subscribe so just pay the diff
          subscription={isSubscribing} // User opted into sub & has sub plans for course
          userData={userData}
          onSubmit={() => {
            onSubmit();
          }}
          onFailure={() => {
            onFailure();
          }}
          onSuccess={onSuccess}
          extraParam={
            subOption !== undefined && subOption < 2 // This is a bad hack to default bundle selection ..
              ? '/' + subOption
              : undefined
          }
          trial={trial}
        />
      );
    return null;
  }

  return (
    <>
      <div className="py-2 font-semibold">Payment Options:</div>
      <div className={'flex items-center flex-wrap flex-1 gap-3'}>
        <button
          className={`cursor-pointer md:mx-0 mx-auto w-36 p-2 shadow-xs border bg-white rounded-md font-semibold ${
            activePaymentMethod === 'card' ? 'text-blue-immigo' : ''
          }`}
          style={
            activePaymentMethod === 'card'
              ? {
                  border: '2px',
                  borderStyle: 'solid',
                }
              : {}
          }
          onClick={() => {
            onPaymentMethodSelected('card');
          }}
        >
          Card
        </button>
        {/* <button
                        className={`cursor-pointer md:mx-0 mx-auto p-2 w-36 shadow-xs border bg-white rounded-md font-semibold ${(activePaymentOption === 1) ? 'text-blue-immigo' : ''}`}
                        style={(activePaymentMethod === 1) ? {
                            border:"2px",
                            borderStyle:"solid"
                        } : {}}
                        onClick={() => {onPaymentMethodSelected(1)}}
                    >
                        WeChat Pay
                    </button> */}
        {!trial ? (
          <button
            className={`cursor-pointer md:mx-0 mx-auto p-2 w-36 shadow-xs border bg-white rounded-md font-semibold ${
              activePaymentMethod === 'alipay' ? 'text-blue-immigo' : ''
            }`}
            style={
              activePaymentMethod === 'alipay'
                ? {
                    border: '2px',
                    borderStyle: 'solid',
                  }
                : {}
            }
            onClick={() => {
              onPaymentMethodSelected('alipay');
            }}
          >
            Alipay
          </button>
        ) : null}
      </div>
      {isSubscribing ? (
        <div className="pt-4">
          <div className="font-bold w-72">
            {'You have chosen '}{' '}
            <span className="text-blue-immigo">{planName}</span>
          </div>
          <div>
            {'Note that you will be charged $' +
              subscriptionPrice +
              ' per month for your subscription'}
          </div>
        </div>
      ) : null}
      <div className={'pt-4'}>{renderPaymentOption()}</div>
    </>
  );
};
