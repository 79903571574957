import { cx } from 'classix';
import { Avatar } from '../../../components/avatar/avatar';
import { getObscuredName } from '../../../util/standardization';
import {
  Bars3Icon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/solid';
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from '@heroicons/react/24/outline';
import { ReactElement } from 'react';

type RankingChange = 'increased' | 'decreased' | 'same';

export const RankingEntry = (props: {
  rank: number;
  id: string;
  onAvatarClick?: Function;
  profileImageUrl?: string;
  highlight?: boolean;
  rankingChange?: RankingChange;
  customIcon?: ReactElement;
  name: string;
  count: number;
  previousCount?: number;
}) => {
  const {
    rank,
    profileImageUrl,
    onAvatarClick,
    id,
    name,
    count,
    highlight,
    rankingChange,
    customIcon,
    previousCount,
  } = props;

  function renderRankingChange(change: RankingChange) {
    switch (change) {
      case 'increased':
        return <ChevronDoubleUpIcon className="w-4 h-4 ml-2 text-green-500" />;
      case 'decreased':
        return <ChevronDoubleDownIcon className="w-4 h-4 ml-2 text-red-500" />;
      case 'same':
        return <Bars3Icon className="w-4 h-4 ml-2 text-gray-500" />;
      default:
        return null;
    }
  }

  function rankRenderer() {
    switch (rank) {
      case 1:
        return <span className="text-3xl">🥇</span>;
      case 2:
        return <span className="text-3xl">🥈</span>;
      case 3:
        return <span className="text-3xl">🥉</span>;
      default:
        return rank;
    }
  }

  return (
    <div
      className={cx(
        'flex items-center py-2',
        highlight
          ? 'border-l-4 border-blue-immigo bg-gradient-to-r from-blue-200'
          : '',
      )}
      key={id}
    >
      {rankingChange ? renderRankingChange(rankingChange) : null}
      <div
        className={cx(
          'font-semibold mr-4 ml-2 text-xl',
          highlight ? 'text-blue-immigo' : '',
        )}
      >
        {rankRenderer()}
      </div>
      <span
        className="cursor-pointer"
        onClick={() => {
          onAvatarClick?.();
        }}
      >
        <Avatar photoUrl={profileImageUrl} />
      </span>
      <div
        className={cx('ml-4 text-xl', highlight ? 'text-blue-immigo' : null)}
      >
        {getObscuredName(name)}
      </div>
      <div className="ml-auto flex items-center">
        <div className="flex items-center mr-1">
          {renderRankingChange(
            !previousCount || previousCount === count
              ? 'same'
              : previousCount < count
              ? 'increased'
              : 'decreased',
          )}
        </div>
        {customIcon}
        <div
          className={cx(
            'font-semibold',
            highlight ? 'text-blue-immigo' : 'text-gray-500',
          )}
        >
          {count}
        </div>
        <div className="text-xs text-gray-400 mt-auto ml-1">
          {previousCount}
        </div>
      </div>
    </div>
  );
};
