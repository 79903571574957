import { LoadingCircle } from '../../icons/loadingCircle';

export const SecondaryRedButton = (props: {
  text: string;
  onClick: Function;
  loading?: boolean;
}) => {
  const { text, onClick, loading } = props;
  return (
    <button
      className="flex justify-center items-center text-sm text-red-warning font-bold w-full py-2 px-4 rounded-md hover:text-red-400"
      onClick={(e) => onClick(e)}
    >
      {loading ? <LoadingCircle className="mx-2" /> : null}
      {text}
    </button>
  );
};
