export function ReportCardIntro({ name }: { name: string }) {
  return (
    <div>
      <h1 className="text-4xl font-bold text-gray-700 capitalize">
        <span className="animate-[wiggle_1s_ease-in-out_infinite] inline-block">
          👋
        </span>{' '}
        Hello, {name}
      </h1>
      <p className="text-md mt-2">
        We're super glad you're here, we've just prepared{' '}
        <strong>your report</strong>. Let's take a look at how much you've
        learned during your time at Immigo.
      </p>
    </div>
  );
}
