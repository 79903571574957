import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import { cx } from 'classix';

export interface BasicStatBox {
  name: string;
  stat: string;
  unit: string;
  subText?: string;
  change?: string;
  changeType?: string;
}

export function BasicStatBox(props: { statArray: Array<BasicStatBox> }) {
  const { statArray } = props;

  return (
    <div className="flex">
      <dl className="grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow w-full">
        {statArray.map((item) => (
          <div key={item.name} className="px-4 py-2 sm:p-4">
            <dt className="truncate md:text-xl font-medium text-gray-500">
              {item.name}
            </dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-xl font-semibold text-blue-immigo">
                {item.stat}
                <span className="ml-2 text-sm font-medium text-gray-500">
                  {item.unit}
                </span>
              </div>
              {item.subText ? (
                <div
                  className={cx(
                    item.changeType === 'increase'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800',
                    'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0',
                  )}
                >
                  {item.changeType === 'increase' ? (
                    <ArrowUpIcon
                      className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <ArrowDownIcon
                      className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                      aria-hidden="true"
                    />
                  )}
                  <span className="sr-only">
                    {' '}
                    {item.changeType === 'increase'
                      ? 'Increased'
                      : 'Decreased'}{' '}
                    by{' '}
                  </span>
                  {item.subText}
                </div>
              ) : null}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
