import { BookingData } from '../firebase/subscription/subscription';
import { ClassType } from '../types/class';

export function getTheLatestAttendedBooking(bookings: Array<BookingData>) {
  const attendedClasses = bookings.filter((b) => {
    return b.attended && !b.canceled;
  });
  attendedClasses.sort((a, b) => {
    return b.startMili - a.startMili;
  });

  return attendedClasses[0];
}

export function tagToColorStyle(tag: ClassType) {
  switch (tag) {
    case 'grammar':
      return 'bg-purple-50 text-purple-700 ring-purple-700/10';
    case 'pronunciation':
      return 'bg-red-50 text-red-700 ring-red-700/10';
    case 'vocab':
      return 'bg-pink-50 text-pink-700 ring-pink-700/10';
    case 'idiom':
      return 'bg-yellow-50 text-yellow-700 ring-yellow-700/10';
    case 'free-talking':
      return 'bg-cyan-50 text-cyan-700 ring-cyan-700/10';
    default:
  }
}
