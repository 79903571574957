const INITIAL_STATE = {
  authUser: null,
  userData: null,
};

const applySetAuthUser = (state: any, action: any) => ({
  ...state,
  authUser: action.authUser,
});

const applySetUserData = (state: any, action: any) => ({
  ...state,
  userData: action.userData,
});

const applyUpdateUserCredit = (state: any, action: any) => ({
  ...state,
  userData: { ...state.userData, availableClasses: action.availableClasses },
});

const applyUpdateUserPoints = (state: any, action: any) => ({
  ...state,
  userData: { ...state.userData, points: action.points },
});

export function sessionReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case 'AUTH_USER_SET': {
      return applySetAuthUser(state, action);
    }
    case 'USER_DATA_SET': {
      return applySetUserData(state, action);
    }
    case 'UPDATE_USER_CREDIT': {
      return applyUpdateUserCredit(state, action);
    }
    case 'UPDATE_USER_POINTS': {
      return applyUpdateUserPoints(state, action);
    }
    default:
      return state;
  }
}
