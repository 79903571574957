import { UserData } from '../../../types/user';
import { getObscuredName } from '../../../util/standardization';
import { Avatar } from '../../avatar/avatar';

export default function PeopleCheckBoxTable(props: {
  userList: Array<Partial<UserData>>;
  title?: string;
  chosenMembers: Array<Partial<UserData>>;
  onChosenChange?: Function;
}) {
  const { userList, title, onChosenChange, chosenMembers } = props;

  function handleChosenChange(person: Partial<UserData>, checked: boolean) {
    if (checked) {
      // If the person was chosen
      if (!chosenMembers.map((m) => m.id).includes(person?.id))
        onChosenChange?.([...chosenMembers, person]);
    } else {
      // If the person was deselected
      onChosenChange?.([...chosenMembers.filter((c) => c.id !== person.id)]);
    }
  }

  return (
    <fieldset>
      <legend className="text-base font-semibold leading-6 text-gray-900">
        {title}
      </legend>
      <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200 max-h-[500px] overflow-scroll px-2">
        {userList.map((person, personIdx) => (
          <div key={personIdx} className="relative flex items-start py-4">
            <div className="min-w-0 flex-1 text-sm leading-6 flex items-center">
              <Avatar photoUrl={person.profilePictureLink} />
              <label
                htmlFor={`person-${person.id}`}
                className="select-none font-medium text-gray-900 ml-2"
              >
                {getObscuredName(person.name ?? '')}
              </label>
            </div>
            <div className="ml-3 flex h-6 items-center">
              <input
                id={`person-${person.id}`}
                name={`person-${person.id}`}
                onChange={(e) => {
                  handleChosenChange(person, e.target.checked);
                }}
                checked={chosenMembers.map((m) => m.id).includes(person?.id)}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-blue-immigo focus:ring-blue-immigo"
              />
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
}
