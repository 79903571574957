import mixpanel from '../../analytics/mixpanel';

type Events =
  | 'Visited my classes page'
  | 'Clicked upcoming classes on My Classes page'
  | 'Clicked past classes on My Classes page'
  | 'Clicked cancel on booking cancel confirmation modal on My Classes page'
  | 'Clicked not today on booking cancelation modal on My Classes page'
  | 'Closed booking cancelation modal on My Classes page'
  | 'Canceled booking in my classes page'
  | 'Clicked add to my calendar on my classes page'
  | 'Clicked slide link on My Classes page class container'
  | 'Clicked recording link on My Classes page class container'
  | 'Clicked class link on My Classes page class container';

type MyClassesData = {
  userID?: string;
  bookingTitle?: string;
  bookingStartMili?: number;
};

export const trackMyClassesAnalytics = (
  event: Events,
  data: MyClassesData = {},
) => {
  mixpanel.track(event, data);
};
