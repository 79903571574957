import PeopleCheckBoxTable from '../../../../../../components/forms/peopleCheckBoxTable/peopleCheckBoxTable';
import HorizontalMessageModal from '../../../../../../components/modals/horizontalMessageModal/horizontalMessageModal';
import { BookerData } from '../../../../../../types/bookings';

export const WhoDidYouTalkTo = (props: {
  bookers: Array<BookerData>;
  chosenMembers: Array<BookerData>;
  onChosenChange: Function;
  onDidNotTalkToAnyone: Function;
  confirmModalOpen: boolean;
  onConfirmModalClose: Function;
  confirmLoading: boolean;
}) => {
  const {
    bookers,
    onChosenChange,
    chosenMembers,
    onDidNotTalkToAnyone,
    confirmModalOpen,
    onConfirmModalClose,
    confirmLoading,
  } = props;

  return (
    <>
      <HorizontalMessageModal
        mainMessage="Did you not talk to anyone?"
        subMessage={`You didn't select anyone who you talked to for this class. Did you not talk to anyone?`}
        buttonText={`I didn't talk to anyone`}
        secondaryButtonText="Back"
        secondaryButtonOnClick={onConfirmModalClose}
        onCloseModal={onConfirmModalClose}
        onButtonClick={() => {
          onDidNotTalkToAnyone();
        }}
        theme={'info'}
        icon={'exclamation'}
        isOpen={confirmModalOpen}
        loading={confirmLoading}
      />
      <div className="text-gray-500 mb-2">
        Please take 1 minute to answer this question to help us provide you more
        customized experience. Your response will be completely anonymous and
        will only be used to improve your experience internally.
      </div>
      <PeopleCheckBoxTable
        userList={bookers}
        title="Who did you talk to today?"
        chosenMembers={chosenMembers}
        onChosenChange={onChosenChange}
      />
    </>
  );
};
