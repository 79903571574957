import { SubTier } from '../../stripe';

export let classesSubscriptionTiersYearlyKorea: Array<SubTier> = [
  // New prices
  {
    name: `베이직`,
    id:
      process.env.REACT_APP_ENV === 'PROD'
        ? 'price_1Od1GMIJPWWhjYyoD7Xz7n7X'
        : 'price_1OcEXAIJPWWhjYyoykHe8Lpw',
    price: {
      krw: 89000,
    },
    classesCount: 52,
    discount: {
      krw: 641.1,
    },
    description: 'theEssentials',
    features: [
      `Up to 140 minutes of speaking time`,
      `Book up to 4 sessions per 4 weeks`,
    ],
    mostPopular: false,
  },
  {
    name: `프리미엄`,
    classesCount: 104,
    id:
      process.env.REACT_APP_ENV === 'PROD'
        ? 'price_1Od1FlIJPWWhjYyoumU5FIsE'
        : 'price_1OcEYyIJPWWhjYyoJ7WWmS5z',
    price: {
      krw: 174000,
    },
    discount: {
      krw: 1474.1,
    },
    description: 'thePerfect',
    features: [
      `Up to 280 minutes of speaking time`,
      `Book up to 8 sessions per 4 weeks`,
    ],
    mostPopular: true,
  },
  {
    name: `프리미엄 플러스`,
    classesCount: 156,
    id:
      process.env.REACT_APP_ENV === 'PROD'
        ? 'price_1Od1FCIJPWWhjYyo1057UAoi'
        : 'price_1OcEbIIJPWWhjYyo4rXGSY9Y',
    price: {
      krw: 249600,
    },
    discount: {
      krw: 2310.5,
    },
    description: 'theReserved',
    features: [
      `Up to 480 minutes of speaking time`,
      `Book up to 12 sessions per 4 weeks`,
    ],
    mostPopular: false,
  },
];
