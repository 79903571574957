import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import { initializeFirebase } from '../../configValues';
import { updateUserData } from '../../users/users';
const { firestore: db } = initializeFirebase();

export const getAllClasses = async () => {
  const classesReference = await collection(db, 'classes');
  const classes = await getDocs(classesReference);
  const classesData: any[] = [];
  classes.forEach((c: any) => {
    const data = c.data();
    data.id = c.id;
    classesData.push(data);
  });
  return classesData;
};

export const getClass = async (classID: string) => {
  const classRef = await doc(db, 'classes', classID);
  const classData = await getDoc(classRef);
  return classData.data();
};

export const updateClass = async (classID: string, updateMap: any) => {
  const classRef = await doc(db, 'classes', classID);
  const update = await updateDoc(classRef, updateMap);
  return update;
};

export const updateRescheduleOnClass = async (
  classID: string,
  originalMili: number,
  changedMili: number,
) => {
  const classData = await getClass(classID);
  const reschedule = classData?.reschedule ?? {};
  reschedule[originalMili] = changedMili;
  await updateClass(classID, {
    reschedule: reschedule,
  });
  console.log(reschedule);
  return reschedule;
};

export const updateClassCredit = async (userID: string, newCredit: number) => {
  await updateUserData(userID, {
    availableClasses: newCredit,
  });
};
