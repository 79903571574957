import { RankingContainer } from '../../features/HomePage/ranking/RankingContainer';

export const RankingPage = (props: { uid: string }) => {
  const { uid } = props;
  return (
    <div>
      <RankingContainer uid={uid} limit={800} />
    </div>
  );
};
