import * as yup from 'yup';

import { FormData, SchemaFor } from '../../../../components/forms/hooks';

export const cefrLevels = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
type CEFRLevel = typeof cefrLevels[number];

export const usesEnglishOptions = [
  'Not at all',
  'Not at all, but I am looking to move to a job that will require me to speak English',
  'Yes, sometimes',
  'Yes, frequently',
];
type UsesEnglish = typeof usesEnglishOptions[number];

export interface EnglishDetailInformationData extends FormData {
  currentlyHasTutor?: boolean;
  usesEnglishAtWork?: UsesEnglish;
  cefrLevel?: CEFRLevel;
}

export const validationSchema: SchemaFor<EnglishDetailInformationData> = {
  currentlyHasTutor: yup
    .boolean()
    .defined('This field is required.')
    .required('This field is required.'),
  usesEnglishAtWork: yup
    .string()
    .oneOf(usesEnglishOptions, 'This is not a valid option')
    .required('This field is required.'),
  cefrLevel: yup
    .string()
    .oneOf(cefrLevels, 'This is not a valid option')
    .required('This field is required.'),
};
