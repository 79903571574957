import { arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore';
import { initializeFirebase } from '../configValues';
import { updateUserData } from '../users/users';
import {
  addAvailableClassesToUser,
  updateAvailableClassesToUser,
} from '../subscription/subscription';

const { firestore: db } = initializeFirebase();

export async function addUserToOrganization(userID: string, orgID: string) {
  try {
    const organizationDoc = doc(db, 'organization', orgID);
    const organizationData = await getDoc(organizationDoc);
    const organization = organizationData.data();
    let credit = organization?.defaultCredit;

    if (organization?.active?.includes(userID)) {
      return false;
    } else {
      await updateUserData(userID, {
        organization: orgID,
      });
      await updateDoc(organizationDoc, {
        active: arrayUnion(userID),
      });
      if (credit) await updateAvailableClassesToUser(userID, credit);
      return true;
    }
  } catch (err) {
    console.log(err);
  }
}
