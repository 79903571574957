import mixpanel from '../../analytics/mixpanel';

type Events =
  | 'Shown trial or buy now option'
  | 'Clicked try free trial button on the application flow'
  | 'Clicked buy now on the application flow'
  | 'Saved card information'
  | 'Chose single course'
  | 'Chose 3 course bundle'
  | 'Chose 6 courses bundle';

type CheckOutData = {
  userID?: string;
  email?: string;
  courseID?: string;
};

export const trackCheckoutEvent = (event: Events, data: CheckOutData = {}) => {
  mixpanel.track(event, data);
};
