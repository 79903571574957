export const oneDollarPerClass = {
  currency: ['krw'],

  // Yearly
  price_1Od1GMIJPWWhjYyoD7Xz7n7X: {
    coupon: 'XjKuz9rg',
    amount: {
      krw: 26600,
    },
  }, // 4 prod
  price_1OcEXAIJPWWhjYyoykHe8Lpw: {
    coupon: 'jm3lXWqN',
    amount: {
      krw: 26600,
    },
  }, // 4 dev
  price_1Od1FlIJPWWhjYyoumU5FIsE: {
    coupon: '9dKt0WIZ',
    amount: {
      krw: 70000,
    },
  }, // 8 prod
  price_1OcEYyIJPWWhjYyoJ7WWmS5z: {
    coupon: 'ajy8Cgq5',
    amount: {
      krw: 70000,
    },
  }, // 8 dev
  price_1Od1FCIJPWWhjYyo1057UAoi: {
    coupon: 'UEN4AcC0',
    amount: {
      krw: 124800,
    },
  }, // 12 prod
  price_1OcEbIIJPWWhjYyo4rXGSY9Y: {
    coupon: 'UmVoNgVp',
    amount: {
      krw: 124800,
    },
  }, // 12 dev
};

export let couponMap: any = {
  immigo: oneDollarPerClass,
};
