import { useEffect, useRef } from 'react';
import { trackClassLinkRedirect } from '../../analytics';

export const ClassPrepFinishedClassPage = () => {
  return (
    <div className="justify-center items-center flex flex-col max-w-[500px] text-center">
      <div className="md:text-3xl text-xl">You did it!</div>
      <div className="text-gray-500 text-sm mt-4">
        Learning English is hard, but your determination to learn English
        conquered the difficulty today. You rock! 🤘
      </div>
      <div className="my-12 text-8xl">🎉</div>
    </div>
  );
};
