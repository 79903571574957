import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { Correction, CorrectionDocument } from '../../../types/feedback';
import { CorrectionContainer } from '../../FeedbackListener/Corrections/CorrectionContainer';
import { BookingData } from '../../../firebase/subscription/subscription';
import { InsightHeaderContainer } from '../../ClassInsight/insightHeaderContainer';
import { useEffect } from 'react';
import { trackHomePageClassInsight } from './analytics';
import { PeerReactionCollectionContainer } from '../../ClassInsight/peerReactionCollectionContainer';

export const ClassInsightContainer = (props: {
  correctionData: CorrectionDocument;
  bookingData: BookingData;
  onSeeMoreClick?: Function;
  onRecordingPlayed?: Function;
}) => {
  const { correctionData, bookingData, onSeeMoreClick, onRecordingPlayed } =
    props;

  useEffect(() => {
    trackHomePageClassInsight('Class insight container shown in the homepage');
  }, []);

  useEffect(() => {
    if (bookingData?.peerReactionMap?.[bookingData.bookerID]) {
      trackHomePageClassInsight(
        'Shown peer reaction in the homepage class insight',
      );
    }
  }, [bookingData]);

  return (
    <div className="@container rounded-lg bg-white shadow w-full py-6 px-4">
      <div className="text-base font-semibold leading-6 text-gray-900 mb-3">
        {`🧑‍🎓️ Your previous class report`}
      </div>
      <InsightHeaderContainer booking={bookingData} />
      {bookingData?.peerReactionMap?.[bookingData.bookerID] ? (
        <div className="mb-2">
          <PeerReactionCollectionContainer
            reactionMap={bookingData?.peerReactionMap?.[bookingData.bookerID]}
          />
        </div>
      ) : null}
      <div className="text-gray-500 font-semibold">AI Corrections</div>
      <CorrectionContainer
        correction={correctionData?.corrections?.[0]}
        audioRecordingURL={bookingData?.classVoiceRecordingURL}
        onRecordingPlayClicked={() => {
          onRecordingPlayed?.();
        }}
      />
      <div
        onClick={() => {
          onSeeMoreClick?.();
          window.open(`/insight?bid=${correctionData?.bookingID}`);
        }}
        className="text-right w-full flex items-center justify-end cursor-pointer text-gray-500"
      >
        See more
        <ArrowRightIcon className="w-5 h-5 ml-1" />
      </div>
    </div>
  );
};
