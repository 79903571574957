import emptyAvatar from '../../../images/empty-avatar.webp';

// Limits 3
export default function AvatarGroupClustered(props: {
  imageLinks: Array<string | undefined>;
}) {
  const { imageLinks } = props;

  imageLinks.sort((a: string | undefined, b: string | undefined) => {
    if (a) return 1;
    else return 0;
  }); // Image with picture first

  return (
    <>
      <div className="flex flex-shrink-0 relative min-w-[200px]">
        <div>
          <img
            key={imageLinks[0] ?? 'avatar'}
            className="inline-block h-28 w-28 absolute left-0 top-0 rounded-full ring-1 ring-gray-200 object-cover object-center bg-white"
            src={imageLinks[0] ?? emptyAvatar}
            alt={imageLinks[0] ?? 'avatar'}
          />
        </div>
        <div>
          <img
            key={imageLinks[1] ?? 'avatar'}
            className="inline-block h-20 w-20  absolute left-28 top-20 rounded-full ring-1 ring-gray-200 object-cover object-center bg-white"
            src={imageLinks[1] ?? emptyAvatar}
            alt={imageLinks[1] ?? 'avatar'}
          />
        </div>
        <div>
          <img
            key={imageLinks[2] ?? 'avatar'}
            className="inline-block h-16 w-16  absolute left-6 top-32 rounded-full ring-1 ring-gray-200 object-cover object-center bg-white"
            src={imageLinks[2] ?? emptyAvatar}
            alt={imageLinks[2] ?? 'avatar'}
          />
        </div>
      </div>
    </>
  );
}
