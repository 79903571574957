import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SimpleBackAndNext } from '../../../../components/buttons/simpleBackAndNext/simpleBackAndNext';
import { useForm } from '../../../../components/forms/hooks';
import { Radio } from '../../../../components/forms/radio';
import { Select } from '../../../../components/forms/select';
import { updateUserData } from '../../../../firebase/users/users';
import { UserData } from '../../../../types/user';
import { removeUndefinedProperties } from '../../../../util/data';
import { arrayAsSelectOptions, yesNoOptions } from '../../../../util/forms';
import { OnBoardingPageProps } from '../../OnBoard';
import {
  cefrLevels,
  EnglishDetailInformationData,
  usesEnglishOptions,
  validationSchema,
} from './data';

export const EnglishInformation = ({
  userID,
  onNextClick,
  onBackClick,
}: OnBoardingPageProps) => {
  const [loading, setLoading] = useState(false);
  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );
  const dispatch = useDispatch();

  const formik = useForm<EnglishDetailInformationData>({
    initialValues: { ...userData },
    validationSchema,
    onSubmit: async (values) => {
      await updateUserData(userID, removeUndefinedProperties({ ...values }));
      const newUserValue = { ...userData, ...values };
      dispatch({ type: 'USER_DATA_SET', userData: newUserValue });
      onNextClick();
    },
  });

  return (
    <div>
      <div className="text-2xl text-center">Your English journey</div>
      <div className="mt-6 text-center text-sm text-gray-500">
        To help us design and recommend classes that fit your learning goals,
        it's important for us to learn about your English journey.
      </div>
      <div className="mt-6">
        <Select
          label={'What is your CEFR level?'}
          {...formik.getSelectProps('cefrLevel')}
          options={arrayAsSelectOptions(cefrLevels)}
        />
      </div>
      <div className="mt-6">
        <Radio
          label={'Do you currently have a tutor?'}
          description={'e.g. 1:1 class or a group class'}
          {...formik.getRadioProps(
            'currentlyHasTutor',
            (value) => (value ? 'yes' : 'no'),
            (newValue) => {
              formik.setFieldValue('currentlyHasTutor', newValue === 'yes');
            },
          )}
          options={yesNoOptions}
        />
      </div>
      <div className="mt-6">
        <Select
          label={'Do you use English at work?'}
          {...formik.getSelectProps('usesEnglishAtWork')}
          options={arrayAsSelectOptions(usesEnglishOptions)}
        />
      </div>
      <SimpleBackAndNext
        onBack={() => {
          onBackClick?.();
        }}
        onNext={async () => {
          setLoading(true);
          await formik.submitForm();
          setLoading(false);
        }}
        nextLoading={loading}
      />
    </div>
  );
};
