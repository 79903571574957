import mixpanel from '../../../analytics/mixpanel';

type Events =
  | 'Clicked see more on homepage class insight container'
  | 'Clicked speaker icon on homepage class insight container'
  | 'Class insight container shown in the homepage'
  | 'Shown peer reaction in the homepage class insight';

type NextClassContainerData = {};

export const trackHomePageClassInsight = (
  event: Events,
  data?: NextClassContainerData,
) => {
  mixpanel.track(event, data);
};
