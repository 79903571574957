import axios from 'axios';
import { CEFRLevel } from '../types/level';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com/guestPass'
    : 'http://localhost:8080/guestPass'; // Dev env

export async function inviteGuest(
  passId: string,
  email: string,
  fullName: string,
  cefrLevel: CEFRLevel,
  phoneNumber: string,
) {
  const data = await axios.post(`${endPoint}/invite`, {
    passId,
    email,
    fullName,
    cefrLevel,
    phoneNumber,
  });

  return data?.data?.data;
}

export async function generateGuestPasses(
  ids: Array<string>,
  quantity: number,
) {
  const data = await axios.post(`${endPoint}/generate`, {
    ids: JSON.stringify(ids),
    quantity,
  });

  return data?.data?.data;
}

export async function getAllGuestPasses(uid: string) {
  const data = await axios.get(`${endPoint}/get/${uid}`);
  return data?.data?.data;
}
