import axios from 'axios';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com/certificate'
    : 'http://localhost:8080/certificate'; // Dev env

export async function generateEnrollmentCertificate(
  name: string,
  startMili: number,
) {
  const data = await axios.post(endPoint + '/generateEnrollmentCertificate', {
    name,
    startMili,
  });
  return data?.data;
}
