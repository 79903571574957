import { useContext, useEffect, useState } from 'react';
import { MeetingSessionSummaryModal } from '../../components/modals/meetingSessionSummaryModal/meetingSessionSummaryModal';
// import { SocketContext } from '../../context/socket';
import { trackMeetingNotifierEvent } from '../../features/MeetingNotifier/analytics';

export const MeetingNotifier = (props: { userData: any }) => {
  const [openReportModal, setOpenReportModal] = useState(false);
  const [meetingSessionID, setMeetingSessionID] = useState<string | null>(null);

  // const socket = useContext(SocketContext);

  const { userData } = props;

  // useEffect(() => {
  //   if (socket) {
  //     // Triggers when user leaves an ongoing meeting
  //     socket.on('left_ongoing_meeting', (data) => {
  //       const sessionID = data?.sessionID;
  //       if (sessionID) {
  //         setMeetingSessionID(sessionID);
  //         setOpenReportModal(true);
  //       }
  //     });
  //   }
  // }, [socket]);

  return (
    <>
      <MeetingSessionSummaryModal
        isOpen={openReportModal}
        onClose={() => setOpenReportModal(false)}
        sessionID={meetingSessionID}
        userData={userData}
      />
    </>
  );
};
