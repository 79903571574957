import {
  Bars2Icon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import { UserData } from '../../../types/user';
import { getObscuredName } from '../../../util/standardization';
import { Avatar } from '../../avatar/avatar';
import { cx } from 'classix';

type PeopleTableUpDownSameRatingType = 'lower' | 'same' | 'higher';

export default function PeopleTableUpDownSame(props: {
  userList: Array<Partial<UserData>>;
  title?: string;
  ratingMap?: any;
  onRatingChange: Function;
}) {
  const { userList, title, onRatingChange, ratingMap } = props;

  function ratingChangeHandler(
    uid: string,
    rating: PeopleTableUpDownSameRatingType,
  ) {
    onRatingChange?.(uid, rating);
  }

  return (
    <fieldset>
      <legend className="text-base font-semibold leading-6 text-gray-900">
        {title}
      </legend>
      <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200 max-h-[500px] overflow-scroll px-2">
        {userList.map((person, personIdx) => (
          <div key={personIdx} className="relative flex items-start py-4">
            <div className="min-w-0 flex-1 text-sm leading-6 flex items-center">
              <Avatar photoUrl={person.profilePictureLink} />
              <label
                htmlFor={`person-${person.id}`}
                className="select-none font-medium text-gray-900 ml-2"
              >
                {getObscuredName(person.name ?? '')}
              </label>
            </div>
            <div className="ml-3 flex h-6 items-center">
              <ChevronDownIcon
                className={cx(
                  'w-5 h-5 mx-1 cursor-pointer hover:text-blue-300',
                  ratingMap?.[person?.id ?? ''] === 'lower'
                    ? 'text-blue-immigo font-bold'
                    : 'text-gray-500',
                )}
                onClick={() => {
                  if (person.id) ratingChangeHandler(person.id, 'lower');
                }}
              />
              <Bars2Icon
                className={cx(
                  'w-5 h-5 mx-1 cursor-pointer hover:text-blue-300',
                  ratingMap?.[person?.id ?? ''] === 'same'
                    ? 'text-blue-immigo font-bold'
                    : 'text-gray-500',
                )}
                onClick={() => {
                  if (person.id) ratingChangeHandler(person.id, 'same');
                }}
              />
              <ChevronUpIcon
                className={cx(
                  'w-5 h-5 mx-1 cursor-pointer hover:text-blue-300',
                  ratingMap?.[person?.id ?? ''] === 'higher'
                    ? 'text-blue-immigo font-bold'
                    : 'text-gray-500',
                )}
                onClick={() => {
                  if (person.id) ratingChangeHandler(person.id, 'higher');
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
}
