import * as yup from 'yup';

import { FormData, SchemaFor } from '../../../../components/forms/hooks';
import { nationalities, Nationality } from '../../../../util/forms';

export const planOnMovingOptions = [
  'No',
  'Yes, sometime in the future',
  "Yes, in the near future but I don't have a plan yet",
  'Yes, I already have a confirmed plan to move',
];
type PlanOnMovingOption = typeof planOnMovingOptions[number];

export const whenToMoveOptions = [
  "No, I don't plan on moving",
  'In less than 3 months from now',
  'In less than 6 months from now',
  'In less than a year from now',
  'In more than a year from now',
];
type WhenToMoveOption = typeof whenToMoveOptions[number];

export const livingAbroadDurationOptions = [
  'Less than 3 months',
  'Less than 6 months',
  'Less than a year',
  'More than a year',
];
export type LivingAbroadDurationOption =
  typeof livingAbroadDurationOptions[number];

export interface CountryDetailInformationData extends FormData {
  nationality?: Nationality;
  basedCountry?: Nationality;
  livingAbroad?: boolean;
  whenToMove?: WhenToMoveOption;
  howLongLivedAbroad?: LivingAbroadDurationOption;
}

const nationalityValidationSchema = (errorMessage?: string): yup.StringSchema =>
  yup
    .string()
    .min(2, 'This is not a valid option')
    .oneOf(nationalities, 'This is not a valid option')
    .strict()
    .required(errorMessage || 'This field is required.');

export const validationSchema: SchemaFor<CountryDetailInformationData> = {
  nationality: nationalityValidationSchema(
    'This field is required, please choose a nationality',
  ),
  basedCountry: yup.string().when('livingAbroad', {
    is: (value: boolean) => value === true,
    then: nationalityValidationSchema(
      'This field is required, please choose the country you are based in!',
    ),
  }),
  livingAbroad: yup.boolean().required('Please select an option'),
  whenToMove: yup.string().when('planOnMoving', {
    is: (value?: string) => value && value !== planOnMovingOptions[0],
    then: yup
      .string()
      .min(1, 'This is not a valid option')
      .oneOf(whenToMoveOptions, 'This is not a valid option')
      .required('This field is required, please choose an option'),
  }),
  howLongLivedAbroad: yup.string().when('livingAbroad', {
    is: (value: boolean) => value === true,
    then: yup
      .string()
      .min(1, 'This is not a valid option')
      .oneOf(livingAbroadDurationOptions, 'This is not a valid option')
      .required('This field is required, please choose an option'),
  }),
};
