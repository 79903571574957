import PeopleReactionOption from '../../../../../../components/forms/peopleReactionOption/peopleReactionOption';
import PeopleTableUpDownSame from '../../../../../../components/forms/peopleTableUpDownSameOption/peopleTableUpDownSame';
import { BookerData } from '../../../../../../types/bookings';

export const PeerReaction = (props: {
  chosenMembers: Array<BookerData>;
  onReactionChange: Function;
  reactionMap: any;
}) => {
  const { chosenMembers, onReactionChange, reactionMap } = props;
  return (
    <div>
      <PeopleReactionOption
        userList={chosenMembers}
        reactionMap={reactionMap}
        title="Send a reaction to your peers!"
        reactionList={['💯', '💙', '👍', '👏', '🔥']}
        onReactionChange={onReactionChange}
      />
    </div>
  );
};
