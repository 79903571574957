import mixpanel from '../../analytics/mixpanel';

export type Event =
  | 'Clicked cancel subscription'
  | 'Cancelation "Are you sure" modal brought up'
  | 'Cancelation "Are you sure" modal clicked "cancel anyways"'
  | 'Cancelation "Are you sure" modal clicked get free clicked'
  | 'Cancelation reason modal brought up'
  | 'Clicked skip next billing cycle clicked'
  | 'Closed "Are you sure" modal'
  | 'Closed cancelation reason modal'
  | 'Submitted cancelation reason modal'
  | 'Clicked resume subscription'
  | 'Clicked resume subscription (unpause)'
  | 'Clicked reset billing button'
  | 'Clicked view payment method'
  | 'Clicked save payment method';

type SubscriptionAnalyticsData = {};

export const trackSubscriptionPageData = (
  event: Event,
  data?: SubscriptionAnalyticsData,
) => {
  mixpanel.track(event, data);
};
