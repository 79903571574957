import { Dialog } from '@headlessui/react';
import {
  CheckIcon,
  ClockIcon,
  CreditCardIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  FlagIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline';
import HorizontalModalTemplate from '../templates/horizontalModalTemplate/horizontalModalTemplate';
type IconThemes =
  | 'no-icon'
  | 'check-mark'
  | 'clock'
  | 'exclamation'
  | 'exclamation-triangle'
  | 'flag'
  | 'credit-card'
  | 'phone';

type ModalThemes =
  | 'default'
  | 'warning-red'
  | 'info'
  | 'warning-yellow'
  | 'success';

interface HorizontalMessageModalProps {
  mainMessage: string;
  subMessage: string;
  isOpen?: boolean;
  onCloseModal?: Function;
  buttonText: string;
  secondaryButtonText?: string;
  onButtonClick?: Function;
  secondaryButtonOnClick?: Function;
  children?: React.ReactNode;
  icon?: IconThemes;
  loading?: boolean;
  theme: ModalThemes;
}

const themeToColorMap = {
  default: 'blue-immigo',
  success: 'green-600',
  'warning-red': 'red-600',
  'warning-yellow': 'yellow-600',
  info: 'blue-600',
};

const themeToLightColorMap = {
  default: 'blue-100',
  success: 'green-100',
  'warning-red': 'red-100',
  'warning-yellow': 'yellow-100',
  info: 'blue-100',
};

const HorizontalMessageModal = ({
  mainMessage,
  subMessage,
  isOpen,
  onCloseModal,
  buttonText,
  secondaryButtonText,
  onButtonClick,
  icon,
  secondaryButtonOnClick,
  children,
  theme,
  loading,
}: HorizontalMessageModalProps) => {
  function renderIcon() {
    const cs = `h-6 w-6 text-${themeToColorMap[theme]}`;
    switch (icon) {
      case 'no-icon':
        return null;
      case 'check-mark':
        return <CheckIcon className={cs} aria-hidden="true" />;
      case 'clock':
        return <ClockIcon className={cs} aria-hidden="true" />;
      case 'exclamation':
        return <ExclamationCircleIcon className={cs} aria-hidden="true" />;
      case 'exclamation-triangle':
        return <ExclamationTriangleIcon className={cs} aria-hidden="true" />;
      case 'phone':
        return <PhoneIcon className={cs} aria-hidden="true" />;
      case 'flag':
        return <FlagIcon className={cs} aria-hidden="true" />;
      case 'credit-card':
        return <CreditCardIcon className={cs} aria-hidden="true" />;
      default:
        return <CheckIcon className={cs} aria-hidden="true" />;
    }
  }

  return (
    <HorizontalModalTemplate
      isOpen={isOpen}
      closeModal={onCloseModal}
      primaryButtonOnClick={onButtonClick ?? (() => {})}
      primaryButtonText={buttonText}
      secondaryButtonText={secondaryButtonText}
      secondaryButtonOnClick={secondaryButtonOnClick}
      theme={theme}
      loading={loading}
    >
      <div className="sm:flex sm:items-start">
        <div
          className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-${themeToLightColorMap[theme]} sm:mx-0 sm:h-10 sm:w-10`}
        >
          {renderIcon()}
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            {mainMessage}
          </Dialog.Title>
          <div className="mt-1">
            <p className="text-sm text-gray-500">{subMessage}</p>
            {children}
          </div>
        </div>
      </div>
    </HorizontalModalTemplate>
  );
};

export default HorizontalMessageModal;
