import { CSSProperties, ComponentProps, FC, memo } from 'react';
import cx from 'classix';

import { FieldError } from '../field-error';

interface BaseProps {
  id: string;
  label: string;
  hideLabel?: boolean;
  touched?: boolean;
  error?: string | null;
  description?: string;

  containerClassName?: string;
  containerStyle?: CSSProperties;

  labelClassName?: string;
  labelStyle?: CSSProperties;

  descriptionClassName?: string;
  descriptionStyle?: CSSProperties;

  errorClassName?: string;
  errorStyle?: CSSProperties;

  hide?: boolean;
  asTextArea?: boolean;
  optional?: boolean;
}

type InputProps = { asTextArea?: false } & ComponentProps<'input'> & BaseProps;
type TextAreaProps = { asTextArea?: true } & ComponentProps<'textarea'> &
  BaseProps;

const InputComponent: FC<InputProps | TextAreaProps> = (props) => {
  const {
    id,
    name,
    label,
    hideLabel,
    description,
    touched,
    error,
    containerClassName,
    containerStyle,
    labelClassName,
    labelStyle,
    descriptionClassName,
    descriptionStyle,
    errorClassName,
    errorStyle,
    asTextArea,
    hide,
    disabled,
    className,
    style,
    required = true,
    optional = !required,
    value = '',
    ...otherProps
  } = props;

  const FieldComponent = asTextArea ? 'textarea' : 'input';

  return (
    <div
      className={cx(
        'flex flex-col items-start',
        containerClassName,
        hide && 'hidden select-none pointer-events-none',
      )}
      style={containerStyle}
    >
      <label
        htmlFor={id || name}
        className={cx(
          'block text-sm font-medium leading-6 text-gray-900',
          hideLabel && 'hidden select-none pointer-events-none',
          labelClassName,
        )}
        style={labelStyle}
      >
        {label}
        {!required || optional ? (
          <>
            {' '}
            <span className={cx('opacity-75 select-none')}>(optional)</span>
          </>
        ) : null}
      </label>
      {description ? (
        <p
          className={cx('opacity-70 text-sm', descriptionClassName)}
          style={descriptionStyle}
        >
          {description}
        </p>
      ) : null}
      {/* @ts-ignore */}
      <FieldComponent
        id={id || name}
        name={id || name}
        className={cx(
          'mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-immigo sm:text-sm sm:leading-6',
          'w-full px-3 border rounded-md focus:shadow',
          !asTextArea && 'py-1',
          asTextArea && 'pt-3 pb-5 resize-y',
          className,
        )}
        style={{ ...style, minHeight: asTextArea ? 72 : 0 }}
        disabled={disabled || hide}
        value={value}
        required={required || !optional}
        {...otherProps}
      />
      <FieldError
        touched={touched}
        error={error}
        className={errorClassName}
        style={errorStyle}
      />
    </div>
  );
};

export const Input = memo(InputComponent);
