import mixpanel from '../../../analytics/mixpanel';

type Events =
  | 'Buy more credit button clicked'
  | 'Closed buy more credit button'
  | 'Buy more credit button shown';

type TrackBuyMoreCreditButtonData = {
  email?: string;
};

export const trackBuyMoreCreditButton = (
  event: Events,
  data?: TrackBuyMoreCreditButtonData,
) => {
  mixpanel.track(event, data);
};
