import { useEffect, useState } from 'react';
import { getAllUserMistakes } from '../../../api/insight';
import { CorrectionContainer } from '../../FeedbackListener/Corrections/CorrectionContainer';
import { Correction } from '../../../types/feedback';
import { weekInMili } from '../../../usefuldata/mili';
import { capitalizeEveryFirstLetter } from '../../../util/standardization';
import { BookOpenIcon, LightBulbIcon } from '@heroicons/react/24/outline';
import VerticalMessageModal from '../../../components/modals/verticalMessageModal/verticalMessageModal';
import { PrimaryButton } from '../../../components/buttons/primary';
import { trackNextClassTip } from './analytics';
import mistakeDefinitionMap from '../../../usefuldata/mistakeDefinition.json';
import { getTopNKeysInMapWithArrayValues } from '../../../util/data';

const mistakeIcon = [];

interface MistakeMap {
  [key: string]: Array<Correction>;
}

export const MistakeTipContainer = (props: {
  mistakes: Array<string>;
  mistakesMap: MistakeMap;
  onClickSeeMore: Function;
}) => {
  const { mistakes, mistakesMap, onClickSeeMore } = props;

  return (
    <div>
      {mistakes.map((type, index) => {
        return (
          <div
            key={type}
            className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 my-2"
          >
            <div className="flex-shrink-0"></div>
            <div className="min-w-0 flex-1">
              <div>
                <p className="text-lg font-medium text-gray-900 flex items-center">
                  <BookOpenIcon className="text-blue-500 w-5 h-5 flex-shrink-0 mr-2" />
                  {capitalizeEveryFirstLetter(type)}
                  <span className="text-xs text-semibold ml-2 flex justify-center text-yellow-600 bg-yellow-200 rounded-sm py-1 px-2">
                    {mistakesMap?.[type].length}
                  </span>
                </p>
                <p className="text-sm text-gray-500 flex">
                  <LightBulbIcon className="text-yellow-500 w-5 h-5 flex-shrink-0 mr-2" />{' '}
                  {(mistakeDefinitionMap as any)[type]}
                </p>
              </div>
            </div>
            <div
              className="ml-auto text-sm mt-auto cursor-pointer text-gray-500 hover:text-blue-immigo"
              onClick={() => {
                onClickSeeMore(type);
              }}
            >
              See my mistakes/corrections
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const NextClassTip = (props: { userID: string }) => {
  const { userID } = props;
  const [mistakesMap, setMistakesMap] = useState<MistakeMap | undefined>();
  const [topMistakes, setTopMistakes] = useState<Array<string>>([]);
  const [correctionModalOpen, setCorrectionModalOpen] = useState(false);
  const [chosenMistake, setChosenMistake] = useState<string | undefined>();

  const sevenDaysAgoMili = new Date().valueOf() - weekInMili;

  useEffect(() => {
    if (userID) updateUserMistake();
  }, [userID]);

  async function updateUserMistake() {
    const mistakeData = await getAllUserMistakes(userID, sevenDaysAgoMili);
    if (mistakeData?.mistakes) setMistakesMap(mistakeData?.mistakes);
    if (mistakeData?.topMistake) {
      const topMistakeList = getTopNKeysInMapWithArrayValues(
        mistakeData?.mistakes,
        3,
      )?.filter((type) => (mistakeDefinitionMap as any)[type]);
      setTopMistakes(topMistakeList);
    }
  }

  function onSeeMore(type: string) {
    trackNextClassTip('Clicked see my mistake on next class tip', {});
    setChosenMistake(type);
    setCorrectionModalOpen(true);
  }

  return (
    <div className="@container rounded-lg bg-white shadow w-full py-6 px-4 my-2">
      <VerticalMessageModal
        mainMessage={capitalizeEveryFirstLetter(chosenMistake ?? '')}
        subMessage=""
        theme="default"
        icon="pencil"
        onCloseModal={() => {
          setCorrectionModalOpen(false);
        }}
        isOpen={correctionModalOpen}
      >
        <div className="text-start overflow-y-scroll max-h-[600px] mt-8 overflow-x-visible">
          {mistakesMap?.[chosenMistake ?? '']?.map((correction: Correction) => {
            return <CorrectionContainer correction={correction} useFullDate />;
          })}
        </div>
      </VerticalMessageModal>
      {topMistakes.length > 0 && mistakesMap ? (
        <>
          <div className="text-base font-semibold leading-6 text-gray-900">
            Your top grammar mistakes past 7 days
          </div>
          <MistakeTipContainer
            mistakes={topMistakes}
            mistakesMap={mistakesMap}
            onClickSeeMore={onSeeMore}
          />
        </>
      ) : null}
    </div>
  );
};
