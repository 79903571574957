import countryData from '../usefuldata/countries.json';
import { countryCodeToSVG } from './flagLoader';

// chronoMap :
// {165323000 : 1, 16532321 : 2 ... } = 2 Streak based on weekly attendance data.
// Data doesn't count week that student wasn't takeing a course in
export const chronoMapToStreakCount = (chronoMap: any) => {
  if (!chronoMap) return 0;

  const orderedWeekList = Object.keys(chronoMap).sort();
  let streak = 0;

  // Iterate from most recent to least recent
  for (let i = orderedWeekList.length - 1; i >= 0; i--) {
    if (chronoMap[orderedWeekList[i]] > 0) {
      streak++; // add to streak
    } else {
      break; // streak ends
    }
  }

  return streak;
};

export const countryCodeToName = (code?: string): string => {
  if (!code) return '';
  return (
    countryData.find((country) => country.code.includes(code.toUpperCase()))
      ?.name || ''
  );
};

export const countryCodeToEmoji = (code?: string): string => {
  if (!code) return countryCodeToSVG(code);
  return (
    countryData.find((country) => country.code.includes(code.toUpperCase()))
      ?.image || countryCodeToSVG(code)
  );
};

export const getCurrentWeekIndex = (courseStartMili: number): number => {
  const currentMili = new Date().valueOf();
  const diffMili = currentMili - courseStartMili;
  const diffWeek = diffMili / 1000 / 60 / 60 / 24 / 7;

  return Math.max(0, Math.floor(diffWeek));
};

export const getCourseWeekLength = (course: any) => {
  const miliDuration = course.coursePeriod.end - course.coursePeriod.start;
  const weekDuration = miliDuration / 1000 / 60 / 60 / 24 / 7;
  return Math.floor(weekDuration);
};

export const getFirstNameFromFullName = (fullName: string) => {
  return fullName.split(' ')[0];
};

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const blobToBase64 = (blob: File) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export function makeRandomID(length: number) {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getRandomArbitrary(min: number, max: number) {
  return Math.floor(Math.random() * (max - min) + min);
}
