import { TextInsightItem } from './insightItem';

export const TextInsightBox = (props: {
  title: string;
  texts: Array<string>;
}) => {
  const { texts, title } = props;
  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
      <dt className="truncate md:text-xl font-medium text-gray-500">{title}</dt>
      {texts.map((text, index) => {
        return <TextInsightItem text={text} firstEntry={index === 0} />;
      })}
    </div>
  );
};
