import ReactLoading from 'react-loading';

export const LoadingOverlay = (props: {
  enabled: boolean;
  loadingMessage?: string;
}) => {
  return (
    <div>
      {props.enabled ? (
        <div
          className="w-full h-full 
                    bg-gray-500 bg-opacity-50 
                    fixed  
                    top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
                    flex items-center justify-center z-50"
        >
          <div className="text-center">
            <ReactLoading
              className="mb-20 mx-auto"
              type={'spin'}
              color={'#009eff'}
              height={300}
              width={300}
            />
            <div className="text-3xl font-bold text-blue-immigo-lighter">
              {props.loadingMessage
                ? props.loadingMessage
                : 'Please wait a moment :)'}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
