import mixpanel from '../../analytics/mixpanel';

type Events =
  | 'Visited wrapped intro page'
  | 'Visited wrapped class insight page'
  | 'Visited wrapped words page'
  | 'Visited wrapped top three page'
  | 'Visited wrapped friends page'
  | 'Visited wrapped summary page'
  | 'Wrapped clicked next'
  | 'Wrapped clicked back'
  | 'Wrapped share with friends clicked'
  | 'Clicked wrapped container at homepage'
  | 'Shown wrapped container at homepage';

type YearlyInsightData = {
  page?: string;
  uid?: string;
  year?: number;
};

export const trackYearlyInsight = (event: Events, data?: YearlyInsightData) => {
  mixpanel.track(event, data);
};
