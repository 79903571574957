import ReactConfetti from 'react-confetti';
import VerticalMessageModal from '../verticalMessageModal/verticalMessageModal';
import { UserPointHistoryReasonType } from '../../../types/user';
import { awardReasonToText } from '../../../features/Immigots/awardReasonToText';
import CountUp from 'react-countup';
import { StarIcon } from '@heroicons/react/20/solid';

export const ImmigotRewardModal = (props: {
  isOpen: boolean;
  amount: number;
  reason: UserPointHistoryReasonType;
  onCloseModal: Function;
}) => {
  const { isOpen, onCloseModal, amount, reason } = props;

  return (
    <VerticalMessageModal
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      mainMessage=""
      subMessage=""
      buttonText="Continue"
      onButtonClick={() => {
        onCloseModal();
      }}
      theme="success"
      icon="star"
    >
      <div>
        <div className="text-xl my-2">
          {`Yay! You've earned ${amount} Immigots`}
        </div>
        <div className="text-5xl flex justify-center items-center my-8">
          <CountUp end={amount} duration={2} />
          <StarIcon className="w-12 h-12 text-yellow-300 animate-bounce" />
        </div>
        <div className="text-gray-500 text-xl my-2">
          {`${awardReasonToText[reason]}!`}
        </div>
      </div>
    </VerticalMessageModal>
  );
};
