import VerticalMessageModal from '../../components/modals/verticalMessageModal/verticalMessageModal';
import { TranscriptContainer } from '../../components/scriptContainers/transcriptContainer';
import { getOneSampleEachSpeaker } from '../../util/speechmaticDataHandler';

export const SpeakerChoiceModal = (props: {
  formattedTranscriptArray: Array<string>;
  classVoiceRecordingURL?: string;
  isOpen: boolean;
  chosenSpeakerList?: Array<string>;
  onSpeakerChosen?: Function;
  onSubmit?: Function;
}) => {
  const {
    formattedTranscriptArray,
    classVoiceRecordingURL,
    isOpen,
    chosenSpeakerList,
    onSpeakerChosen,
    onSubmit,
  } = props;

  function renderSamples(arr: Array<string>) {
    const speakerMap = getOneSampleEachSpeaker(arr);
    const speakers = Object.keys(speakerMap);
    return speakers.map((s) => {
      return (
        <TranscriptContainer
          content={speakerMap[s].content}
          startSeconds={speakerMap[s].startSeconds}
          endSeconds={speakerMap[s].endSeconds}
          speaker={speakerMap[s].speaker}
          audioRecordingURL={classVoiceRecordingURL}
          onChosen={onSpeakerChosen}
          chosen={chosenSpeakerList?.includes(s)}
        />
      );
    });
  }

  return (
    <VerticalMessageModal
      mainMessage="We detected multiple speakers in your recording."
      subMessage="Please choose all the recordings that correspond to your voice, so that we can provide a customized insight for you!"
      buttonText="Submit"
      onButtonClick={onSubmit}
      theme="default"
      isOpen={isOpen}
    >
      <div>
        {formattedTranscriptArray
          ? renderSamples(formattedTranscriptArray)
          : null}
      </div>
    </VerticalMessageModal>
  );
};
