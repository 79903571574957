import axios from 'axios';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com'
    : 'http://localhost:8080'; // Dev env
const meetingEndPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-meeting.herokuapp.com'
    : 'http://localhost:8888'; // Dev env

const podMatchingEndPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-pod-matching.herokuapp.com'
    : 'http://localhost:8040'; // Dev env

// Generate Twillio server auth token (This needs to be more protected in the future....)
async function generateMeetingAuthToken() {
  const token = await axios.get(meetingEndPoint + '/auth');
  if (!token || !token.data) {
    return null;
  } else return token.data;
}

async function getGranularUserData() {
  const userData = await axios.get(endPoint + '/database/getAllUsersGranular');
  if (!userData || !userData.data || !userData.data) {
    return null;
  } else return userData.data;
}

async function updateUserGranularData(userID: string) {
  const userData = await axios.post(
    endPoint + '/database/updateUserGranularData',
    {
      userID,
    },
  );
  if (!userData || !userData.data || !userData.data.user) {
    return null;
  } else return userData.data.user;
}

async function createMeetingVideoSDK(meetingID: string) {
  const meetingData = await axios.post(endPoint + '/meeting/createMeeting', {
    meetingID,
  });
  if (!meetingData || !meetingData.data) {
    return null;
  } else return meetingData?.data;
}

async function fetchOptimalPodRosterV1(courseId: string) {
  const optimalPodRoster = await axios.get(
    `${podMatchingEndPoint}/database/generatePodsInCourseV1/${courseId}`,
  );
  return optimalPodRoster;
}

async function fetchOptimalPodRosterV2(courseId: string) {
  const globalMissedWeeks = 0; // 10
  const globalAttendedWeeks = 0; // 15
  const differentCEFRLevel = 0; // 10
  const globalAttendanceRate = 0; // 15
  const timeZoneOffset = 0; // 5
  const timeOverlap = 120; // 120
  const nationality = 0; // 100
  const sameCEFRLevel = 20; // 20
  const engagedStudents = 120; // 300

  const optimalPodRoster = await axios.get(
    `${podMatchingEndPoint}/courses/${courseId}/pods?
    weights[globalMissedWeeks]=${globalMissedWeeks}
    &weights[globalAttendedWeeks]=${globalAttendedWeeks}
    &weights[differentCEFRLevel]=${differentCEFRLevel}
    &weights[globalAttendanceRate]=${globalAttendanceRate}
    &weights[timeZoneOffset]=${timeZoneOffset}
    &weights[timeOverlap]=${timeOverlap}
    &weights[nationality]=${nationality}
    &weights[sameCEFRLevel]=${sameCEFRLevel}
    &weights[engagedStudents]=${engagedStudents}`,
  );
  return optimalPodRoster;
}

async function getClientSecret(
  amount: number,
  currency: string,
  paymentMethodType: string,
  email: string,
  fullName: string,
  userID: string,
  customerID?: string,
) {
  const intentData = await axios.post(
    endPoint + '/payment/createPaymentIntent',
    {
      amount,
      currency,
      paymentMethodType,
      email,
      fullName,
      userID,
      customerID,
    },
  );
  return intentData;
}

async function confirmPaymentIntent(paymentIntentId: string) {
  const paymentIntent = await axios.post(
    endPoint + '/payment/confirmPaymentIntent/',
    {
      paymentIntentId,
    },
  );
  return paymentIntent;
}

async function getPaymentIntent(intentID: string) {
  const paymentIntent = await axios.get(
    endPoint + '/payment/retrievePaymentIntent/' + intentID,
  );
  return paymentIntent;
}

async function createSetupIntent(
  paymentMethodType: string,
  email: string,
  fullName: string,
  userID: string,
  customerID?: string,
) {
  const intentData = await axios.post(endPoint + '/payment/createSetupIntent', {
    paymentMethodType,
    email,
    fullName,
    userID,
    customerID,
  });
  return intentData;
}

async function cancelUserSubscription(subscriptionID: string) {
  const res = await axios.post(endPoint + '/payment/cancelSubscription', {
    subID: subscriptionID,
  });
  return res;
}

async function activateUserSubscription(subscriptionID: string) {
  const res = await axios.post(endPoint + '/payment/activateSubscription', {
    subID: subscriptionID,
  });
  return res;
}

async function pauseUserSubscription(subscriptionID: string, resumeAt: number) {
  const res = await axios.post(endPoint + '/payment/pauseSubscription', {
    subID: subscriptionID,
    resumeAt,
  });
  return res;
}

async function resumeUserSubscription(subscriptionID: string) {
  const res = await axios.post(endPoint + '/payment/resumeSubscription', {
    subID: subscriptionID,
  });
  return res;
}

async function subscribeUserMembership(
  paymentMethodID: string,
  email: string,
  fullName: string,
  priceID: string,
  userID: string,
  customerID?: string,
  trialPeriodDays?: number,
  couponID?: string,
  currency?: string,
) {
  const res = await axios.post(endPoint + '/payment/subscribe', {
    payment_method: paymentMethodID,
    email: email,
    fullName: fullName,
    priceID: priceID,
    userID: userID,
    customerID: customerID,
    trialPeriodDays: trialPeriodDays,
    couponID: couponID,
    currency: currency ?? 'usd',
  });
  return res;
}

async function getCourseEngagementData(courseId: string) {
  const response = await axios.get(
    endPoint + '/database/getPodMeetingEngagementData/' + courseId,
  );
  const courseEngagementData = response.data;
  return courseEngagementData;
}

async function sendApplicationConfirmationEmail(
  toEmail: string,
  courseTitle: string,
  firstName: string,
) {
  const emailResponse = await axios.post(
    endPoint + '/email/sendApplicationConfirmationEmail',
    {
      toEmail,
      courseTitle,
      firstName,
      apiKey: process.env.REACT_APP_EMAIL_API,
    },
  );
  return emailResponse;
}

async function sendCompletionCertificateEmail(
  toEmail: string,
  courseTitle: string,
  startMili: number,
  endMili: number,
  name: string,
) {
  const emailResponse = await axios.post(
    endPoint + '/email/sendCompletionCertificate',
    {
      toEmail,
      courseTitle,
      startMili,
      endMili,
      name,
      apiKey: process.env.REACT_APP_EMAIL_API,
    },
  );
  return emailResponse;
}

async function getCustomerSubscriptionInformation(customerId: string) {
  const response = await axios.get(
    endPoint + '/payment/customer/' + customerId,
  );

  if (
    !response ||
    !response.data ||
    !response.data.customer ||
    !response.data.customer.subscriptions
  ) {
    return null;
  } else {
    const id =
      response.data?.customer?.subscriptions?.data?.[0]?.items?.data?.[0]
        ?.subscription;
    if (id) response.data.customer.subscriptions.data[0].subID = id;
    if (response.data.customer.subscriptions.data[0])
      response.data.customer.subscriptions.data[0].customerData =
        response.data.customer;
    return response.data.customer.subscriptions.data[0]; //TODO: To keep an eye on when users will have more than one subscription
  }
}

async function getSubscriptionProductInformation(productID: string) {
  const response = await axios.get(endPoint + '/payment/product/' + productID);

  if (!response || !response.data || !response.data.product) {
    return undefined;
  } else return response.data.product; //TODO: To keep an eye on when users will have more than one subscription
}

async function getSubscriptionProductInformationWithCustomerID(
  customerId: string,
) {
  const subData = await getCustomerSubscriptionInformation(customerId);

  if (subData && subData.plan && subData.plan.product) {
    const response = await axios.get(
      endPoint + '/payment/product/' + subData.plan.product,
    );

    if (
      !response ||
      !response.data ||
      !response.data ||
      !response.data.product
    ) {
      return undefined;
    } else return response.data.product; //TODO: To keep an eye on when users will have more than one subscription
  } else {
    return undefined;
  }
}

async function assignUserReferralCode(userID: string) {
  const userData = await axios.post(
    endPoint + '/social/createUserReferralCode',
    {
      userID,
    },
  );
  return userData;
}

async function savePodMeetingClip(video: FormData, roomID: string) {
  const status = await axios.post(
    endPoint + '/meeting/savePodMeetingClip',
    video,
  );
  return status;
}

async function getRandomPodMemberFact(
  courseId: string,
  podIndex: number,
  requesterID?: string,
) {
  const response = await axios.get(
    `${endPoint}/insight/getRandomPodMemberInsight/${courseId}/${podIndex}/${requesterID}`,
  );

  if (!response?.data?.insight) {
    return undefined;
  } else return response.data.insight; //TODO: To keep an eye on when users will have more than one subscription
}

export async function getDefinitionAndExample(idiom: string) {
  const response = await axios.post(
    endPoint + '/feedback/getDefinitionAndExample',
    {
      idiom,
    },
  );

  if (!response?.data) {
    return null;
  } else {
    const res = response?.data;
    const definition = res?.definition;
    const example = res?.example;
    return { definition, example };
  }
}

export async function getCorrectionAI(toBeCorrected: string) {
  const response = await axios.post(`${endPoint}/feedback/getCorrection`, {
    text: toBeCorrected,
  });
  return response;
}

export {
  getGranularUserData,
  getClientSecret,
  subscribeUserMembership,
  getCourseEngagementData,
  sendApplicationConfirmationEmail,
  getCustomerSubscriptionInformation,
  getSubscriptionProductInformation,
  getSubscriptionProductInformationWithCustomerID,
  getPaymentIntent,
  assignUserReferralCode,
  fetchOptimalPodRosterV1,
  fetchOptimalPodRosterV2,
  savePodMeetingClip,
  updateUserGranularData,
  generateMeetingAuthToken,
  sendCompletionCertificateEmail,
  createMeetingVideoSDK,
  getRandomPodMemberFact,
  cancelUserSubscription,
  activateUserSubscription,
  pauseUserSubscription,
  resumeUserSubscription,
  createSetupIntent,
  confirmPaymentIntent,
};
