import {
  CheckIcon,
  LightBulbIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Correction } from '../../../types/feedback';
import { generateCorrectionPairWithStyle } from '../../../util/feedback';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { initializeFirebase } from '../../../firebase/configValues';
import { doc } from 'firebase/firestore';
import moment from 'moment-timezone';
import { SpeakerWaveIcon } from '@heroicons/react/20/solid';
import { cx } from 'classix';
import { CorrectionContainer } from './CorrectionContainer';

const { firestore } = initializeFirebase();

const useCorrections = (correctionID: string) => {
  const [data, loading, error] = useDocumentData(
    doc(firestore, `corrections/${correctionID}`),
  );

  let parsedCorrection;

  console.log(data);

  if (data?.corrections) {
    const parsed = data?.corrections?.map((correctionString: string) => {
      return JSON.parse(correctionString);
    });
    parsedCorrection = parsed;
  }

  if (error) throw error;
  if (!data) {
    return {
      corrections: [],
      correctionsLoading: false,
      correctionsError: '',
    };
  }
  return {
    correctionData: data,
    corrections: parsedCorrection,
    correctionsLoading: loading,
    correctionsError: error,
  };
};

export const CorrectionsRenderer = (props: {
  correctionID: string;
  classStarted: boolean;
  audioRecordingURL?: string;
  replacedArray?: Array<Correction>;
}) => {
  const { correctionID, classStarted, replacedArray, audioRecordingURL } =
    props;

  const { correctionData, corrections, correctionsLoading, correctionsError } =
    useCorrections(correctionID);

  const correctionArray = replacedArray ? replacedArray : corrections;

  return (
    <div className="overflow-y-scroll md:max-h-full flex-1 h-screen">
      {correctionArray?.length > 0 ? (
        correctionArray.map((correction: Correction) => {
          return (
            <CorrectionContainer
              correction={correction}
              audioRecordingURL={audioRecordingURL}
            />
          );
        })
      ) : (
        <div className="text-gray-600">
          {classStarted
            ? 'Press record & start speaking! Corrections will appear here as youspeak ✍️'
            : `⌛ Your class hasn't started yet! Recording button will become available 5 minutes before the class.`}
        </div>
      )}
    </div>
  );
};
