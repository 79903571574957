import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { handleProfilePictureUpload } from '../../../firebase/configuration';

const noAvatarPic =
  'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2Fno-profile.png?alt=media&token=715e8e99-1e05-4315-8aa5-c2e5ec97eeab';

const ProfilePictureComponent = ({
  userData,
  currentStudent,
  onPictureUpload,
  onPictureSubmitted,
  onProgress,
}: any) => {
  function dataURLtoFile(dataurl: string, filename: string) {
    var arr: any = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader: any = new FileReader();
      reader.addEventListener('load', async () => {
        const fileBlob = dataURLtoFile(
          reader.result,
          userData.id + '_profile_picture',
        );
        onPictureUpload();
        await handleProfilePictureUpload(
          fileBlob,
          userData.id,
          () => {
            onPictureSubmitted();
          },
          (progress: number) => {
            onProgress(progress);
          },
        );
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  function onUploadClick() {
    if (window.document != null) {
      const up = window.document.getElementById(
        'profile-teacher-picture-upload',
      )!;
      up.click();
    }
  }

  return (
    <div>
      <div style={{ display: 'none' }}>
        <input
          id="profile-teacher-picture-upload"
          type="file"
          accept="image/*"
          onChange={onSelectFile}
        />
      </div>
      <div
        className="h-48 w-48 rounded-full border-solid border-2 z-0"
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundImage:
            'url(' +
            (currentStudent && currentStudent.profilePictureLink
              ? currentStudent.profilePictureLink
              : noAvatarPic) +
            ')',
        }}
      >
        {userData &&
        currentStudent &&
        userData.email === currentStudent.email ? (
          <div
            className="
                        bg-black-default text-white w-48 h-48
                        justify-center items-center cursor-pointer
                        flex rounded-full opacity-0 hover:opacity-50 transition-opacity
                    "
            data-tip="<br>Make sure to upload a cropped picture with your face centered already. <br/> Otherwise your photo will not be rendered correctly."
            onClick={onUploadClick}
          >
            Edit
            <ReactTooltip
              className="font-bold"
              backgroundColor="#009eff"
              multiline
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  onSetUserData: (userData: any) =>
    dispatch({ type: 'USER_DATA_SET', userData }),
});

const mapStateToProps = (state: any) => ({
  userData: state.sessionState.userData,
});

export const ProfilePicture = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfilePictureComponent);
