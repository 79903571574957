import { collection, getDocs } from 'firebase/firestore';
import { initializeFirebase } from '../configValues';

const { firestore: db } = initializeFirebase();

export async function getNumbers() {
  const numbersRef = await collection(db, 'numbers');
  const numbers = await getDocs(numbersRef);
  console.log(numbers);
  const numbersData: any[] = [];
  numbers.forEach((ach: any) => {
    const data = ach.data();
    numbersData.push(data);
  });
  // console.log(numbers)
  return numbersData;
}
