import { createStore } from 'redux';
import { rootReducer } from '../reducers';

const store: any = createStore(
  rootReducer,
  process.env.NODE_ENV === 'development' &&
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;
