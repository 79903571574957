import mixpanel from '../../analytics/mixpanel';

type Events =
  | 'Visited get started page'
  | 'Clicked finish my profile button on boarding page'
  | 'Submitted lead information on boarding page'
  | 'Submitted tried information on boarding page'
  | 'Submitted nationality information on boarding page'
  | 'Submitted English journey information on boarding page'
  | 'Submitted addtional information on boarding page'
  | 'Clicked check my classes on boarding page'
  | 'Clicked choose my learning path button';

interface OnBoardingData {
  referMethod?: string;
  referredByHandle?: string;
}

export const trackOnBoardingPage = (
  event: Events,
  onBoardingData?: OnBoardingData,
) => {
  mixpanel.track(event);
};
