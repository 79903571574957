import validTLDList from '../usefuldata/tldlist.json';

export const validateEmail = (email: string): Boolean => {
  const regexp = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

  // Case where the email is not okay
  if (!email || email.length < 1 || !regexp.test(email)) {
    return false;
  } else {
    const domain = email.split('@')[1]; // gmail.com , yahoo.com etc
    const tld = domain.split('.')[1]; // .com .io etc
    if (
      !validTLDList.includes(tld.toUpperCase()) ||
      domain === 'gmail.co' ||
      domain === 'yahoo.co'
    )
      return false;

    return true;
  }
};

// Checks if the the name contains more than 2 names ( First and Last name )
export const validateFullName = (fullname: string): Boolean => {
  return fullname.length > 0 && fullname.split(' ').length >= 2;
};

// Will need to check for all the required informatio
export const isUserMissingPreRegistrationInformation = (user: any): Boolean => {
  return user && user.age && user.occupation;
};
