export const SurveyPageThankYou = (props: {}) => {
  return (
    <div className="text-center">
      <div className="md:text-3xl text-xl">Thank you for letting us know!</div>
      <div className="text-gray-500 text-sm mt-4">
        We will use this data to provide more customized learning experience for
        you :)
      </div>
      <div className="my-12 text-8xl">🎓</div>
    </div>
  );
};
