import mixpanel from '../../analytics/mixpanel';

type Events =
  | 'Achievement notification modal opened'
  | 'Achievement notification modal closed'
  | 'Achievement notification modal continue button clicked';

type UserData = {
  userID?: string;
  achievementID?: string;
};

export const trackAchievementEvent = (event: Events, data: UserData = {}) => {
  mixpanel.track(event, data);
};
