import { doc, getDoc } from 'firebase/firestore';
import { initializeFirebase } from '../configValues';

const { firestore: db } = initializeFirebase();

export async function getPhraseRanking() {
  const phraseRankingSnapShot = doc(db, 'ranking', 'phrasesLearned');
  const phraseRankingDoc = await getDoc(phraseRankingSnapShot);
  return phraseRankingDoc.data();
}

export async function getStreakRanking() {
  const streakRankingSnapShot = doc(db, 'ranking', 'streak');
  const streakRankingDoc = await getDoc(streakRankingSnapShot);
  return streakRankingDoc.data();
}
