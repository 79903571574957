import * as yup from 'yup';

import { FormData, SchemaFor } from '../../components/forms/hooks/useForm';

export interface DiscountCodesData extends FormData {
  code?: string;
  value?: number;
  isPercentage?: boolean;
  creatorFirstName?: string;
  creatorLastName?: string;
  creatorEmail?: string;
}

export const validationSchema: SchemaFor<DiscountCodesData> = {
  code: yup
    .string()
    .min(1, 'This field cannot be so short')
    .required('This field is required')
    .lowercase(),
  value: yup
    .number()
    .required('This field is required')
    .test(
      'Zero or more',
      'Value should be zero (0) or more',
      (value): boolean => {
        if (typeof value === 'string' || typeof value === 'undefined')
          return false;
        return value >= 0;
      },
    ),
  isPercentage: yup.boolean().required('This field is required'),
  creatorFirstName: yup
    .string()
    .min(1, 'This field cannot be so short')
    .required('This field is required'),
  creatorLastName: yup
    .string()
    .min(1, 'This field cannot be so short')
    .required('This field is required'),
  creatorEmail: yup
    .string()
    .min(1, 'This field cannot be so short')
    .email('This is not a valid email')
    .required('This field is required'),
};
