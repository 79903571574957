import { useEffect } from 'react';
import { PrimaryButton } from '../../../../components/buttons/primary';
import { trackClassLinkRedirect } from '../../analytics';

export const ClassPrepTranscribePage = (props: {}) => {
  // useEffect(() => {
  //   window.addEventListener('beforeunload', function (e) {
  //     // trackClassLinkRedirect('Attempted to close class transcription page', {
  //     //   status: 'transcribe',
  //     // });
  //     // Cancel the event
  //     // Cancel the event
  //     e.preventDefault();
  //     // Chrome requires returnValue to be set
  //     e.returnValue = '';
  //   });
  // });
  return (
    <div className="justify-center items-center flex flex-col max-w-[500px] text-center">
      <div className="md:text-3xl text-xl flex justify-center items-center">
        <span className="relative flex h-3 w-3 mr-6">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
        </span>
        Keep this page open!
      </div>
      <div className="text-gray-500 text-sm mt-4">
        This page is recording your class performance for us to share with your
        teacher to give you more personalized feedback in the future. You may
        close this page after your class.
      </div>
      <div className="my-12 text-8xl">🎓</div>
    </div>
  );
};
