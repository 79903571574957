import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { initializeFirebase } from '../configValues';
import { updateBookingDetail } from '../subscription/bookings/bookings';

const { storage } = initializeFirebase();

// Saves and update classVoiceRecordingURL in booking docs
export const uploadClassVoiceRecording = async (
  file: File,
  classSessionID: string,
  bookingID: string,
  userID: string,
  onProgress?: Function,
  onSuccess?: Function,
  onProgressMileStone?: Function,
  onError?: Function,
) => {
  let past0flag = false;
  let past20flag = false;
  let past50flag = false;
  let past80flag = false;
  const uploadTask = uploadBytesResumable(
    ref(
      storage,
      `/class_voice_recordings/${classSessionID}/${bookingID}/${classSessionID}_${bookingID}_${userID}_class_voice_recording`,
    ),
    file,
  );

  uploadTask.on('state_changed', function (s) {
    let progress = (s.bytesTransferred / s.totalBytes) * 100;
    onProgress?.(progress);

    if (progress > 0 && !past0flag) {
      onProgressMileStone?.(progress);
    } else if (progress > 20 && !past20flag) {
      onProgressMileStone?.(progress);
    } else if (progress > 50 && !past50flag) {
      onProgressMileStone?.(progress);
    } else if (progress > 80 && !past80flag) {
      onProgressMileStone?.(progress);
    }
  });

  try {
    const uploadedFile = await uploadTask;
    const uploadFileLink = await getDownloadURL(uploadedFile.ref);

    onSuccess?.();

    await updateBookingDetail(bookingID, {
      classVoiceRecordingURL: uploadFileLink,
    });
  } catch (err) {
    onError?.(err);
  }
};
