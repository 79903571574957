import * as yup from 'yup';

import { FormData, SchemaFor } from '../../../../components/forms/hooks';

export interface HowHearAboutUsData extends FormData {
  referMethod?: string;
  instaHandle?: string;
  tiktokHandle?: string;
  youtubeHandle?: string;
}

export const validationSchema: SchemaFor<HowHearAboutUsData> = {
  referMethod: yup
    .string()
    .defined('This field is required.')
    .required('This field is required.'),
  instaHandle: yup.string().when('referMethod', {
    is: (value: string) => value === 'instagram',
    then: yup.string().required('Please specify the Instagram handle.'),
  }),
  tiktokHandle: yup.string().when('referMethod', {
    is: (value: string) => value === 'tiktok',
    then: yup.string().required('Please specify the TikTok handle.'),
  }),
  youtubeHandle: yup.string().when('referMethod', {
    is: (value: string) => value === 'youtube',
    then: yup.string().required('Please specify the channel name.'),
  }),
};

export const instaPartners: any = [
  {
    name: '@immigo.io',
  },
  {
    name: '@soysantiagocano',
  },
  {
    name: '@devcaress',
  },
];

export const tikTokPartners: any = [
  {
    name: '@immigo.io',
  },
  {
    name: '@devcaress',
  },
];

export const youtubePartners: any = [
  {
    name: 'English Speaking Success',
  },
  {
    name: 'Learn English with Chelsea',
  },
];
