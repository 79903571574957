import mixpanel from '../../../analytics/mixpanel';

type Events =
  | 'Clicked authenticate with Google'
  | 'Clicked authenticate with Facebook'
  | 'Clicked start with Kakao';
type UserData = {
  userID?: string;
  userName?: string;
  userEmail?: string;
};

export const trackAuthEvents = (event: Events, data: UserData = {}) => {
  mixpanel.track(event, data);
};
