import { cx } from 'classix';
import moment from 'moment-timezone';
import { LoadingCircle } from '../icons/loadingCircle';
import { useEffect, useRef, useState } from 'react';
import { CheckIcon, SpeakerWaveIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

export const TranscriptContainer = (props: {
  content: string;
  audioRecordingURL?: string;
  startSeconds?: number;
  endSeconds?: number;
  speaker?: string;
  onChosen?: Function;
  chosen?: boolean;
}) => {
  const {
    content,
    audioRecordingURL,
    startSeconds,
    endSeconds,
    speaker,
    onChosen,
    chosen,
  } = props;
  const startTime = startSeconds || 0;
  const endTime = endSeconds || 0;
  const audioID = `${startTime}-${endTime}-${content}`;

  const [audioPlaying, setAudioPlaying] = useState(false);
  const [audioTimeLeft, setAudioTimeLeft] = useState(endTime - startTime);
  const [audioLoading, setAudioLoading] = useState(false);
  const audioRef = useRef<HTMLAudioElement>();

  useEffect(() => {
    const audioObj: HTMLAudioElement = document.getElementById(
      audioID,
    ) as HTMLAudioElement;
    if (audioObj) {
      audioRef.current = audioObj;
      audioObj.currentTime = startTime - 1; // buffer
      audioObj.addEventListener('timeupdate', function () {
        setAudioTimeLeft(endTime - this.currentTime);
        if (this.currentTime >= endTime) {
          this.pause();
          audioObj.currentTime = startTime - 1;
        }
      });
      audioObj.addEventListener('loadstart', () => {
        setAudioLoading(true);
      });
      audioObj.addEventListener('loadeddata', () => {
        setAudioLoading(false);
      });
      audioObj.addEventListener('playing', () => {
        setAudioPlaying(true);
      });
      audioObj.addEventListener('pause', () => {
        setAudioPlaying(false);
      });
    }
  }, []);

  return (
    <div className="bg-white rounded-md my-3 shadow p-2 text-left">
      <div className="flex items-center">
        {audioRecordingURL ? (
          <audio id={audioID} src={audioRecordingURL} />
        ) : null}
        <span className="text-gray-500 mr-2">{`Speaker ${
          speaker?.split('S')?.[1] ?? 'X'
        }`}</span>
        <span>{content}</span>

        {audioRecordingURL && audioTimeLeft > 0 ? (
          <span className="flex items-center text-left mr-4">
            <SpeakerWaveIcon
              className={cx(
                audioLoading ? 'text-gray-400' : 'cursor-pointer',
                'w-5 h-5 ml-2 flex-shrink-0',
                audioPlaying ? 'text-blue-immigo animate-pulse' : 'text-black',
              )}
              onClick={() => {
                const audioObj: HTMLAudioElement = document.getElementById(
                  audioID,
                ) as HTMLAudioElement;

                if (audioObj.paused) {
                  audioObj.currentTime = startTime; // buffer
                  audioObj.play();
                } else {
                  audioObj.pause();
                }
              }}
            />
            {audioLoading ? <LoadingCircle className="text-gray-400" /> : null}
            <span
              className={cx(
                'ml-2',
                audioPlaying ? 'text-blue-immigo' : 'text-black',
              )}
            >
              {`${moment
                .duration(audioTimeLeft * 1000)
                .minutes()
                .toString()
                .padStart(2, '0')}:${moment
                .duration(audioTimeLeft * 1000)
                .seconds()
                .toString()
                .padStart(2, '0')}`}
            </span>
          </span>
        ) : null}
        {onChosen ? (
          <CheckCircleIcon
            onClick={() => {
              onChosen(content, startSeconds, endSeconds, speaker);
            }}
            className={cx(
              'ml-auto w-5 h-5 cursor-pointer flex-shrink-0',
              chosen ? 'text-green-500' : 'text-gray-300',
            )}
          />
        ) : null}
      </div>
    </div>
  );
};
