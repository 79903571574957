import {
  useFormik,
  FormikValues,
  FieldInputProps,
  FormikErrors,
  yupToFormErrors,
  FieldMetaProps,
  FormikConfig,
} from 'formik';
import { SchemaOf, BaseSchema } from 'yup';

export type FieldOf<T> = keyof T;
export type TypeOfFieldOf<T> = T[keyof T];
export type SchemaFor<T> = { [Key in keyof Required<T>]: BaseSchema };

export type FormData = FormikValues &
  Partial<Record<string, string | number | boolean>>;

export interface FormConfig<T extends FormData> extends FormikConfig<T> {
  validationSchema?: SchemaFor<T>;
}

class WrappedFormik<T extends FormData> {
  formik(config: FormConfig<T>) {
    // eslint-disable-next-line
    return useFormik<T>(config);
  }
}
type FormikReturnType<T extends FormData> = ReturnType<
  WrappedFormik<T>['formik']
>;

export interface FieldProps
  extends FieldInputProps<any>,
    Omit<FieldMetaProps<any>, 'initialTouched'> {
  id: string;
  disabled?: boolean;
}

type FieldPropsWithoutOnChange = Omit<FieldProps, 'onChange'>;

export interface SelectFieldProps extends FieldPropsWithoutOnChange {
  onChange?: (newValue: string, index?: number) => void;
}

export interface RadioFieldProps extends FieldPropsWithoutOnChange {
  onChange?: (newValue?: string) => void;
}

type OverridenFormikReturnType<T extends FormData> = Omit<
  FormikReturnType<T>,
  'getFieldProps'
>;

export interface FormHookReturnType<T extends FormData>
  extends OverridenFormikReturnType<T> {
  getFieldProps: (
    field: FieldOf<T>,
    forceValidationOnBlur?: boolean,
  ) => FieldProps;
  getSelectProps: (
    field: FieldOf<T>,
    onChange?: (value: string, index?: number) => void,
    forceValidationOnBlur?: boolean,
  ) => SelectFieldProps;
  getRadioProps: (
    field: FieldOf<T>,
    getValue: (fieldValue: TypeOfFieldOf<T>) => string | null,
    onChange?: (newValue?: string) => void,
    forceValidationOnBlur?: boolean,
  ) => RadioFieldProps;
}

export async function validateValues<T extends FormData>(
  values: T,
  validationSchema?: SchemaOf<T>,
): Promise<FormikErrors<T> | undefined> {
  if (!validationSchema) return undefined;
  try {
    await validationSchema.validate(values, { abortEarly: false });
  } catch (err) {
    // console.error(JSON.stringify(err, null, 2));
    return yupToFormErrors(err);
  }
  return undefined;
}

export function yupFormikWrapper<T extends FormData>(
  validationSchema?: SchemaOf<T>,
): (values: T) => Promise<FormikErrors<T> | undefined> {
  return (values) => {
    return validateValues(values, validationSchema);
  };
}
