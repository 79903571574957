import { CsatRadio } from '../../../../../../components/forms/csatRadio/csatRadio';
import { CsatRadioButtonType } from '../../../../../../components/forms/csatRadio/csatRadioButton';

const csatList: Array<CsatRadioButtonType> = [
  {
    emoji: '😠',
    color: 'border-red-600 hover:bg-red-600',
    textStyle: 'text-white bg-red-600',
    chosenStyle: 'bg-red-600',
    label: 'Very unsatisfied',
    id: 'very-unsatisfied',
  },
  {
    emoji: '😕',
    color: 'border-red-500 hover:bg-red-500',
    textStyle: 'text-white bg-red-500',
    chosenStyle: 'bg-red-500',
    label: 'Unsatisfied',
    id: 'unsatisfied',
  },
  {
    emoji: '😐',
    color: 'border-yellow-500 hover:bg-yellow-500',
    textStyle: 'text-white bg-yellow-500',
    chosenStyle: 'bg-yellow-500',
    label: 'Neutral',
    id: 'neutral',
  },
  {
    emoji: '🙂',
    color: 'border-green-400 hover:bg-green-400',
    textStyle: 'text-white bg-green-400',
    chosenStyle: 'bg-green-400',
    label: 'Satisfied',
    id: 'satisfied',
  },
  {
    emoji: '🤩',
    color: 'border-green-500 hover:bg-green-500',
    textStyle: 'text-white bg-green-500',
    chosenStyle: 'bg-green-500',
    label: 'Very satisfied',
    id: 'very-satisfied',
  },
];

export const ClassRatingSurvey = (props: { onChosen: Function }) => {
  const { onChosen } = props;
  return (
    <div>
      <div className="justify-center items-center flex flex-col mb-4">
        <legend className="text-base font-semibold leading-6 text-gray-900">
          Class Rating
        </legend>
        <div className="text-gray-500 mb-2">Was this class right for you?</div>
      </div>

      <div className="mb-4">
        <CsatRadio
          options={csatList}
          onChosen={(id: string) => {
            onChosen(id);
          }}
        />
      </div>
    </div>
  );
};
