import { useEffect, useState } from 'react';

export const CountDownToTimer = function (props: {
  countDownTo: number;
  onTimeUp?: Function;
  onTimeChange?: Function;
}) {
  const { countDownTo, onTimeUp, onTimeChange } = props;
  const [currentTime, setTime] = useState(
    (countDownTo ?? 0) - new Date().valueOf(),
  );

  useEffect(() => {
    if (countDownTo) {
      setInterval(() => {
        setTime(countDownTo - new Date().valueOf());
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (currentTime <= 0) {
      onTimeUp?.(currentTime); // Trigger onTimesUp event
    }
    onTimeChange?.(currentTime);
  }, [currentTime]);

  return <></>;
};
