import { useEffect } from 'react';
import { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  analyticsLogInPage14DayTrialButtonClick,
  analyticsLoginPageVisited,
} from '../../analytics';
import LoginRegisterBox from '../../components/forms/loginregister';
import { LoadingOverlay } from '../../components/loading';

import { UserData } from '../../types/user';
import { addUserToOrganization } from '../../firebase/organization/organization';

export const EnterpriseOnBoarding = () => {
  const [submitting, setSubmitting] = useState(false);
  const [user, setUser] = useState<undefined | UserData>();

  const organization = window.location.pathname.split('/')[2];

  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );

  useEffect(onLoad, []);

  function onLoad() {
    analyticsLoginPageVisited(); // Track login visited
  }

  async function onLoginSuccess(id: string) {
    setSubmitting(true);
    const addedToOrg = await addUserToOrganization(id, organization);
    setSubmitting(false);
    if (!addedToOrg) {
      window.location.href = '/home';
    } else {
      window.location.href = '/onboard-business';
    }
  }

  useEffect(() => {
    if (userData?.id) onLoginSuccess(userData.id);
  }, [userData]);

  return (
    <div className="flex flex-col justify-center max-w-screen-xl m-auto max-w-screen-xl m-auto">
      <LoadingOverlay enabled={submitting} />
      <img
        className="md:mx-auto h-10 mr-auto"
        src="https://immigoimages.s3.amazonaws.com/Immigo_new_logo.png"
        alt="Your Company"
      />
      <div className="mt-10">
        <LoginRegisterBox onLoginSuccess={() => {}} />
      </div>
    </div>
  );
};
