import cx from 'classix';
import { BundleDeal } from '../../../types/bundle';
import { capitalizeEveryFirstLetter } from '../../../util/standardization';

const baseLiveLessons = 5;
const basePodMeetings = 4;

const nearest = (value: number) => {
  const newValue = value * 10;
  const decimals = newValue - Math.floor(newValue);
  if (decimals === 0.5) return value;
  return Math.round(newValue) / 10;
};

export const BundleBox = (
  props: BundleDeal & {
    onClick: Function;
    chosen: boolean;
    coursePrice: number;
    originalPrice: number;
    isReturneePrice?: boolean;
    returneeDiscount?: number;
  },
) => {
  const {
    onClick,
    chosen,
    discount,
    numberOfCohorts,
    name: bundleName,
    coursePrice,
    bestDeal,
    priceBefore,
    isReturneePrice,
    returneeDiscount,
  } = props;

  const bundlePrice: string = Math.floor(
    coursePrice * numberOfCohorts * (1 - discount),
  ).toFixed(2);
  const awardedCredit: number = coursePrice * Math.floor(numberOfCohorts);
  const perCohortCalculatedPrice: string = Math.floor(
    parseFloat(bundlePrice) / numberOfCohorts,
  ).toFixed(0);

  return (
    <div
      role={'button'}
      className={cx(
        `relative overflow-hidden flex flex-col gap-4 cursor-pointer p-4 bg-white rounded-md hover:bg-black-soft ${
          chosen ? 'border-2 border-blue-immigo shadow-md' : 'border shadow-sm'
        }`,
        'pt-8',
      )}
      onClick={() => onClick()}
    >
      {bestDeal ? (
        <div className="absolute right-4 -top-4 h-8 w-16">
          <div className="shadow-md absolute transform rotate-45 bg-blue-immigo font-sm text-center text-white font-semibold py-1 pl-10 pr-12 left-[-34px] top-[32px] w-[170px]">
            Popular
          </div>
        </div>
      ) : null}

      <div className={cx('flex flex-col gap-4')}>
        <div>
          <h6
            className={cx(
              'text-xl',
              chosen
                ? 'text-blue-immigo font-bold'
                : 'text-lightBlue-900 font-semibold',
            )}
          >
            {capitalizeEveryFirstLetter(bundleName)}
          </h6>
          {isReturneePrice ? (
            <span className={'text-lightBlue-900 opacity-90'}>
              (Save ~{(returneeDiscount || discount) * 100}%)
            </span>
          ) : (
            <>
              {numberOfCohorts > 1 && (
                <span className={'text-lightBlue-900 opacity-90'}>
                  (Save ~{nearest(discount) * 100}%)
                </span>
              )}
            </>
          )}
        </div>
        <p className={cx('font-bold text-lightBlue-900')}>
          <span className={cx('text-4xl')}>${perCohortCalculatedPrice}</span>
          {numberOfCohorts > 1 && (
            <>
              <br />
              <small>per course</small>
            </>
          )}
        </p>
        {numberOfCohorts > 1 || isReturneePrice ? (
          <small className={cx('text-sm font-normal')}>
            <span className={cx('line-through opacity-85 text-green-500')}>
              $
              {priceBefore ||
                Math.round(
                  (isReturneePrice ? 39 : coursePrice) * numberOfCohorts,
                )}
            </span>{' '}
            {numberOfCohorts > 1 ? (
              <span>${Math.round(Number(bundlePrice))}</span>
            ) : null}
          </small>
        ) : (
          <br />
        )}
      </div>
      <ul className={'list-disc px-3 text-sm flex flex-col gap-1'}>
        {numberOfCohorts > 1 ? <li>Use the credit anytime</li> : null}
        <li>{Math.floor(baseLiveLessons * numberOfCohorts)} Live lessons</li>
        <li>{Math.floor(basePodMeetings * numberOfCohorts)} Pod meetings</li>
        <li>1-on-1 follow-up if you fall behind</li>
        <li>Unlimited practice sessions</li>
        <li>Access to all the materials and recordings</li>
      </ul>
    </div>
  );
};
