import CountUp from 'react-countup';
import {
  topOccurenceMap,
  topOccurenceMapWithoutCommonWords,
} from '../../../util/data';
import { Fade } from 'react-awesome-reveal';
import { SimpleBackAndNext } from '../../../components/buttons/simpleBackAndNext/simpleBackAndNext';
import { capitalizeFirstLetter } from '../../../util/standardization';
import { ImmigoWhiteLogo } from '../../../components/icons/immigoWhite';
import { ScreenShotButton } from '../../../components/buttons/screenShot/screenShotButton';
import { trackYearlyInsight } from '../../../features/YearlyInsight/analytics';
import { useEffect } from 'react';

export const YearlyTopThreeWords = (props: {
  wordMap?: any;
  onNext: Function;
  onBack: Function;
}) => {
  const { wordMap, onNext, onBack } = props;
  function getTopThreeWords() {
    return topOccurenceMapWithoutCommonWords(wordMap, 3);
  }
  useEffect(() => {
    trackYearlyInsight('Visited wrapped top three page');
  });
  return (
    <div className="text-center flex flex-col items-center justify-center py-24 bg-gradient-to-r from-cyan-500 to-blue-500 h-full">
      <div className="w-48 flex">
        <ImmigoWhiteLogo />
      </div>
      <ScreenShotButton
        onClick={() => {
          trackYearlyInsight('Wrapped share with friends clicked', {
            page: 'top-three',
          });
        }}
      />
      {wordMap ? (
        <div className="text-white">
          <div className="text-3xl font-semibold">
            📊 Here are your top 3 words:
          </div>
          {getTopThreeWords().map((w: string, index: number) => {
            return (
              <div className="text-3xl my-2" key={w + index}>
                <Fade delay={1000 * (index + 1)}>{`${
                  index + 1
                }. ${capitalizeFirstLetter(w)} : ${wordMap?.[w]}`}</Fade>
              </div>
            );
          })}
        </div>
      ) : null}
      <div data-html2canvas-ignore="true">
        <SimpleBackAndNext onNext={onNext} onBack={onBack} color="text-white" />
      </div>
    </div>
  );
};
