import cx from 'classix';
import { CSSProperties, FC } from 'react';

interface FieldErrorProps {
  touched?: boolean;
  error?: string | null;
  className?: string;
  style?: CSSProperties;
}

export const FieldError: FC<FieldErrorProps> = (props) => {
  const { touched, error, className, style } = props;
  return (
    <>
      {touched && error ? (
        <small
          className={cx(
            'text-red-warning',
            'rounded-sm mt-1 self-start',
            className,
          )}
          style={style}
        >
          {error}
        </small>
      ) : null}
    </>
  );
};
