import { PrimaryButton } from '../../../../components/buttons/primary';
import { useWindowSize } from 'react-use';
import { useEffect, useState } from 'react';
import ReactConfetti from 'react-confetti';
import { trackOnBoardingPage } from '../../../../features/OnBoard/analytics';

export const OnboardingFinishedPage = (props: {}) => {
  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    setShowConfetti(true);
  }, []);

  return (
    <div className="text-center">
      {showConfetti ? (
        <ReactConfetti
          width={width}
          height={height}
          numberOfPieces={320}
          recycle={false}
        />
      ) : null}
      <div className="animate-[wiggle_1s_ease-in-out_infinite] text-8xl mb-6">
        🚀
      </div>
      <div className="text-blue-immigo text-3xl mb-2">You are all set!</div>
      <div className="text-gray-500 text-md mb-6">
        Let's check out the classes you booked.
      </div>
      <PrimaryButton
        text="Check out my classes"
        onClick={() => {
          trackOnBoardingPage('Clicked check my classes on boarding page');
          window.location.href = '/home';
        }}
      />
    </div>
  );
};
