import { PlusIcon, UserIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { LoadingCircle } from '../../icons/loadingCircle';
import { CheckIcon } from '@heroicons/react/24/outline';

export const FollowButton = (props: {
  onClick: Function;
  followed: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const { onClick, followed } = props;

  return (
    <button
      type="button"
      className="rounded bg-blue-immigo px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-immigo"
      onClick={async () => {
        setLoading(true);
        await onClick();
        setLoading(false);
      }}
    >
      <div className="flex items-center">
        {loading ? <LoadingCircle className="mr-2" /> : null}
        {!followed && !loading ? (
          <div className="flex mr-2 items-center">
            <UserIcon className="h-5 w-5 text-white" />
            <PlusIcon className="h-3 w-3 text-white" />
          </div>
        ) : (
          <div className="flex mr-2 items-center">
            <CheckIcon className="h-5 w-5 text-white" />
          </div>
        )}
        <div>{followed ? 'Following' : 'Follow'}</div>
      </div>
    </button>
  );
};
