import { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import LoginRegisterBox from '../../components/forms/loginregister';

import {
  enrollStudentInCourse,
  getCourseByUID,
  returnUserByUID,
} from '../../firebase/configuration';
import ApplicationQuestionForm from './questionform';
import MessageModal from '../../components/modals/messageModal/index';
import { useHistory } from 'react-router-dom';
import { LoadingOverlay } from '../../components/loading/index';
import {
  PaymentPage,
  DistributorType,
} from '../../components/registration/paymentpage/index';
import { onAuthStateChanged } from 'firebase/auth';
import { initializeFirebase } from '../../firebase/configValues';
import { trackRegistrationEvent } from '../../features/CourseRegistration/analytics';
import { trackCheckoutEvent } from '../../features/Payment/analytics';
import VerticalMessageModal from '../../components/modals/verticalMessageModal/verticalMessageModal';

const { auth } = initializeFirebase();

const StartComponent = ({
  courseTitle,
  onNextClick,
  courseSessionStartTime,
}: any) => {
  return (
    <div className="flex flex-col">
      <div className="text-3xl md:text-4xl mb-4 text-center font-semibold">
        {'Apply to ' + courseTitle}
      </div>
      <div className="text-center">
        {
          'Take just 3 minutes to answer the required questions to apply to join the course '
        }
      </div>

      {courseSessionStartTime ? (
        <div className="border-blue-immigo w-96 rounded-lg bg-white shadow-xl px-2 w-4/9 my-4 py-8 m-auto text-center">
          {'Our weekly workshop will take place '}{' '}
          <span className="font-bold text-blue-immigo">
            {courseSessionStartTime}
          </span>
          {
            ", for 5 weeks. Even if you can't make it to the workshop, we will record all the sessions for you to watch and you will experience our curated program that you will go through with your peers."
          }
        </div>
      ) : null}
      <button
        className="mx-auto mt-8 bg-blue-immigo hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        value="Submit"
        type="button"
        onClick={onNextClick}
      >
        Start
      </button>
      <div className="text-center mt-7 text-black-light">
        {
          'Disclaimer: This course is only suitable for intermediate or above English speakers (A2 or above). By clicking Start, you have acknowledged that you have the right English level to join the course.'
        }
      </div>
    </div>
  );
};

const ChooseTrialComponent = ({ onFreeTrialClick, onSkipTrialClick }: any) => {
  useEffect(onLoad, []);
  function onLoad() {
    trackCheckoutEvent('Shown trial or buy now option');
  }
  return (
    <div className="flex flex-col">
      <div className="text-3xl md:text-4xl mb-4 text-center font-semibold">
        {'Try your first workshop for free'}
      </div>
      <div className="text-center">
        {
          'If you are trying us for the first time, you can experience your first workshop free of charge. You can decide after the first workshop if you want to pay for your full course experience.'
        }
      </div>
      <div className="flex flex-col w-72 mx-auto my-6">
        <button
          type="button"
          onClick={onFreeTrialClick}
          className="my-2 text-center items-center rounded-md border border-transparent bg-blue-immigo px-4 
          py-2 text-base font-medium text-white shadow-sm hover:bg-blue-immigo-darker 
          focus:outline-none focus:ring-2 focus:ring-blue-immigo focus:ring-offset-2"
        >
          Start free trial
        </button>
        <button
          type="button"
          onClick={onSkipTrialClick}
          className="my-2 text-center items-center rounded-md border border-gray-300 
          bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 
          focus:outline-none focus:ring-2 focus:ring-blue-immigo focus:ring-offset-2"
        >
          Skip the free trial and buy now
        </button>
      </div>

      <div className="text-center text-black-light">
        {`After the free workshop, you have 24 hours to cancel anytime to avoid the charge.`}
      </div>
    </div>
  );
};

const AccountRegistrationComponent = ({ courseTitle, onNext }: any) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(onLoad, []);

  function onLoad() {
    onAuthStateChanged(auth, (user) => {
      if (user) onNext();
    });
  }

  function onLogInSuccess() {
    onNext();
  }

  function onLogInFail() {
    setSubmitting(false);
  }

  function onSignInClick() {
    setSubmitting(true);
  }

  function onRegisterClick() {
    setSubmitting(true);
  }

  return (
    <div className="flex flex-col">
      <LoadingOverlay enabled={submitting} />
      <div className="text-2xl md:text-2xl mb-2 text-center w-72">
        {'Sign up to start learning English with Immigo'}
      </div>
      <div className="m-auto mt-10">
        <LoginRegisterBox onLoginSuccess={onLogInSuccess} defaultRegister />
      </div>
    </div>
  );
};

const CourseRegistrationComponent = ({ userData, onSetUserData }: any) => {
  const [course, setCourse] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [messageModalOpen, setMessageModalOpen] = useState<boolean>(false);
  const [freeTrial, setFreeTrial] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const history = useHistory();
  const courseID = window.location.pathname.split('/')[2];
  const distributorType = window.location.pathname.split(
    '/',
  )[3] as DistributorType;
  const referralCode =
    distributorType === 'referral'
      ? window.location.pathname.split('/')[4]
      : undefined;

  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    userDataLoaded();
  }, []);

  useEffect(() => {
    async function updateUserData() {
      if (userData?.id) {
        const user = await returnUserByUID(userData.id);
        if (user) onSetUserData(user);
      }
    }
    updateUserData();
  }, [currentPage]);

  function userDataLoaded() {
    if (courseID) {
      getCourseByUID(courseID, (course: any) => {
        setCourse(course);
        trackRegistrationEvent('Landed on the application', {
          courseID: courseID,
          courseTitle: course.courseTitle,
        });
      });
    } else {
      console.log('User or course ID is missing!');
    }

    const pi = params.get('payment_intent');
    if (pi) setCurrentPage(3);

    onAuthStateChanged(auth, (user) => {
      if (!user) {
        onSetUserData(undefined);
        setCurrentPage(0);
      }
    });
  }

  function renderCurrentPage() {
    const pages = [
      // <StartComponent
      //   courseTitle={course ? course.courseTitle : null}
      //   onNextClick={() => {
      //     console.log(userData?.courses?.length);
      //     // if (userData && (userData?.courses?.length > 0 || userData?.credit))
      //     //   setCurrentPage(3); // Skip trial if returning or have credit
      //     if (userData) setCurrentPage(2);
      //     else setCurrentPage(1);
      //   }}
      //   courseSessionStartTime={course ? course.sessionStartTime : null}
      // />,
      <ChooseTrialComponent
        onSkipTrialClick={() => {
          trackCheckoutEvent('Clicked buy now on the application flow', {
            courseID: courseID,
            userID: userData?.id,
            email: userData?.email,
          });
          setFreeTrial(false);
          setCurrentPage(1);
        }}
        onFreeTrialClick={() => {
          trackCheckoutEvent(
            'Clicked try free trial button on the application flow',
            {
              courseID: courseID,
              userID: userData?.id,
              email: userData?.email,
            },
          );
          setFreeTrial(true);
          setCurrentPage(1);
        }}
      />,
      <AccountRegistrationComponent
        onNext={() => {
          setCurrentPage(2);
        }}
      />,
      <PaymentPage
        userData={userData}
        course={course}
        courseID={courseID}
        courseTitle={course ? course.courseTitle : null}
        price={
          course
            ? distributorType === 'referral' && // Do not include discount for returnees
              userData?.courses?.length === 0 // No discount if user is not new
              ? course?.returneePrice
                ? course.returneePrice
                : 25
              : course.price
            : 0
        }
        distributorType={distributorType}
        referralCode={referralCode}
        onBack={() => {
          setCurrentPage(0);
        }}
        trial={freeTrial}
        onNext={() => {
          setCurrentPage(3);
        }}
        onSubmit={() => setSubmitting(true)}
        onFailure={() => setSubmitting(false)}
        onSuccess={async () => {
          setSubmitting(false);
          setCurrentPage(3);
          if (courseID && !userData.courses?.includes(courseID))
            await enrollStudentInCourse(courseID, userData.id).catch();
        }}
      />,
      <ApplicationQuestionForm
        userData={userData}
        onSetUserData={onSetUserData}
        studentID={userData ? userData.id : undefined}
        courseID={courseID}
        onNextClick={async () => {
          setSubmitting(false);
          setMessageModalOpen(true);
          trackRegistrationEvent('Submitted application', {
            courseID: courseID,
            courseTitle: course.courseTitle,
          });
        }}
        distributorType={distributorType}
        referralCode={referralCode}
      />,
    ];

    return currentPage < pages.length ? pages[currentPage] : null;
  }

  return (
    <div className="flex w-full justify-center">
      <LoadingOverlay enabled={submitting} />
      <VerticalMessageModal
        isOpen={messageModalOpen}
        onCloseModal={() => {
          setMessageModalOpen(false);
          history.push('/additional-info');
        }}
        mainMessage="You are in!"
        subMessage="You will be redirected to finish the rest of your profile. Please fill it out today to ensure you can have the optimal course experience."
        onButtonClick={() => {
          setMessageModalOpen(false);
          history.push('/additional-info');
        }}
        buttonText="Continue"
        theme={'default'}
      />
      {course ? (
        <div className="mx-auto mt-28 px-4 md:px-6 xl:px-0 max-w-prose">
          {renderCurrentPage()}
        </div>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  onSetUserData: (userData: any) =>
    dispatch({ type: 'USER_DATA_SET', userData }),
});

const mapStateToProps = (state: any) => ({
  userData: state.sessionState.userData,
});

export const CourseRegistration = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CourseRegistrationComponent);
