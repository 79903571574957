import * as yup from 'yup';

import { FormData, SchemaFor } from '../../../../components/forms/hooks';

export const relationshipStatuses = ['Single', 'In a relationship', 'Married'];
type RelationshipStatus = typeof relationshipStatuses[number];

export const kindsOfWork = [
  "I'm a student",
  'Education (e.g. Teacher, Professor)',
  'Engineering',
  'Technology (e.g. Product Manager, UX Designer)',
  'Research',
  'Law',
  'Healthcare',
  'Management',
  'Homemaker',
  'Finance',
  'Psychology',
  'Architecture',
  'Accounting',
  'Academia',
  'Service',
  'Construction',
  'Translation',
  'Sales',
  'Artists',
  'Government',
  'Unemployed',
  'Other',
];
type KindOfWork = typeof kindsOfWork[number];

export interface AdditionalInformationData extends FormData {
  age?: number;
  kindOfWork?: KindOfWork;
  occupation?: string;
  haveKids?: boolean;
  relationshipStatus?: RelationshipStatus;
  wishToBeContacted?: boolean;
  infoToKnow?: string;
}

export const validationSchema: SchemaFor<AdditionalInformationData> = {
  age: yup
    .number()
    .positive('This value is not valid.')
    .integer('This value is not valid.')
    .defined('This field is required.')
    .required('This field is required.'),
  relationshipStatus: yup
    .string()
    .oneOf(relationshipStatuses, 'This is not a valid option')
    .required('This field is required.'),
  haveKids: yup
    .boolean()
    .defined('This field is required.')
    .required('This field is required.'),
  occupation: yup.string().required('This field is required.'),
  kindOfWork: yup
    .string()
    .oneOf(kindsOfWork, 'This is not a valid option')
    .required('This field is required.'),
  wishToBeContacted: yup
    .boolean()
    .defined('This field is required.')
    .required('This field is required.'),
  infoToKnow: yup.string(),
};
