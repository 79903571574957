import { doc, updateDoc } from 'firebase/firestore';
import { initializeFirebase } from '../configValues';
const { firestore: db } = initializeFirebase();

export const updateApplicationStatus = async (
  applicationID: string,
  toUpdateMap: any,
) => {
  try {
    const courseReference = await doc(db, 'applications', applicationID);
    updateDoc(courseReference, toUpdateMap);
  } catch (err) {
    console.log(err);
  }
};
