import mixpanel from '../../analytics/mixpanel';

type Events =
  | 'Landed on the application'
  | 'Submitted application'
  | 'Paid for the course';

type CourseData = {
  courseID?: string;
  courseTitle?: string;
};

export const trackRegistrationEvent = (event: Events, data: CourseData) => {
  mixpanel.track(event, data);
};
