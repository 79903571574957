import {
  CalendarIcon,
  ClockIcon,
  LinkIcon,
  UserGroupIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import { cx } from 'classix';
import moment from 'moment';
import AvatarGroupStacked from '../../../components/avatar/avatarGroupStacked/avatarGroupStacked';
import { UserData } from '../../../types/user';
import { useEffect, useState } from 'react';
import { PrimaryButton } from '../../../components/buttons/primary';
import { useCollection } from 'react-firebase-hooks/firestore';
import { collection } from 'firebase/firestore';
import {
  BookingData,
  getSessionBookers,
} from '../../../firebase/subscription/subscription';
import { initializeFirebase } from '../../../firebase/configValues';
import { BookerData } from '../../../types/bookings';
import AvatarGroupClustered from '../../../components/avatar/avatarGroupClustered/avatarGroupClustered';
import { trackNextClassContainerEvents } from './analytics';
import { SecondaryBlueButton } from '../../../components/buttons/secondaryBlue';
const { firestore } = initializeFirebase();

interface NextClassContainerProps {
  userID: string;
  bookings: Array<BookingData>;
  bookingsLoading: boolean;
}

const useUserBookings = (userID: string) => {
  const [data, loading, error] = useCollection(
    collection(firestore, `users/${userID}/bookings`),
  );
  let newBookings: Array<BookingData> = [];
  data?.forEach((d: any) => {
    const docData: BookingData = d.data();
    docData.id = d.id;
    newBookings.push(docData);
  });
  newBookings = newBookings.filter((b: BookingData) => {
    return (
      b.startMili + b.durationMili > new Date().valueOf() &&
      !b.canceled &&
      !b.skipTime?.includes(b.startMili)
    );
  });
  newBookings = newBookings.sort((a: BookingData, b: BookingData) => {
    return a.startMili - b.startMili;
  });

  if (error) throw error;
  return {
    bookings: newBookings,
    bookingsLoading: loading,
    bookingsError: error,
  };
};

export const NextClassContainer = ({
  userID,
  bookings,
  bookingsLoading,
}: NextClassContainerProps) => {
  const [timeLeftToStartMoment, setTimeLeftToStart] = useState<moment.Duration>(
    moment.duration(),
  );
  const [bookersList, setBookersList] = useState<Array<BookerData>>([]);
  const [countryList, setCountryList] = useState<Array<string>>([]);

  function onClassLinkClick() {
    trackNextClassContainerEvents(
      'Clicked join class button on next class container',
    );
  }

  function onPrepareForClassClick() {
    trackNextClassContainerEvents(
      'Clicked prepare for class button on next class container',
    );
  }

  function onGetLiveFeedbackClick() {
    trackNextClassContainerEvents('Clicked get live feedback button');
  }

  const upcomingBooking = bookings?.[0];
  const bookingId = upcomingBooking?.id;
  const startMili = upcomingBooking?.startMili;
  const durationMili = upcomingBooking?.durationMili;
  const classSessionID = upcomingBooking?.classSessionID;
  const classTitle = upcomingBooking?.title;
  const cleanSlide = upcomingBooking?.cleanSlide;
  const timeLeftMili = startMili - new Date().valueOf();
  const joinTimeLimit = 1000 * 60 * 5; // 5 minutes
  const canJoin = timeLeftMili >= joinTimeLimit;
  const inProgress = timeLeftMili < 0;

  async function updateBookers() {
    if (!classSessionID) return;
    const bookers = (await getSessionBookers(
      classSessionID,
    )) as Array<BookerData>;
    let newCountryList: string[] = [];
    bookers?.map((booker) => {
      if (booker.nationality && !newCountryList.includes(booker.nationality))
        newCountryList.push(booker.nationality);
    });
    setCountryList(newCountryList);
    setBookersList(bookers);
  }

  useEffect(() => {
    setTimeLeftToStart(
      moment.duration(startMili - new Date().valueOf() - joinTimeLimit),
    );
  }, [startMili]);

  useEffect(() => {
    updateBookers();
  }, [classSessionID]);

  return (
    <div className="@container rounded-lg shadow w-full py-6 px-4 flex relative overflow-hidden bg-white">
      {upcomingBooking ? (
        <>
          <div className="flex-auto w-fill">
            <div className="text-blue-immigo flex items-center font-semibold mb-2">
              Next class
              {inProgress ? (
                <span className="ml-2 my-auto inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
                  <span className="relative flex h-2 w-2">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
                  </span>
                  In Progress
                </span>
              ) : null}
            </div>
            <h3
              className={
                'text-gray-700 pr-10 font-semibold xl:pr-0 text-2xl flex'
              }
            >
              <span>{classTitle}</span>
            </h3>
            <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
              <div className="flex items-start space-x-3">
                <dt className="mt-0.5">
                  <span className="sr-only">Date</span>
                  <CalendarIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </dt>
                <dd>
                  <time dateTime={moment(startMili).format()}>
                    {`${moment(startMili)
                      .local()
                      .format('MMMM Do, YYYY [at] hh:mm A')} - ${moment(
                      startMili,
                    )
                      .add(durationMili)
                      .local()
                      .format('hh:mm A')}`}
                  </time>
                </dd>
              </div>
            </dl>
            <dl className="mt-2">
              <div className="flex items-start space-x-3 text-gray-500">
                <dt className="mt-0.5">
                  <span className="sr-only">Date</span>
                  <VideoCameraIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </dt>
                <dd
                  onClick={() => {
                    onClassLinkClick?.();
                  }}
                >
                  Group class on{' '}
                  <a
                    className="underline hover:text-blue-immigo"
                    href="https://zoom.us/download?os=mac"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Zoom
                  </a>
                </dd>
              </div>
            </dl>
            {bookersList.length > 0 ? (
              <dl className="mt-2">
                <div className="flex items-center space-x-3 text-gray-500">
                  <dt className="mt-0.5">
                    <span className="sr-only">Date</span>
                    <UserGroupIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd>
                    <AvatarGroupStacked
                      key={`avatar-${bookingId}-${startMili}`}
                      limit={6}
                      imageLinks={bookersList.map(
                        (booker: UserData) => booker.profilePictureLink,
                      )}
                      names={bookersList.map((booker: UserData) => booker.name)}
                      nationalities={bookersList.map(
                        (booker: UserData) => booker.nationality ?? '',
                      )}
                      ids={bookersList.map((booker: UserData) => booker.id)}
                      onClickAvatar={(id: string) => {
                        trackNextClassContainerEvents(
                          'Clicked avatar in next class container',
                        );
                        window.open(
                          `${window.location.origin}/profile/${id}`,
                          '_blank',
                        );
                      }}
                    />
                  </dd>
                </div>
              </dl>
            ) : null}

            <div className="max-w-[450px] md:flex-row flex-col gap-4 flex mt-6 justify-between">
              <PrimaryButton
                text={
                  canJoin && classSessionID
                    ? `Join in ${
                        timeLeftToStartMoment.days()
                          ? timeLeftToStartMoment.days() + ' days '
                          : ''
                      }
              ${
                timeLeftToStartMoment.hours()
                  ? timeLeftToStartMoment.hours() + ' hours '
                  : ''
              }
              ${
                timeLeftToStartMoment.minutes()
                  ? timeLeftToStartMoment.minutes() + ' minutes'
                  : ''
              }`
                    : 'Join class'
                }
                onClick={() => {
                  window
                    .open(
                      `${window.location.origin}/class/${classSessionID}`,
                      '_blank',
                    )
                    ?.focus();
                  onClassLinkClick();
                }}
                disabled={!upcomingBooking || canJoin}
              />
              {cleanSlide ? (
                <SecondaryBlueButton
                  text="Prepare for class"
                  onClick={() => {
                    window
                      .open(
                        `${window.location.origin}/preview?bid=${bookingId}`,
                        '_blank',
                      )
                      ?.focus();
                    onPrepareForClassClick();
                  }}
                />
              ) : null}
              {/* <div className="relative">
                <span className="-top-2 -right-4 absolute inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-blue-700/10">
                  New!
                </span>
                <SecondaryBlueButton
                  text="Get live feedback"
                  onClick={() => {
                    window
                      .open(
                        `${window.location.origin}/ai?bid=${bookingId}`,
                        '_blank',
                      )
                      ?.focus();
                    onGetLiveFeedbackClick();
                  }}
                />
              </div> */}
            </div>
          </div>
          {bookersList?.length >= 3 ? (
            <div className="xl:block hidden">
              <AvatarGroupClustered
                imageLinks={bookersList?.map(
                  (booker) => booker.profilePictureLink,
                )}
              />
            </div>
          ) : null}
        </>
      ) : (
        <div></div>
      )}
      <div
        className="absolute left-0 top-48 -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
        aria-hidden="true"
      >
        <div
          className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#1cf7ff] to-[#9089FC]"
          style={{
            clipPath:
              'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
          }}
        />
      </div>
    </div>
  );
};
