import { Fragment, ReactElement } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import cx from 'classix';

type DropDownFloatOrientation =
  | 'bottom-left'
  | 'bottom-right'
  | 'top-left'
  | 'top-right';

export const DropDownFloat = (props: {
  options: Array<string>;
  onChosen: Function;
  icon?: ReactElement;
  placeholder?: string;
  chosenIndex?: number;
  orientation?: DropDownFloatOrientation;
}) => {
  const { options, orientation, onChosen, placeholder, chosenIndex, icon } =
    props;

  function getPosition() {
    switch (orientation) {
      case 'bottom-left':
        return 'bottom-0 left-0';
      case 'bottom-right':
        return 'bottom-0 right-0';
      case 'top-left':
        return 'top-0 left-0';
      case 'top-right':
        return 'top-0 right-0';
      default:
        return 'bottom-0 left-0';
    }
  }

  return (
    <Menu
      as="div"
      className={cx('inline-block text-left fixed', getPosition())}
    >
      {orientation === 'top-left' || orientation === 'top-right' ? (
        <div>
          <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            {icon}
            {chosenIndex !== undefined && options[chosenIndex]
              ? options[chosenIndex]
              : placeholder
              ? placeholder
              : 'Options'}
            <ChevronDownIcon
              className="-mr-1 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
      ) : null}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="right-0 z-10 my-1 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options.map((option: string, index: number) => {
              return (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => {
                        onChosen(index);
                      }}
                      className={cx(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm cursor-pointer',
                      )}
                      key={option + index}
                    >
                      {option}
                    </div>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
      {!orientation ||
      orientation === 'bottom-left' ||
      orientation === 'bottom-right' ? (
        <div>
          <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            {icon}
            {chosenIndex !== undefined && options[chosenIndex]
              ? options[chosenIndex]
              : placeholder
              ? placeholder
              : 'Options'}
            <ChevronDownIcon
              className="-mr-1 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
      ) : null}
    </Menu>
  );
};
