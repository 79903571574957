import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  FieldValue,
} from 'firebase/firestore';
import { returnUserByUID } from '../configuration';
import { initializeFirebase } from '../configValues';
import { UserData } from '../../types/user';
import { BookerData } from '../../types/bookings';
import {
  updateAllFutureClassSessionsInClass,
  updateTopicDetailForAllFutureClassSessionsInClass,
} from './classSessions/classSessions';
import { getCoachDocument } from './coaches/coaches';
import { EnglishLevelRatingMap } from '../../types/rating';
import { ClassType } from '../../types/class';
import { capitalizeFirstLetter } from '../../util/standardization';
const { firestore: db } = initializeFirebase();

export type ClassRescheduleObject = {
  [key: number]: number;
};

export type IdiomDefinitionObject = {
  definition: string;
  example?: string;
};

export type IdiomDefinitionMap = {
  [key: string]: IdiomDefinitionObject;
};

export type HowToAnwerObject = {
  question: string;
  questionIndex: number;
  answer: string;
};

export type HowToAnswerMap = {
  [key: string]: HowToAnwerObject;
};

export type UserPreviewActivityType = 'review' | 'preview' | 'warmup';

export type PreviewActivityProgress = {
  [key in UserPreviewActivityType]: boolean;
};

export type PreviewActivityData = {
  idiomToDefinitionExampleMap: IdiomDefinitionMap;
  mostRecentIdiomToDefinitionExampleMap: IdiomDefinitionMap;
  howToAnswerPreviousIdioms: HowToAnswerMap;
  mostRecentPreviousPhrases: Array<string>;
  progress?: PreviewActivityProgress;
};

export type TeacherData = {
  name: string;
  id: string;
  profilePictureLink: string | undefined;
  assignedAt?: number;
};

export interface BookingData {
  classID: string;
  startMili: number;
  durationMili: number;
  title: string;
  recurring: boolean;
  createdAt: number;
  bookerID: string;
  tag?: ClassType;
  recLevel?: string;
  teacher?: TeacherData;
  classSessionID?: string;
  id?: string;
  canceled?: boolean;
  canceledAt?: number;
  canceledReason?: string;
  recurringMeetingUrl?: string;
  skipTime?: Array<number>;
  topicID?: string;
  recurringTopic?: boolean;
  attended?: boolean;
  attendedTime?: number;
  timeZone?: string;
  slide?: string;
  recording?: string;
  instructorPhotoUrl?: string;
  bookerList?: Array<UserData>;
  noCap?: boolean;
  reschedule?: ClassRescheduleObject;
  phrases?: Array<string>;
  questions?: Array<string>;
  cleanSlide?: string;
  classRating?: string;
  correctionID?: string;
  previewActivity?: PreviewActivityData;
  insightID?: string;
  classVoiceRecordingURL?: string;
  addedBy?: string;
  theme?: string;
  didNotTalkToAnyone?: boolean;
  talkedTo?: Array<string>; // Array of userID of students that the student has talked to
  englishLevelRatings?: EnglishLevelRatingMap;
  peerReactionMap?: any;
}

export interface BookingDataUpdateMap
  extends Omit<BookingData, 'skipTime' | 'bookerList'> {
  skipTime?: Array<number> | FieldValue;
  bookerList?: Array<UserData> | FieldValue;
}

const weekMili = 1000 * 7 * 24 * 60 * 60;

export const getAllSessions = async () => {
  const classesReference = await collection(db, 'classSessions');
  const classes = await getDocs(classesReference);
  const classesData: any[] = [];
  classes.forEach((c: any) => {
    const data = c.data();
    data.id = c.id;
    classesData.push(data);
  });
  return classesData;
};

export const getClass = async (classID: string) => {
  const classRef = await doc(db, 'classes', classID);
  const classDoc = await getDoc(classRef);
  const classData = classDoc.data();
  return classData;
};

export const getClassSessionBookings = async (
  classID: string,
  startMili: number,
) => {
  const bookingsRef = await collection(
    db,
    'classes',
    classID,
    'classSessions',
    `${startMili}`,
    'bookings',
  );
  const bookingsDocs = await getDocs(bookingsRef);
  return bookingsDocs.docs.map((b) => b.data()) as Array<BookingData>;
};

export const getClassSessionDocByStartMili = async (
  classID: string,
  startMili: number,
) => {
  const classSessionRef = await doc(
    db,
    'classes',
    classID,
    'classSessions',
    `${startMili}`,
  );
  const classSessionDoc = await getDoc(classSessionRef);
  return classSessionDoc.data() as BookingData;
};

export const getTopic = async (topicID: string) => {
  const topicRef = await doc(db, 'topics', topicID);
  const topicDoc = await getDoc(topicRef);
  const topicData = topicDoc.data();
  return topicData;
};

export const getClassWeekTopic = async (classID: string, startMili: number) => {
  const c: BookingData = (await getClass(classID)) as BookingData;

  if (!c.recurring) return c.title; // Not a recurring class, so it must retain original
  if (c.recurringTopic) return c.title; // If this topic should be recurring
  if (!c.topicID) {
    if (!c.tag) return 'Topic coming soon!';
    else {
      if (c.tag === 'grammar' || c.tag === 'pronunciation' || c.tag === 'vocab')
        return `(${c.title}) Topic coming soon!`;
      else return `${capitalizeFirstLetter(c.tag)} Topic coming soon!`;
    }
  }

  const topic = await getTopic(c.topicID);
  const weekIndexSinceStart = Math.round((startMili - c.startMili) / weekMili);
  const title = topic?.titles?.[weekIndexSinceStart];

  if (!title) {
    if (!c.tag) return 'Topic coming soon!';
    else {
      if (c.tag === 'grammar' || c.tag === 'pronunciation' || c.tag === 'vocab')
        return `(${c.title}) Topic coming soon!`;
      else return `(${capitalizeFirstLetter(c.tag)}) Topic coming soon!`;
    }
  }

  return title;
};

export const getClassWeekCleanSlide = async (
  classID: string,
  startMili: number,
) => {
  const c: BookingData = (await getClass(classID)) as BookingData;
  if (!c.topicID) return undefined;
  const topic = await getTopic(c.topicID);
  const weekIndexSinceStart = Math.round((startMili - c.startMili) / weekMili);
  const slide = topic?.slides?.[weekIndexSinceStart];
  return slide;
};

export const getClassWeekTheme = async (classID: string, startMili: number) => {
  const c: BookingData = (await getClass(classID)) as BookingData;
  if (!c.topicID) return undefined;
  const topic = await getTopic(c.topicID);
  const weekIndexSinceStart = Math.round((startMili - c.startMili) / weekMili);
  const theme = topic?.themes?.[weekIndexSinceStart];
  return theme;
};

export const getClassWeekPhrases = async (
  classID: string,
  startMili: number,
) => {
  const c: BookingData = (await getClass(classID)) as BookingData;
  if (!c.topicID) return undefined;
  const topic = await getTopic(c.topicID);
  const weekIndexSinceStart = Math.round((startMili - c.startMili) / weekMili);
  const phrases = topic?.phrases?.[weekIndexSinceStart];
  return phrases;
};

export const getClassWeekQuestions = async (
  classID: string,
  startMili: number,
) => {
  const c: BookingData = (await getClass(classID)) as BookingData;
  if (!c.topicID) return undefined;
  const topic = await getTopic(c.topicID);
  const weekIndexSinceStart = Math.round((startMili - c.startMili) / weekMili);
  const questions = topic?.questions?.[weekIndexSinceStart];
  return questions;
};

export const getClassSession = async (sessionID: string) => {
  const sessionRef = await doc(db, 'classSessions', sessionID);
  const sessionDoc = await getDoc(sessionRef);
  const sessionData = sessionDoc.data();
  return sessionData as BookingData;
};

export const updateClassRecurringUrl = async (classID: string, url: string) => {
  const classRef = await doc(db, 'classes', classID);
  const update = await updateDoc(classRef, {
    recurringMeetingUrl: url,
  });
  return update;
};

export const updateRecurringCoach = async (
  classID: string,
  coachID: string,
) => {
  const classRef = await doc(db, 'classes', classID);
  const coach = await getCoachDocument(coachID);
  const coachProfile = coach?.id ? await returnUserByUID(coach.id) : undefined;

  if (!coachProfile || !coach) return;

  const coachData: TeacherData = {
    name: coachProfile.name,
    profilePictureLink: coachProfile.profilePictureLink,
    assignedAt: new Date().valueOf(),
    id: coach.id,
  };

  const update = await updateDoc(classRef, {
    teacher: coachData,
  });
  return coachData;
};

export const updateClassRecurringCoachForAllClassSessions = async (
  classID: string,
  coachID: string,
) => {
  const coach = await updateRecurringCoach(classID, coachID);

  if (!coach) return;

  const futureSessionUpdates = await updateAllFutureClassSessionsInClass(
    classID,
    {
      teacher: coach,
    },
  );

  return { coach, futureSessionUpdates };
};

export const updateClassRecurringUrlForAllClassSessions = async (
  classID: string,
  url: string,
) => {
  const classUpdate = await updateClassRecurringUrl(classID, url);
  const futureSessionUpdates = await updateAllFutureClassSessionsInClass(
    classID,
    {
      recurringMeetingUrl: url,
    },
  );
  return { classUpdate, futureSessionUpdates };
};

export const updateTopicIDForAllClassSessions = async (
  classID: string,
  topicID: string,
) => {
  const classUpdate = await updateClassTopicID(classID, topicID);
  const futureSessionUpdates =
    await updateTopicDetailForAllFutureClassSessionsInClass(classID);
  return { classUpdate, futureSessionUpdates };
};

export const updateClassTopicID = async (classID: string, topicID: string) => {
  const classRef = await doc(db, 'classes', classID);
  const classDoc = await getDoc(classRef);
  const classData = classDoc.data();

  const topicRef = await doc(db, 'topics', topicID);
  const topicDoc = await getDoc(topicRef);
  const topicData = topicDoc.data();

  // If the class already had assigned topic
  if (classData?.topicID) {
    const removeTopicRef = await doc(db, 'topics', classData?.topicID);
    await updateDoc(removeTopicRef, {
      classes: arrayRemove(classID),
    });
  }

  const updateClass = await updateDoc(classRef, {
    topicID: topicID,
  });
  const updateTopic = await updateDoc(topicRef, {
    classes: arrayUnion(classID),
  });

  return { updateTopic, updateClass };
};

export const getSessionBookers = async (sessionID: string) => {
  const bookings = await getSessionBookings(sessionID);
  const bookers = await Promise.all(
    bookings.map(async (bookingData: BookingData) => {
      if (bookingData.canceled) return null;
      const booker: BookerData = (await returnUserByUID(
        bookingData.bookerID,
      )) as BookerData;
      booker.attended = bookingData.attended ?? false;
      if (bookingData?.id) booker.bookingID = bookingData.id;
      return booker;
    }),
  );
  return (bookers as Array<BookerData>).filter((b: BookerData) => {
    return b;
  });
};

export const getSessionBookings = async (sessionID: string) => {
  const sessionsRef = await collection(
    db,
    'classSessions',
    sessionID,
    'bookings',
  );
  const sessionDocs = await getDocs(sessionsRef);
  const sessionsData: any[] = [];
  sessionDocs.forEach((c: any) => {
    const data = c.data();
    data.id = c.id;
    sessionsData.push(data);
  });
  return sessionsData;
};

export const getUserBookingClassSession = async (
  bookerID: string,
  sessionID: string,
) => {
  let bookings = await getSessionBookings(sessionID);

  bookings = bookings?.filter((booking: BookingData) => {
    return booking.bookerID === bookerID && !booking.canceled;
  });

  return bookings?.[0];
};

export const getSessionsOnClass = async (classID: string) => {
  const sessionsRef = await collection(db, 'classes', classID, 'classSessions');
  const sessionDocs = await getDocs(sessionsRef);
  const sessionsData: any[] = [];
  sessionDocs.forEach((c: any) => {
    const data = c.data();
    data.id = parseInt(c.id);
    sessionsData.push(data);
  });
  return sessionsData;
};

export const getSessionOnClass = async (classID: string, startMili: number) => {
  const sessionRef = await doc(
    db,
    'classes',
    classID,
    'classSessions',
    `${startMili}`,
  );
  const sessionDoc = await getDoc(sessionRef);
  const sessionData = sessionDoc.data();
  return sessionData;
};

export const createClassSessionDocument = async (
  classID: string,
  bookingData: BookingData,
) => {
  const classSessionData: Partial<BookingData> = {
    classID,
    startMili: bookingData.startMili,
    durationMili: bookingData.durationMili,
    title: bookingData.title,
    createdAt: new Date().valueOf(),
  };

  if (bookingData.phrases) classSessionData.phrases = bookingData.phrases;
  if (bookingData.cleanSlide)
    classSessionData.cleanSlide = bookingData.cleanSlide;
  if (bookingData.recurringMeetingUrl)
    classSessionData.recurringMeetingUrl = bookingData.recurringMeetingUrl;

  const classSession = await addDoc(
    collection(db, 'classSessions'),
    classSessionData,
  ); // Create a new booking document
  classSessionData.id = classSession.id;

  // Add class session data
  if (bookingData.id) {
    await setDoc(
      doc(db, 'classes', classID, 'classSessions', `${bookingData.startMili}`),
      classSessionData,
    ); // Add booking to the class
  }

  return classSession;
};

export const addBookingDataToClassSession = async (
  classSessionID: string,
  bookingData: BookingData,
) => {
  if (!bookingData?.id) return;

  const classSessionOnClassRef = await doc(
    db,
    'classes',
    bookingData.classID,
    'classSessions',
    `${bookingData.startMili}`,
    'bookings',
    bookingData.id,
  );
  const classSessionRef = await doc(
    db,
    'classSessions',
    classSessionID,
    'bookings',
    bookingData.id,
  );
  const bookingRefData = await doc(db, 'bookings', bookingData.id);

  if (bookingData.id) {
    await setDoc(classSessionOnClassRef, bookingData);
    await setDoc(classSessionRef, bookingData);

    // Update booking docs
    await updateDoc(
      doc(db, 'users', bookingData.bookerID, 'bookings', bookingData.id),
      {
        classSessionID: classSessionID,
      },
    ); // Add booking to user
    await updateDoc(bookingRefData, {
      classSessionID: classSessionID,
    });
  }
};

//@params
//id : String of userID in firebase
//amount : number of classes pos/neg
export async function addAvailableClassesToUser(
  studentID: string,
  amount: number,
) {
  const userData = await returnUserByUID(studentID);

  if (userData) {
    let availableClasses =
      userData.availableClasses !== undefined ? userData.availableClasses : 0;
    availableClasses += amount; // Add neg/pos credit
    await updateDoc(doc(db, 'users', studentID), {
      availableClasses: availableClasses,
    });
  }
}

export async function updateAvailableClassesToUser(
  studentID: string,
  amount: number,
) {
  await updateDoc(doc(db, 'users', studentID), {
    availableClasses: amount,
  });
}
