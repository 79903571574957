import { useEffect, useState } from 'react';
import { getNumbers } from '../../firebase/numbers/numbers';
import NumbersTable from '../../features/NumbersPage/table';

export const NumbersPage = () => {
  const [numberList, setNumberList] = useState<Array<any>>([]);

  async function loadNumbers() {
    const list = await getNumbers();
    setNumberList(list);
    console.log(list);
  }

  useEffect(() => {
    loadNumbers();
  }, []);

  return (
    <div>
      <NumbersTable numbers={numberList} />
    </div>
  );
};
