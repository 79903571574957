import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { cx } from 'classix';

export default function NoBorderTabs(props: {
  tabs: Array<string>;
  selectedIndex: number;
  onTabClicked: Function;
  onNextClick?: Function;
  onBackClick?: Function;
}) {
  const { tabs, selectedIndex, onTabClicked, onNextClick, onBackClick } = props;

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-immigo focus:outline-none focus:ring-blue-immigo sm:text-sm"
          defaultValue={tabs[selectedIndex]}
          onChange={(e) => {
            onTabClicked(e.target.selectedIndex);
          }}
        >
          {tabs.map((tab, index) => (
            <option key={tab + index}>{tab}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav
            className="-mb-px flex space-x-8 cursor-pointer"
            aria-label="Tabs"
          >
            {tabs.map((tab, index) => (
              <div
                key={tab + index}
                onClick={() => {
                  onTabClicked(index);
                }}
                className={cx(
                  index === selectedIndex
                    ? 'border-blue-immigo text-blue-immigo'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
                )}
                aria-current={tab ? 'page' : undefined}
              >
                {tab}
              </div>
            ))}
            <div className="flex items-center text-gray-500 w-full justify-end">
              {onBackClick ? (
                <div
                  className="flex items-center mr-1 hover:text-blue-immigo"
                  onClick={() => onBackClick()}
                >
                  <ArrowLeftIcon className="w-5 h-5 mr-2" />
                  <span>Back</span>
                </div>
              ) : null}
              {onNextClick ? (
                <div
                  className="flex items-center ml-2 hover:text-blue-immigo"
                  onClick={() => onNextClick()}
                >
                  <span>Next</span>
                  <ArrowRightIcon className="w-5 h-5 ml-1" />
                </div>
              ) : null}
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
