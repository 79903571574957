import './index.css';
import { App } from './App';
import { Provider } from 'react-redux';
import store from './store/index';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import * as Sentry from '@sentry/react';

if (process.env.REACT_APP_ENV !== 'DEV') {
  Sentry.init({
    dsn: 'https://0fac741b49e340c598cc81abd502ff0c@o4505211909570560.ingest.sentry.io/4505211911274496',
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        networkDetailAllowUrls: [
          'https://firestore.googleapis.com',
          'https://immigo-api.herokuapp.com',
        ],
        networkRequestHeaders: ['X-Custom-Header'],
        networkResponseHeaders: ['X-Custom-Header'],
        networkCaptureBodies: true,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
);
