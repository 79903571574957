import React, { useState } from 'react';

import './textInput.css';

const TextInput = (props: any) => {
  const [value, setValue] = useState(props.initialValue ?? '');

  return (
    <div
      className="text-input-container"
      style={{
        width: props.width,
        fontFamily: props.customFont,
      }}
    >
      <div className="block text-sm font-medium leading-6 text-gray-900">
        {props.title}
        {props.required ? <span style={{ color: 'red' }}> *</span> : null}
      </div>
      <div className="text-input-description">{props.description}</div>
      <input
        value={props.value !== undefined ? props.value : ''}
        type={props.type}
        onChange={(e) => {
          props.onChange?.(e.target.value);
          setValue(e.target.value);
        }}
        onBlur={(e) => {
          if (props.onBlur) props.onBlur(e.target.value);
        }}
        className={
          'px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-immigo sm:text-sm sm:leading-6'
        }
        style={{
          width: props.boxWidth,
        }}
        placeholder={props.placeholder}
      />
      {props.error ? (
        <div className="text-input-error">{props.errorMessage}</div>
      ) : null}
    </div>
  );
};

export default TextInput;
