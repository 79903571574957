import cx from 'classix';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Input } from '../../../components/forms/input';
import { Radio } from '../../../components/forms/radio';
import { Select } from '../../../components/forms/select';

import {
  createApplicationDocument,
  returnAppByUID,
  updateApplicationDocument,
  returnUserByUID,
} from '../../../firebase/configuration';

import { PrimaryButton } from '../../../components/buttons/primary';
import { analyticsReferralCodeVisited } from '../../../analytics/index';
import { LoadingOverlay } from '../../../components/loading/index';
import { useForm } from '../../../components/forms/hooks';

import {
  ApplicationQuestionData,
  cefrLevels,
  livingAbroadDurationOptions,
  planOnMovingOptions,
  whenToMoveOptions,
  preparingForExamOptions,
  validationSchema,
} from './data';
import {
  yesNoOptions,
  nationalitiesOptions,
  arrayAsSelectOptions,
  countryCodeToName,
  countryNameToCode,
} from '../../../util/forms';

const ApplicationQuestionForm = ({
  userData,
  onSetUserData,
  studentID,
  courseID,
  onNextClick,
  distributorType,
  referralCode,
}: any) => {
  const [lastApplicationData, setLastApplicationData] =
    useState<any>(undefined);
  const { submitted: alreadySubmitted = false } = lastApplicationData || {};

  const formik = useForm<ApplicationQuestionData>({
    initialValues: {
      cefrLevel: lastApplicationData?.cefrLevel,
      nationality: countryCodeToName(lastApplicationData?.nationality),
      based: countryCodeToName(lastApplicationData?.basedCountry),
      whyLearnEnglish: lastApplicationData?.whyLearnEnglish,
      personalRestrictions: lastApplicationData?.restrictions,
      preparingForExam: lastApplicationData?.preparingForExam,
      livingAbroad: lastApplicationData?.livingAbroad,
      howLongLivedAbroad: lastApplicationData?.howLongLivedAbroad,
      planOnMoving: lastApplicationData?.planOnLivingAbroad,
      whenToMove: lastApplicationData?.whenPlannedLivingAbroad,
      referralCode: referralCode,
    },
    validationSchema,
    onSubmit: async (values) => {
      const ownReferralCode =
        values.referralCode &&
        values.referralCode.toUpperCase() ===
          userData?.referralCode?.toUpperCase();
      if (ownReferralCode) {
        formik.setFieldError(
          'referralCode',
          'Unfortunately, you cannot use your own referral code',
        );
      } else {
        await onSubmit(values);
      }
    },
    enableReinitialize: true,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    if (userData && userData.applications) {
      const userApplications = userData.applications;
      const currentAppID: string = userApplications[courseID];
      // Check if the person had already filled out the application
      if (currentAppID) {
        const getLastApplicationData = async () => {
          return returnAppByUID(currentAppID);
        };
        getLastApplicationData().then(setLastApplicationData).catch();
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [courseID, userData]);

  async function onSubmit(values: ApplicationQuestionData) {
    const {
      referralCode: typedReferralCode,
      cefrLevel,
      nationality,
      based,
      whyLearnEnglish,
      personalRestrictions,
      livingAbroad,
      howLongLivedAbroad,
      planOnMoving,
      whenToMove,
      countryToMoveTo,
      preparingForExam,
    } = values;

    if (userData && studentID && courseID) {
      const data: any = {};
      const alreadyApplied =
        userData && userData.applications && userData.applications[courseID];
      const usedReferralCode: string | undefined = Boolean(referralCode)
        ? referralCode
        : typedReferralCode;

      if (cefrLevel !== undefined) data.cefrLevel = cefrLevel;
      if (nationality !== undefined)
        data.nationality = countryNameToCode(nationality);
      if (based !== undefined) data.basedCountry = countryNameToCode(based);
      if (whyLearnEnglish && whyLearnEnglish.length > 0)
        data.whyLearnEnglish = whyLearnEnglish;
      if (usedReferralCode) data.referralCode = usedReferralCode?.toUpperCase();
      if (typedReferralCode) {
        analyticsReferralCodeVisited(studentID, referralCode, courseID);
        data.referralCode = typedReferralCode?.toUpperCase();
      }
      if (personalRestrictions !== undefined)
        data.restrictions = personalRestrictions;

      // new data
      if (livingAbroad !== undefined) data.livingAbroad = livingAbroad;
      if (howLongLivedAbroad !== undefined)
        data.howLongLivedAbroad = howLongLivedAbroad;
      if (planOnMoving !== undefined) data.planOnLivingAbroad = planOnMoving;
      if (whenToMove !== undefined) data.whenPlannedLivingAbroad = whenToMove;
      if (countryToMoveTo !== undefined)
        data.whichCountryPlanToMove = countryNameToCode(countryToMoveTo);
      if (preparingForExam !== undefined)
        data.preparingForExam = preparingForExam;
      data.name = userData.name;
      data.email = userData.email;
      data.studentID = studentID;
      data.courseID = courseID;
      data.submitted = true;
      data.createdAt = Date.now();

      console.log(data);

      if (!alreadyApplied) {
        // Create a new application.
        await createApplicationDocument(
          studentID,
          courseID,
          false,
          data,
          distributorType,
          usedReferralCode,
        );
      } else {
        await updateApplicationDocument(
          studentID,
          userData.applications[courseID],
          data,
        );
      }

      const newUserData = await returnUserByUID(studentID);

      // update the userData after creating a new application
      if (onSetUserData) onSetUserData(newUserData);

      if (typedReferralCode) {
        history.push(
          '/register/' + courseID + '/referral/' + typedReferralCode,
        );
      }

      // Move on to the next section
      onNextClick();
    }
  }

  return (
    <div className="flex flex-col mb-10 max-w-prose mx-auto justify-center py-10">
      <LoadingOverlay enabled={loading || formik.isSubmitting} />
      {alreadySubmitted ? (
        <div className="flex flex-col items-center">
          <h2 className="text-3xl md:text-4xl mb-4 text-center md:w-screen font-semibold">
            We already have your application!
          </h2>
          <p className="text-center mb-10">
            We will be in touch closer to the starting date of the course.
            Meanwhile head over to your course portal to complete your profile.
          </p>
          <PrimaryButton
            onClick={() => {
              history.push('/additional-info');
            }}
            text="Complete my profile"
          />
        </div>
      ) : (
        <div className={'flex flex-col gap-4'}>
          <h2 className="text-3xl md:text-4xl text-center font-semibold">
            Finish your application
          </h2>
          <p className="text-center mb-8">
            Take 3 minutes to fill out this application to be enrolled in your
            course
          </p>
          <form className={'flex flex-col gap-8 form'}>
            <Radio
              label={'Disclaimer'}
              labelClassName={'text-red-warning'}
              description={
                'We only let in serious learners. Towards the registration deadline, we will refund your payment for the course if you are rejected after reviewing your application. Please select "Yes" if you wish to proceed.'
              }
              {...formik.getRadioProps(
                'disclaimerAgree',
                (value) => (value ? 'yes' : 'no'),
                (newValue) => {
                  formik.setFieldValue('disclaimerAgree', newValue === 'yes');
                },
              )}
              options={yesNoOptions}
            />
            <Select
              label={'What is your CEFR level?'}
              {...formik.getSelectProps('cefrLevel', undefined, true)}
              options={arrayAsSelectOptions(cefrLevels)}
              onChange={(value) => {
                formik.setFieldValue('cefrLevel', value.toUpperCase());
              }}
            />
            <Select
              label={'Where are you from? (What is your nationality?)'}
              {...formik.getSelectProps('nationality', undefined, true)}
              options={nationalitiesOptions}
            />
            <Radio
              label={'Are you living abroad?'}
              {...formik.getRadioProps(
                'livingAbroad',
                (value) => (value ? 'yes' : 'no'),
                (newValue) => {
                  formik.setFieldValue('livingAbroad', newValue === 'yes');
                },
              )}
              options={yesNoOptions}
            />
            {formik.values.livingAbroad === true && (
              <>
                <Select
                  label={'Where are you currently living?'}
                  {...formik.getSelectProps('based', undefined, true)}
                  options={nationalitiesOptions}
                />
                <Select
                  label={'How long have you lived in this foreign country?'}
                  {...formik.getSelectProps('howLongLivedAbroad')}
                  options={arrayAsSelectOptions(livingAbroadDurationOptions)}
                />
              </>
            )}
            {formik.values.livingAbroad === false && (
              <>
                <Select
                  label={'Do you plan on moving abroad in the future?'}
                  {...formik.getSelectProps('planOnMoving')}
                  options={arrayAsSelectOptions(planOnMovingOptions)}
                />
                {formik.values.planOnMoving &&
                  formik.values.planOnMoving !== planOnMovingOptions[0] && (
                    <>
                      <Select
                        label={'When do you plan to move?'}
                        {...formik.getSelectProps('whenToMove')}
                        options={arrayAsSelectOptions(whenToMoveOptions)}
                      />
                      <Select
                        label={
                          'Which country are you looking to move to? (If no concrete plan, which country do you want to move to?)'
                        }
                        {...formik.getSelectProps(
                          'countryToMoveTo',
                          undefined,
                          true,
                        )}
                        options={nationalitiesOptions}
                      />
                    </>
                  )}
              </>
            )}
            {distributorType !== 'returnee' ? (
              <Input
                label={'Why do you want to learn English? (Min. 50 words)'}
                {...formik.getFieldProps('whyLearnEnglish')}
                asTextArea
              />
            ) : null}
            <Select
              label={'Are you preparing for any English exams?'}
              {...formik.getSelectProps('preparingForExam')}
              options={arrayAsSelectOptions(preparingForExamOptions)}
            />
            {/* {distributorType !== 'promo' ? (
              <Input
                label={'Referral Code'}
                description={
                  "Did someone refer you? Put down their referral code to let us know."
                }
                {...formik.getFieldProps('referralCode', true)}
              />
            ) : null} */}

            <Input
              label={
                'Are there any personal restrictions we should keep in mind of?'
              }
              description={
                "You will be meeting weekly with 2-3 other students over Zoom, where we strongly encourage you to turn your camera on and engage in the discussion. If there are anything that's stopping you from participating (eg. Personal or religious reasons), let us know here. We will do our best to accommodate."
              }
              {...formik.getFieldProps('personalRestrictions')}
              asTextArea
            />
            <div className={'flex flex-col gap-4'}>
              <button
                type={'button'}
                disabled={formik.isSubmitting || formik.isValidating}
                className={cx(
                  'w-full bg-blue-immigo px-4 py-3',
                  'text-center text-white rounded font-semibold',
                  'hover:shadow hover:-translate-y-0.5 transform transition-all ease-in-out',
                  'disabled:opacity-80 disabled:cursor-not-allowed disabled:transform-none',
                )}
                onClick={() => {
                  formik.submitForm();
                }}
              >
                Complete your application
              </button>
              {Boolean(Object.keys(formik.errors).length) && (
                <p
                  className={cx(
                    'text-red-warning border border-red-warning border-opacity-30 border-dashed',
                    'px-2 py-1 rounded-sm mt-1 self-start font-semibold',
                  )}
                >
                  <span role={'img'} aria-label={'emergency light emoji'}>
                    🚨
                  </span>{' '}
                  Please scroll up to make sure all the information is filled
                  out correctly!
                </p>
              )}
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ApplicationQuestionForm;
