import { getApp, getApps, initializeApp } from 'firebase/app';
import {
  getFirestore,
  connectFirestoreEmulator,
  initializeFirestore,
} from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getStorage, connectStorageEmulator } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyCWlznMFiq4mfHkfF_fhXuPbxHStyS1jQw',
  authDomain: 'immigo-application-8ba25.firebaseapp.com',
  projectId: 'immigo-application-8ba25',
  storageBucket: 'immigo-application-8ba25.appspot.com',
  messagingSenderId: '338225679970',
  appId: '1:338225679970:web:4739bfa613f1b7b6ab8b3b',
  measurementId: 'G-KR6GND3DQH',
};

const getFirebaseApp = () => {
  if (getApps().length === 0) {
    return initializeApp(firebaseConfig);
  } else {
    return getApp();
  }
};

const app = getFirebaseApp();
const firestore = initializeFirestore(app, {
  experimentalForceLongPolling: true,
  experimentalLongPollingOptions: {
    timeoutSeconds: 20,
  },
});
const storage = getStorage(app);
const auth = getAuth(app);
const functions = getFunctions(app);

if (
  process.env.REACT_APP_EMULATOR &&
  window.location.hostname === 'localhost'
) {
  connectAuthEmulator(auth, 'http://localhost:9099', {
    disableWarnings: true,
  });
  connectFirestoreEmulator(firestore, 'localhost', 8081);
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectStorageEmulator(storage, 'localhost', 9199);
}

export const initializeFirebase = () => {
  return {
    firestore,
    auth,
    storage,
    functions,
  };
};
