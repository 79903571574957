export const indexToReasonMap = [
  'level-too-low-material',
  'level-too-high-material',
  'level-too-low-students',
  'level-too-high-students',
  'found-other',
  'no-need-english',
  'class-material-bad',
  'coach-bad',
  'time-bad',
];
