import mixpanel from '../../analytics/mixpanel';

type Events =
  | 'Visited the Immigo AI correction page after class ended'
  | 'Visited Immigo AI correction page';

type NextClassContainerData = {};

export const trackFeedBackListenerEvents = (
  event: Events,
  data?: NextClassContainerData,
) => {
  mixpanel.track(event, data);
};
