import { useSelector } from 'react-redux';
import { PreviewDescriptionContainer } from '../../components/grid-list/previewDescriptionContainer.tsx/previewDescriptionContainer';
import { downloadPDF, generateCompletionCertificate } from '../../util/pdf';
import { UserData } from '../../types/user';
import { useEffect, useState } from 'react';
import { generateEnrollmentCertificate } from '../../api/certificates';
import { getUserBookingsAttended } from '../../firebase/subscription/bookings/bookings';
import moment from 'moment-timezone';
import { BookingData } from '../../firebase/subscription/subscription';
import { trackCertificateData } from '../../features/Certificates/analytics';

export const CertificatesPage = (props: {}) => {
  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );

  const [bookings, setBookings] = useState<Array<BookingData>>();
  const [firstClassMili, setFirstClassMili] = useState<number>();

  async function updateUserData(id: string) {
    const _bookings: Array<BookingData> = await getUserBookingsAttended(id);
    const startMili = _bookings?.sort((a, b) => a.startMili - b.startMili)?.[0]
      ?.startMili;

    setBookings(_bookings);
    setFirstClassMili(startMili);
  }

  async function getCertificateBase64(name: string, startMili: number) {
    const cert = await generateEnrollmentCertificate(name, startMili);
    const title = `${name} enrollment certificate`;
    downloadPDF(cert, title);
    console.log(cert);
  }

  useEffect(() => {
    if (userData) {
      updateUserData(userData?.id);
    }
  }, [userData]);

  useEffect(() => {
    trackCertificateData('Visited certificate page');
  }, []);

  return (
    <ul
      role="list"
      className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
    >
      {firstClassMili && userData ? (
        <PreviewDescriptionContainer
          id="s"
          imageUrl="https://t3.ftcdn.net/jpg/01/21/64/88/360_F_121648819_ZQ0tZ6tjLzxim1SG7CQ86raBw4sglCzB.jpg"
          title="Enrollment Certificate"
          labels={[
            {
              label: 'Awarded',
              entry: moment(firstClassMili).format('MMMM DD YYYY'),
            },
          ]}
          bottomButtonText="Download"
          onClick={async () => {
            trackCertificateData('Clicked certificate container', {
              certificateName: 'Enrollment Certificate',
            });
            await getCertificateBase64(userData?.name, firstClassMili);
          }}
        />
      ) : null}
      {bookings && bookings.length === 0 ? (
        <div className="text-gray-500">
          Attend your first class to earn your first certificate!
        </div>
      ) : null}
    </ul>
  );
};
