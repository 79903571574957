type CircularAddButtonProps = {
  onClick: Function;
};

export function CircularAddButton({ onClick }: CircularAddButtonProps) {
  return (
    <button
      type="button"
      onClick={() => {
        onClick();
      }}
      className="inline-flex items-center rounded-full border border-transparent bg-blue-immigo p-1 text-white shadow-sm hover:bg-immigo-darker focus:outline-none focus:ring-2 focus:ring-blue-immigo focus:ring-offset-2"
    >
      <svg
        className="h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
      </svg>
    </button>
  );
}
