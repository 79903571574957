import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import { initializeFirebase } from '../../configValues';
import { updateClassSession } from '../classSessions/classSessions';
import { getClass } from '../subscription';
import { convertMiliTimeToChosenWeekTime } from '../../../util/dates';
const { firestore: db } = initializeFirebase();

const weekMili = 1000 * 7 * 24 * 60 * 60;

export const getAllTopics = async () => {
  const topicsReference = await collection(db, 'topics');
  const topics = await getDocs(topicsReference);
  const topicsData: any[] = [];
  topics.forEach((c: any) => {
    const data = c.data();
    data.id = c.id;
    topicsData.push(data);
  });
  return topicsData;
};

/* Add topic & slide & recordings */

export const addTopicWeeklyTopicTitle = async (
  topicID: string,
  title: string,
) => {
  const topicRef = await doc(db, 'topics', topicID);
  const topicDoc = await getDoc(topicRef);
  const topic = topicDoc.data();

  const toBeUpdatedWeekIndex = topic?.titles?.length ?? 0;

  const update = await updateDoc(topicRef, {
    titles: arrayUnion(title),
  });

  console.log(topic?.classes);

  await Promise.all(
    topic?.classes?.map(async (id: string) => {
      const c = await getClass(id);
      const sessionStartMili = convertMiliTimeToChosenWeekTime(
        c?.startMili,
        c?.startMili + toBeUpdatedWeekIndex * weekMili,
      );
      const session = await getDoc(
        doc(db, 'classes', id, 'classSessions', `${sessionStartMili}`),
      );
      const sessionData = session.data();
      console.log(sessionStartMili);
      console.log(toBeUpdatedWeekIndex);
      console.log(sessionData);

      if (!sessionData) {
        console.log(`${id} ${sessionStartMili}`);
      } else {
        await updateClassSession(sessionData?.id, {
          title: title,
        }); // Update all the session ids
      }
    }),
  );

  return update;
};

export const addTopicWeeklySlide = async (
  topicID: string,
  slideURL: string,
) => {
  const topicRef = await doc(db, 'topics', topicID);
  const topicDoc = await getDoc(topicRef);
  const topic = topicDoc.data();

  const toBeUpdatedWeekIndex = topic?.slides?.length ?? 0;

  const update = await updateDoc(topicRef, {
    slides: arrayUnion(slideURL),
  });

  console.log(topic?.classes);

  await Promise.all(
    topic?.classes?.map(async (id: string) => {
      const c = await getClass(id);
      const sessionStartMili = convertMiliTimeToChosenWeekTime(
        c?.startMili,
        c?.startMili + toBeUpdatedWeekIndex * weekMili,
      );
      const session = await getDoc(
        doc(db, 'classes', id, 'classSessions', `${sessionStartMili}`),
      );
      const sessionData = session.data();
      console.log(sessionStartMili);
      console.log(toBeUpdatedWeekIndex);
      console.log(sessionData);

      if (!sessionData) {
        console.log(`${id} ${sessionStartMili}`);
      } else {
        await updateClassSession(sessionData?.id, {
          slide: slideURL,
        }); // Update all the session ids
      }
    }),
  );

  return update;
};

/* Edit topic & slide & recordings */

export const editTopicWeeklyTopicSlide = async (
  topicID: string,
  newSlideLink: string,
  toBeUpdatedWeekIndex: number,
) => {
  const topicRef = await doc(db, 'topics', topicID);
  const topicDoc = await getDoc(topicRef);
  const topic = topicDoc.data();

  if (topic && !topic.slides) topic.slides = {};

  if (topic?.slides) {
    console.log(topic.slides);
    topic.slides[toBeUpdatedWeekIndex] = newSlideLink; // Update the title under topic
    console.log(topic.slides);
    await updateDoc(topicRef, {
      slides: topic.slides,
    });
  }

  const updates = await Promise.all(
    topic?.classes?.map(async (id: string) => {
      const c = await getClass(id);
      const sessionStartMili = convertMiliTimeToChosenWeekTime(
        c?.startMili,
        c?.startMili + toBeUpdatedWeekIndex * weekMili,
      );
      const session = await getDoc(
        doc(db, 'classes', id, 'classSessions', `${sessionStartMili}`),
      );
      const sessionData = session.data();

      if (!sessionData?.id) {
        console.log(`${id} ${sessionStartMili}`);
      } else {
        const update = await updateClassSession(sessionData?.id, {
          cleanSlide: newSlideLink,
        }); // Update all the session ids
        return update;
      }
    }),
  );

  return updates;
};

export const editTopicWeeklyTheme = async (
  topicID: string,
  theme: string,
  toBeUpdatedWeekIndex: number,
) => {
  const topicRef = await doc(db, 'topics', topicID);
  const topicDoc = await getDoc(topicRef);
  const topic = topicDoc.data();

  if (topic && !topic.themes) topic.themes = {};

  if (topic?.themes) {
    topic.themes[toBeUpdatedWeekIndex] = theme; // Update the title under topic
    console.log(topic.themes);
    await updateDoc(topicRef, {
      themes: topic.themes,
    });
  }

  const updates = await Promise.all(
    topic?.classes?.map(async (id: string) => {
      const c = await getClass(id);
      const sessionStartMili = convertMiliTimeToChosenWeekTime(
        c?.startMili,
        c?.startMili + toBeUpdatedWeekIndex * weekMili,
      );
      const session = await getDoc(
        doc(db, 'classes', id, 'classSessions', `${sessionStartMili}`),
      );
      const sessionData = session.data();

      if (!sessionData?.id) {
        console.log(`${id} ${sessionStartMili}`);
      } else {
        const update = await updateClassSession(sessionData?.id, {
          theme: theme,
        }); // Update all the session ids
        return update;
      }
    }),
  );

  return updates;
};

export const editTopicWeeklyTopicTitle = async (
  topicID: string,
  newTitle: string,
  toBeUpdatedWeekIndex: number,
) => {
  const topicRef = await doc(db, 'topics', topicID);
  const topicDoc = await getDoc(topicRef);
  const topic = topicDoc.data();

  if (topic?.titles) {
    topic.titles[toBeUpdatedWeekIndex] = newTitle; // Update the title under topic
    await updateDoc(topicRef, {
      titles: topic.titles,
    });
  }

  const updates = await Promise.all(
    topic?.classes?.map(async (id: string) => {
      const c = await getClass(id);
      const sessionStartMili = convertMiliTimeToChosenWeekTime(
        c?.startMili,
        c?.startMili + toBeUpdatedWeekIndex * weekMili,
      );
      const session = await getDoc(
        doc(db, 'classes', id, 'classSessions', `${sessionStartMili}`),
      );
      const sessionData = session.data();

      if (!sessionData?.id) {
        console.log(`${id} ${sessionStartMili}`);
      } else {
        const update = await updateClassSession(sessionData?.id, {
          title: newTitle,
        }); // Update all the session ids
        return update;
      }
    }),
  );

  return updates;
};

export const editTopicWeeklyPhraseList = async (
  topicID: string,
  phraseList: Array<string>,
  toBeUpdatedWeekIndex: number,
) => {
  try {
    const topicRef = await doc(db, 'topics', topicID);
    const topicDoc = await getDoc(topicRef);
    const topic = topicDoc.data();

    if (!topic) return;

    if (!topic?.phrases) topic.phrases = {};
    topic.phrases[toBeUpdatedWeekIndex] = phraseList; // Update the title under topic
    await updateDoc(topicRef, {
      phrases: topic.phrases,
    });

    const updates = await Promise.all(
      topic?.classes?.map(async (id: string) => {
        const c = await getClass(id);
        const sessionStartMili = convertMiliTimeToChosenWeekTime(
          c?.startMili,
          c?.startMili + toBeUpdatedWeekIndex * weekMili,
        );
        const session = await getDoc(
          doc(db, 'classes', id, 'classSessions', `${sessionStartMili}`),
        );
        const sessionData = session.data();

        if (!sessionData?.id) {
          console.log(`${id} ${sessionStartMili}`);
        } else {
          const update = await updateClassSession(sessionData?.id, {
            phrases: phraseList,
          }); // Update all the session ids
          return update;
        }
      }),
    );

    return updates;
  } catch (err) {
    console.log(err);
  }
};

export const editTopicWeeklyQuestionList = async (
  topicID: string,
  questionsList: Array<string>,
  toBeUpdatedWeekIndex: number,
) => {
  const topicRef = await doc(db, 'topics', topicID);
  const topicDoc = await getDoc(topicRef);
  const topic = topicDoc.data();

  if (!topic) return;

  if (!topic?.questions) topic.questions = {};
  topic.questions[toBeUpdatedWeekIndex] = questionsList; // Update the title under topic
  await updateDoc(topicRef, {
    questions: topic.questions,
  });

  const updates = await Promise.all(
    topic?.classes?.map(async (id: string) => {
      const c = await getClass(id);
      const sessionStartMili = convertMiliTimeToChosenWeekTime(
        c?.startMili,
        c?.startMili + toBeUpdatedWeekIndex * weekMili,
      );
      const session = await getDoc(
        doc(db, 'classes', id, 'classSessions', `${sessionStartMili}`),
      );
      const sessionData = session.data();

      if (!sessionData?.id) {
        console.log(`${id} ${sessionStartMili}`);
      } else {
        const update = await updateClassSession(sessionData?.id, {
          questions: questionsList,
        }); // Update all the session ids
        return update;
      }
    }),
  );

  return updates;
};
