import { Fragment, useEffect, useRef, useState } from 'react';
import { CalendarIcon } from '@heroicons/react/20/solid';
import { BookingData } from '../../firebase/subscription/subscription';
import { UserData } from '../../types/user';
import { checkIsAdmin } from '../../util/admin';
import { useSelector } from 'react-redux';
import NoBorderTabs from '../../components/tabs/noBorderTabs/NoBorderTabs';
import { useCollection } from 'react-firebase-hooks/firestore';
import { collection } from 'firebase/firestore';
import { initializeFirebase } from '../../firebase/configValues';
import { LargerClassContainer } from '../../features/MyClasses/upcomingClassContainer/largerClassContainer';
import { trackMyClassesAnalytics } from '../../features/MyClasses/analytics';
import { cancelUserBooking } from '../../firebase/subscription/bookings/bookings';
import { CancelBookingConfirmationModal } from '../../components/modals/cancelBookingModals/cancelBookingConfirmationModal/cancelBookingConfirmationModal';
import { CancelBookingConfirmedModal } from '../../components/modals/cancelBookingModals/cancelBookingConfirmedModal/cancelBookingConfirmedModal';
const { firestore } = initializeFirebase();

const useUserBookings = (
  userID: string,
  showOnlyPast: boolean,
  showOnlyUpComing: boolean,
) => {
  function getFourWeeksFutureSchedule(b: BookingData): Array<BookingData> {
    let weekInMili = 1000 * 60 * 60 * 24 * 7;
    return [
      { ...b, startMili: b.startMili + weekInMili },
      { ...b, startMili: b.startMili + weekInMili * 2 },
      { ...b, startMili: b.startMili + weekInMili * 3 },
      { ...b, startMili: b.startMili + weekInMili * 4 },
    ];
  }

  const [data, loading, error] = useCollection(
    collection(firestore, `users/${userID}/bookings`),
  );

  let newBookings: Array<BookingData> = [];
  data?.forEach((d: any) => {
    const docData: BookingData = d.data();
    docData.id = d.id;
    newBookings.push(docData);
  });

  newBookings.forEach((b: BookingData) => {
    if (b.recurring)
      newBookings = newBookings.concat(getFourWeeksFutureSchedule(b));
  });
  newBookings = newBookings.filter((b: BookingData) => {
    return (
      ((b.startMili + b.durationMili > new Date().valueOf() && !showOnlyPast) ||
        (b.startMili + b.durationMili <= new Date().valueOf() &&
          !showOnlyUpComing)) &&
      !b.canceled &&
      !b.skipTime?.includes(b.startMili)
    );
  });
  if (showOnlyUpComing)
    newBookings = newBookings.sort((a: BookingData, b: BookingData) => {
      return a.startMili - b.startMili; // Sort ascending if upcoming
    });
  if (showOnlyPast)
    newBookings = newBookings.sort((a: BookingData, b: BookingData) => {
      return b.startMili - a.startMili; // Sort descending if showing past
    });

  return {
    bookings: newBookings,
    bookingsLoading: loading,
    bookingsError: error,
  };
};

export default function MyClasses() {
  const [selectedPage, setSelectedPage] = useState(0);
  const [cancelBooking, setCancelBooking] = useState<undefined | BookingData>();
  const [openConfirmCancelModal, setOpenConfirmCancelModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );
  const { bookings, bookingsLoading, bookingsError } = useUserBookings(
    userData?.id,
    selectedPage === 1,
    selectedPage === 0,
  );

  useEffect(() => {
    trackMyClassesAnalytics('Visited my classes page');
  }, []);

  return (
    <div>
      <CancelBookingConfirmedModal
        userID={userData?.id}
        isOpen={openConfirmCancelModal}
        onConfirm={() => {
          setOpenConfirmCancelModal(false);
          setCancelBooking(undefined);
        }}
        onClose={() => {
          setOpenConfirmCancelModal(false);
          setCancelBooking(undefined);
        }}
      />
      <CancelBookingConfirmationModal
        cancelBooking={cancelBooking}
        onNotTodayClick={() => {
          trackMyClassesAnalytics(
            'Clicked not today on booking cancelation modal on My Classes page',
            {
              userID: userData?.id,
              bookingTitle: cancelBooking?.title,
              bookingStartMili: cancelBooking?.startMili,
            },
          );
          setCancelBooking(undefined);
        }}
        onCloseModal={() => {
          trackMyClassesAnalytics(
            'Closed booking cancelation modal on My Classes page',
            {
              userID: userData?.id,
              bookingTitle: cancelBooking?.title,
              bookingStartMili: cancelBooking?.startMili,
            },
          );
          setCancelBooking(undefined);
        }}
        onCancelCompleted={() => {
          trackMyClassesAnalytics('Canceled booking in my classes page', {
            userID: userData?.id,
            bookingTitle: cancelBooking?.title,
            bookingStartMili: cancelBooking?.startMili,
          });
          setSubmitting(false);
        }}
        onCancelClick={async () => {
          setSubmitting(true);
          if (cancelBooking?.id) {
            trackMyClassesAnalytics(
              'Clicked cancel on booking cancel confirmation modal on My Classes page',
              {
                userID: userData?.id,
                bookingTitle: cancelBooking.title,
                bookingStartMili: cancelBooking.startMili,
              },
            );
          }
          setOpenConfirmCancelModal(true);
        }}
      />
      <NoBorderTabs
        tabs={['Upcoming Classes', 'Past Classes']}
        selectedIndex={selectedPage}
        onTabClicked={(index: number) => {
          if (index === 0)
            trackMyClassesAnalytics(
              'Clicked upcoming classes on My Classes page',
            );
          else if (index === 1)
            trackMyClassesAnalytics('Clicked past classes on My Classes page');
          setSelectedPage(index);
        }}
      />
      <div className="w-full">
        <ol>
          {bookings?.map((c: BookingData) => (
            <li
              key={c.id}
              className="relative flex space-x-6 xl:static bg-white my-8 px-4 rounded-md"
            >
              <LargerClassContainer
                {...c}
                id={c.id}
                startMili={c.startMili}
                title={c.title}
                recurring={c.recurring}
                classSessionID={c.classSessionID}
                durationMili={c.durationMili}
                onTrashClick={async () => {
                  if (c.id) {
                    setCancelBooking(c);
                  }
                }}
                onClassLinkClick={() => {}}
              />
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
}
