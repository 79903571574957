import ContentLoader from 'react-content-loader';

export const BookingCalendarModalLoader = () => {
  return (
    <ContentLoader viewBox="0 0 420 310">
      <rect x="10" y="10" rx="5" ry="5" width="400" height="40" />
      <rect x="10" y="60" rx="5" ry="5" width="400" height="40" />
      <rect x="10" y="110" rx="5" ry="5" width="400" height="40" />
      <rect x="10" y="160" rx="5" ry="5" width="400" height="40" />
      <rect x="10" y="210" rx="5" ry="5" width="400" height="40" />
      <rect x="10" y="260" rx="5" ry="5" width="400" height="40" />
    </ContentLoader>
  );
};
