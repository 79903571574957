import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { initializeFirebase } from '../firebase/configValues';

const { auth } = initializeFirebase();

export function useRedirectIfNotLoggedIn() {
  const history = useHistory();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) history.push('/login');
    });
  }, []);
}
