import { RadioGroup } from '@headlessui/react';
import { cx } from 'classix';
import { useEffect, useState } from 'react';
import {
  classesSubscriptionTiers,
  classesSubscriptionTiersYearly,
  couponMap,
} from '../../../config/stripe';
import { trackBookingAnalytics } from '../../../features/Booking/analytics';
import { PricingOptionBox } from '../../../features/Booking/pricingOptionBox';
import { UserData } from '../../../types/user';
import { frequenciesPricing, FrequencyPricng } from '../PricingPage';
import { stringsPricingPage } from '../../../util/localization';
import DropDownFancy from '../../../components/forms/dropDownFancy/dropDownFancy';
import { supportedCurrencies } from '../../../util/currency';

const animationDuration = 300;

export function ChoicePage(props: {
  page: number;
  setPage: Function;
  userData: UserData;
  setSubOption: Function;
  setFrequency: Function;
  frequency: FrequencyPricng;
  currency?: string;
  onCurrencyChange?: Function;
  ready?: boolean;
}) {
  const search = new URLSearchParams(window.location.search);
  const viaToken = search.get('via');

  const [clicked, setClicked] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const {
    page,
    setPage,
    userData,
    setSubOption,
    setFrequency,
    frequency,
    currency,
    onCurrencyChange,
    ready,
  } = props;

  const currencyOptions = (
    viaToken && couponMap[viaToken]?.currency
      ? couponMap[viaToken]?.currency
      : supportedCurrencies
  ).map((c: string) => {
    return { id: c, name: c.toLocaleUpperCase() };
  });

  const currencyIndex =
    currencyOptions.findIndex((obj: any) => obj.id === currency) ?? 0;

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div
      className={cx(
        `mx-auto max-w-7xl md:px-6 xl:px-8 transition-all ease-in duration-${animationDuration} translate-x-0`,
        !clicked && loaded ? 'opacity-100' : 'opacity-0 -translate-x-20',
      )}
    >
      <div className="mx-auto max-w-4xl text-center">
        <p className="mt-2 md:text-3xl text-xl font-bold tracking-tight text-gray-900">
          {stringsPricingPage.chooseYourLearningPage}
        </p>
      </div>
      <p className="mx-auto md:mt-6 max-w-2xl text-center md:text-lg text-md leading-8 text-gray-600">
        {stringsPricingPage.changePlanAnyTime}
      </p>
      <div className="mt-8 flex justify-between">
        <div></div>
        <div className="ml-12">
          <RadioGroup
            value={frequency}
            onChange={(val: FrequencyPricng) => {
              setFrequency(val);
            }}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">
              Payment frequency
            </RadioGroup.Label>
            {frequenciesPricing.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  cx(
                    checked ? 'bg-blue-immigo text-white' : 'text-gray-500',
                    'cursor-pointer rounded-full md:px-4 px-2.5 md:py-2 py-1 md:text-xl',
                  )
                }
                onClick={() => {
                  trackBookingAnalytics(
                    option.label === 'annually'
                      ? 'Switched to yearly button on learning plan'
                      : 'Switched to yearly button on learning plan',
                  );
                }}
              >
                <span>
                  {option.label === 'monthly'
                    ? stringsPricingPage.monthly
                    : stringsPricingPage.annually}
                </span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="min-w-[100px]">
          <DropDownFancy
            title=""
            options={currencyOptions}
            defaultIndex={currencyIndex}
            onOptionSelected={(currencyObj: any) => {
              onCurrencyChange?.(currencyObj.id);
            }}
          />
        </div>
      </div>
      <div className="isolate mx-auto md:mt-10 mt-4 grid max-w-md grid-cols-1 md:gap-8 gap-4 xl:mx-0 xl:max-w-none xl:grid-cols-3">
        {(frequency.value === 'monthly'
          ? classesSubscriptionTiers
          : classesSubscriptionTiersYearly
        ).map((tier, index) => {
          const price_ = tier.price[currency ?? 'usd']
            ? tier.price[currency ?? 'usd']
            : tier.price['usd'];
          const discount_ = tier.discount?.[currency ?? 'usd']
            ? tier.discount?.[currency ?? 'usd']
            : tier.discount?.['usd'];
          const coupon =
            couponMap[viaToken ?? '']?.[tier.id]?.amount?.[currency ?? 'usd'];

          const totalPrice = price_ - (coupon ?? 0);
          const discount = coupon
            ? price_ - totalPrice + (discount_ ?? 0)
            : discount_;

          return (
            <PricingOptionBox
              description={tier.description}
              price={totalPrice}
              id={tier.id}
              classesCount={tier.classesCount}
              mostPopular={tier.mostPopular}
              currency={currency}
              features={tier.features}
              discount={discount}
              onSelect={() => {
                setClicked(true);
                setSubOption(index);
                trackBookingAnalytics('Chose learning plan at pricing page', {
                  subOption: index,
                });
                setTimeout(() => {
                  !ready ? setPage(7) : setPage(8);
                }, animationDuration);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
