import { ForwardRefExoticComponent, SVGProps, useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { cx } from 'classix';
import { FieldError } from '../field-error';
import AvatarGroupStacked from '../../avatar/avatarGroupStacked/avatarGroupStacked';

interface Option {
  name: string;
  icon?: JSX.Element;
  onClick?: Function;
  sideText?: String;
  sideIcon?: ForwardRefExoticComponent<Pick<SVGProps<SVGSVGElement>, any>>;
  userPhotos?: Array<string>;
  chosen?: boolean;
}

export default function StackedCardRadio(props: {
  choices: Array<Option>;
  error?: string;
}) {
  const { choices, error } = props;
  const [selected, setSelected] = useState(choices[0]);

  return (
    <RadioGroup value={selected} onChange={setSelected}>
      <RadioGroup.Label className="sr-only">Choices</RadioGroup.Label>
      <div className="space-y-4">
        {choices.map((choice) => {
          if (!choice.name) return;
          return (
            <RadioGroup.Option
              key={choice.name}
              value={choice.name}
              onClick={() => {
                choice?.onClick?.();
              }}
              className={({ checked, active }) =>
                cx(
                  choice.chosen ? 'bg-blue-200' : 'bg-white',
                  checked ? 'border-transparent' : 'border-gray-300',
                  active ? 'border-indigo-600 ring-2 ring-blue-immigo' : '',
                  'relative flex justify-between cursor-pointer rounded-lg border px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between',
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span className="flex items-center">
                    <span className="flex flex-col text-sm">
                      <RadioGroup.Label
                        as="span"
                        className="font-medium text-gray-900 flex items-center"
                      >
                        {choice.icon ? (
                          <span className="mr-2 w-5 h-5">{choice.icon}</span>
                        ) : null}
                        {choice.name}
                      </RadioGroup.Label>
                    </span>
                  </span>
                  {choice.sideText || choice.userPhotos ? (
                    <RadioGroup.Description
                      as="span"
                      className="mt-2 flex text-sm sm:ml-4 sm:mt-0 sm:text-right text-gray-500"
                    >
                      {choice.sideText ? (
                        <span className="font-medium">{choice.sideText}</span>
                      ) : null}
                      {choice.sideIcon ? (
                        <choice.sideIcon
                          className="w-5 h-5 ml-2 my-auto"
                          aria-hidden="true"
                        />
                      ) : null}
                      {choice.userPhotos ? (
                        <AvatarGroupStacked
                          imageLinks={choice.userPhotos}
                          limit={choice.userPhotos.length}
                          names={choice.userPhotos}
                          ids={choice.userPhotos}
                          showDots
                        />
                      ) : null}
                    </RadioGroup.Description>
                  ) : null}
                </>
              )}
            </RadioGroup.Option>
          );
        })}
        <FieldError touched error={error} />
      </div>
    </RadioGroup>
  );
}
