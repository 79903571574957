import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getClientSecret, subscribeUserMembership } from '../../../../api';
import { PrimaryButton } from '../../../buttons/primary';

const STRIPE_KEY =
  process.env.REACT_APP_ENV === 'PROD'
    ? process.env.REACT_APP_LIVE_PUBLISHABLE_KEY
    : process.env.REACT_APP_TEST_PUBLISHABLE_KEY;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_KEY ? STRIPE_KEY : '');

const CheckoutForm = ({
  price,
  userData,
  onSubmit,
  onFailure,
  onSuccess,
  subscription,
  extraParam,
}: any) => {
  const stripe = useStripe();

  async function handleSubmit(e: any) {
    e.preventDefault();
    if (onSubmit) onSubmit();
    if (!userData) return; // Stop the user from checking out unless registered

    const intentResponse = await getClientSecret(
      Math.round(price.toFixed(2) * 100),
      'usd',
      'alipay',
      userData?.email,
      userData?.name,
      userData?.id,
    );
    console.log(intentResponse);
    const clientSecret = intentResponse
      ? intentResponse.data.paymentIntent.client_secret
      : undefined;

    console.log(window.location.href);
    // Confirm the payment on the client
    const { paymentIntent }: any = await stripe?.confirmAlipayPayment(
      clientSecret,
      {
        payment_method: {
          billing_details: {
            name: userData.name,
            email: userData.email,
          },
        },
        return_url: window.location.href + (extraParam ? extraParam : ''),
      },
    );

    // This probably should go into the server and process everything there for security & consistency
    if (paymentIntent && paymentIntent.status === 'succeeded') {
      if (onSuccess) onSuccess();
    } else {
      if (onFailure) onFailure();
    }
  }

  async function handleSubmitSub(e: any) {
    e.preventDefault();
    if (onSubmit) onSubmit();
    if (!userData) return; // Stop the user from checking out unless registered

    const result = await stripe?.createPaymentMethod({
      type: 'alipay',
      billing_details: {
        name: userData.name,
        email: userData.email,
      },
    });

    if (!result || result.error) {
      console.log('Error');
    } else {
      const res = await subscribeUserMembership(
        result.paymentMethod.id,
        userData.email,
        userData.name,
        price,
        userData.id,
      );
      // eslint-disable-next-line camelcase
      const { client_secret, customer_id, status } = res.data;

      if (status === 'requires_action') {
        stripe?.confirmCardPayment(client_secret).then(function (result) {
          if (result.error) {
            console.log('There was an issue!');
            console.log(result.error);
            if (onFailure) onFailure();
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
          } else {
            console.log('You got the money!');
            if (onSuccess) onSuccess();
            // Show a success message to your customer
          }
        });
      } else {
        if (onSuccess) onSuccess();
        console.log('You got the money!');
        // No additional information was needed
        // Show a success message to your customer
      }
    }
    // This probably should go into the server and process everything there for security & consistency
    // if(paymentIntent && paymentIntent.status === "succeeded") {
    //   if(onSuccess) onSuccess();
    // }
    // else {
    //   if(onFailure) onFailure();
    // }
  }

  return (
    <form className="w-auto md:mx-0 mx-auto" id="payment-form">
      <PrimaryButton
        text="Pay with Alipay"
        onClick={subscription ? handleSubmitSub : handleSubmit}
      />
    </form>
  );
};

const AliPayForm = ({
  price,
  userData,
  onSubmit,
  onFailure,
  onSuccess,
  subscription,
  extraParam,
  trial,
}: any) => {
  return (
    <div className="flex w-auto md:justify-start justify-center">
      <Elements stripe={stripePromise} options={{}}>
        <CheckoutForm
          subscription={subscription}
          price={price}
          userData={userData}
          onSubmit={onSubmit}
          onFailure={onFailure}
          onSuccess={onSuccess}
          extraParam={extraParam}
          trial={trial}
        />
      </Elements>
    </div>
  );
};

export default AliPayForm;
