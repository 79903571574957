import mixpanel from '../../../analytics/mixpanel';

type Events =
  | 'Clicked join class button on next class container'
  | 'Clicked prepare for class button on next class container'
  | 'Clicked get live feedback button'
  | 'Clicked avatar in next class container';

type NextClassContainerData = {};

export const trackNextClassContainerEvents = (
  event: Events,
  data?: NextClassContainerData,
) => {
  mixpanel.track(event, data);
};
