import axios from 'axios';
import { UserPreviewActivityType } from '../firebase/subscription/subscription';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com/social'
    : 'http://localhost:8080/social'; // Dev env

export async function followUser(followerID: string, followeeID: string) {
  const data = await axios.post(endPoint + `/followUser`, {
    followerID,
    followeeID,
  });
  return data?.data;
}

export async function unfollowUser(followerID: string, followeeID: string) {
  const data = await axios.post(endPoint + `/unfollowUser`, {
    followerID,
    followeeID,
  });
  return data?.data;
}

export async function createUserActivity(uid: string, activityType: string) {
  const data = await axios.post(endPoint + `/createUserActivity`, {
    uid,
    activityType,
  });
  return data?.data;
}

export async function createUserReferralCode(uid: string) {
  const data = await axios.post(endPoint + `/createUserReferralCode`, {
    uid,
  });
  return data?.data?.data;
}

export async function referUser(refereeID: string, referralCode: string) {
  const data = await axios.post(endPoint + `/referUser`, {
    refereeID,
    referralCode,
  });
  return data?.data?.data;
}
