import { SelectOption } from '../components/forms/select';
import countryData from '../usefuldata/countries.json';

export const nationalities = [
  ...countryData
    .sort((a, b) => {
      return ('' + a.name).localeCompare(b.name);
    })
    .map((country) => `${country.emoji} ${country.name}`),
];
export type Nationality = typeof nationalities[number];

export const nationalitiesOptions: Array<SelectOption> = [
  ...nationalities.map((country) => ({ value: country, text: country })),
];

export const countryCodeToName = (code?: string): string | undefined => {
  if (!code) return undefined;
  try {
    const country = countryData.find((country) => country.code === code);
    if (!country) return undefined;
    return `${country.emoji} ${country.name}`;
  } catch (e) {
    return undefined;
  }
};

export const countryNameToCode = (name?: string): string | undefined => {
  if (!name) return undefined;
  try {
    const nameWithoutEmoji = name.split(' ').slice(1).join(' ');
    return countryData.find((country) => country.name === nameWithoutEmoji)
      ?.code;
  } catch (e) {
    return undefined;
  }
};

export const yesNoOptions = [
  {
    value: 'yes',
    text: 'Yes',
  },
  {
    value: 'no',
    text: 'No',
  },
];

export const arrayAsSelectOptions = (
  array?: Array<string>,
): Array<SelectOption> => {
  if (!array) return [];
  return (array || [])
    .filter((it) => Boolean(it) && it !== null)
    .map((it: string) => ({ value: it, text: it }));
};
