import axios from 'axios';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com/user'
    : 'http://localhost:8080/user'; // Dev env

export async function getUserNextHook(uid: string, type: string) {
  const data = await axios.get(`${endPoint}/getUserNextHook/${uid}/${type}`);
  return data?.data?.data;
}
