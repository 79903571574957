import axios from 'axios';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com/learningPath'
    : 'http://localhost:8080/learningPath'; // Dev env

export async function startLearningPath(uid: string, pathId: string) {
  const data = await axios.post(`${endPoint}/start`, {
    uid,
    pathId,
  });
  return data?.data;
}

export async function getAllLearningPaths() {
  const data = await axios.get(`${endPoint}/all`);
  return data?.data?.data;
}

export async function getUserCurrentLearningPath(uid: string) {
  const data = await axios.get(`${endPoint}/current/${uid}`);
  return data?.data?.data;
}
