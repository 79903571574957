import { useEffect, useState } from 'react';
import { UserData } from '../../types/user';
import { getRecentlyCreatedUsers } from '../../firebase/users/users';
import UserTable from '../../components/userTable/userTable';
import { getUserBookings } from '../../firebase/subscription/bookings/bookings';

export const AdminLearnerData = () => {
  const [users, setUsers] = useState<Array<UserData>>([]);

  async function updateLearners() {
    const { res, err } = await getRecentlyCreatedUsers();
    await Promise.all(
      res.map(async (u: UserData) => {
        const bookings = await getUserBookings(u.id);
        u.bookings = bookings;
      }),
    );
    setUsers(res.filter((u: UserData) => !u?.bookings?.length));
  }

  useEffect(() => {
    updateLearners();
  }, []);

  return (
    <div className="w-screen flex justify-center">
      <UserTable userList={users} job phone country name signedUpAt />
    </div>
  );
};
