import { ReactNode } from 'react';

type ListStatBoxItem = {
  name: string;
  number: number;
  customDescription?: ReactNode;
};

export function ListStatBox(props: {
  title: string;
  items: Array<ListStatBoxItem>;
  customDescription?: ReactNode;
}) {
  const { title, items, customDescription } = props;

  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
      <dt className="truncate md:text-xl font-medium text-gray-500">{title}</dt>
      <>{customDescription}</>
      {items.map((item: ListStatBoxItem) => {
        return (
          <div>
            <div className="flex justify-between w-full">
              <dd
                key={item.name + item.number}
                className="mt-1 text-3xl font-semibold tracking-tight text-blue-immigo"
              >
                {item.name}
              </dd>
              <dd
                key={item.name + item.number}
                className="mt-1 text-3xl font-semibold tracking-tight text-gray-900"
              >
                {item.number}
              </dd>
            </div>
            {item.customDescription ? <dd>{item.customDescription}</dd> : null}
          </div>
        );
      })}
    </div>
  );
}
