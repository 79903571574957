import { TrashIcon } from '@heroicons/react/24/outline';
import { PaymentMethod } from '../../../types/payment';
import { capitalizeFirstLetter } from '../../../util/standardization';

export default function StackedPaymentRadio(props: {
  paymentMethods: Array<PaymentMethod>;
  onChosen: Function;
  onDelete: Function;
  defaultChosenID?: string;
}) {
  const { paymentMethods, onChosen, defaultChosenID, onDelete } = props;

  return (
    <div>
      <fieldset className="mt-2">
        <legend className="sr-only">Payment methods</legend>
        <div className="divide-y divide-gray-200">
          {paymentMethods.map((method, accountIdx) => (
            <div
              key={accountIdx}
              className="relative flex items-start pb-4 pt-3.5"
            >
              <div className="min-w-0 flex-1 text-sm leading-6">
                <label
                  htmlFor={`account-${method.lastFour}`}
                  className="font-medium text-gray-900"
                >
                  {capitalizeFirstLetter(method.brand ?? '')}
                </label>
                <p
                  id={`account-${method.lastFour}-description`}
                  className="text-gray-500"
                >
                  {`•••• ${method.lastFour}  ${method.expMonth}/${method.expYear}`}
                </p>
              </div>
              <div className="ml-3 flex h-6 items-center">
                <input
                  id={`account-${method.lastFour}`}
                  aria-describedby={`account-${method.lastFour}-description`}
                  name="account"
                  type="radio"
                  onChange={() => {
                    onChosen(method.id);
                  }}
                  defaultChecked={defaultChosenID === method.id}
                  className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                />
              </div>
              {paymentMethods?.length > 1 ? (
                <div>
                  <TrashIcon
                    onClick={async () => {
                      await onDelete(method.id);
                    }}
                    className="text-red-warning w-5 h-5 ml-4 cursor-pointer hover:text-red-400"
                  />
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
}
