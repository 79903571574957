import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { initializeFirebase } from '../../configValues';
import { updateBookingDetail } from '../bookings/bookings';
import { returnUserByUID } from '../../configuration';
import { UserData } from '../../../types/user';
import {
  BookingData,
  TeacherData,
  getClass,
  getClassSession,
  getClassWeekCleanSlide,
  getClassWeekPhrases,
  getClassWeekQuestions,
  getClassWeekTopic,
} from '../subscription';
import { updateRescheduleOnClass } from '../classes/classes';
const { firestore: db } = initializeFirebase();

export const getAllClassSessionFromClass = async (classID: string) => {
  const classRef = await collection(db, 'classes', classID, 'classSessions');
  const classDocs = await getDocs(classRef);
  return classDocs.docs.map((b) => b.data()) as Array<BookingData>;
};

export const getFutureClassSessionsInClass = async (classID: string) => {
  const sessions = await getAllClassSessionFromClass(classID);
  const futureSessions = sessions.filter((s: BookingData) => {
    return s.startMili > new Date().valueOf(); // The class has not happened yet
  });
  console.log(futureSessions);
  return futureSessions;
};

export const updateAllFutureClassSessionsInClass = async (
  classID: string,
  updateMap: Partial<BookingData>,
) => {
  const futureSessions = await getFutureClassSessionsInClass(classID); // Get all the future class sessions that belong to this class
  return await Promise.all(
    futureSessions.map(async (s) => {
      if (s.id) return await updateClassSession(s.id, updateMap);
    }),
  );
};

export const updateTopicDetailForAllFutureClassSessionsInClass = async (
  classID: string,
) => {
  const futureSessions = await getFutureClassSessionsInClass(classID); // Get all the future class sessions that belong to this class
  return await Promise.all(
    futureSessions.map(async (s) => {
      if (s.id && s.startMili) {
        const startMili = s.startMili;
        const title = await getClassWeekTopic(classID, startMili);
        const phrases = (await getClassWeekPhrases(classID, startMili)) ?? [];
        const questions =
          (await getClassWeekQuestions(classID, startMili)) ?? [];
        const cleanSlide = await getClassWeekCleanSlide(classID, startMili);

        if (!title || !cleanSlide) return;

        const updateMap = {
          title,
          phrases,
          questions,
          cleanSlide,
        };
        return await updateClassSession(s.id, updateMap);
      }
    }),
  );
};

export const rescheduleClass = async (
  sessionID: string,
  rescheduleToMili: number,
) => {
  const classSession = await getClassSession(sessionID);

  if (classSession?.startMili && classSession?.classID) {
    const updateClass = await updateRescheduleOnClass(
      classSession.classID,
      classSession.startMili,
      rescheduleToMili,
    );
    const updateSession = await updateClassSession(sessionID, {
      startMili: rescheduleToMili,
    });
  }
};

export const pushClassAnHour = async (sessionID: string) => {
  const classSession = await getClassSession(sessionID);

  if (classSession?.startMili && classSession?.classID) {
    const newTime = classSession.startMili + 1000 * 60 * 60;
    const updateClass = await updateRescheduleOnClass(
      classSession.classID,
      classSession.startMili,
      newTime,
    );
    const updateSession = await updateClassSession(sessionID, {
      startMili: newTime,
    });
  }
};

export const pullClassAnHour = async (sessionID: string) => {
  const classSession = await getClassSession(sessionID);

  if (classSession?.startMili && classSession?.classID) {
    const newTime = classSession.startMili - 1000 * 60 * 60;
    const updateClass = await updateRescheduleOnClass(
      classSession.classID,
      classSession.startMili,
      newTime,
    );
    const updateSession = await updateClassSession(sessionID, {
      startMili: newTime,
    });
  }
};

export const updatePeerReactionInClassSession = async (
  sessionID: string,
  reactorID: string,
  reactionMap: any,
) => {
  const classSession = await getClassSession(sessionID);
  const updateReactionMap = classSession?.peerReactionMap ?? {};
  Object.keys(reactionMap).map((id) => {
    if (!updateReactionMap[id]) updateReactionMap[id] = {};
    updateReactionMap[id][reactorID] = reactionMap[id];
  });

  await updateClassSession(sessionID, {
    peerReactionMap: updateReactionMap,
    surveyFilled: arrayUnion(reactorID),
  });
};

export const updateClassRatingInClassSession = async (
  bookingID: string,
  rating: string,
) => {
  await updateBookingDetail(bookingID, {
    classRating: rating,
  });
};

export const updateClassSession = async (sessionID: string, updateMap: any) => {
  const sessionRef = await doc(db, 'classSessions', sessionID);
  const sessionDoc = await getDoc(sessionRef);
  const sessionData = sessionDoc.data();

  if (sessionData) {
    const sessionClassesRef = doc(
      db,
      'classes',
      sessionData.classID,
      'classSessions',
      `${sessionData.startMili}`,
    );
    await updateDoc(sessionClassesRef, updateMap);
    await updateDoc(sessionRef, updateMap);

    // Also update their booking data
    const bookingsRef = collection(db, 'classSessions', sessionID, 'bookings');
    const bookings = await getDocs(bookingsRef);

    const res = await Promise.all(
      bookings.docs.map(async (booking) => {
        console.log(booking.id);
        await updateBookingDetail(booking.id, updateMap);
      }),
    );
    return res;
  } else {
    console.log('updateClassSession: Session Data Does not exist');
  }
};

export const updateClassRecordingLink = async (
  sessionID: string,
  newRecordingURL: string,
) => {
  const res = await updateClassSession(sessionID, {
    recording: newRecordingURL,
  });
  return res;
};

export const updateClassSlideLink = async (
  sessionID: string,
  newSlideURL: string,
) => {
  const res = await updateClassSession(sessionID, {
    slide: newSlideURL,
  });
  return res;
};

export const updateClassTitle = async (sessionID: string, newTitle: string) => {
  const res = await updateClassSession(sessionID, {
    title: newTitle,
  });
  return res;
};

export const claimBookingAsACoach = async (
  coachID: string,
  sessionID: string,
) => {
  const coach = (await returnUserByUID(coachID)) as UserData;

  if (coach) {
    const { name, profilePictureLink, id } = coach;
    const teacherData: TeacherData = {
      id,
      name,
      profilePictureLink,
      assignedAt: new Date().valueOf(),
    };

    await updateClassSession(sessionID, {
      teacher: teacherData,
    });

    return teacherData;
  } else {
    return null;
  }
};

export const getSessionLimitByStartMili = async (
  lowest: number,
  highest: number,
  queryLimit: number,
) => {
  const classesReference = await collection(db, 'classSessions');
  const classesQuery = query(
    classesReference,
    where('startMili', '>=', lowest),
    where('startMili', '<=', highest),
    limit(queryLimit),
  );
  const classes = await getDocs(classesQuery);
  const classesData: any[] = [];
  classes.forEach((c: any) => {
    const data = c.data();
    data.id = c.id;
    classesData.push(data);
  });
  return classesData;
};

export const getSessionLimitByLowestStartMili = async (
  lowest: number,
  queryLimit: number,
) => {
  const classesReference = await collection(db, 'classSessions');
  const classesQuery = query(
    classesReference,
    where('startMili', '>=', lowest),
    limit(queryLimit),
  );
  const classes = await getDocs(classesQuery);
  const classesData: any[] = [];
  classes.forEach((c: any) => {
    const data = c.data();
    data.id = c.id;
    classesData.push(data);
  });
  return classesData;
};

export const getSessionLimitByHighestStartMili = async (
  highest: number,
  queryLimit: number,
) => {
  const classesReference = await collection(db, 'classSessions');
  const classesQuery = query(
    classesReference,
    orderBy('startMili', 'desc'),
    where('startMili', '<=', highest),
    limit(queryLimit),
  );
  const classes = await getDocs(classesQuery);
  const classesData: any[] = [];
  classes.forEach((c: any) => {
    const data = c.data();
    data.id = c.id;
    classesData.push(data);
  });
  return classesData;
};

export const getBookingsFromClassSession = async (sessionID: string) => {
  const bookingRefs = await collection(
    db,
    'classSessions',
    sessionID,
    'bookings',
  );
  const bookingDocs = await getDocs(bookingRefs);

  const bookings: Array<BookingData> = [];
  bookingDocs.forEach((b: any) => {
    const data = b.data();
    data.id = b.id;
    bookings.push(data);
  });

  return bookings as Array<BookingData>;
};

export const getBookersFromClassSession = async (sessionID: string) => {
  const bookings: Array<BookingData> = await getBookingsFromClassSession(
    sessionID,
  );

  const bookers: Array<UserData> = await Promise.all(
    bookings.map(async (b: BookingData) => {
      const booker = (await returnUserByUID(b.bookerID)) as UserData;
      return booker;
    }),
  );
  return bookers as Array<UserData>;
};
