import {
  CefrLevelPair,
  CefrToClassesCountMapObject,
  CEFRLevel,
} from '../types/level';

export const nextCefrClassCount = 48;
export const nextLevelClassCount = nextCefrClassCount / 3;
export const cefrToClassesCountMap: CefrToClassesCountMapObject = {
  A1: nextCefrClassCount * 0,
  A2: nextCefrClassCount * 1,
  B1: nextCefrClassCount * 2,
  B2: nextCefrClassCount * 3,
  C1: nextCefrClassCount * 4,
  C2: nextCefrClassCount * 5,
};
export const cefrToColorStyle = {
  A1: '',
  A2: 'bg-yellow-400 text-yellow-700',
  B1: 'bg-blue-500 text-white',
  B2: 'bg-red-600 text-white',
  C1: 'bg-gradient-to-t from-red-700 to-slate-900 text-white',
  C2: 'bg-neutral-950 text-yellow-300',
};

export const levelToStyle: any = {
  1: 'bg-yellow-400 text-yellow-700',
  2: 'bg-gradient-to-t from-red-700 to-slate-900 text-white',
  3: 'bg-neutral-950 text-yellow-300',
  4: 'bg-red-500',
};

export function cefrPairToLevelID(pair: CefrLevelPair) {
  return `${pair.cefr}-${pair.level}`;
}

export function cefrLevelIDToLevelPair(id: string): CefrLevelPair | undefined {
  try {
    const info = id.split('-');
    const cefr = info?.[0] as CEFRLevel;
    const level = parseInt(info?.[1]);

    if (info.length !== 2 || !cefr || !level) {
      throw `Error: cefrLevelIDToLevelPair needs to be in {cefrLevel}-{level} format. ${id} is a wrong format.`;
    }
    return { cefr: cefr, level: level };
  } catch (err) {
    console.log(err);
  }
}

export function getNextCefrLevel(level: CEFRLevel) {
  switch (level) {
    case 'A1':
      return 'A2';
    case 'A2':
      return 'B1';
    case 'B1':
      return 'B2';
    case 'B2':
      return 'C1';
    case 'C1':
      return 'C2';
    default:
      return undefined;
  }
}

export function getCurrentCefrLevelAndLevelPair(
  cefrLevel: CEFRLevel,
  attended: number,
): CefrLevelPair {
  const base = cefrToClassesCountMap[cefrLevel];
  const total = base + attended;

  console.log(cefrLevel);
  console.log(attended);
  console.log(total);

  if (total < nextLevelClassCount * 1) {
    return { cefr: 'A1', level: 1 };
  } else if (total < nextLevelClassCount * 2) {
    return { cefr: 'A1', level: 2 };
  } else if (total < nextLevelClassCount * 3) {
    return { cefr: 'A1', level: 3 };
  } else if (total < nextLevelClassCount * 4) {
    return { cefr: 'A2', level: 1 };
  } else if (total < nextLevelClassCount * 5) {
    return { cefr: 'A2', level: 2 };
  } else if (total < nextLevelClassCount * 6) {
    return { cefr: 'A2', level: 3 };
  } else if (total < nextLevelClassCount * 7) {
    return { cefr: 'B1', level: 1 };
  } else if (total < nextLevelClassCount * 8) {
    return { cefr: 'B1', level: 2 };
  } else if (total < nextLevelClassCount * 9) {
    return { cefr: 'B1', level: 3 };
  } else if (total < nextLevelClassCount * 10) {
    return { cefr: 'B2', level: 1 };
  } else if (total < nextLevelClassCount * 11) {
    return { cefr: 'B2', level: 2 };
  } else if (total < nextLevelClassCount * 12) {
    return { cefr: 'B2', level: 3 };
  } else if (total < nextLevelClassCount * 13) {
    return { cefr: 'C1', level: 1 };
  } else if (total < nextLevelClassCount * 14) {
    return { cefr: 'C1', level: 2 };
  } else if (total < nextLevelClassCount * 15) {
    return { cefr: 'C1', level: 3 };
  } else if (total < nextLevelClassCount * 16) {
    return { cefr: 'C2', level: 1 };
  } else if (total < nextLevelClassCount * 17) {
    return { cefr: 'C2', level: 2 };
  } else if (total < nextLevelClassCount * 18) {
    return { cefr: 'C2', level: 3 };
  } else {
    return { cefr: 'C2', level: 4 };
  }
}

export function getNextCefrLevelPair(
  cefrLevel: CEFRLevel,
  attended: number,
): CefrLevelPair {
  const nextLevel = getCurrentCefrLevelAndLevelPair(cefrLevel, attended + 16);
  return nextLevel;
}

export function getRemainingClassUntilNextLevel(attended: number): number {
  return nextLevelClassCount - (attended % nextLevelClassCount);
}

export function getLevelProgressPercentage(attended: number) {
  const percentage = (attended % nextCefrClassCount) / nextCefrClassCount;
  return percentage * 100;
}

export function getCurrentCefrBasedOnAttendance(
  level: CEFRLevel,
  attended: number,
) {
  let finalLevel = level;
  let advanceCefrCount = Math.floor(attended / nextCefrClassCount);
  for (let i = 0; i < advanceCefrCount; i++) {
    const newCEFR = getNextCefrLevel(level);
    if (newCEFR) finalLevel = newCEFR;
  }
  return finalLevel;
}

export function getCurrentLevelIndexBasedOnAttendance(
  cefrLevel: CEFRLevel,
  attended: number,
) {
  const currentCefr = getCurrentCefrBasedOnAttendance(cefrLevel, attended);

  const level = Math.floor(
    (attended % nextCefrClassCount) / nextLevelClassCount,
  );

  return level > 1 ? 0 : level;
}
