import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import emptyAvatar from '../../../images/empty-avatar.webp';
import { countryCodeToSVG } from '../../../util/flagLoader';
import cx from 'classix';

export default function AvatarGroupStacked(props: {
  imageLinks: Array<string | undefined>;
  names: Array<string>;
  ids: Array<string>;
  limit: number;
  showDots?: boolean;
  nationalities?: Array<string>;
  onClickAvatar?: Function;
}) {
  const {
    imageLinks,
    limit,
    names,
    nationalities,
    ids,
    showDots,
    onClickAvatar,
  } = props;

  return (
    <>
      <div className="flex flex-shrink-0 -space-x-2 p-1">
        {imageLinks
          .slice(0, limit)
          .map((imageLink: string | undefined, index: number) => {
            return (
              <div
                className="relative"
                key={ids[index] + ''}
                id={ids[index] + ''}
              >
                <img
                  key={(imageLink ?? 'avatar') + index}
                  className={cx(
                    'inline-block h-8 w-8 rounded-full ring-1 ring-gray-200 object-cover object-center bg-white',
                    onClickAvatar ? 'cursor-pointer' : '',
                  )}
                  src={imageLink ?? emptyAvatar}
                  alt={(imageLink ?? 'avatar') + index}
                  onClick={() => {
                    onClickAvatar?.(ids[index]);
                  }}
                />
                {nationalities?.[index] ? (
                  <img
                    className="absolute -bottom-2 -right-1 w-5 h-5"
                    src={countryCodeToSVG(nationalities?.[index])}
                  />
                ) : null}
              </div>
            );
          })}
        {limit < imageLinks.length ? (
          <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-blue-immigo">
            <span className="text-md font-medium leading-none text-white">
              +{imageLinks.length - limit}
            </span>
          </span>
        ) : null}
        {showDots ? (
          <span className="leading-none relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-blue-immigo">
            <span className="text-md font-medium text-white inline-block text-center align-middle">
              <EllipsisHorizontalIcon className="w-5 h-5" />
            </span>
          </span>
        ) : null}
      </div>
    </>
  );
}
