import cx from 'classix';
import { LoadingCircle } from '../../icons/loadingCircle';

export const SecondaryBlueButton = (props: {
  text: string;
  onClick: Function;
  disabled?: boolean;
  loading?: boolean;
}) => {
  const { text, onClick, disabled, loading } = props;
  return (
    <button
      disabled={disabled}
      className={cx(
        disabled
          ? 'text-gray-500'
          : 'text-blue-immigo border-blue-immigo hover:text-blue-immigo-lighter hover:border-blue-immigo-lighter',
        'flex justify-center items-center border text-sm font-bold w-full py-2 px-4 rounded-md',
      )}
      onClick={(e) => onClick(e)}
    >
      {loading ? <LoadingCircle className="mx-2" /> : null}
      {text}
    </button>
  );
};
