// Takes in month to studentId list map

import moment, { utc } from 'moment';
import { returnAppByUID } from '../firebase/configuration';
import commonWords from '../usefuldata/commonWords.json';
import nlp from 'compromise/three';

// eg. { 1/2021 : [DbdkjwbakJA, KJDwahhdak, KJDghajkw ...], 2/2021 : [DbdkjwbakJA, OIWUANMDAS]}
export const monthlyMapToCohortAnalysis = (monthlyMap: any) => {
  const cohortMap: any = {};
  const cohortUserMap: any = {};
  const months = Object.keys(monthlyMap).sort((a: string, b: string) => {
    return moment(a).valueOf() - moment(b).valueOf();
  });

  let checked: Array<string> = [];

  for (let i = 0; i < months.length; i++) {
    const curMonth = months[i];
    if (!cohortMap[curMonth]) cohortMap[curMonth] = [];
    if (!cohortUserMap[curMonth]) cohortUserMap[curMonth] = [];

    // Count retained students starting from "curMonth"
    for (let j = i; j < months.length; j++) {
      const retained = monthlyMap[curMonth].filter((val: string) => {
        return monthlyMap[months[j]].includes(val) && !checked.includes(val); // Accounted user does not go on the data
      });
      cohortMap[curMonth].push(retained.length);
      cohortUserMap[curMonth].push(retained);
    }

    checked = Array.from(
      new Set<string>([...checked, ...monthlyMap[curMonth]]),
    ); // Mark users who were already accounted for. (new users that month)
  }

  console.log(cohortMap);
  return { cohortMap, cohortUserMap };
};

export const miliToUTCObject = (mili?: number) => {
  if (!mili)
    return {
      day: 'undefined',
      hour: 'undefined',
      minute: 'undefined',
    };
  const dateObject = moment(mili).utc();

  return {
    day: dateObject.format('dddd'),
    hour: dateObject.format('HH'),
    minute: dateObject.format('mm'),
  };
};

export const miliToLocalTimeObject = (mili?: number) => {
  if (!mili)
    return {
      day: 'undefined',
      hour: 'undefined',
      minute: 'undefined',
    };
  const dateObject = moment(mili).utc().local();

  return {
    day: dateObject.format('dddd'),
    hour: dateObject.format('HH'),
    minute: dateObject.format('mm'),
    dateNumber: dateObject.format('d'),
    ampmTime: dateObject.format('h:mmA'),
  };
};

export const getWordFrequency = (text: string) => {
  var words = text.replace(/[.]/g, '').split(/\s/);
  var freqMap: any = {};
  words.forEach(function (w) {
    if (!freqMap[w]) {
      freqMap[w] = 0;
    }
    freqMap[w] += 1;
  });
  return freqMap;
};

export const findAllSubstringOcc = (text: string, toFind: string) => {
  var regex = new RegExp(toFind, 'g');
  var count = (text.match(regex) || []).length;
  return count;
};

export const getUniqueArray = (a: Array<string>) => {
  return a.filter(function (item, pos) {
    return a.indexOf(item) === pos;
  });
};

export const getTwoArrayOverlap = (array1: Array<any>, array2: Array<any>) => {
  const filteredArray = array1.filter((value) => array2.includes(value));
  return filteredArray;
};

// Given a list of meetingSession document, caclulate overall data
export function calculateMeetingOverviewData(a: Array<any>) {
  const data = {
    totalTime: 0,
    totalWordsSpoken: 0,
  };

  a.forEach((a: any) => {
    data.totalTime += getRealMeetingTime(a);
    data.totalWordsSpoken += getTotalWordSpokenMeeting(a);
  });

  return data;
}

export const getRealMeetingTime = (m: any) => {
  const users = Object.keys(m?.userTimeData ?? {});
  let time = 0;

  users.forEach((userID: string) => {
    const userTime = m?.userTimeData?.[userID];
    if (time == 0 && userTime) time = userTime;
    else time = Math.min(userTime, time);
  });

  return time;
};

export const getTotalWordSpokenMeeting = (m: any) => {
  const users = Object.keys(m?.userTimeData ?? {});
  let spokenWordsCount = 0;

  users.forEach((userID: string) => {
    const userScriptArray = (m?.script?.[userID] ?? '').split(' ');
    spokenWordsCount += userScriptArray.length;
  });

  return spokenWordsCount;
};

export const getNumberPercential = (num: number, a: Array<number>) => {
  let lowerN = 0;

  a.forEach((n: number) => {
    if (n > num) lowerN += 1;
  });

  return (lowerN / a.length) * 100;
};

export const getEarliestApp = async (appMap: any) => {
  const courseIDs = Object.keys(appMap);
  let earliestApp: any;

  await Promise.all(
    courseIDs.map(async (id: string) => {
      let app = await returnAppByUID(appMap[id]);
      if (!earliestApp || app?.createdAt < earliestApp?.createdAt)
        earliestApp = app; // get the earliest app
    }),
  );

  return earliestApp;
};

export const removeUndefinedProperties = (
  obj: Record<string, unknown | undefined>,
): Record<string, unknown> => {
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
  return obj;
};

// Returns a map of word : count of top N occuring words
export function topOccurenceMap(wordMap: any, n: number) {
  const countToWordsMap: any = {};
  let wordList: Array<string> = [];
  Object.keys(wordMap).map((word) => {
    const count = wordMap[word];
    if (!countToWordsMap[count]) countToWordsMap[count] = [word];
    else countToWordsMap[count].push(word);
  });

  const occurenceArraySorted = Object.keys(countToWordsMap).sort(
    (a, b) => parseInt(b) - parseInt(a),
  );

  for (let i = 0; i < occurenceArraySorted.length; i++) {
    wordList = [
      ...wordList,
      ...countToWordsMap[occurenceArraySorted[i]].slice(0, n - wordList.length),
    ]; // n - wordList.length is how many we can fit in
    if (wordList.length === n) break; // Stop if reached the limit
  }

  return wordList; // Return top to low ex) index 0 is most frequent
}

export function topOccurenceMapWithoutCommonWords(wordMap: any, n: number) {
  Object.keys(wordMap).forEach((w: string) => {
    const transformed =
      nlp(w).verbs().toInfinitive().text().trim() ||
      nlp(w).nouns().toSingular().text().trim() ||
      w;
    if (commonWords.includes(transformed)) delete wordMap[w]; // Delete field that's common
  });

  return topOccurenceMap(wordMap, n);
}

export function getNumberRangeContainingN(
  n: number,
  count: number,
  end: number,
  topBuffer?: number,
) {
  let startIndex = n === 0 ? 0 : n - (topBuffer ?? 0);

  if (end - startIndex < count) {
    startIndex = Math.max(0, end - count);
  }

  let endIndex = Math.min(end, startIndex + count);

  return {
    startIndex,
    endIndex,
  };
}

// Takes a map with value as Array<T>, returns an array of keys ordered based on
// which key has a value with the most elements. [1st in length, 2nd length, 3rd in length etc]
export function getTopNKeysInMapWithArrayValues(map: any, n: number) {
  const orderedArray = Object.keys(map).sort(
    (k1, k2) => map[k2].length - map[k1].length,
  );
  return orderedArray.splice(0, n);
}

// ex : y, {x : {y : 1, z : 2}, z : {y : 3, x : 1}} => {z : 3, x : 1} (Primarily used to get what the reactor reacted to)
export function get2DInvertedMapGivenNestedValue(map: any, value: any) {
  const result: any = {};
  Object.keys(map).forEach((id: any) => {
    if (map[id][value]) result[id] = map[id][value];
  });
  return result;
}
