import { useState } from 'react';
import { stringsPricingPage } from '../../../util/localization';
import { AuthProvider } from '../loginregister/authProviders/authProviders';
import { PrimaryButton } from '../../buttons/primary';
import { PartialUserData } from '../loginregister';
import { validateEmail, validateFullName } from '../../../util/validation';
import { registerWithEmailAndPassword } from '../../../firebase/auth/auth';

export const SimpleLoginForm = (props: {
  onSubmit: Function;
  defaultEmail?: string;
  defaultName?: string;
  defaultId?: string;
  disableOAuth?: boolean;
  userDataOverride?: PartialUserData;
}) => {
  const {
    onSubmit,
    userDataOverride,
    disableOAuth,
    defaultEmail,
    defaultId,
    defaultName,
  } = props;

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [name, setName] = useState(defaultName ?? '');
  const [email, setEmail] = useState(defaultEmail ?? '');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [registerErrorText, setRegisterErrorText] = useState('');
  const [loading, setLoading] = useState(false);

  async function registerUser() {
    const validEmail = validateEmail(email);
    const validName = validateFullName(name);
    const validPassword = password.length > 0;
    const validConfirmPassword = password === confirmPassword;
    if (!validEmail) {
      setEmailError(true);
    }
    if (!validName) {
      setNameError(true);
    }

    if (!validPassword) {
      setPasswordError(true);
    }

    if (!validConfirmPassword) {
      setConfirmPasswordError(true);
    }

    if (validEmail && validName && validPassword && validConfirmPassword) {
      setLoading(true);
      // Lowercase everything to standardize
      const { user, error } = await registerWithEmailAndPassword(
        name.toLowerCase(),
        email.toLowerCase(),
        password,
        userDataOverride,
      );
      setRegisterErrorText(error);
      if (!error && user) {
        await onRegistrationSuccess(user.id);
      }
      setLoading(false);
    }
  }

  async function onCreateYourAccountClicked() {
    const validEmail = validateEmail(email);
    const validName = validateFullName(name);
    const validPassword = password.length > 0;
    const validConfirmPassword = password === confirmPassword;
    if (!validEmail) {
      setEmailError(true);
    }
    if (!validName) {
      setNameError(true);
    }

    if (!validPassword) {
      setPasswordError(true);
    }

    if (!validConfirmPassword) {
      setConfirmPasswordError(true);
    }

    if (validEmail && validName && validPassword && validConfirmPassword) {
      setLoading(true);
      // Lowercase everything to standardize
      const { user, error } = await onSubmit(email, password, defaultId);
      setRegisterErrorText(error);
      if (!error && user) {
        await onRegistrationSuccess(user.id);
      }
      setLoading(false);
    }
  }

  function onRegistrationSuccess(uid: string) {}

  return (
    <form
      className="space-y-6"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {!disableOAuth ? (
        <div>
          <div>
            <AuthProvider
              onAction={() => {
                setLoading(true);
              }}
              onError={async (error: string) => {
                setRegisterErrorText(error);
                setLoading(false);
              }}
              userDataOverride={userDataOverride}
              onSuccess={async (uid: string) => {
                await onRegistrationSuccess(uid);
                setLoading(false);
              }}
              isLoading={loading}
            />
            <div className="relative my-5">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center text-sm font-medium leading-6">
                <span className="bg-white px-6 text-gray-900">
                  {stringsPricingPage.orContinueWith}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {stringsPricingPage.emailAddress}
        </label>
        <div className="mt-2">
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-immigo sm:text-sm sm:leading-6"
          />
        </div>
        {emailError ? (
          <div className="text-sm text-red-warning">
            {stringsPricingPage.pleaseEnterValidEmail}
          </div>
        ) : null}
      </div>

      <div>
        <div className="flex items-center justify-between">
          <label
            htmlFor="name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {stringsPricingPage.fullName}
          </label>
        </div>
        <div className="mt-2">
          <input
            id="name"
            name="name"
            type="text"
            autoComplete="given-name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-immigo sm:text-sm sm:leading-6"
          />
        </div>
        {nameError ? (
          <div className="text-sm text-red-warning">
            {stringsPricingPage.pleaseEnterFullName}
          </div>
        ) : null}
      </div>

      <div>
        <div className="flex items-center justify-between">
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {stringsPricingPage.password}
          </label>
        </div>
        <div className="mt-2">
          <input
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-immigo sm:text-sm sm:leading-6"
          />
        </div>
        {passwordError ? (
          <div className="text-sm text-red-warning">
            {stringsPricingPage.pleaseEnterPassword}
          </div>
        ) : null}
      </div>

      <div>
        <div className="flex items-center justify-between">
          <label
            htmlFor="confirm-password"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {stringsPricingPage.confirmPassword}
          </label>
        </div>
        <div className="mt-2">
          <input
            id="confirm-password"
            name="confirm-password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-immigo sm:text-sm sm:leading-6"
          />
        </div>
        {confirmPasswordError ? (
          <div className="text-sm text-red-warning">
            {stringsPricingPage.passwordDoesNotMatch}
          </div>
        ) : null}
      </div>

      {/* <div className="flex items-center justify-between">
          <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
              />
              <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">
                Remember me
              </label>
            </div>
        </div> */}

      <div>
        <PrimaryButton
          className="mt-1"
          text={stringsPricingPage.createYourAccount}
          onClick={() => {
            onCreateYourAccountClicked();
          }}
          loading={loading}
        />
      </div>
    </form>
  );
};
