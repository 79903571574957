import { CheckIcon, LockClosedIcon } from '@heroicons/react/20/solid';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import cx from 'classix';

export default function ProgressSteps(props: { steps: Array<any> }) {
  const { steps } = props;
  return (
    <nav aria-label="Progress">
      <ol role="list" className="flex items-center">
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={cx(
              stepIdx !== steps.length - 1 ? 'pr-12 sm:pr-16' : '',
              'relative',
            )}
          >
            {step.status === 'complete' ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-blue-300" />
                </div>
                <div className="relative flex h-8 w-8 items-center justify-center rounded-full bg-blue-immigo">
                  <CheckIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </div>
              </>
            ) : step.status === 'current' ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <div
                  className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-blue-immigo bg-white"
                  aria-current="step"
                >
                  <ArrowPathIcon
                    className="h-4 w-4 rounded-full text-blue-immigo"
                    aria-hidden="true"
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <div className="relative flex h-8 w-8 items-center justify-center rounded-full bg-gray-200">
                  <LockClosedIcon
                    className="h-4 w-4 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
      <ol className="flex">
        {steps.map((step, stepIdx) => {
          return (
            <li
              key={step.name}
              className={cx(
                stepIdx !== steps.length - 1 ? 'pr-10 sm:pr-14' : '',
                'relative text-xs text-center',
              )}
            >
              {step.name}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
