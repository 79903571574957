import { cx } from 'classix';
import { useEffect, useRef, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { BookingEventObject } from '../../features/Booking/bookingEventContainer';
import WeekBookingCalendar from '../../features/Booking/weekBookingCalendar';
import {
  stringsPricingPage,
  stringsSchedulePage,
} from '../../util/localization';
import { getAllClasses } from '../../firebase/subscription/classes/classes';

const animationDuration = 300;

export function SchedulePage(props: {}) {
  const [loaded, setLoaded] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [classList, setClassList] = useState<
    Array<BookingEventObject> | undefined
  >();

  const buttonRef = useRef<any>(null);

  const search = new URLSearchParams(window.location.search);
  const viaToken = search.get('via');
  const lang = search.get('lang') || 'en';

  async function updateClasses() {
    setLoaded(false);
    const classes = await getAllClasses();
    setClassList(classes);
    setLoaded(true);
  }

  useEffect(() => {
    updateClasses();
  }, []);

  return (
    <div
      className={cx(
        'mx-auto h-vh transition-all ease-in duration-${animationDuration} translate-x-0',
        !clicked && loaded ? 'opacity-100' : 'opacity-0 -translate-x-20',
      )}
    >
      <div className="mx-auto max-w-4xl md:text-center">
        <p className="my-12  md:text-3xl text-xl font-bold tracking-tight text-gray-900">
          {`${stringsSchedulePage.classSchedule}`}
        </p>
      </div>
      <div className="mb-4 text-gray-500">
        {stringsPricingPage.adjustedToYourTimeZone}
      </div>
      {!classList ? (
        <div className="flex justify-center items-center">
          <ContentLoader viewBox="0 0 380 140">
            <rect x="20" y="0" rx="5" ry="5" width="40" height="120" />
            <rect x="70" y="0" rx="5" ry="5" width="40" height="120" />
            <rect x="120" y="0" rx="5" ry="5" width="40" height="120" />
            <rect x="170" y="0" rx="5" ry="5" width="40" height="120" />
            <rect x="220" y="0" rx="5" ry="5" width="40" height="120" />
            <rect x="270" y="0" rx="5" ry="5" width="40" height="120" />
            <rect x="320" y="0" rx="5" ry="5" width="40" height="120" />
          </ContentLoader>
        </div>
      ) : (
        <div className="h-0 min-h-[90%] bg-gray-50 rounded-md mb-2">
          <WeekBookingCalendar
            chosenSchedule={[]}
            setChosenSchedule={() => {
              window.open(`/pricing?via=${viaToken}&lang=${lang}`);
            }}
            events={classList}
            setLoading={() => {}}
          />
        </div>
      )}
    </div>
  );
}
