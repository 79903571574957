import {
  SpeechMaticTranscript,
  SpeechMaticTranscriptResult,
} from '../types/speechmatic';

// Finds end of the sentence in a given transcript
export function getEOSIndexInTranscript(
  transcript: SpeechMaticTranscript,
): number {
  let index = -1;
  for (let i = 0; i < (transcript?.results?.length ?? 0); i++) {
    const result = transcript?.results?.[i];
    if (result.is_eos) {
      index = i;
      break;
    }
  }

  return index;
}

export function parseFormattedTranscriptString(text: string) {
  const content = text.split(':')?.[1];
  const metadata = text.split(':')?.[0];
  const createdAt = parseInt(metadata.split('-')?.[0] ?? -1);
  const startSeconds = parseInt(metadata.split('-')?.[1] ?? -1);
  const endSeconds = parseInt(metadata.split('-')?.[2] ?? -1);
  const speaker = metadata.split('-')?.[3] ?? 'NA';

  return {
    content,
    createdAt,
    startSeconds,
    endSeconds,
    speaker,
  };
}

export function returnFormattedStringsGivenResultsArray(
  results: Array<SpeechMaticTranscriptResult>,
): Array<string> {
  const finalArray: Array<string> = [];
  let tempStr = '';
  let tempStartTime: undefined | number = undefined;

  results.map((result: SpeechMaticTranscriptResult, index: number) => {
    if (!result.is_eos) {
      const content = result.alternatives?.[0]?.content;
      if (tempStartTime === undefined) tempStartTime = result.start_time; // Keep the start time of the sentence
      if (result.type !== 'punctuation') tempStr += ' ';
      tempStr += content;
    } else {
      // If end of the sentence

      const endTime = results[index - 1]?.end_time ?? result.end_time; // Try to take the end time of the previous result to take account of the pause.

      finalArray.push(
        `${Math.round(tempStartTime ?? 0)}-${Math.round(
          endTime,
        )}:${tempStr?.trim()}${result.alternatives?.[0]?.content.trim()}`,
      );
      tempStr = '';
      tempStartTime = undefined;
    }
  });

  return finalArray;
}

export function returnFormattedStringsGivenResultsArrayWithMultipleSpeakers(
  results: Array<SpeechMaticTranscriptResult>,
): Array<string> {
  const finalArray: Array<string> = [];

  const tempStrMap: any = {}; // Stores temporary string for each speaker
  const tempStartMap: any = {};

  results.map((result: SpeechMaticTranscriptResult, index: number) => {
    const content = result.alternatives?.[0]?.content;
    const speaker = result.alternatives?.[0]?.speaker ?? 'S1';

    if (!result.is_eos) {
      if (tempStartMap[speaker] === undefined)
        tempStartMap[speaker] = result.start_time; // Keep the start time of the sentence
      if (!tempStrMap[speaker]) tempStrMap[speaker] = '';
      if (result.type !== 'punctuation') tempStrMap[speaker] += ' ';
      tempStrMap[speaker] += content;
    } else {
      const endTime = results[index - 1]?.end_time ?? result.end_time; // Try to take the end time of the previous result to take account of the pause.
      const startTime = tempStartMap[speaker];
      const content = tempStrMap[speaker];
      finalArray.push(
        `${Math.round(startTime ?? 0)}-${Math.round(
          endTime,
        )}-${speaker}:${content?.trim()}${result.alternatives?.[0]?.content.trim()}`,
      );

      tempStrMap[speaker] = '';
      tempStartMap[speaker] = undefined;
    }
  });

  return finalArray;
}

export function getOneSampleEachSpeaker(formattedTranscript: Array<string>) {
  const speakerMap: any = {};
  formattedTranscript.forEach((t: string) => {
    const { content, createdAt, startSeconds, endSeconds, speaker } =
      parseFormattedTranscriptString(t);

    if (!speakerMap[speaker] && content.length > 20)
      speakerMap[speaker] = {
        // Has to be long enough to qualify
        content,
        createdAt,
        startSeconds,
        endSeconds,
        speaker,
      };
  });

  return speakerMap;
}

export function getListOfSpeakers(formattedTranscript: Array<string>) {
  const speakers: any = [];
  formattedTranscript.forEach((t: string) => {
    const { content, createdAt, startSeconds, endSeconds, speaker } =
      parseFormattedTranscriptString(t);
    if (!speakers.includes(speaker)) speakers.push(speaker);
  });

  return speakers;
}
