import {
  ForwardRefExoticComponent,
  Fragment,
  ReactNode,
  SVGProps,
  useState,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  BanknotesIcon as BankNotesIcon,
  ClockIcon,
  IdentificationIcon,
  DocumentChartBarIcon,
  AcademicCapIcon,
  StarIcon,
  PencilIcon,
  RocketLaunchIcon,
} from '@heroicons/react/24/outline';
import cx from 'classix';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UserData } from '../../types/user';
import { countryCodeToEmoji } from '../../util/helper';
import { signOut } from 'firebase/auth';
import { initializeFirebase } from '../../firebase/configValues';
import emptyAvatar from '../../images/empty-avatar.webp';
import { LayOutEvents, trackLayOutEvent } from './analytics';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { countryCodeToSVG } from '../../util/flagLoader';

const { auth } = initializeFirebase();

type NavigationElement = {
  name: string;
  href: (args?: any) => string;
  event?: LayOutEvents;
  icon: ForwardRefExoticComponent<Pick<SVGProps<SVGSVGElement>, any>>;
};

type UserProfileProps = {
  id: string;
  name: string;
  avatar: string | undefined;
  nationality: string;
  points?: number;
};

function UserProfile({
  id,
  name,
  avatar,
  nationality,
  points,
}: UserProfileProps) {
  return (
    <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
      <Link to={`/profile/${id}`} className="group block w-full flex-shrink-0">
        <div className="flex items-center gap-5">
          <div className="relative">
            <img
              className="inline-block h-10 w-10 rounded-full border-gray-200 border"
              src={avatar ?? emptyAvatar}
              alt=""
            />
            <img
              className="rounded-sm absolute -bottom-1 -right-1 text-xl w-5 h-5"
              src={countryCodeToSVG(nationality)}
              alt={'flag'}
            />
            <span className="inline-block absolute top-0 right-0 h-3 w-3 my-1 border-2 border-white rounded-full bg-green-400" />
          </div>
          <div className={'flex flex-col'}>
            <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 capitalize">
              {name}
            </p>
            {points !== undefined ? (
              <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                Immigots:{' '}
                <span className={'text-green-600 group-hover:text-green-700'}>
                  {points}P
                </span>
              </p>
            ) : null}
            <small className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
              View profile
            </small>
          </div>
        </div>
      </Link>
    </div>
  );
}

const navigation: NavigationElement[] = [
  {
    name: 'Home',
    href: () => '/home',
    icon: HomeIcon,
    event: 'Clicked home navbar button',
  },
  {
    name: 'Subscription',
    href: () => '/subscription',
    icon: ArrowPathIcon,
    event: 'Clicked subscription navbar button',
  },
  {
    name: 'Upgrade Plan',
    href: () => '/plans',
    icon: RocketLaunchIcon,
    event: 'Clicked plans navbar button',
  },
  {
    name: 'My Classes',
    href: () => '/classes',
    icon: AcademicCapIcon,
    event: 'Clicked my classes navbar button',
  },
  {
    name: 'Certificates',
    href: () => '/certificates',
    icon: StarIcon,
    event: 'Clicked certificates navbar button',
  },
  // {
  //   name: 'My reports',
  //   href: (userId: string) => `/reports/${userId}`,
  //   icon: ChartBarIcon,
  //   event: 'Clicked my reports navbar button',
  // },
  // {
  //   name: 'Meeting board',
  //   href: () => '/meeting-board',
  //   icon: CalendarIcon,
  //   event: 'Clicked meeting board navbar button',
  // },
];

type AppLayoutProps = {
  children: ReactNode;
  pageTitle: string;
};

export function AppLayout({ children, pageTitle }: AppLayoutProps) {
  const location = useLocation();
  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );
  const dispatch = useDispatch();
  const onSetUserData = (userData: any) =>
    dispatch({ type: 'USER_DATA_SET', userData });

  const isLoggedIn = !!userData?.id;

  // useCanny({
  //   id: userData?.id,
  //   email: userData?.email,
  //   name: userData?.email,
  //   createdAt: userData?.createdAt,
  //   avatarURL: userData?.profilePictureLink ?? '',
  // });

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const hasOnGoingCourses = (userData?.onGoingCourses?.length ?? [].length) > 0;

  return (
    <>
      <div className="w-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                    <div className="flex flex-shrink-0 items-center px-4">
                      <img
                        className="h-12 w-auto"
                        src="https://immigoimages.s3.amazonaws.com/Immigo_new_logo.png"
                        alt="Immigo logo"
                      />
                    </div>
                    <nav className="mt-5 space-y-1 px-2">
                      {navigation.map((item) => {
                        let href = item.href();
                        if (item.name === 'Courses' && !hasOnGoingCourses)
                          return null;

                        if (
                          item.name === 'Subscription' &&
                          !userData?.subProductID
                        )
                          return null;

                        if (href.includes('/reports/')) {
                          href = item.href(userData?.id ?? '');
                        }

                        return (
                          <Link
                            key={item.name}
                            to={href}
                            className={cx(
                              location.pathname.includes(href)
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                              'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                            )}
                            onClick={() => {
                              if (item.event)
                                trackLayOutEvent(item.event, {
                                  userID: userData?.id,
                                  userName: userData?.name,
                                  userEmail: userData?.email,
                                });
                            }}
                          >
                            <>
                              <item.icon
                                className={cx(
                                  location.pathname.includes(href)
                                    ? 'text-gray-500'
                                    : 'text-gray-400 group-hover:text-gray-500',
                                  'mr-4 flex-shrink-0 h-6 w-6',
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </>
                          </Link>
                        );
                      })}
                      {isLoggedIn ? (
                        <>
                          <a
                            onClick={() => {
                              trackLayOutEvent(
                                'Clicked additional info navbar button',
                                {
                                  userID: userData?.id,
                                  userName: userData?.name,
                                  userEmail: userData?.email,
                                },
                              );
                            }}
                            href={'/profile-setup'}
                            className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          >
                            <IdentificationIcon
                              className={cx(
                                'text-gray-400 group-hover:text-gray-500',
                                'mr-3 flex-shrink-0 h-6 w-6',
                              )}
                              aria-hidden="true"
                            />
                            Profile Information
                          </a>
                          {/* <a
                            onClick={() => {
                              trackLayOutEvent(
                                'Clicked availability navbar button',
                                {
                                  userID: userData?.id,
                                  userName: userData?.name,
                                  userEmail: userData?.email,
                                },
                              );
                            }}
                            href="/availability"
                            className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          >
                            <ClockIcon
                              className={cx(
                                'text-gray-400 group-hover:text-gray-500',
                                'mr-3 flex-shrink-0 h-6 w-6',
                              )}
                              aria-hidden="true"
                            />
                            Availability
                          </a> */}
                          {userData?.email?.endsWith('@immigo.io') && (
                            <a
                              href="/discount-codes"
                              className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            >
                              <BankNotesIcon
                                className={cx(
                                  'text-gray-400 group-hover:text-gray-500',
                                  'mr-3 flex-shrink-0 h-6 w-6',
                                )}
                                aria-hidden="true"
                              />
                              Discount Codes
                            </a>
                          )}
                          {/* <a
                            href="https://immigo.canny.io"
                            className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            onClick={() => {
                              trackCannyEvent(
                                'Click Canny link in navigation bar',
                                {
                                  userID: userData?.id,
                                  userName: userData?.name,
                                  userEmail: userData?.email,
                                },
                              );
                            }}
                          >
                            <MegaphoneIcon
                              className={cx(
                                'text-gray-400 group-hover:text-gray-500',
                                'mr-3 flex-shrink-0 h-6 w-6',
                              )}
                              aria-hidden="true"
                            />
                            Request Feature
                          </a> */}
                          {/* <button
                            type="button"
                            className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md w-full"
                            onClick={() => {
                              trackLayOutEvent(
                                'Clicked start a video call navbar button',
                                {
                                  userID: userData?.id,
                                  userName: userData?.name,
                                  userEmail: userData?.email,
                                },
                              );
                              openInNewTab(
                                generatePersonalMeetingLink(userData.id),
                              );
                            }}
                          >
                            <VideoCameraIcon
                              className={cx(
                                'text-gray-400 group-hover:text-gray-500',
                                'mr-3 flex-shrink-0 h-6 w-6',
                              )}
                            />
                            Start a video call
                          </button> */}
                        </>
                      ) : null}
                    </nav>
                  </div>
                  <button
                    type="button"
                    className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md w-full"
                    onClick={() => {
                      onSetUserData(undefined);
                      void signOut(auth);
                    }}
                  >
                    <ArrowRightOnRectangleIcon
                      className={cx(
                        'text-gray-400 group-hover:text-gray-500',
                        'mr-3 flex-shrink-0 h-6 w-6',
                      )}
                    />
                    Logout
                  </button>
                  <UserProfile
                    id={userData?.id ?? ''}
                    name={userData?.name}
                    avatar={userData?.profilePictureLink}
                    nationality={userData?.nationality ?? ''}
                    points={userData?.points}
                  />
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
              <div className="flex flex-shrink-0 items-center px-4">
                <img
                  className="h-12 w-auto"
                  src="https://immigoimages.s3.amazonaws.com/Immigo_new_logo.png"
                  alt="Your Company"
                />
              </div>
              <nav className="mt-5 flex-1 space-y-1 bg-white px-2">
                {navigation.map((item) => {
                  let href = item.href();
                  if (item.name === 'Courses' && !hasOnGoingCourses)
                    return null;
                  if (item.name === 'Subscription' && !userData?.subProductID)
                    return null;

                  if (
                    item.name === 'My Classes' &&
                    (!userData?.bookings || userData?.bookings.length <= 0)
                  )
                    return null;

                  if (href.includes('/reports/')) {
                    href = item.href(userData?.id ?? '');
                  }

                  return (
                    <Link
                      key={item.name}
                      to={href}
                      className={cx(
                        location.pathname.includes(href)
                          ? 'bg-gray-100 text-gray-900'
                          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                      )}
                      onClick={() => {
                        if (item.event)
                          trackLayOutEvent(item.event, {
                            userID: userData?.id,
                            userName: userData?.name,
                            userEmail: userData?.email,
                          });
                      }}
                    >
                      <>
                        <item.icon
                          className={cx(
                            location.pathname.includes(href)
                              ? 'text-gray-500'
                              : 'text-gray-400 group-hover:text-gray-500',
                            'mr-3 flex-shrink-0 h-6 w-6',
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </>
                    </Link>
                  );
                })}
                {isLoggedIn ? (
                  <>
                    <a
                      onClick={() => {
                        trackLayOutEvent(
                          'Clicked additional info navbar button',
                          {
                            userID: userData?.id,
                            userName: userData?.name,
                            userEmail: userData?.email,
                          },
                        );
                      }}
                      href={'/profile-setup'}
                      className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    >
                      <IdentificationIcon
                        className={cx(
                          'text-gray-400 group-hover:text-gray-500',
                          'mr-3 flex-shrink-0 h-6 w-6',
                        )}
                        aria-hidden="true"
                      />
                      Profile Information
                    </a>
                    {/* <a
                      onClick={() => {
                        trackLayOutEvent('Clicked availability navbar button', {
                          userID: userData?.id,
                          userName: userData?.name,
                          userEmail: userData?.email,
                        });
                      }}
                      href="/availability"
                      className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    >
                      <ClockIcon
                        className={cx(
                          'text-gray-400 group-hover:text-gray-500',
                          'mr-3 flex-shrink-0 h-6 w-6',
                        )}
                        aria-hidden="true"
                      />
                      Availability
                    </a> */}
                    {userData?.email?.endsWith('@immigo.io') && (
                      <a
                        href="/discount-codes"
                        className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      >
                        <BankNotesIcon
                          className={cx(
                            'text-gray-400 group-hover:text-gray-500',
                            'mr-3 flex-shrink-0 h-6 w-6',
                          )}
                          aria-hidden="true"
                        />
                        Discount Codes
                      </a>
                    )}
                    {userData?.email?.endsWith('@immigo.io') && (
                      <a
                        href="/data"
                        className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      >
                        <DocumentChartBarIcon
                          className={cx(
                            'text-gray-400 group-hover:text-gray-500',
                            'mr-3 flex-shrink-0 h-6 w-6',
                          )}
                          aria-hidden="true"
                        />
                        Data
                      </a>
                    )}
                    {/* <a
                      href="https://immigo.canny.io"
                      className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      onClick={() => {
                        trackCannyEvent('Click Canny link in navigation bar', {
                          userID: userData?.id,
                          userName: userData?.name,
                          userEmail: userData?.email,
                        });
                      }}
                    >
                      <MegaphoneIcon
                        className={cx(
                          'text-gray-400 group-hover:text-gray-500',
                          'mr-3 flex-shrink-0 h-6 w-6',
                        )}
                        aria-hidden="true"
                      />
                      Request Feature
                    </a> */}
                    {/* <button
                      type="button"
                      className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full"
                      onClick={() => {
                        trackLayOutEvent(
                          'Clicked start a video call navbar button',
                          {
                            userID: userData?.id,
                            userName: userData?.name,
                            userEmail: userData?.email,
                          },
                        );
                        openInNewTab(generatePersonalMeetingLink(userData.id));
                      }}
                    >
                      <VideoCameraIcon
                        className={cx(
                          'text-gray-400 group-hover:text-gray-500',
                          'mr-3 flex-shrink-0 h-6 w-6',
                        )}
                      />
                      Start a video call
                    </button> */}
                  </>
                ) : null}
              </nav>
            </div>
            <button
              type="button"
              className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full"
              onClick={() => {
                onSetUserData(undefined);
                void signOut(auth);
              }}
            >
              <ArrowRightOnRectangleIcon
                className={cx(
                  'text-gray-400 group-hover:text-gray-500',
                  'mr-3 flex-shrink-0 h-6 w-6',
                )}
              />
              Logout
            </button>
            {isLoggedIn ? (
              <UserProfile
                id={userData?.id}
                name={userData?.name}
                avatar={userData?.profilePictureLink}
                nationality={userData?.nationality ?? ''}
                points={userData?.points}
              />
            ) : null}
          </div>
        </div>
        <div className="flex flex-1 flex-col md:pl-64">
          <div className="sticky flex flex-row items-center top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden bg-white md:bg-none">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div>
              <img
                src="https://immigoimages.s3.amazonaws.com/Immigo_new_logo.png"
                className="h-12 w-auto"
                alt="Immigo logo"
              />
            </div>
          </div>
          <main className="flex-1">
            <div className="pb-6">
              <div className="mx-auto sm:px-6 md:px-8">
                {pageTitle ? (
                  <h1 className="pt-6 text-3xl text-gray-900 md:text-left text-center">
                    {pageTitle}
                  </h1>
                ) : null}
              </div>
              {children}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export function Content({ children }: { children: ReactNode }) {
  return <div className="mx-auto px-4 sm:px-6 md:px-8 mt-6">{children}</div>;
}
