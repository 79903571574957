import mixpanel from '../../analytics/mixpanel';

type Events =
  | 'Visited plan change page'
  | 'Selected learning plan in change plan page'
  | 'Clicked change plan button in change plan page'
  | 'Clicked choose different learning plan in change plan page'
  | 'Clicked change now in change plan page'
  | 'Clicked change end of billing period in change plan page'
  | 'Clicked payment method change in change plan page';

type PlanChangeData = {
  subOption?: number;
};

export const trackPlanChangePageEvents = (
  event: Events,
  data: PlanChangeData = {},
) => {
  mixpanel.track(event, data);
};
