import { ClockIcon, DocumentIcon } from '@heroicons/react/24/outline';
import { BookingData } from '../../../firebase/subscription/subscription';
import { IdiomAndExplanationContainerProp } from '../Idioms/idiomContainer';
import moment from 'moment';
import { PrimaryButton } from '../../../components/buttons/primary';
import { IdiomExplanationContainerLarge } from '../Idioms/idiomContainerLarge';
import { SimpleCarouselWithBottomArrows } from '../../../components/carousels/simpleCarouselWithBottomArrows';
import { ReactChild, useEffect, useState } from 'react';
import BlueAlert from '../../../components/alerts/blueAlert';
import { SecondaryButton } from '../../../components/buttons/secondary';
import { SecondaryBlueButton } from '../../../components/buttons/secondaryBlue';

export const ClassPreviewReviewPage = (props: {
  booking?: BookingData;
  idioms: Array<IdiomAndExplanationContainerProp>;
  onNextClick?: Function;
  onBackClick?: Function;
}) => {
  const { idioms, booking, onNextClick, onBackClick } = props;
  const [idiomBoxList, setIdiomBoxList] = useState<Array<ReactChild>>([]);

  useEffect(() => {
    if (idioms) {
      const newIdiomBoxList = idioms.map(
        (idiom: IdiomAndExplanationContainerProp, index: number) => {
          return (
            <div key={idiom.phrase}>
              <IdiomExplanationContainerLarge
                key={idiom.phrase}
                phrase={idiom.phrase}
                definition={idiom.definition}
                example={idiom.example}
              />
            </div>
          );
        },
      );
      setIdiomBoxList(newIdiomBoxList);
    }
  }, [idioms]);

  return (
    <div>
      <div className="mt-2 text-md text-gray-500">
        <BlueAlert
          text={`🧑‍🎓️ Before you preview your next class, take 5 minutes to review your last class first to solidify your learning.`}
        />
      </div>
      <div className="my-4 p-4 rounded-md border border-gray-200 my-4">
        <div className="flex items-center mb-2">
          <DocumentIcon className="w-5 h-5 mr-2" />
          <div>
            <a
              target="_blank"
              href={booking?.slide}
              className="text-blue-immigo"
              rel="noreferrer"
            >
              Review the class material
            </a>
          </div>
        </div>
        <div className="flex items-center">
          <ClockIcon className="w-5 h-5 mr-2" />
          <div className="text-gray-500">
            {`You took this class ${moment(booking?.startMili)
              .local()
              .format('MMMM DD, YYYY hh:mm A')}`}
          </div>
        </div>
      </div>
      <div className="mb-8">
        {idiomBoxList.length > 0 ? (
          <SimpleCarouselWithBottomArrows items={idiomBoxList} />
        ) : null}
      </div>
      <div className="mb-2">
        <PrimaryButton
          text="I'm done reviewing!"
          onClick={() => {
            onNextClick?.();
          }}
        />
      </div>
      <SecondaryBlueButton
        text="Back"
        onClick={() => {
          onBackClick?.();
        }}
      />
    </div>
  );
};
