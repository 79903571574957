import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import RecordRTC, {
  RecordRTCPromisesHandler,
  StereoAudioRecorder,
} from 'recordrtc';
import { PrimaryButton } from '../../components/buttons/primary';
import { SecondaryBlueButton } from '../../components/buttons/secondaryBlue';
import { PauseCircleIcon } from '@heroicons/react/24/outline';
import { Correction, CorrectionDocument } from '../../types/feedback';
import {
  addCorrection,
  addTranscript,
  createCorrectionDocument,
  getCorrectionsFromBooking,
} from '../../firebase/feedback/correction';
import { getBookingDocument } from '../../firebase/subscription/bookings/bookings';
import { BookingData } from '../../firebase/subscription/subscription';
import { CountDownToTimer } from '../../components/timer/countDownToTimer';
import VerticalMessageModal from '../../components/modals/verticalMessageModal/verticalMessageModal';
import { trackFeedBackListenerEvents } from '../../features/FeedbackListener/analytics';
import { saveAs } from 'file-saver';
import { CorrectionsRenderer } from '../../features/FeedbackListener/Corrections/CorrectionsRenderer';
import { SpeechMaticTranscriptResult } from '../../types/speechmatic';
import {
  parseFormattedTranscriptString,
  returnFormattedStringsGivenResultsArray,
} from '../../util/speechmaticDataHandler';
import moment from 'moment-timezone';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com'
    : 'http://localhost:8080'; // Dev env

export const FeedbackListener = () => {
  const [socket, setSocket] = useState<undefined | WebSocket>(undefined);
  const [text, setText] = useState('');
  const [resultsArray, setResultsArray] = useState<
    Array<SpeechMaticTranscriptResult>
  >([]);
  const [formattedTranscriptArray, setFormattedTranscriptArray] = useState<
    Array<string>
  >([]);
  const [incomingResults, setIncomingResults] = useState<
    Array<SpeechMaticTranscriptResult>
  >([]);
  const [incomingText, setIncomingText] = useState('');
  const [correctionArray, setCorrectionArray] = useState<Array<Correction>>([]);

  const [transcribing, setTranscribing] = useState(false);
  const [seqNo, setSeqNo] = useState(0);
  const [correctionDoc, setCorrectionDoc] = useState<
    CorrectionDocument | undefined
  >();
  const [bookingDoc, setBookingDoc] = useState<BookingData | undefined>();
  const [partialTranscript, setPartialTranscript] = useState('');
  const [correctionIndex, setCorrectionIndex] = useState(0);

  const recordRef = useRef<RecordRTC>();

  const urlParams = new URLSearchParams(window.location.search);
  const bookingID = urlParams.get('bid');

  const startMili = bookingDoc?.startMili;
  const durationMili = 1000 * 60 * 50;
  let inProgress =
    startMili !== undefined &&
    startMili <= new Date().valueOf() &&
    startMili + durationMili >= new Date().valueOf();

  const [classStarted, setClassStarted] = useState(inProgress);
  const [ready, setReady] = useState(false);
  const [classEnded, setClassEnded] = useState(
    startMili && startMili + durationMili < new Date().valueOf(),
  );

  async function onIncomingText(incomingText: string) {
    // Need to include question mark
    const punctReg = new RegExp(/[?.!,]/);
    let originalText = text;
    let newTranscript = incomingText[0]?.match(punctReg)
      ? originalText.trim() + incomingText
      : originalText + incomingText;
    let newCorrection: Correction | undefined;

    // if (punctReg.test(incomingText[0])) {
    //   // If the first letter is a punctuation, we have to trim the original
    //   originalText = originalText.trim();
    //   newTranscript = originalText + incomingText;
    // }

    const sentenceArray = newTranscript ? newTranscript.split(/[?.!]/) : [];

    if (correctionIndex < sentenceArray.length - 1) {
      // If a sentence was ended
      // // If end of the sentence was detected (Remove comma)
      // const punctIndex = incomingText.match(/[?.!]/)?.index || 0; // This will always be some value, because punctReg.test should find
      // const toBeCorrected = newTranscript
      //   .slice(lastSentenceIndex, originalText.length + punctIndex + 1)
      //   .trim();

      // setLastSentenceIndex(originalText.length + punctIndex + 1);

      // if (toBeCorrected.split(' ').length > 2)
      //   newCorrection = await getCorrection(toBeCorrected);

      await Promise.all(
        sentenceArray
          .slice(correctionIndex, sentenceArray.length - 1)
          .map(async (uncorrectedSentence) => {
            console.log(uncorrectedSentence?.trim());
            newCorrection = await getCorrection(uncorrectedSentence?.trim());
            if (newCorrection && correctionDoc?.id) {
              newCorrection.correctedAt = new Date().valueOf();
              await addCorrection(correctionDoc.id, newCorrection);
            }
          }),
      );

      console.log(sentenceArray);

      setCorrectionIndex(sentenceArray.length - 1);
    }

    if (incomingText && correctionDoc?.id)
      await addTranscript(correctionDoc.id, incomingText);

    setText(newTranscript);
  }

  async function getCorrection(
    toBeCorrected: string,
  ): Promise<Correction | undefined> {
    let tobeCorrectedArray = toBeCorrected.split(' ');
    if (tobeCorrectedArray.length < 2) return; // Things like "Yeah" , "Okay" should not be corrected
    const response = await axios.post(`${endPoint}/feedback/getCorrection`, {
      text: toBeCorrected,
    });
    let correctionPair: Correction;

    let correction = response?.data?.correction;
    let explanation = response?.data?.explanation;
    if (correction?.[correction?.length - 1]?.match(/[?.!,]/))
      correction = correction.slice(0, correction.length - 1);

    console.log('Correction: ' + toBeCorrected);

    if (
      !correction ||
      !toBeCorrected ||
      correction?.toLowerCase().trim() ===
        toBeCorrected?.toLowerCase().trim() ||
      toBeCorrected?.includes(correction) ||
      correction?.includes(toBeCorrected) || // If this is an extension, it's not a correction
      explanation?.includes('repeat') || // If the advice is just repetition, it's not important
      explanation?.includes('duplicate') ||
      !explanation // No explanation -> already correct
    )
      return;
    else {
      correctionPair = {
        original: toBeCorrected,
        fixed: correction,
      };
      if (explanation) correctionPair.explanation = explanation;
      console.log(correctionPair);
    }

    // New correction array
    const newCorrections = [...correctionArray, correctionPair];
    setCorrectionArray(newCorrections);

    // Need to save correction

    return correctionPair;
  }

  async function updateCorrectionDocument() {
    if (!bookingID) return;

    let bookingDoc = (await getBookingDocument(bookingID)) as BookingData;

    if (!bookingDoc) return;

    let correctionDoc = (await getCorrectionsFromBooking(
      bookingID,
    )) as CorrectionDocument;

    if (!correctionDoc) {
      const bookerID = bookingDoc.bookerID;
      correctionDoc = await createCorrectionDocument(bookerID, bookingID);
    }

    if (correctionDoc.corrections)
      setCorrectionArray(correctionDoc.corrections);
    if (correctionDoc.transcript) setText(correctionDoc.transcript);
    if (correctionDoc.formattedTranscriptArray)
      setFormattedTranscriptArray(correctionDoc.formattedTranscriptArray);

    setBookingDoc(bookingDoc);
    setCorrectionDoc(correctionDoc);
  }

  async function establistConnection() {
    const jwtData = await axios.post(`${endPoint}/database/auth_speechmatics`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${'aYHv1cxVszvnCv7CoVWeqOh7O8ccP5Aq'}`,
      },
    });
    await updateCorrectionDocument(); // Update the correction data
    const jwt = jwtData?.data?.data?.key_value;
    const socketClient = new WebSocket(
      `wss://eu.rt.speechmatics.com/v2/en?jwt=${jwt}`,
    );

    socketClient.onopen = function (e) {
      console.log('[open] Connection established');
      console.log('Sending to server');
      socketClient.send(
        `{"message": "StartRecognition", 
          "audio_format": {"type": "raw", "encoding": "pcm_s16le", "sample_rate": 16000},
          "transcription_config": {
            "language": "en", 
            "operating_point": "enhanced", 
            "enable_entities": true, 
            "diarization": "speaker", 
            "speaker_diarization_config": { "max_speakers": 10 }, 
            "enable_partials": true, 
            "max_delay": 5,
            "max_delay_mode": "flexible",
            "punctuation_overrides": {
              "permitted_marks": [
                ".",
                ","
              ],
              "sensitivity": 0.5
            },
            "additional_vocab": [
              {
                "content": "Immigo",
                "sounds_like": [
                  "Mingo",
                  "amigo",
                  "in amigo"
                ]
              },
              {
                "content": "firmly",
                "sounds_like": [
                  "family"
                ]
              }
            ]
          }
        }`,
      );
      setReady(true);
    };

    socketClient.onmessage = function (event) {
      const data = JSON.parse(event.data);

      const { message } = data;

      switch (message) {
        case 'AddTranscript':
          setIncomingText(data?.metadata?.transcript);
          if (data?.results) setIncomingResults(data.results);
          console.log(data);
          break;
        case 'RecognitionStarted':
          setReady(true);
          break;
        case 'AddPartialTranscript':
          setPartialTranscript(data?.metadata?.transcript);
          break;
        case 'AudioAdded':
          setSeqNo(data?.seq_no);
          break;
        default:
      }
    };

    setSocket(socketClient);
  }

  async function startRecording() {
    if (!socket || !ready) return;
    let recordAudio: RecordRTC;
    if (!recordRef.current) {
      await navigator.mediaDevices
        .getUserMedia({ video: false, audio: true })
        .then((stream) => {
          // @ts-ignore
          recordAudio = new RecordRTCPromisesHandler(stream, {
            type: 'audio',
            mimeType: 'audio/wav', // sampleRate option is the "device's sample rate", use desiredSampleRate to convert sampling frequency
            desiredSampRate: 16000, // This sampling rate has to match with the sample rate to Speechmatic
            recorderType: StereoAudioRecorder,
            numberOfAudioChannels: 1,
            timeSlice: 2000,

            ondataavailable: async function (blob: Blob) {
              if (socket) {
                socket.send(blob);
              }
            },
          });
          recordRef.current = recordAudio;
          recordAudio.startRecording();
        });
    } else {
      recordRef.current.resumeRecording();
    }

    setTranscribing(true);
  }

  async function pauseRecording() {
    if (!socket || !recordRef.current) return;

    try {
      await recordRef.current?.pauseRecording();
      setTranscribing(false);
    } catch {
      console.log('Recording failed');
    }
  }

  async function stopRecording() {
    if (!socket || !recordRef.current || !ready) return;
    else {
      console.log(ready);
    }
    try {
      await socket.send(`{"message":"EndOfStream","last_seq_no":${seqNo}}`); // End the stream
      await recordRef.current?.stopRecording();

      const blob: Blob = await recordRef.current.getBlob();

      const videoFile = new File([blob], 'recording.wav', {
        type: 'video/webm;codecs=vp9',
      });
      saveAs(videoFile);
    } catch {
      console.log('Recording failed');
    }
  }

  useEffect(() => {
    establistConnection();
    if (classEnded)
      trackFeedBackListenerEvents(
        'Visited the Immigo AI correction page after class ended',
      );
    else trackFeedBackListenerEvents('Visited Immigo AI correction page');
  }, []);

  useEffect(() => {
    onIncomingText(incomingText);
  }, [incomingText]);

  useEffect(() => {
    const newArray = [...resultsArray, ...incomingResults];
    setResultsArray(newArray);
    setFormattedTranscriptArray(
      returnFormattedStringsGivenResultsArray(newArray),
    );
  }, [incomingResults]);

  return (
    <div>
      <VerticalMessageModal
        mainMessage="Please put your headphones on!"
        subMessage="In order to accurately capture voice and no one elses, we need you to wear a pair of headphones"
        buttonText="I have them on!"
        theme="info"
        icon="exclamation"
        isOpen={bookingDoc !== undefined && ready && !classEnded}
      />
      {bookingDoc?.startMili && bookingDoc?.durationMili ? (
        <CountDownToTimer
          countDownTo={bookingDoc.startMili + bookingDoc.durationMili}
          onTimeChange={(time: number) => {
            if (time <= bookingDoc.durationMili + 1000 * 60 * 10)
              setClassStarted(true); // Allow recording 10 minutes before
          }}
          onTimeUp={async () => {
            setClassEnded(true);
            await stopRecording(); // Stop the transcription when the class ends
          }}
        />
      ) : null}
      <main className="flex flex-col-reverse h-[calc(100vh-160px)] md:h-[calc(100vh-110px)] xl:mr-96">
        {
          // If class ended don't show the button
          !bookingID || (bookingDoc && !classEnded) ? (
            <div className="flex flex-col bottom-0 w-full border-t border-gray-200 gap-2 mt-2 pt-6 md:hidden block left-0 p-2">
              <PrimaryButton
                loading={!ready}
                text={transcribing ? 'Pause' : 'Record'}
                onClick={transcribing ? pauseRecording : startRecording}
                disabled={!classStarted && bookingID !== null}
              />
              <SecondaryBlueButton text="Stop" onClick={stopRecording} />
            </div>
          ) : null
        }
        {correctionDoc?.id ? (
          <CorrectionsRenderer
            correctionID={correctionDoc.id}
            classStarted={classStarted}
          />
        ) : (
          <CorrectionsRenderer
            correctionID={'none'}
            classStarted={classStarted}
            replacedArray={correctionArray}
          />
        )}
      </main>

      <aside className="h-screen fixed flex-col-reverse inset-y-0 right-0 hidden w-96 overflow-y-auto border-l border-gray-200 px-4 py-6 sm:px-6 lg:px-8 md:flex">
        {
          // If class ended don't show the button
          !bookingID || (bookingDoc && !classEnded) ? (
            <div className="flex flex-shrink-0 border-t border-gray-200 gap-2 mt-2">
              <PrimaryButton
                loading={!ready}
                text={transcribing ? 'Pause' : 'Record'}
                onClick={transcribing ? pauseRecording : startRecording}
                disabled={!classStarted && bookingID !== null}
              />
              <SecondaryBlueButton text="Stop" onClick={stopRecording} />
            </div>
          ) : null
        }
        <div className="flex-1">
          <div className="flex items-center">
            {transcribing ? (
              <span className="relative flex h-3 w-3 mr-2">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
              </span>
            ) : (
              <span>
                <PauseCircleIcon className="w-5 h-5 text-red-500 mr-2" />
              </span>
            )}

            <div className="text-xl text-gray-700">Transcript</div>
          </div>

          <div className="overflow-y-scroll mt-5 flex-1 space-y-1 px-2">
            {formattedTranscriptArray?.length > 0
              ? formattedTranscriptArray.map((t: string) => {
                  const { content, endSeconds, startSeconds, createdAt } =
                    parseFormattedTranscriptString(t);
                  return (
                    <div>
                      <span className="text-gray-500">{`
                  [${moment(createdAt).format('HH:mm:ss')}] `}</span>
                      <span
                        onClick={() => {
                          const obj: HTMLAudioElement = document.getElementById(
                            t,
                          ) as HTMLAudioElement;
                          obj.currentTime = startSeconds - 1; // buffer
                          const endTime = endSeconds + 1;
                          obj.addEventListener('timeupdate', function () {
                            if (this.currentTime >= endTime) {
                              this.pause();
                            }
                          });
                          obj.play();
                        }}
                      >
                        {content}
                      </span>
                      <audio
                        id={t}
                        src={bookingDoc?.classVoiceRecordingURL}
                        controls
                      />
                    </div>
                  );
                })
              : text}
            <div className="mt-4">
              {transcribing ? (
                <span className=" animate-pulse text-gray-500">
                  {partialTranscript}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};
