export const WarningButton = (props: { text: string; onClick: Function }) => {
  return (
    <button
      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
      onClick={(e) => props.onClick(e)}
    >
      {props.text}
    </button>
  );
};
