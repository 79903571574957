import { usdToX } from '../util/currency';
import { stringsPricingPage } from '../util/localization';
import {
  fiveDollarPerClassAnnualThreeDollars,
  oneDollarPerClass,
  threeDollarPerClass,
  threeDollarPerClassAnnualTwoDollars,
} from './stripe/coupons';

export interface SubTierPriceMap {
  [key: string]: number;
}

export interface SubTierDiscountMap {
  [key: string]: number;
}

export interface SubTier {
  name: string;
  id: string;
  classesCount: number;
  price: SubTierPriceMap;
  description: string;
  features: Array<string>;
  mostPopular?: boolean;
  onSelect?: Function;
  hideSelect?: boolean;
  discount?: SubTierDiscountMap;
}

interface ClassSubscriptionMap {
  [key: string]: {
    classesPerMonth: number;
  };
}

const classesSubscriptionProductMapDev: ClassSubscriptionMap = {
  prod_QFHN38GA17u6BW: {
    classesPerMonth: 16,
  },
  prod_NhDdfQFQQDvu3G: {
    classesPerMonth: 12,
  },
  prod_NhDdlgyNWc1ZPy: {
    classesPerMonth: 8,
  },
  prod_NhDc3w7l1888lF: {
    classesPerMonth: 4,
  },
};

const classesSubscriptionProductMapProd: ClassSubscriptionMap = {
  prod_QFJgXFXE8DLlxK: {
    classesPerMonth: 16,
  },
  prod_NhbJL5pc7tnMmA: {
    classesPerMonth: 12,
  },
  prod_NhbIaoPruicotb: {
    classesPerMonth: 8,
  },
  prod_NhbISr8wFS9tIT: {
    classesPerMonth: 4,
  },
};

export const classesSubscriptionProductMap =
  process.env.REACT_APP_ENV === 'PROD'
    ? classesSubscriptionProductMapProd
    : classesSubscriptionProductMapDev;

export const couponMap: any = {
  becaenglishexperts: oneDollarPerClass,
  keith: threeDollarPerClassAnnualTwoDollars,
  sofi: oneDollarPerClass,
  omar: oneDollarPerClass,
  byte: oneDollarPerClass,
  santiago: oneDollarPerClass,
  'juan-limon': oneDollarPerClass,
  immigo: oneDollarPerClass,
  'daniel-yanez': oneDollarPerClass,
  kolibries: oneDollarPerClass,
  studyisel: oneDollarPerClass,
  luz: oneDollarPerClass,
  velu: oneDollarPerClass,
  leila: oneDollarPerClass,
  hispanic: oneDollarPerClass,
  ferchus: oneDollarPerClass,
  b82d0f: oneDollarPerClass,
  marcela: oneDollarPerClass,
  john: oneDollarPerClass,
  alex: oneDollarPerClass,
  eylul: oneDollarPerClass,
  josielly: oneDollarPerClass,
  latinos: oneDollarPerClass,
  karen: oneDollarPerClass,
  isadora: threeDollarPerClass,
  latina: threeDollarPerClass,
  primos: threeDollarPerClass,
  remoto: threeDollarPerClass,
  wifi: threeDollarPerClass,
  david: threeDollarPerClass,
  naek: threeDollarPerClass,
  daniela: threeDollarPerClass,
  '1e64f8': threeDollarPerClass,
  resat: threeDollarPerClass,
  ximena: threeDollarPerClass,
  alokroto: threeDollarPerClass,
  alejo: threeDollarPerClass,
  jovanna: threeDollarPerClass,
  kombi: threeDollarPerClass,
  ruta: threeDollarPerClass,
  dalto: threeDollarPerClass,
  diegoveloper: threeDollarPerClass,
  clara: threeDollarPerClass,
  voovlog: threeDollarPerClass,
  jared: threeDollarPerClassAnnualTwoDollars,
  'immigo-star': threeDollarPerClassAnnualTwoDollars,
  resilentos: threeDollarPerClassAnnualTwoDollars,
  'talent-match': threeDollarPerClassAnnualTwoDollars,
  cayspro: threeDollarPerClassAnnualTwoDollars,
  carlos: threeDollarPerClassAnnualTwoDollars,
  'vida-programador': threeDollarPerClassAnnualTwoDollars,
  jorge: threeDollarPerClassAnnualTwoDollars,
  paloma: threeDollarPerClassAnnualTwoDollars,
  polygloteen: threeDollarPerClassAnnualTwoDollars,
  didi: threeDollarPerClassAnnualTwoDollars,
  chesstudy: threeDollarPerClassAnnualTwoDollars,
  alan: threeDollarPerClassAnnualTwoDollars,
  yoliene: threeDollarPerClassAnnualTwoDollars,
  colombia: threeDollarPerClassAnnualTwoDollars,
  elena: threeDollarPerClassAnnualTwoDollars,
  zaarah: threeDollarPerClassAnnualTwoDollars,
  sebas: threeDollarPerClassAnnualTwoDollars,
  gonza: threeDollarPerClassAnnualTwoDollars,
  camilo: threeDollarPerClassAnnualTwoDollars,
  alitas: threeDollarPerClassAnnualTwoDollars,
  shux: threeDollarPerClassAnnualTwoDollars,
  swing: threeDollarPerClassAnnualTwoDollars,
  financia: threeDollarPerClassAnnualTwoDollars,
  pao: threeDollarPerClassAnnualTwoDollars,
  arabe: threeDollarPerClassAnnualTwoDollars,
  gabino: threeDollarPerClassAnnualTwoDollars,
  code: threeDollarPerClassAnnualTwoDollars,
  fazt: threeDollarPerClassAnnualTwoDollars,
  nicolas: threeDollarPerClassAnnualTwoDollars,
  forcuban: threeDollarPerClassAnnualTwoDollars,
  rosy: threeDollarPerClassAnnualTwoDollars,
  ezequiel: threeDollarPerClassAnnualTwoDollars,
  castro: threeDollarPerClassAnnualTwoDollars,
  maybemar: threeDollarPerClassAnnualTwoDollars,
  reyes: threeDollarPerClassAnnualTwoDollars,
  mava: threeDollarPerClassAnnualTwoDollars,
  mundos: threeDollarPerClassAnnualTwoDollars,
  espresso: threeDollarPerClassAnnualTwoDollars,
  chris: threeDollarPerClassAnnualTwoDollars,
  betania: threeDollarPerClassAnnualTwoDollars,

  maissi: threeDollarPerClassAnnualTwoDollars,
  'todo-code': threeDollarPerClassAnnualTwoDollars,
  ingrid: threeDollarPerClassAnnualTwoDollars,
  emilio: threeDollarPerClassAnnualTwoDollars,
  proto: threeDollarPerClassAnnualTwoDollars,
  cano: threeDollarPerClassAnnualTwoDollars,
  fogonix: threeDollarPerClassAnnualTwoDollars,

  manuelca: threeDollarPerClassAnnualTwoDollars,
  'vani-mati': threeDollarPerClassAnnualTwoDollars,
  yuligrafia: threeDollarPerClassAnnualTwoDollars,
  'gaby-castro': threeDollarPerClassAnnualTwoDollars,

  'luis-angel': threeDollarPerClassAnnualTwoDollars,

  trippar: threeDollarPerClassAnnualTwoDollars,
  marinaangiolini: threeDollarPerClassAnnualTwoDollars,
  laura: threeDollarPerClassAnnualTwoDollars,
  camallerys: threeDollarPerClassAnnualTwoDollars,
  'alejo-jobs': threeDollarPerClassAnnualTwoDollars,
  fazttech: threeDollarPerClassAnnualTwoDollars,
  faztcode: threeDollarPerClassAnnualTwoDollars,
  pika: threeDollarPerClassAnnualTwoDollars,
  maybemarhs: threeDollarPerClassAnnualTwoDollars,
  'espresso-lungo': threeDollarPerClassAnnualTwoDollars,

  'isadora-vera': threeDollarPerClassAnnualTwoDollars,
  elenyork: threeDollarPerClassAnnualTwoDollars,
  valeria: threeDollarPerClassAnnualTwoDollars,
  'anita-mateu': threeDollarPerClassAnnualTwoDollars,

  elisa: threeDollarPerClassAnnualTwoDollars,
  abeejita: threeDollarPerClassAnnualTwoDollars,
  estival: threeDollarPerClassAnnualTwoDollars,
  leehxd: threeDollarPerClassAnnualTwoDollars,
  anshin: threeDollarPerClassAnnualTwoDollars,
  caio: threeDollarPerClassAnnualTwoDollars,
  tropiezos: threeDollarPerClassAnnualTwoDollars,
  eze: threeDollarPerClassAnnualTwoDollars,
  sofia: threeDollarPerClassAnnualTwoDollars,
  thevoovlog: threeDollarPerClassAnnualTwoDollars,
  priceit: threeDollarPerClassAnnualTwoDollars,
  lau: threeDollarPerClassAnnualTwoDollars,
  'pao-tips': threeDollarPerClassAnnualTwoDollars,
  'super-teacher': threeDollarPerClassAnnualTwoDollars,

  'fazt-web': threeDollarPerClassAnnualTwoDollars,
  toni: threeDollarPerClassAnnualTwoDollars,
  esparza: threeDollarPerClassAnnualTwoDollars,
  'pika-codes': threeDollarPerClassAnnualTwoDollars,
  francken: threeDollarPerClassAnnualTwoDollars,
  martin: threeDollarPerClassAnnualTwoDollars,
  mircha: threeDollarPerClassAnnualTwoDollars,

  grazi: threeDollarPerClassAnnualTwoDollars,
  'remote-work': threeDollarPerClassAnnualTwoDollars,
  gabi: threeDollarPerClassAnnualTwoDollars,
  wendy: threeDollarPerClassAnnualTwoDollars,
  marcelaengo: threeDollarPerClassAnnualTwoDollars,
  lucero: threeDollarPerClassAnnualTwoDollars,
  cintia: threeDollarPerClassAnnualTwoDollars,
  veronica: threeDollarPerClassAnnualTwoDollars,

  moni: threeDollarPerClassAnnualTwoDollars,
  canela: threeDollarPerClassAnnualTwoDollars,
  tripledobleu: threeDollarPerClassAnnualTwoDollars,
  anny: threeDollarPerClassAnnualTwoDollars,
  freelancer: threeDollarPerClassAnnualTwoDollars,

  federico: threeDollarPerClassAnnualTwoDollars,
  hdeleon: threeDollarPerClassAnnualTwoDollars,
  pamsho: threeDollarPerClassAnnualTwoDollars,
  mictalks: threeDollarPerClassAnnualTwoDollars,
  elly: threeDollarPerClassAnnualTwoDollars,
  'adriana-tech': threeDollarPerClassAnnualTwoDollars,
  adrijs: threeDollarPerClassAnnualTwoDollars,
  carla: threeDollarPerClassAnnualTwoDollars,

  brian: threeDollarPerClassAnnualTwoDollars,
  elif: threeDollarPerClassAnnualTwoDollars,
  ingeniela: threeDollarPerClassAnnualTwoDollars,
  anderson: threeDollarPerClassAnnualTwoDollars,
  maria: threeDollarPerClassAnnualTwoDollars,
  olivia: threeDollarPerClassAnnualTwoDollars,
  ana: threeDollarPerClassAnnualTwoDollars,

  brujula: threeDollarPerClassAnnualTwoDollars,
  adevsays: threeDollarPerClassAnnualTwoDollars,
  linkfy: threeDollarPerClassAnnualTwoDollars,

  r2d2: threeDollarPerClassAnnualTwoDollars,
  cris: threeDollarPerClassAnnualTwoDollars,
  yasmina: threeDollarPerClassAnnualTwoDollars,
  'dev-steph': threeDollarPerClassAnnualTwoDollars,

  dani: threeDollarPerClassAnnualTwoDollars,
  mimi: threeDollarPerClassAnnualTwoDollars,
  samxntha: threeDollarPerClassAnnualTwoDollars,
  leandro: threeDollarPerClassAnnualTwoDollars,
  'ana-carolina': threeDollarPerClassAnnualTwoDollars,
  edward: threeDollarPerClassAnnualTwoDollars,

  programarc: threeDollarPerClassAnnualTwoDollars,
  todocode: threeDollarPerClassAnnualTwoDollars,
  'study-ng': threeDollarPerClassAnnualTwoDollars,
  'code-cret': threeDollarPerClassAnnualTwoDollars,
  'kristel-tech': threeDollarPerClassAnnualTwoDollars,
  'code-carol': threeDollarPerClassAnnualTwoDollars,
  'solo-arled': threeDollarPerClassAnnualTwoDollars,
  'bus-codes': threeDollarPerClassAnnualTwoDollars,
  'elif-code': threeDollarPerClassAnnualTwoDollars,
  'code-syla': threeDollarPerClassAnnualTwoDollars,

  anacarolinatz: threeDollarPerClassAnnualTwoDollars,
  berkin: threeDollarPerClassAnnualTwoDollars,
  zeynep: threeDollarPerClassAnnualTwoDollars,

  nikorasu: threeDollarPerClassAnnualTwoDollars,
  francisco: threeDollarPerClassAnnualTwoDollars,
  'primo-dev': threeDollarPerClassAnnualTwoDollars,
  'hina-tech': threeDollarPerClassAnnualTwoDollars,
  alfy: threeDollarPerClassAnnualTwoDollars,
  'coding-kite': threeDollarPerClassAnnualTwoDollars,
  ojala: threeDollarPerClassAnnualTwoDollars,

  faztweb: threeDollarPerClassAnnualTwoDollars,
  paotips: threeDollarPerClassAnnualTwoDollars,
  mariana: threeDollarPerClassAnnualTwoDollars,
  viajoytrabajo: threeDollarPerClassAnnualTwoDollars,
  callmejorge: threeDollarPerClassAnnualTwoDollars,
  leon: threeDollarPerClassAnnualTwoDollars,
  todo: threeDollarPerClassAnnualTwoDollars,
  gabibalbuena: threeDollarPerClassAnnualTwoDollars,
  villagomez: threeDollarPerClassAnnualTwoDollars,
  priceitco: threeDollarPerClassAnnualTwoDollars,
  doomling: threeDollarPerClassAnnualTwoDollars,
  marengo: threeDollarPerClassAnnualTwoDollars,
  maybe: threeDollarPerClassAnnualTwoDollars,
  denegri: threeDollarPerClassAnnualTwoDollars,
  luigi: threeDollarPerClassAnnualTwoDollars,

  migma: threeDollarPerClassAnnualTwoDollars,
  londono: threeDollarPerClassAnnualTwoDollars,
  andrea: threeDollarPerClassAnnualTwoDollars,
  stephie: threeDollarPerClassAnnualTwoDollars,
  aziel: threeDollarPerClassAnnualTwoDollars,

  'pamsho-dev': threeDollarPerClassAnnualTwoDollars,
  leeh: threeDollarPerClassAnnualTwoDollars,
  'ana-velez': threeDollarPerClassAnnualTwoDollars,
  ikuro: threeDollarPerClassAnnualTwoDollars,
  'munon-code': threeDollarPerClassAnnualTwoDollars,
  'fazt-tech': threeDollarPerClassAnnualTwoDollars,

  anacarolina: threeDollarPerClassAnnualTwoDollars,
  fazt90: threeDollarPerClassAnnualTwoDollars,
  arturo: threeDollarPerClassAnnualTwoDollars,
  camino: threeDollarPerClassAnnualTwoDollars,
  gentleman: threeDollarPerClassAnnualTwoDollars,
  devtalles: threeDollarPerClassAnnualTwoDollars,
  belenrey: threeDollarPerClassAnnualTwoDollars,
  lopera: threeDollarPerClassAnnualTwoDollars,

  grazitech: threeDollarPerClassAnnualTwoDollars,
  'fazt-dev': threeDollarPerClassAnnualTwoDollars,
  thevisagirl: threeDollarPerClassAnnualTwoDollars,
  fioreintech: threeDollarPerClassAnnualTwoDollars,
  devgusta: threeDollarPerClassAnnualTwoDollars,
  elisadev: threeDollarPerClassAnnualTwoDollars,
  erickdev: threeDollarPerClassAnnualTwoDollars,
  devgio: threeDollarPerClassAnnualTwoDollars,
  'victor-dev': threeDollarPerClassAnnualTwoDollars,
  'sousa-dev': threeDollarPerClassAnnualTwoDollars,
  'rafa-dev': threeDollarPerClassAnnualTwoDollars,
  'eve-devs': threeDollarPerClassAnnualTwoDollars,

  'bia-coding': threeDollarPerClassAnnualTwoDollars,
  nico: threeDollarPerClassAnnualTwoDollars,
  faztdev: threeDollarPerClassAnnualTwoDollars,
  empaque: threeDollarPerClassAnnualTwoDollars,
  'tarre-dev': threeDollarPerClassAnnualTwoDollars,
  karla: threeDollarPerClassAnnualTwoDollars,
  maripydev: threeDollarPerClassAnnualTwoDollars,
  'pablo-dev': threeDollarPerClassAnnualTwoDollars,
  'maissi-tech': threeDollarPerClassAnnualTwoDollars,

  'nicolas-dev': threeDollarPerClassAnnualTwoDollars,
  hector: threeDollarPerClassAnnualTwoDollars,
  paola: threeDollarPerClassAnnualTwoDollars,
  remotework: threeDollarPerClassAnnualTwoDollars,
  'adri-js': threeDollarPerClassAnnualTwoDollars,
  nita: threeDollarPerClassAnnualTwoDollars,
  'ariana-scholarships': threeDollarPerClassAnnualTwoDollars,
  'lauti-js': threeDollarPerClassAnnualTwoDollars,
  triple: threeDollarPerClassAnnualTwoDollars,
  paula: threeDollarPerClassAnnualTwoDollars,
  darle: threeDollarPerClassAnnualTwoDollars,
  'gabi-balbuena': threeDollarPerClassAnnualTwoDollars,
  pildoras: threeDollarPerClassAnnualTwoDollars,
  oprimo: threeDollarPerClassAnnualTwoDollars,
  'teacher-iliana': threeDollarPerClassAnnualTwoDollars,

  'fazt-code': threeDollarPerClassAnnualTwoDollars,
  'ana-ramirez': threeDollarPerClassAnnualTwoDollars,
  pamshodev: threeDollarPerClassAnnualTwoDollars,
  'camino-dev': threeDollarPerClassAnnualTwoDollars,
  gabriela: threeDollarPerClassAnnualTwoDollars,
  'laura-remote': threeDollarPerClassAnnualTwoDollars,
  olesia: threeDollarPerClassAnnualTwoDollars,
  'yasmina-dev': threeDollarPerClassAnnualTwoDollars,

  'nikorasu-dev': threeDollarPerClassAnnualTwoDollars,
  alessandra: threeDollarPerClassAnnualTwoDollars,
  'chica-apps': threeDollarPerClassAnnualTwoDollars,
  'silver-dev': threeDollarPerClassAnnualTwoDollars,
  simara: threeDollarPerClassAnnualTwoDollars,
  kokidure: threeDollarPerClassAnnualTwoDollars,

  holbies: fiveDollarPerClassAnnualThreeDollars,
  hacker: fiveDollarPerClassAnnualThreeDollars,

  // Flyers
  'chinese-cute': threeDollarPerClass,
  'chinese-flyer': threeDollarPerClass,
  'chinese-funny': threeDollarPerClass,
  'regular-in-english': threeDollarPerClass,
  dominican: threeDollarPerClass,
  'spanish-in-us': threeDollarPerClass,

  //test
  iloveyam: threeDollarPerClassAnnualTwoDollars,
};

export let classesSubscriptionTiers: Array<SubTier> = [
  // New prices
  // {
  //   name: `4 ${stringsPricingPage.classes}`,
  //   id:
  //     process.env.REACT_APP_ENV === 'PROD'
  //       ? 'price_1OXXP0IJPWWhjYyo8rfijEnr'
  //       : 'price_1OXYMRIJPWWhjYyobpbeOrFT',
  //   price: {
  //     usd: 59,
  //     cop: usdToX(59, 'cop'),
  //     brl: usdToX(59, 'brl'),
  //     mxn: usdToX(59, 'mxn'),
  //   },
  //   classesCount: 4,
  //   description: 'theEssentials',
  //   features: ['upto140Minutes', 'upto4Classes'],
  //   discount: {
  //     usd: 20,
  //     cop: usdToX(20, 'cop'),
  //     brl: usdToX(20, 'brl'),
  //     mxn: usdToX(20, 'mxn'),
  //   },
  //   mostPopular: false,
  // },
  {
    name: `8 ${stringsPricingPage.classes}`,
    classesCount: 8,
    id:
      process.env.REACT_APP_ENV === 'PROD'
        ? 'price_1OXvvFIJPWWhjYyotHNqtU7M'
        : 'price_1OXYqTIJPWWhjYyoQbqLP0yo',
    price: {
      usd: 89,
      cop: usdToX(89, 'cop'),
      brl: usdToX(89, 'brl'),
      mxn: usdToX(89, 'mxn'),
    },
    description: 'theEssentials',
    features: ['upto280Minutes', 'upto8Classes'],
    discount: {
      usd: 40,
      cop: usdToX(40, 'cop'),
      brl: usdToX(40, 'brl'),
      mxn: usdToX(40, 'mxn'),
    },
    mostPopular: false,
  },
  {
    name: `12 ${stringsPricingPage.classes}`,
    classesCount: 12,
    id:
      process.env.REACT_APP_ENV === 'PROD'
        ? 'price_1OXvxpIJPWWhjYyoLdVq4Wap'
        : 'price_1OXYyoIJPWWhjYyoqZ6iMJSR',
    price: {
      usd: 119,
      cop: usdToX(119, 'cop'),
      brl: usdToX(119, 'brl'),
      mxn: usdToX(119, 'mxn'),
    },
    description: 'thePerfect',
    features: ['upto420Minutes', 'upto12Classes'],
    discount: {
      usd: 60,
      cop: usdToX(60, 'cop'),
      brl: usdToX(60, 'brl'),
      mxn: usdToX(60, 'mxn'),
    },
    mostPopular: true,
  },
  {
    name: `16 ${stringsPricingPage.classes}`,
    classesCount: 16,
    id:
      process.env.REACT_APP_ENV === 'PROD'
        ? 'price_1POp2mIJPWWhjYyoCD6YCm2J'
        : 'price_1POmoxIJPWWhjYyoVPRFoPxs',
    price: {
      usd: 149,
      cop: usdToX(149, 'cop'),
      brl: usdToX(149, 'brl'),
      mxn: usdToX(149, 'mxn'),
      eur: usdToX(149, 'eur'),
      krw: usdToX(149, 'krw'),
    },
    description: 'theReserved',
    features: ['upto560Minutes', 'upto16Classes'],
    discount: {
      usd: 90,
      cop: usdToX(90, 'cop'),
      brl: usdToX(90, 'brl'),
      mxn: usdToX(90, 'mxn'),
    },
    mostPopular: false,
  },
];

export let fiveDollarextraClassCredit: SubTier = {
  name: '1 class credit',
  id:
    process.env.REACT_APP_ENV === 'PROD'
      ? 'price_1P72RbIJPWWhjYyoiI2JS2nf'
      : 'price_1P72RbIJPWWhjYyoiI2JS2nf',
  price: {
    usd: 4.99,
    cop: 19629.0,
    mxn: 84.99,
    brl: 25.99,
  },
  description: '1 class credit',
  features: [],
  classesCount: 1,
};

export let classesSubscriptionTiersYearly: Array<SubTier> = [
  // New prices
  // {
  //   name: `4 ${stringsPricingPage.classes}`,
  //   id:
  //     process.env.REACT_APP_ENV === 'PROD'
  //       ? 'price_1OXvtMIJPWWhjYyofgItQ62U'
  //       : 'price_1OXYwGIJPWWhjYyouUdR9snX',
  //   price: {
  //     usd: 385.9,
  //     cop: usdToX(385.9, 'cop'),
  //     brl: usdToX(385.9, 'brl'),
  //     mxn: usdToX(385.9, 'mxn'),
  //   },
  //   classesCount: 52,
  //   discount: {
  //     usd: 641.1,
  //     cop: usdToX(641.1, 'cop'),
  //     brl: usdToX(641.1, 'brl'),
  //     mxn: usdToX(641.1, 'mxn'),
  //   },
  //   description: 'theEssentials',
  //   features: ['upto140Minutes', 'upto4Classes'],
  //   mostPopular: false,
  // },
  {
    name: `8 ${stringsPricingPage.classes}`,
    classesCount: 104,
    id:
      process.env.REACT_APP_ENV === 'PROD'
        ? 'price_1OXvwlIJPWWhjYyolZtLq4TV'
        : 'price_1OXYuXIJPWWhjYyoGjtn1gps',
    price: {
      usd: 579.9,
      cop: usdToX(579.9, 'cop'),
      brl: usdToX(579.9, 'brl'),
      mxn: usdToX(579.9, 'mxn'),
    },
    discount: {
      usd: 1474.1,
      cop: usdToX(1474.1, 'cop'),
      brl: usdToX(1474.1, 'brl'),
      mxn: usdToX(1474.1, 'mxn'),
    },
    description: 'theEssentials',
    features: ['upto280Minutes', 'upto8Classes'],
    mostPopular: false,
  },
  {
    name: `12 ${stringsPricingPage.classes}`,
    classesCount: 156,
    id:
      process.env.REACT_APP_ENV === 'PROD'
        ? 'price_1OXvz2IJPWWhjYyo3gqZqFJZ'
        : 'price_1OXYzpIJPWWhjYyoPp2S5J9f',
    price: {
      usd: 770.5,
      cop: usdToX(770.5, 'cop'),
      brl: usdToX(770.5, 'brl'),
      mxn: usdToX(770.5, 'mxn'),
    },
    discount: {
      usd: 2310.5,
      cop: usdToX(2310.5, 'cop'),
      brl: usdToX(2310.5, 'brl'),
      mxn: usdToX(2310.5, 'mxn'),
    },
    description: 'thePerfect',
    features: ['upto420Minutes', 'upto12Classes'],
    mostPopular: true,
  },
  {
    name: `16 ${stringsPricingPage.classes}`,
    classesCount: 208,
    id:
      process.env.REACT_APP_ENV === 'PROD'
        ? 'price_1POp2mIJPWWhjYyor5xXau8X'
        : 'price_1POn6LIJPWWhjYyoivqS2TEd',
    price: {
      usd: 879.9,
      cop: usdToX(879.9, 'cop'),
      brl: usdToX(879.9, 'brl'),
      mxn: usdToX(879.9, 'mxn'),
    },
    discount: {
      usd: 3298.1,
      cop: usdToX(3120.1, 'cop'),
      brl: usdToX(3120.1, 'brl'),
      mxn: usdToX(3120.1, 'mxn'),
    },
    description: 'theReserved',
    features: ['upto560Minutes', 'upto16Classes'],
    mostPopular: false,
  },
];
