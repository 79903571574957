import * as yup from 'yup';

import { FormData, SchemaFor } from '../../components/forms/hooks';
import { nationalities, Nationality } from '../../util/forms';
import {
  LivingAbroadDurationOption,
  livingAbroadDurationOptions,
} from '../CourseRegistration/questionform/data';

export const relationshipStatuses = ['Single', 'In a relationship', 'Married'];
type RelationshipStatus = typeof relationshipStatuses[number];

export const cefrLevels = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
type CEFRLevel = typeof cefrLevels[number];

export const kindsOfWork = [
  "I'm a student",
  'Education (e.g. Teacher, Professor)',
  'Engineering',
  'Technology (e.g. Product Manager, UX Designer)',
  'Research',
  'Law',
  'Healthcare',
  'Management',
  'Homemaker',
  'Finance',
  'Psychology',
  'Architecture',
  'Accounting',
  'Academia',
  'Service',
  'Construction',
  'Translation',
  'Sales',
  'Artists',
  'Government',
  'Unemployed',
  'Other',
];
type KindOfWork = typeof kindsOfWork[number];

export const typesOfStudent = [
  'University/College',
  'Masters',
  'PhD',
  'Post Doctor',
];
type TypeOfStudent = typeof typesOfStudent[number];

export const studyFocuses = [
  'Humanities (e.g. Literature, Philosophy)',
  'Social Science (e.g. Sociology, Economics)',
  'Science (e.g. Biology, Chemistry)',
  'Communication',
  'Engineering',
  'Music',
  'Art',
  'Medicine',
  'Law',
  'Business (e.g. Finance, Marketing)',
  'Journalism',
  'Design',
];
type StudyFocus = typeof studyFocuses[number];

export const usesEnglishOptions = [
  'Not at all',
  'Not at all, but I am looking to move to a job that will require me to speak English',
  'Yes, sometimes',
  'Yes, frequently',
];
type UsesEnglish = typeof usesEnglishOptions[number];

export const partnerExperienceOptions = ['Good', 'OK', 'Bad'];
type PartnerExperience = typeof partnerExperienceOptions[number];

export interface AdditionalInfoFormData extends FormData {
  age?: number;
  relationshipStatus?: RelationshipStatus;
  cefrLevel?: CEFRLevel;
  haveKids?: boolean;
  kindOfWork?: KindOfWork;
  typeOfStudent?: TypeOfStudent;
  studyFocus?: StudyFocus;
  occupation?: string;
  usesEnglishAtWork?: UsesEnglish;
  currentlyHasTutor?: boolean;
  triedSpeakingPartner?: boolean;
  currentlyHasSpeakingPartner?: boolean;
  speakingPartnerExperience?: PartnerExperience;
  whyJoinedCourse?: string;
  phoneNumber?: string;
  wishToBeContacted?: boolean;
  infoToKnow?: string;
  seriousLearner?: boolean;
  nationality?: Nationality;
  basedCountry?: Nationality;
  livingAbroad?: boolean;
  howLongLivedAbroad?: LivingAbroadDurationOption;
}

const nationalityValidationSchema = (errorMessage?: string): yup.StringSchema =>
  yup
    .string()
    .min(2, 'This is not a valid option')
    .oneOf(nationalities, 'This is not a valid option')
    .strict()
    .required(errorMessage || 'This field is required.');

export const validationSchema: SchemaFor<AdditionalInfoFormData> = {
  age: yup
    .number()
    .positive('This value is not valid.')
    .integer('This value is not valid.')
    .defined('This field is required.')
    .required('This field is required.'),
  relationshipStatus: yup
    .string()
    .oneOf(relationshipStatuses, 'This is not a valid option')
    .required('This field is required.'),
  cefrLevel: yup
    .string()
    .oneOf(cefrLevels, 'This is not a valid option')
    .required('This field is required.'),
  nationality: nationalityValidationSchema(
    'This field is required, please choose a nationality',
  ),
  basedCountry: yup.string().when('livingAbroad', {
    is: (value: boolean) => value === true,
    then: nationalityValidationSchema(
      'This field is required, please choose the country you are based in!',
    ),
  }),
  livingAbroad: yup.boolean().required('Please select an option'),
  howLongLivedAbroad: yup.string().when('livingAbroad', {
    is: (value: boolean) => value === true,
    then: yup
      .string()
      .min(1, 'This is not a valid option')
      .oneOf(livingAbroadDurationOptions, 'This is not a valid option')
      .required('This field is required, please choose an option'),
  }),
  haveKids: yup
    .boolean()
    .defined('This field is required.')
    .required('This field is required.'),
  kindOfWork: yup
    .string()
    .oneOf(kindsOfWork, 'This is not a valid option')
    .required('This field is required.'),
  typeOfStudent: yup.string().when('kindOfWork', {
    is: (value: string) => value === kindsOfWork[0],
    then: yup
      .string()
      .oneOf(typesOfStudent, 'This is not a valid option')
      .required('This field is required.'),
  }),
  studyFocus: yup.string().when('kindOfWork', {
    is: (value: string) => value === kindsOfWork[0],
    then: yup
      .string()
      .oneOf(studyFocuses, 'This is not a valid option')
      .required('This field is required.'),
  }),
  occupation: yup.string().required('This field is required.'),
  usesEnglishAtWork: yup
    .string()
    .oneOf(usesEnglishOptions, 'This is not a valid option')
    .required('This field is required.'),
  currentlyHasTutor: yup
    .boolean()
    .defined('This field is required.')
    .required('This field is required.'),
  triedSpeakingPartner: yup
    .boolean()
    .defined('This field is required.')
    .required('This field is required.'),
  currentlyHasSpeakingPartner: yup
    .boolean()
    .defined('This field is required.')
    .required('This field is required.'),
  speakingPartnerExperience: yup.string().when('currentlyHasSpeakingPartner', {
    is: (value?: boolean) => value === true,
    then: yup
      .string()
      .min(1, 'This is not a valid option')
      .oneOf(partnerExperienceOptions, 'This is not a valid option')
      .required('This field is required.'),
  }),
  whyJoinedCourse: yup.string(),
  phoneNumber: yup
    .string()
    .min(5, 'Please enter a valid number')
    .required('This field is required.'),
  wishToBeContacted: yup
    .boolean()
    .defined('This field is required.')
    .required('This field is required.'),
  infoToKnow: yup.string(),
  seriousLearner: yup
    .boolean()
    .defined('This field is required.')
    .oneOf([true], 'We cannot proceed to the course without your agreement!')
    .required('This field is required.'),
};
