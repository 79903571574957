import {
  ArrowRightIcon,
  ChatBubbleLeftEllipsisIcon,
} from '@heroicons/react/24/outline';
import { RadialProgress } from '../../progress/radialProgress';
import cx from 'classix';
import { ForwardRefExoticComponent, SVGProps } from 'react';

type LearningPathCardColorTheme =
  | 'green'
  | 'blue'
  | 'yellow'
  | 'orange'
  | 'red';

type LearningPathThemeToColorMap = {
  [key in LearningPathCardColorTheme]: string;
};

const mainThemeToColorMap: LearningPathThemeToColorMap = {
  green: 'green-500',
  blue: 'blue-500',
  yellow: 'yellow-500',
  red: 'red-500',
  orange: 'orange-500',
};

const subThemeToColorMap: LearningPathThemeToColorMap = {
  green: 'green-100',
  blue: 'blue-100',
  yellow: 'yellow-100',
  red: 'red-100',
  orange: 'orange-100',
};

export const LearningPathRequirementCard = (props: {
  colorTheme: LearningPathCardColorTheme;
  title: string;
  description: string;
  completed: number;
  needed: number;
  Icon: ForwardRefExoticComponent<Pick<SVGProps<SVGSVGElement>, any>>;
  onSuggestionClick?: Function;
}) => {
  const {
    colorTheme,
    Icon,
    title,
    description,
    completed,
    needed,
    onSuggestionClick,
  } = props;

  return (
    <div className="bg-white rounded-md">
      <div className="p-4">
        <div className="flex">
          <div
            className={cx(
              'p-3 rounded-md max-h-14 max-w-14',
              `bg-${subThemeToColorMap[colorTheme]}`,
            )}
          >
            <Icon
              className={cx(
                'w-8 h-8',
                `text-${mainThemeToColorMap[colorTheme]}`,
              )}
            />
          </div>
          <div className="ml-4 w-full">
            <div
              className={cx(
                `text-${mainThemeToColorMap[colorTheme]}`,
                'font-semibold text-2xl',
              )}
            >
              {title}
            </div>
            <div className="text-gray-500 w-full">{description}</div>
            <div className="flex items-center justify-end mt-4">
              <div className="flex items-center">
                <RadialProgress
                  percentage={Math.round((completed / needed) * 100)}
                  color={mainThemeToColorMap[colorTheme]}
                />
                <div className="ml-2">
                  <div
                    className={cx(
                      `text-${mainThemeToColorMap[colorTheme]}`,
                      'font-semibold text-xl',
                    )}
                  >
                    {`${completed}/${needed}`}
                  </div>
                  <div className="text-sm text-gray-500">Completed</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {onSuggestionClick ? (
        <div
          className={cx(
            `bg-${mainThemeToColorMap[colorTheme]}`,
            'rounded-b-md p-2 text-white flex items-center justify-between cursor-pointer',
          )}
          onClick={() => {
            onSuggestionClick();
          }}
        >
          <span>Show suggested classes</span>
          <ArrowRightIcon className="w-5 h-5" />
        </div>
      ) : null}
    </div>
  );
};
