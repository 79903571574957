import moment from 'moment';
import BookingEventContainer, {
  BookingEventObject,
} from './bookingEventContainer';
import { cx } from 'classix';

interface BookingEventContainerStackedProps {
  currentClassSessions: Array<BookingEventObject>;
  weekDayMoments: Array<moment.Moment>;
  chosenDateMoment: moment.Moment;
  chosenSchedule: Array<BookingEventObject>;
  setChosenSchedule: Function;
  setChosenDateMoment: Function;
}

export const BookingEventContainerStacked = ({
  currentClassSessions,
  weekDayMoments,
  chosenDateMoment,
  chosenSchedule,
  setChosenSchedule,
  setChosenDateMoment,
}: BookingEventContainerStackedProps) => {
  return (
    <div className="overflow-y-scroll overflow-x-hidden">
      {/* Events */}
      <div className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-81">
        <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
          {weekDayMoments.map((day: moment.Moment, index: number) => {
            return (
              <button
                key={day.valueOf() + 'mobile-view-day'}
                type="button"
                className={cx(
                  'flex flex-col items-center pb-3 pt-2',
                  chosenDateMoment.day() === day.day()
                    ? 'text-white bg-blue-immigo'
                    : '',
                )}
                onClick={() => {
                  setChosenDateMoment(day);
                }}
              >
                <div>{day.format('D')}</div>
                <div>{day.format('dd')}</div>
              </button>
            );
          })}
        </div>
        <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
          {weekDayMoments.map((day) => {
            return (
              <div
                key={'day-' + day.valueOf()}
                className="flex items-center justify-center py-3"
              >
                <span>{day.format('MMMM Do dddd')}</span>
              </div>
            );
          })}
        </div>
      </div>
      <BookingEventContainer
        events={currentClassSessions}
        weekDayMoments={weekDayMoments}
        chosenDateMoment={chosenDateMoment}
        chosenSchedule={chosenSchedule}
        setChosenSchedule={setChosenSchedule}
      />
    </div>
  );
};
