import { BookingEventObject } from './bookingEventContainer';
import { EventBox } from './bookingEventContainerStacked/bookingEventContainer';

export const RecommendedBookingsContainer = (props: {
  classes: Array<BookingEventObject>;
}) => {
  const { classes } = props;

  return (
    <div>
      {classes.map((c: BookingEventObject) => {
        return (
          <div className="text-center">
            <EventBox
              {...c}
              disableColumn
              enablePreview={c.cleanSlide !== undefined}
              onPreviewClick={() => {
                window.open(c.cleanSlide, '_blank');
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
