import { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import {
  analyticsLogInPage14DayTrialButtonClick,
  analyticsLoginPageVisited,
} from '../../analytics';
import LoginRegisterBox from '../../components/forms/loginregister';
import { LoadingOverlay } from '../../components/loading';

import { onAuthStateChanged } from 'firebase/auth';
import { initializeFirebase } from '../../firebase/configValues';
import { getUserWholeProfileByUID } from '../../firebase/configuration';
const { auth } = initializeFirebase();

const LoginComponent = ({ userData, onSetUserData }: any) => {
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (userData) {
      window.location.href = '/home';
    }
  }, [userData]);
  useEffect(onLoad, []);

  function onLoad() {
    analyticsLoginPageVisited(); // Track login visited
  }

  return (
    <div className="flex flex-col justify-center max-w-screen-xl m-auto max-w-screen-xl m-auto">
      <LoadingOverlay enabled={submitting} />
      <img
        className="md:mx-auto h-10 mr-auto"
        src="https://immigoimages.s3.amazonaws.com/Immigo_new_logo.png"
        alt="Your Company"
      />
      <LoginRegisterBox
        onLoginSuccess={() => {
          setSubmitting(false);
          window.location.href = '/home';
        }}
      />
      <p className="text-center text-sm text-gray-500 my-6">
        Not a member?{' '}
        <a
          target="_blank"
          href="http://www.immigo.io"
          className="font-semibold leading-6 text-blue-600 hover:text-blue-500"
          onClick={(e) => {
            analyticsLogInPage14DayTrialButtonClick();
          }}
          rel="noreferrer"
        >
          Start learning today
        </a>
      </p>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  onSetUserData: (userData: any) =>
    dispatch({ type: 'USER_DATA_SET', userData }),
});

const mapStateToProps = (state: any) => ({
  userData: state.sessionState.userData,
});

export const Login = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginComponent);
