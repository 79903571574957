import { capitalizeFirstLetter } from '../../../util/standardization';
import { ChatBubbleOvalLeftIcon } from '@heroicons/react/24/outline';
export interface IdiomAndExplanationContainerProp {
  phrase: string;
  definition: string;
  example: string;
}

export const IdiomExplanationContainerLarge = ({
  phrase,
  definition,
  example,
}: IdiomAndExplanationContainerProp) => {
  return (
    <div className="text-left bg-white rounded-md my-3 shadow p-6 h-full">
      <div className="font-semibold pb-4 md:pb-8 text-2xl md:text-4xl">
        {capitalizeFirstLetter(phrase)}
      </div>
      <div className="pb-8 text-gray-600 text-xl md:text-2xl">{definition}</div>
      <div className="py-4 md:py-8 text-xl md:text-2xl border-t border-gray-300 italic text-gray-500 flex items-center">
        {`“${example}”`}
        <ChatBubbleOvalLeftIcon className="w-8 h-8 md:w-10 md:h-10 text-gray-500 mr-4 flex-shrink-0 ml-auto md:pl-0 pl-2" />
      </div>
    </div>
  );
};
