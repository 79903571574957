import ContentLoader from 'react-content-loader';

export const CancelBookingConfirmationModalLoader = () => {
  return (
    <ContentLoader viewBox="0 0 1500 300">
      <rect x="10" y="10" rx="5" ry="5" width="400" height="80" />
      <rect x="10" y="110" rx="5" ry="5" width="1500" height="80" />
      <rect x="10" y="210" rx="5" ry="5" width="1500" height="80" />
    </ContentLoader>
  );
};
