import { useRedirectIfNotLoggedIn } from '../../hooks/useRedirectIfNotLoggedIn';
import { useSelector } from 'react-redux';
import { UserData } from '../../types/user';
import { useEffect, useState } from 'react';
import { trackHomePageData } from './analytics';
import { LoadingOverlay } from '../../components/loading';
import { SubscriptionLayout } from './SubscriptionLayout/SubscriptionLayout';
import HorizontalMessageModal from '../../components/modals/horizontalMessageModal/horizontalMessageModal';
import { WhatToWorkOnModal } from '../../components/modals/whatToWorkOnModal/whatToWorkOnModal';

export function Homepage() {
  useRedirectIfNotLoggedIn();

  useEffect(() => {
    trackHomePageData('Visited platform homepage');
  }, []);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [messageModalOpen, setMessageModalOpen] = useState<boolean>(false);
  const [buyMoreCreditOpen, setBuyMoreCreditOpen] = useState<boolean>(false);

  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );

  useEffect(() => {
    onUserDataLoaded();
    console.log(userData);
  }, [userData]);

  async function onUserDataLoaded() {
    if (userData) {
      if (!userData?.profilePictureLink) setMessageModalOpen(true);
      if (
        userData.availableClasses === 0 &&
        userData.customerID &&
        userData.subNextInvoice &&
        (userData.subNextInvoice === 144 ||
          userData.subNextInvoice === 8 ||
          userData.subNextInvoice === 4 ||
          userData.subNextInvoice === 96 ||
          userData.subNextInvoice === 48 ||
          userData.subNextInvoice === 12) // Only for $4 / $8 subscribers
      )
        setBuyMoreCreditOpen(true);
    }
  }

  return (
    <div>
      <LoadingOverlay
        enabled={submitting}
        loadingMessage={'Please wait a moment :)'}
      />
      <HorizontalMessageModal
        isOpen={messageModalOpen}
        onCloseModal={() => {
          setMessageModalOpen(false);
        }}
        mainMessage="We don't have your profile picture yet"
        subMessage="Upload your profile picture so that your peers and teachers can see who you are!"
        buttonText="Let's go upload my picture"
        onButtonClick={() => {
          if (userData && userData.id)
            window.open('/profile/' + userData.id, '_blank');
        }}
        theme={'default'}
      />
      <SubscriptionLayout setSubmitting={setSubmitting} />
    </div>
  );
}
