import { PrimaryButton } from '../../../../components/buttons/primary';
import { trackOnBoardingPage } from '../../../../features/OnBoard/analytics';

export const OnBoardingStartPage = (props: { onNextClick: Function }) => {
  const { onNextClick } = props;
  return (
    <div className="text-center h-fill">
      <div className="animate-[wiggle_1s_ease-in-out_infinite] text-8xl mb-6">
        👋
      </div>
      <div className="text-blue-immigo text-3xl mb-2">Welcome to Immigo!</div>
      <div className="text-gray-500 text-md mb-6">
        You are almost there! Please answer a few more questions to complete
        your profile so that we can better understand you to create more
        customized experience for you.
      </div>
      <PrimaryButton
        text="Finish my profile"
        onClick={() => {
          trackOnBoardingPage(
            'Clicked finish my profile button on boarding page',
          );
          onNextClick();
        }}
      />
    </div>
  );
};
