import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { yearBookVisited } from '../../analytics';

const yearBookUrl =
  'https://rain-drill-1dd.notion.site/Immigo-Yearbook-Veronika-s-Cohort-February-20-March-20-2022-e352fe2b0a1648ad835cd9983970bbdd';

export const YearBook = () => {
  useEffect(onLoad, []);

  function onLoad() {
    yearBookVisited('C8nhULAzKczVlKryM4qL');
    window.location.replace(yearBookUrl);
  }

  return <div></div>;
};
