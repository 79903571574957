import cx from 'classix';

interface PreviewDescriptionEntry {
  label: string;
  entry: string | number;
}

export const PreviewDescriptionContainer = (props: {
  id: string;
  title: string;
  imageUrl: string;
  labels: Array<PreviewDescriptionEntry>;
  onClick?: Function;
  bottomButtonText?: string;
}) => {
  const { id, imageUrl, title, labels, onClick, bottomButtonText } = props;

  return (
    <li
      key={id}
      className="overflow-hidden rounded-xl border border-gray-200 cursor-pointer"
      onClick={() => {
        onClick?.();
      }}
    >
      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
        <img
          src={imageUrl}
          alt={title}
          className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
        />
        <div className="text-sm font-medium leading-6 text-gray-900">
          {title}
        </div>
      </div>
      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        <div className="flex justify-between gap-x-4 py-3">
          <dd className="text-gray-700">{title}</dd>
        </div>
        {labels.map((label) => {
          return (
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-gray-500">{label.label}</dt>
              <dd className="text-gray-700">{label.entry}</dd>
            </div>
          );
        })}
      </dl>
      {bottomButtonText ? (
        <div className="flex ml-auto gap-x-4 py-3 px-6">
          <dt className="text-blue-immigo hover:text-blue-immigo-lighter ml-auto">
            {bottomButtonText}
          </dt>
        </div>
      ) : null}
    </li>
  );
};
