import mixpanel from '../../analytics/mixpanel';
import { CorrectionRatingType } from '../../types/feedback';

type Events =
  | 'Visited class insight page'
  | 'Clicked expand on the word usage in insight page'
  | 'Clicked collapse on the word usage in insight page'
  | 'Clicked speaker icon in correction container'
  | 'Shown speaker tag chooser'
  | 'Submitted speaker tag chooser'
  | 'Shown peer reaction in insight page'
  | 'Rated a correction in insight page';

type ClassInsightData = {
  bookingID?: string;
  fillerUsagePercentage?: number;
  correctionOriginalText?: string;
  correction?: string;
  status?: string;
  explanation?: string;
  rating?: CorrectionRatingType;
};

export const trackClassInsightEvent = (
  event: Events,
  data: ClassInsightData,
) => {
  mixpanel.track(event, data);
};
