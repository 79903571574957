import PhoneInput from 'react-phone-number-input';
import TextInput from '../../forms/textInput';
import HorizontalMessageModal from '../horizontalMessageModal/horizontalMessageModal';
import SmallCardsRadio from '../../forms/smallCardsRadio/SmallCardsRadio';
import { useEffect, useState } from 'react';
import { getAllGuestPasses, inviteGuest } from '../../../api/guestPass';
import { filterValidPasses } from '../../../util/guestPass';
import { CEFRLevel } from '../../../types/level';
import VerticalMessageModal from '../verticalMessageModal/verticalMessageModal';
import { getFirstNameFromFullName } from '../../../util/helper';
import { capitalizeFirstLetter } from '../../../util/standardization';
import { trackGuestBookingInviteModal } from './analytics';

export const GuestBookingInviteModal = (props: {
  userID: string;
  open: boolean;
  onClose: Function;
  onSubmit: Function;
}) => {
  const { open, onClose, onSubmit, userID } = props;

  const [email, setEmail] = useState<string | undefined>();
  const [fullName, setFullName] = useState<string | undefined>();
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [cefrLevel, setCefrLevel] = useState<CEFRLevel | undefined>();
  const [error, setError] = useState<undefined | string>();
  const [loading, setLoading] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  async function onInvite() {
    setLoading(true);
    const passes = await getAllGuestPasses(userID);
    const validPasses = filterValidPasses(passes);
    const passToUse = validPasses[0]; // Use the first pass
    const passId = passToUse?.id;

    trackGuestBookingInviteModal('Guest booking invite modal pressed submit');

    if (passId && email && fullName && phoneNumber && cefrLevel) {
      // Create user data
      const { event, user } = await inviteGuest(
        passId,
        email,
        fullName,
        cefrLevel,
        phoneNumber,
      );

      console.log(user);
      await onSubmit(user);

      setOpenConfirmModal(true);
      onClose(); // Close modal after
      // Should open confirmation modal
    } else {
      if (!passId) {
        console.log('No valid pass');
        setError(`You don't have a valid guest pass`);
      } else if (!email) {
        setError('Please enter a valid email for your guest');
      } else if (!fullName) {
        setError(`Please enter your guest's full name`);
      } else if (!phoneNumber) {
        setError(`Please enter your guest's WhatsApp number`);
      } else if (!cefrLevel) {
        setError(`Please set guest's English level`);
      }
    }

    setLoading(false);
  }

  useEffect(() => {
    if (error) setError(undefined);
  }, [email, fullName, phoneNumber, cefrLevel]);

  useEffect(() => {
    if (open) trackGuestBookingInviteModal('Guest booking invite modal shown');
  }, [open]);

  return (
    <>
      <VerticalMessageModal
        mainMessage="Invitation Emailed"
        subMessage={`You successfully invited ${capitalizeFirstLetter(
          getFirstNameFromFullName(fullName ?? 'your friend'),
        )} to the class. 
      Please tell ${capitalizeFirstLetter(
        getFirstNameFromFullName(fullName ?? 'your friend'),
      )} to check the spam folder if they don't see the invite.`}
        theme="success"
        buttonText="Continue"
        onButtonClick={() => {
          setOpenConfirmModal(false);
        }}
        onCloseModal={onClose}
        isOpen={openConfirmModal}
      />
      <HorizontalMessageModal
        isOpen={open}
        onCloseModal={onClose}
        mainMessage="Tell us about your friend"
        subMessage="Please provide some information about the friend you are inviting to your class."
        buttonText="Send invitation"
        onButtonClick={onInvite}
        loading={loading}
        theme="default"
      >
        <div className="my-2">
          <TextInput
            title="Email"
            value={email}
            onChange={(v: string) => setEmail(v)}
          />
          <TextInput
            title="Full name"
            value={fullName}
            onChange={(v: string) => setFullName(v)}
          />
          <div className="text-sm font-medium mb-1">WhatsApp Number</div>
          <PhoneInput
            value={phoneNumber}
            onChange={(v: string) => setPhoneNumber(v)}
          />
          <div className="text-sm font-medium mt-5">English level</div>
          <SmallCardsRadio
            options={[
              {
                name: `Beginner`,
                checked: false,
                tag: 'A1',
              },
              {
                name: `Intermediate`,
                checked: false,
                tag: 'B1',
              },
              {
                name: `Advanced`,
                checked: false,
                tag: 'C1',
              },
            ]}
            chosenTag={cefrLevel}
            onChosen={(tag: CEFRLevel) => {
              setCefrLevel(tag);
            }}
          />
          <div className="text-red-warning mt-2 mb-2">{error}</div>
        </div>
      </HorizontalMessageModal>
    </>
  );
};
