import { useMemo } from 'react';
import { useFormik } from 'formik';
import { object, SchemaOf } from 'yup';

import {
  FormData,
  FormConfig,
  FormHookReturnType,
  yupFormikWrapper,
  FieldOf,
  TypeOfFieldOf,
  FieldProps,
  SelectFieldProps,
  RadioFieldProps,
  validateValues,
} from './types';

export type { FormData, FormHookReturnType, SchemaFor } from './types';

export function useForm<T extends FormData>(
  config: FormConfig<T>,
): FormHookReturnType<T> {
  const { validationSchema: yupSchema, ...otherConfig } = config;

  const validationSchema: SchemaOf<T> = useMemo(() => {
    return object(yupSchema || {}) as SchemaOf<T>;
  }, [yupSchema]);

  const forkimConfig = useMemo<FormConfig<T>>(() => {
    return {
      validateOnBlur: false,
      validateOnChange: false,
      enableReinitialize: false,
      ...otherConfig,
      validate: validationSchema
        ? yupFormikWrapper(validationSchema)
        : undefined,
    };
  }, [otherConfig, validationSchema]);

  const formik = useFormik<T>(forkimConfig);

  const getFieldProps = (
    field: FieldOf<T>,
    forceValidationOnBlur?: boolean,
  ): FieldProps => {
    const fieldKey = field.toString();
    const meta = formik.getFieldMeta(fieldKey);
    return {
      ...formik.getFieldProps(field),
      error: meta.error,
      touched: meta.touched,
      onChange: (e: React.ChangeEvent<any>) => {
        formik.setFieldError(fieldKey, undefined);
        formik.handleChange(e);
      },
      onBlur: (e: React.FocusEvent<any>) => {
        formik.validateField(fieldKey).then((error) => {
          if (!error && forceValidationOnBlur) {
            // Formik validation might have failed, so let's do it on our own
            validateValues(formik.values, validationSchema).then((errors) => {
              const fieldError = errors?.[field];
              if (fieldError) {
                if (Boolean(formik.values[field]))
                  formik.setFieldTouched(fieldKey);
                formik.setFieldError(fieldKey, fieldError.toString());
              }
            });
          }
        });
        formik.handleBlur(e);
      },
      id: field.toString(),
      disabled: formik.isSubmitting || formik.isValidating,
    };
  };

  const getSelectProps = (
    field: FieldOf<T>,
    onChange?: (newValue: string, index?: number) => void,
    forceValidationOnBlur?: boolean,
  ): SelectFieldProps => {
    return {
      ...getFieldProps(field, forceValidationOnBlur),
      onChange: (newValue, index) => {
        formik.setFieldError(field.toString(), undefined);
        onChange
          ? onChange(newValue, index)
          : formik.setFieldValue(field.toString(), newValue);
      },
    };
  };

  const getRadioProps = (
    field: FieldOf<T>,
    getValue: (fieldValue: TypeOfFieldOf<T>) => string | null,
    onChange?: (newValue?: string) => void,
    forceValidationOnBlur?: boolean,
  ): RadioFieldProps => {
    return {
      ...getFieldProps(field, forceValidationOnBlur),
      value:
        typeof formik.values[field] !== 'undefined'
          ? getValue(formik.values[field])
          : undefined,
      onChange: (newValue) => {
        formik.setFieldError(field.toString(), undefined);
        onChange
          ? onChange(newValue)
          : formik.setFieldValue(field.toString(), newValue);
      },
    };
  };

  return {
    ...formik,
    getFieldProps,
    getSelectProps,
    getRadioProps,
    submitForm: async () => {
      formik.setSubmitting(true);
      await formik.submitForm();
    },
  };
}
