import axios, { AxiosResponse } from 'axios';
import { signInWithCustomToken } from 'firebase/auth';
import { useEffect } from 'react';
import { initializeFirebase } from '../../../firebase/configValues';
import { authKakao } from '../../../api/auth';
const { auth } = initializeFirebase();

interface Auth {
  firebaseToken: string;
}

export const KakaoAuthgCallBackPage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get('code');

  if (!code) {
    console.log('Code missing');
  }

  useEffect(() => {
    (async () => {
      try {
        if (!code) throw 'kakao code is missing';
        const res: AxiosResponse<Auth> = await authKakao(code);
        const { firebaseToken } = res.data;
        // custom token을 이용한 로그인
        const authData = await signInWithCustomToken(auth, firebaseToken);
        if (authData?.user) {
          // Login succeeded
          window.close(); // You can close the window then
        } else {
          console.log('auth failed');
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return <></>;
};
