import {
  classesSubscriptionTiers,
  classesSubscriptionTiersYearly,
  couponMap,
} from '../stripe';
import { findDiscountAmountWithID } from './coupons';

export function getFinalPriceGivenPriceIDAndCouponID(
  priceID: string,
  couponID?: string,
  currency?: string,
  balance?: number,
) {
  let index = classesSubscriptionTiers.findIndex((tier) => tier.id === priceID);
  let priceObj;

  if (index === -1) {
    index = classesSubscriptionTiersYearly.findIndex(
      (tier) => tier.id === priceID,
    );
    priceObj = classesSubscriptionTiersYearly[index];
  } else {
    priceObj = classesSubscriptionTiers[index];
  }

  if (index > -1) {
    const original = priceObj.price[currency ?? 'usd'];
    const discount = couponID
      ? findDiscountAmountWithID(couponID, currency ?? 'usd')
      : 0;
    console.log(findDiscountAmountWithID(couponID ?? '', currency ?? 'usd'));
    return original - discount + (balance ? balance : 0);
  } else {
    return 0;
  }
}
