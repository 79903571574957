import cx from 'classix';

export interface CsatRadioButtonType {
  emoji: string;
  label: string;
  id: string;
  color: string;
  textStyle: string;
  chosenStyle: string;
  chosen?: boolean;
  onClick?: Function;
}

export const CsatRadioButton = (props: CsatRadioButtonType) => {
  const { emoji, label, chosenStyle, color, textStyle, chosen, onClick } =
    props;

  return (
    <div
      onClick={() => onClick?.()}
      className={cx(
        'mx-2 p-2 min-w-[140px] cursor-pointer',
        `rounded-md border-2 ${color}`,
        chosen ? chosenStyle : '',
      )}
    >
      <div className="text-6xl text-center mb-2">{emoji}</div>
      <div className={cx('text-sm text-center rounded-md p-1', `${textStyle}`)}>
        {label}
      </div>
    </div>
  );
};
