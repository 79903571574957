import { useEffect, useState } from 'react';
import { DropDownFloat } from '../../../components/forms/dropDownFloat/dropDownFloat';
import { GlobeAltIcon } from '@heroicons/react/24/outline';

const laguageOfferings = ['English', 'Español'];
export const PricingLanguageDropDown = (props: {
  onLanguageChosen: Function;
  language: string;
}) => {
  const { onLanguageChosen, language } = props;

  function getDefaultIndex() {
    switch (language) {
      case 'en':
        return 0;
      case 'es':
        return 1;
      default:
        return 0;
    }
  }

  const [chosenIndex, setChosenIndex] = useState(getDefaultIndex());

  function onLanguageChange(lang: string) {
    switch (lang) {
      case 'Español':
        onLanguageChosen('es');
        break;
      case 'English':
        onLanguageChosen('en');
        break;
      default:
    }
  }

  useEffect(() => {
    onLanguageChange(laguageOfferings[chosenIndex]);
  }, [chosenIndex]);

  return (
    <DropDownFloat
      options={laguageOfferings}
      chosenIndex={chosenIndex}
      icon={<GlobeAltIcon className="w-5 h-5" />}
      onChosen={(i: number) => {
        setChosenIndex(i);
      }}
      orientation="bottom-left"
    />
  );
};
