import mixpanel from './mixpanel';

/**
 * Identify user for analytics
 */
export function analyticsIdentifyUser(email?: string) {
  if (email) {
    mixpanel.alias(email);
    mixpanel.identify(email);
  } else {
    mixpanel.identify();
  }
  mixpanel.register_once({
    'Reffered Website': document.referrer,
    $direct: document.referrer,
  });
  mixpanel.people.set({
    'Reffered Website': document.referrer,
    $direct: document.referrer,
  });
}

/**
 * Identify user for analytics
 * @param email : Email used to sign up (String)
 */
export function analyticsSignUpMain(email: string) {
  mixpanel.track('Successfully signed up on the main sign-up list', {
    email: email,
  });
  mixpanel.register({
    Email: email,
  });
  mixpanel.people.set({
    $email: email,
  });
}

/**
 * Identify user for analytics
 * @param email : Email used to sign up (String)
 */
export function analyticsSetUserEmail(email: string) {
  mixpanel.people.set({
    $email: email,
  });
  mixpanel.register({
    Email: email,
  });
}

/**
 * Track user visiting login/registration page (app.immigo.io/login)
 */
export function analyticsLoginPageVisited() {
  mixpanel.track('User visited portal login page');
}

export function analyticsLogInPage14DayTrialButtonClick() {
  mixpanel.track('User clicked start a 14 day free trial button in login page');
}

/**
 * Track user visiting login/registration page (app.immigo.io/login)
 */
export function analyticsUserCreatedAccount(email: string) {
  mixpanel.track('User created account', {
    email: email,
  });
  mixpanel.people.set({
    $email: email,
  });
  mixpanel.register({
    Email: email,
  });
}

/**
 * Track user visiting yearbook page
 * @param courseID : Relevant courseID for the yearbook visiting
 */
export function yearBookVisited(courseID: string) {
  mixpanel.track('Visited yearbook page', {
    courseID: courseID,
  });
}

// -----↓↓ DASHBOARD PAGE ANALYTICS ↓↓----- //

/**
 * Track user visiting dashboard
 */
export function analyticsDashboardVisited(studentID: string, courseID: string) {
  mixpanel.track('Visited course dashboard', {
    studentID: studentID,
    courseID: courseID,
  });
}

/**
 * Track user clicking profile bubble on dashboard
 */
export function analyticsDashboardProfileBubbleClick(
  courseID: string,
  userID: string,
) {
  mixpanel.track('Clicked profile bubble on the dashboard', {
    courseID: courseID,
    userID: userID,
  });
}

export function analyticsDashboardPodRecordingSubmitted(
  courseID: string,
  userID: string,
  weekIndex: number,
) {
  mixpanel.track('Uploaded pod recording', {
    courseID: courseID,
    userID: userID,
    weekIndex: weekIndex,
  });
}

/**
 * Track user view roster
 */
export function analyticsDashboardClickedCountryRepresentationViewRoster(
  courseID: string,
) {
  mixpanel.track('Dashboard clicked country representation view roster', {
    courseID: courseID,
  });
}

export function analyticsDashboardClickedPodMatchingForm(userID: string) {
  mixpanel.track('Click on Complete Profile Banner', { userID });
}

// -----↓↓ PROFILE PAGE ANALYTICS ↓↓----- //

/**
 * Track user visiting profile page
 */
export function analyticsProfilePagesVisited() {
  mixpanel.track('Visited profile pages');
}

/**
 * Track user visiting profile page
 */
export function analyticsProfilePictureUploaded() {
  mixpanel.track('Uploaded profile picture');
}

/**
 * Track user visiting profile page
 * @param userID : userID for specific
 */
export function analyticsUserProfilePageVisited(
  userID: string,
  isExternal: boolean,
) {
  mixpanel.track('Visited user profile page', {
    userID: userID,
    isExternal: isExternal,
  });
}

/**
 * Track user sharing profile page
 */
export function analyticsProfilePagesShare() {
  mixpanel.track('Shared profile pages');
}

/**
 * Track users clicking on ad button to immigo.io page
 */
export function analyticsProfilePageAdButtonToCourses() {
  mixpanel.track('Visited Immigo landing page from profile page ad button');
}

/**
 * Track users clicking on ad button to immigo.io page
 * @param userID : userID for specific
 */
export function analyticsAdButtonToCoursesFromUserProfilePage(userID: string) {
  mixpanel.track('Visited Immigo landing page from profile page ad button', {
    userID: userID,
  });
}

// -----↓↓ Referral Analytics ↓↓----- //

/**
 * Track referral code use attempted
 */
export function analyticsReferralCodeVisited(
  referredID: string,
  referralCode: string,
  referredCourseID: string,
) {
  mixpanel.track('Referral code use attempted', {
    referredID: referredID,
    referralCode: referralCode,
    referredCourseID: referredCourseID,
  });
}

/**
 * Track referral code used
 */
export function analyticsReferralCodeUsed(
  referredID: string,
  referralCode: string,
  referredCourseID: string,
) {
  mixpanel.track('Referral code use used', {
    referredID: referredID,
    referralCode: referralCode,
    referredCourseID: referredCourseID,
  });
}

/**
 * Track referral code used
 */
export function analyticsReferralLinkCopied(
  userID: string,
  referralCode: string,
  referredCourseID: string,
) {
  mixpanel.track('Referral code copied from dashboard', {
    userID: userID,
    referralCode: referralCode,
    referredCourseID: referredCourseID,
  });
}

// -----↓↓ Cross Promotion Analytics ↓↓----- //

export function analyticsRecommendedCourseClicked(
  userID: string,
  fromCourseID: string,
  toCourseID: string,
) {
  mixpanel.track('Recommended course clicked', {
    userID: userID,
    fromCourseID: fromCourseID,
    toCourseID: toCourseID,
  });
}

export function analyticsCheckedPodMembers() {
  mixpanel.track(
    'Checked Goto "My Pod" section and check out who you will be meeting every week.',
  );
}

export function analyticsClickedJoinPodMeetingCourseDashboard() {
  mixpanel.track('Clicked join pod meeting course dashboard.');
}

export function analyticsCheckeAddAllPodMembers() {
  mixpanel.track('Add all your pod members to your WhatsApp contact list.');
}
export function analyticsCheckedCreateWhatsAppGroup() {
  mixpanel.track('Create a WhatsApp group chat and invite your pod members.');
}
export function analyticsCheckedCommonAvailability() {
  mixpanel.track(
    `Check under "My Pod" section if your pod has a common availability. If you don't, please fill out more availability at app.immigo.io/availability.`,
  );
}
export function analyticsCheckedScheduleTimeToMeet() {
  mixpanel.track(
    'Schedule a time to meet every week with your pod for the next 4 weeks.',
  );
}
export function analyticsCheckedFirstPodMeeting() {
  mixpanel.track(
    'Meet with your pod members by clicking "Join pod meeting" button, and do your activities found in Pod Meeting Agenda 1 in course information.',
  );
}
