import mixpanel from '../../analytics/mixpanel';

type Events =
  | 'Visited learning path page'
  | 'Chose learning path'
  | 'Clicked suggested class in learning path level'
  | 'Booked class in learning path level suggestion modal';

type PageData = {
  certificateName?: string;
};

export const trackLearningPathData = (event: Events, data: PageData = {}) => {
  mixpanel.track(event, data);
};
