import cx from 'classix';
import { stringsPricingPage } from '../../../../util/localization';
import { SubTierPriceMap } from '../../../../config/stripe';
import { currencySymbols } from '../../../../util/currency';

export interface TotalTableItem {
  name: string;
  price: number;
  isDiscount: boolean;
}

interface TotalTableProps {
  items: Array<TotalTableItem | null>;
  totalPrice: number;
  currency: string;
  notBilledToday?: boolean;
  nextBilling?: string;
  realPrice?: number;
  trial?: boolean;
  credit?: number;
}

export const TotalTable = (props: TotalTableProps) => {
  const {
    trial,
    items,
    credit,
    notBilledToday,
    totalPrice = 0,
    nextBilling,
    realPrice = 0,
    currency,
  } = props;

  return (
    <>
      <div className="py-4 border-b-2">
        {items.map((item: TotalTableItem | null, index: number) => {
          if (!item || item.price < 0) return null;
          console.log(item);
          return (
            <p
              key={index + item.name}
              className={cx(
                'flex justify-between',
                item.isDiscount ? 'text-blue-immigo' : 'text-black opacity-75',
              )}
            >
              <span>{`${item.name}`.trim()}</span>
              <span translate="no">
                {`${item.isDiscount ? '-' : ''} $${item.price.toFixed(
                  2,
                )}`.trim()}
              </span>
            </p>
          );
        })}
      </div>

      <div className="mb-6 py-4">
        <p className="flex justify-between text-black opacity-75 font-semibold">
          <span>{`${stringsPricingPage.todaysTotal}`}</span>
          <span translate="no">
            {notBilledToday || trial
              ? `${currencySymbols[currency]}0`
              : `${currencySymbols[currency]}${totalPrice.toFixed(2)}`}
          </span>
        </p>
        {nextBilling ? (
          <p className="flex justify-between text-gray-500 opacity-75 mt-2">
            <span>{`${stringsPricingPage.billed} ${nextBilling}`}</span>
            <span translate="no">{`${
              currencySymbols[currency]
            }${totalPrice.toFixed(2)}`}</span>
          </p>
        ) : null}
      </div>
    </>
  );
};
