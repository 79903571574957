import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import { initializeFirebase } from '../configValues';
const { firestore: db } = initializeFirebase();

export const getUserBalanceHistory = async (userID: string) => {
  try {
    const balanceRef = await collection(db, 'users', userID, 'balanceHistory');
    const balanceDocs = await getDocs(balanceRef);
    const balances: any[] = [];
    balanceDocs.forEach((b) => {
      const data = b.data();
      data.id = b.id;
      balances.push(data);
    });
    return balances;
  } catch (err) {
    console.log(err);
  }
};
