import ContentLoader from 'react-content-loader';

export const RankLoader = (props: {}) => {
  return (
    <ContentLoader viewBox="0 0 100 11">
      <rect x="0" y="0" rx="0" ry="0" width="100" height="2" />
      <rect x="0" y="3" rx="0" ry="0" width="100" height="2" />
      <rect x="0" y="6" rx="0" ry="0" width="100" height="2" />
      <rect x="0" y="9" rx="0" ry="0" width="100" height="2" />
    </ContentLoader>
  );
};
