import cx from 'classix';
import { type ChangeEvent, type FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';

import {
  getUserByUID,
  updateStudentInformation,
} from '../../firebase/configuration';
import { LoadingOverlay } from '../../components/loading/index';
import { capitalizeEveryFirstLetter } from '../../util/standardization';
import { initializeFirebase } from '../../firebase/configValues';
import { Input } from '../../components/forms/input';
import { Select } from '../../components/forms/select';

const { auth } = initializeFirebase();

type HourOption = `${number}:${number}`;
export const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
] as const;
export type Day = typeof days[number];
export interface AvailabilityOption {
  day: Day;
  start: HourOption;
  end: HourOption;
  startMillis?: number;
  endMillis?: number;
}
type AvailabilityData = Array<AvailabilityOption>;

interface AvailabilityProps {
  userData: Record<string, any>;
  onSetUserData?: (newUserData: Record<string, any>) => void;
}

type SortedAvailability = Record<Day, AvailabilityData>;

export const sortAvailabilityOptionsByTime = (
  options: AvailabilityData,
): AvailabilityData => {
  options.sort((a, b) => {
    const aStart = Number(a.start?.split(':')?.join('') || '0');
    const bStart = Number(b.start?.split(':')?.join('') || '0');
    return aStart - bStart;
  });
  return options;
};

const sortAvailabilityOptions = (
  options: AvailabilityData,
): AvailabilityData => {
  const newData: SortedAvailability = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  };
  options.forEach((item) => {
    if (!(item['day'] in newData)) {
      newData[item['day']] = [];
    }
    newData[item['day']].push(item);
  });
  return [
    ...sortAvailabilityOptionsByTime(newData.monday),
    ...sortAvailabilityOptionsByTime(newData.tuesday),
    ...sortAvailabilityOptionsByTime(newData.wednesday),
    ...sortAvailabilityOptionsByTime(newData.thursday),
    ...sortAvailabilityOptionsByTime(newData.friday),
    ...sortAvailabilityOptionsByTime(newData.saturday),
    ...sortAvailabilityOptionsByTime(newData.sunday),
  ].flat();
};

const hasEnoughDifferentDayAvailabilityOptions = (
  availability?: Array<AvailabilityOption>,
): boolean => {
  if (!availability || !availability.length) return true;
  // @ts-ignore
  const count = [...new Set(availability.map((opt) => opt.day).flat())].length;
  return count > 3;
};

const Availability: FC<AvailabilityProps> = (props) => {
  const { userData, onSetUserData } = props;
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [userAvailability, setUserAvailability] = useState<AvailabilityData>(
    userData?.availability || [],
  );

  const [day, setDay] = useState<Day | undefined>('monday');
  const [startTime, setStartTime] = useState<HourOption | undefined>('19:00');
  const [endTime, setEndTime] = useState<HourOption | undefined>('21:00');

  const history = useHistory();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) history.push('/login');
      else {
        getUserByUID(user.uid, (data: any) => {
          setSubmitting(true);
          onSetUserData?.(data);
          setSubmitting(false);
          setUserAvailability(data?.availability || []);
        });
      }
    });
    // eslint-disable-next-line
  }, []);

  const onCreateAvailabilityItem = () => {
    if (!day) return;
    const [startHour, startMinutes] = (startTime || '00:00').split(':');
    const [endHour, endMinutes] = (endTime || '00:00').split(':');
    const startValue = Number(`${startHour}${startMinutes}`);
    const endValue = Number(`${endHour}${endMinutes}`);

    const date = new Date();
    date.setFullYear(1970, 0, days.indexOf(day) + 5);
    date.setHours(Number(startHour));
    date.setMinutes(Number(startMinutes));
    date.setSeconds(0);
    date.setMilliseconds(0);
    const ssMillis = date.valueOf();

    date.setHours(Number(endHour));
    date.setMinutes(Number(endMinutes));
    const eeMillis = date.valueOf();

    const availabilityExists = userAvailability.some((option) => {
      return (
        option.day === day &&
        option.startMillis === ssMillis &&
        option.endMillis === eeMillis
      );
    });
    // TODO: Show an error?
    if (availabilityExists) return;
    const availabilityOption: AvailabilityOption = {
      day: day as Day,
      start: (startValue <= endValue ? startTime : endTime) as HourOption,
      end: (startValue >= endValue ? startTime : endTime) as HourOption,
      startMillis: startValue <= endValue ? ssMillis : eeMillis,
      endMillis: startValue >= endValue ? ssMillis : eeMillis,
    };
    const newAvailability = [...userAvailability, availabilityOption];
    setUserAvailability(newAvailability);
    onSubmit(newAvailability);
  };

  const onRemoveAvailabilityItem = (index: number) => {
    if (index < 0) return;
    if (userAvailability.length <= 0) return;
    const copy = [...userAvailability];
    copy.splice(index, 1);
    setUserAvailability(copy);
    onSubmit(copy);
  };

  const onSubmit = async (
    newAvailability: Array<AvailabilityOption> = userAvailability,
  ) => {
    setSubmitting(true);
    if (userData && userData.id) {
      await updateStudentInformation(userData.id, {
        availability: newAvailability,
      });
    }
    setSubmitting(false);
  };

  return (
    <>
      <div className="flex flex-col mb-10 max-w-prose mx-auto justify-center py-10">
        <LoadingOverlay enabled={submitting} />
        <div className={'flex flex-col gap-4'}>
          <h2 className="text-3xl md:text-4xl text-center font-semibold">
            Let us know your pod meeting availability
          </h2>
          <p className={cx('text-blue-immigo')}>
            Pod is a group of students you will be practicing English with for
            the duration of a course. For your own benefit, you will meet with
            your pod at least <strong>1 hour a week</strong>.
            <br />
            The more availabilities you provide, the more likely it is for us to
            find you the best group of students for you to practice English
            with.
          </p>
          <p>
            Perfect availability overlap is not guaranteed as people&apos;s
            schedule fluctuates. However this information will help us match you
            with a group better suited for your schedule.
          </p>
          <strong>Instruction:</strong>
          <ol className={cx('list-decimal pl-4 -mt-2')}>
            <li>
              Select or type in the day and the time interval you are available
              in the input below. The example below would indicate you’d be free
              to have pod meeting “Every Monday between 7PM to 9PM your
              timezone”
            </li>
            <li>
              Click “Add availability” to add the time interval as your
              availability
            </li>
            <li>
              Repeat step 1 & 2 for other availabilities for different days and
              time.
            </li>
          </ol>
          <p>
            <strong>Example:</strong>
            <br />A good availability schedule looks like:
          </p>
          <ul className={cx('list-disc pl-4 mb-8 -mt-2')}>
            <li>Monday, 08:00 - 11:00</li>
            <li>Tuesday, 10:00 - 13:00</li>
            <li>Wednesday, 09:00 - 12:00</li>
            <li>Thursday, 16:00 - 20:00</li>
            <li>Friday, 14:00 - 18:00</li>
          </ul>
          <form className={'flex flex-col gap-4 form'}>
            <div className={cx('flex items-center justify-evenly gap-2')}>
              <Select
                id={'week-day'}
                label={'Week day'}
                options={days.map((day) => ({
                  value: day,
                  text: capitalizeEveryFirstLetter(day),
                }))}
                value={day}
                onChange={(value: string) => {
                  setDay(value as Day);
                }}
                containerClassName={cx('flex-1')}
              />
              <Input
                id={'start-time'}
                label={'Start time'}
                type={'time'}
                containerClassName={cx('flex-1')}
                value={startTime}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setStartTime(e.target.value as HourOption);
                }}
              />
              <Input
                id={'end-time'}
                label={'End time'}
                type={'time'}
                containerClassName={cx('flex-1')}
                value={endTime}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setEndTime(e.target.value as HourOption);
                }}
              />
            </div>
            <button
              type={'button'}
              disabled={
                submitting ||
                !Boolean(day) ||
                !Boolean(startTime) ||
                !Boolean(endTime)
              }
              className={cx(
                'bg-blue-immigo px-4 py-3 self-center min-w-[96px]',
                'text-center text-white rounded font-semibold',
                'hover:shadow hover:-translate-y-0.5 transform transition-all ease-in-out',
                'disabled:opacity-80 disabled:cursor-not-allowed disabled:transform-none',
              )}
              onClick={() => {
                onCreateAvailabilityItem();
              }}
            >
              Add availability
            </button>
          </form>
          {Boolean(userAvailability) && (
            <>
              <ul className={cx('list-disc ml-4 flex flex-col gap-2')}>
                {sortAvailabilityOptions(userAvailability).map(
                  (option, index) => {
                    return (
                      <li key={index} className={cx('min-h-[16px]')}>
                        <div className={cx('flex items-center gap-4')}>
                          <p>
                            {capitalizeEveryFirstLetter(option.day)},{' '}
                            {option.start} – {option.end}
                          </p>
                          <button
                            className={cx(
                              'text-blue-600 underline',
                              'min-h-[42px] px-1',
                            )}
                            onClick={() => {
                              onRemoveAvailabilityItem(index);
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      </li>
                    );
                  },
                )}
              </ul>
              {!hasEnoughDifferentDayAvailabilityOptions(userAvailability) && (
                <p className={cx('text-orange-600')}>
                  * Please try to add more than 3 different-day availability
                  options for better pod matching results.
                </p>
              )}
              {userAvailability.length > 0 && (
                <button
                  type={'button'}
                  className={cx(
                    'bg-blue-immigo px-4 py-3 self-center min-w-[96px]',
                    'text-center text-white rounded font-semibold',
                    'hover:shadow hover:-translate-y-0.5 transform transition-all ease-in-out',
                    'disabled:opacity-80 disabled:cursor-not-allowed disabled:transform-none',
                  )}
                  onClick={() => {
                    history.push('/courselist');
                  }}
                >
                  Done
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  onSetUserData: (userData: any) =>
    dispatch({ type: 'USER_DATA_SET', userData }),
});

const mapStateToProps = (state: any) => ({
  userData: state.sessionState.userData,
});

export const AvailabilityPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Availability);
