import { capitalizeFirstLetter } from '../../../util/standardization';
import {
  ChatBubbleLeftRightIcon,
  ChatBubbleOvalLeftIcon,
} from '@heroicons/react/24/outline';

export const PreviewQuestionContainerLarger = (props: {
  question: string;
  answer?: string;
}) => {
  const { question, answer } = props;
  return (
    <div className="bg-white rounded-md my-3 shadow px-2 py-4">
      <div className="p-6 text-gray-500 text-2xl text-left flex items-center">
        <ChatBubbleLeftRightIcon className="w-10 h-10 text-gray-500 mr-4 flex-shrink-0" />
        {question}
      </div>
      {answer ? (
        <div className="py-4 p-6 md:py-6 text-left text-xl md:text-2xl border-t border-gray-300 italic text-gray-500 flex items-center">
          {`“${answer}”`}
          <ChatBubbleOvalLeftIcon className="w-8 h-8 md:w-10 md:h-10 text-gray-500 mr-4 flex-shrink-0 ml-auto md:pl-0 pl-2" />
        </div>
      ) : null}
    </div>
  );
};
