import {
  AcademicCapIcon,
  ChatBubbleLeftEllipsisIcon,
  DocumentTextIcon,
  MegaphoneIcon,
  PencilIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { LearningPathRequirementCard } from '../learingPathRequirementCard/learningPathRequirementCard';
import {
  LearningPathLevelPersonal,
  exampleLearningPathLevelPersonal,
} from '../../../types/learningPath';
import exampleIcon from '../../../images/icons/negotiation.svg';
import { SpreadBookingModal } from '../../booking/modals/spreadBookingModal';
import { useEffect, useState } from 'react';
import { ClassType } from '../../../types/class';
import { UserData } from '../../../types/user';
import { useSelector } from 'react-redux';
import {
  getUserBookings,
  getUserFutureBookings,
} from '../../../firebase/subscription/bookings/bookings';
import { BookingData } from '../../../firebase/subscription/subscription';
import { trackLearningPathData } from '../../../features/LearningPath/analytics';

const classTypeToColor: any = {
  idiom: 'green',
  vocab: 'yellow',
  pronunciation: 'blue',
  'free-talking': 'blue',
  grammar: 'red',
};

const classTypeToIcon: any = {
  idiom: ChatBubbleLeftEllipsisIcon,
  vocab: AcademicCapIcon,
  pronunciation: MegaphoneIcon,
  'free-talking': UserGroupIcon,
  grammar: DocumentTextIcon,
};

export const LearningPathLevel = (props: {
  learningPathLevel: LearningPathLevelPersonal;
}) => {
  const { learningPathLevel } = props;

  console.log(learningPathLevel);

  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );
  const learningPathTitle = 'Mastering Professional English Communication';

  const [classSuggestionType, setClassSuggestedType] = useState<
    ClassType | undefined
  >();
  const [openClassSuggestionModal, setOpenClassSuggestionModal] =
    useState(false);

  const [bookedClassSessionIds, setBookedClassSessionIds] = useState<
    Array<string>
  >([]);

  async function updateUserBookings(id: string) {
    const bookings = await getUserFutureBookings(id);
    setBookedClassSessionIds(
      bookings?.map((b: BookingData) => b.classSessionID ?? ''),
    );
  }

  useEffect(() => {
    if (userData?.id) updateUserBookings(userData.id);
  }, [userData]);

  return (
    <div>
      {classSuggestionType && userData?.availableClasses !== undefined ? (
        <SpreadBookingModal
          onBooking={() => {
            setOpenClassSuggestionModal(false);
            trackLearningPathData(
              'Booked class in learning path level suggestion modal',
            );
          }}
          open={openClassSuggestionModal}
          onClose={() => {
            setOpenClassSuggestionModal(false);
          }}
          availableClassesNumber={userData?.availableClasses}
          bookedClassSessionIds={bookedClassSessionIds}
          onOpen={() => {}}
          userID={userData.id}
          classTypeFilter={classSuggestionType}
        />
      ) : null}
      <div className=" mb-2 bg-white rounded-md p-4">
        <span className="flex items-center">
          <img
            className="bg-opacity-50 w-12 h-12 mr-4 fill-current text-red-warning"
            src={exampleIcon}
            alt="share-screen-icon"
          />
          <span className="text-xl font-semibold">{learningPathTitle}</span>
        </span>
        <div className="overflow-hidden rounded-full bg-gray-200 mt-4">
          <div
            className="h-4 rounded-full bg-blue-immigo"
            style={{
              width: `${50}%`,
            }}
          />
        </div>
        <div className="mt-1 flex justify-between">
          <span className="text-blue-immigo text-sm">{`50% complete`}</span>
          <div className="text-sm text-gray-500">{`B1 Level 2`}</div>
        </div>
      </div>
      <div>
        {learningPathLevel.requirements.map((requirement) => {
          return (
            <div className="mb-2">
              <LearningPathRequirementCard
                key={requirement.name}
                colorTheme={classTypeToColor[requirement.type]}
                Icon={classTypeToIcon[requirement.type]}
                title={requirement.name}
                description={requirement.description}
                completed={requirement.completed ?? 0}
                needed={requirement.needed}
                onSuggestionClick={() => {
                  setOpenClassSuggestionModal(true);
                  setClassSuggestedType(requirement.type);
                  trackLearningPathData(
                    'Clicked suggested class in learning path level',
                  );
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
