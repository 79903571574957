import axios from 'axios';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com/matching'
    : 'http://localhost:8080/matching'; // Dev env

export async function generateEloClusters(
  userIDs: Array<string>,
  maxClusterSize: number,
) {
  const data = await axios.post(endPoint + '/generateEloClusters', {
    userIDs,
    maxClusterSize,
  });
  return data?.data;
}

export async function addTeacherCefrValuation(
  studentId: string,
  teacherId: string,
  classSessionId: string,
  level: string,
) {
  const data = await axios.post(endPoint + '/addTeacherCefrValuation', {
    studentId,
    teacherId,
    classSessionId,
    level,
  });
  return data?.data;
}

export async function getUserTeacherRating(
  studentId: string,
  classSessionId: string,
) {
  const data = await axios.get(
    `${endPoint}/getUserTeacherRating/${studentId}/${classSessionId}`,
  );
  return data?.data;
}

export async function getUserTeacherRatingAgg(studentId: string) {
  const data = await axios.get(
    `${endPoint}/getUserTeacherRatingAgg/${studentId}`,
  );
  return data?.data;
}
