import cefrWordRange from '../usefuldata/cefrWordRange.json';

type CEFRType = 'A1' | 'A2' | 'B1' | 'B2' | 'C1' | 'C2';

const CEFR: Array<CEFRType> = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];

export function getCEFRWithWordCount(wordCount: number) {
  let cefr: CEFRType | undefined;
  let nextLevel: CEFRType | undefined;
  let nextLevelNumber: number | undefined;
  for (let i = 0; i < CEFR.length; i++) {
    if (cefrWordRange[CEFR[i]] >= wordCount) {
      cefr = CEFR[i];
      nextLevel = CEFR[i + 1];
      nextLevelNumber = cefrWordRange[CEFR[i]];
      break;
    }
  }

  if (!cefr) cefr = 'C2';
  return {
    level: cefr,
    nextLevel,
    nextLevelNumber,
  };
}

export function cefrToLevelMap(cefr: CEFRType) {
  switch (cefr) {
    case 'A1':
      return 'Level 1';
    case 'A2':
      return 'Level 2';
    case 'B1':
      return 'Level 3';
    case 'B2':
      return 'Level 4';
    case 'C1':
      return 'Level 5';
    case 'C2':
      return 'Level 6';
    default:
      return undefined;
  }
}
