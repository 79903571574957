import { analyticsDashboardClickedPodMatchingForm } from '../../analytics';

export function IncompleteFormBanner({
  name,
  userID,
}: {
  name: string;
  userID: string;
}) {
  return (
    <div className="border border-red-warning px-3 py-4 rounded border-dashed bg-white">
      <strong className="block font-semi-bold text-red-warning text-xl">
        <span
          role="img"
          aria-label="emergency light emoji"
          className="inline-block mr-1"
        >
          👋
        </span>
        STOP, <span className="capitalize">{name}</span>!, have you filled out
        your profile?
      </strong>
      <p className="mt-4">
        We want to give you the{' '}
        <strong>
          best experience possible by matching you with people with similar
          interests
        </strong>
        , so you can have fruitful, safe, friendly conversations and get the
        best bang for your buck. <br />
        Make sure to take extra five minutes to provide thoughtful answers, and
        our algorithm will do the rest.
      </p>
      <a
        href="/additional-info/"
        className="font-semibold underline cursor-pointer text-red-warning mt-4 inline-block"
        onClick={() => analyticsDashboardClickedPodMatchingForm(userID)}
      >
        Complete my profile
      </a>
    </div>
  );
}
