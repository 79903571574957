import { useEffect, useState } from 'react';
import { CsatRadioButton, CsatRadioButtonType } from './csatRadioButton';

export const CsatRadio = (props: {
  onChosen: Function;
  options: Array<CsatRadioButtonType>;
}) => {
  const { onChosen, options } = props;
  const [chosen, setChosen] = useState<undefined | string>();

  useEffect(() => {
    onChosen(chosen);
  }, [chosen]);

  return (
    <div className="flex">
      {options.map((button) => {
        return (
          <CsatRadioButton
            chosenStyle={button.chosenStyle}
            textStyle={button.textStyle}
            color={button.color}
            chosen={button.id === chosen}
            emoji={button.emoji}
            label={button.label}
            id={button.id}
            onClick={() => setChosen(button.id)}
          />
        );
      })}
    </div>
  );
};
