import { useEffect, useState } from 'react';
import {
  returnAllUsers,
  returnAllCourses,
  returnUserByUID,
  returnCourseByUID,
} from '../../../firebase/configuration';
import { countryCodeToName, getCourseWeekLength } from '../../../util/helper';
import { useHistory } from 'react-router-dom';
import CsvDownload from 'react-json-to-csv';
import { getGranularUserData } from '../../../api';
import { LoadingOverlay } from '../../../components/loading';
import moment from 'moment';
import { TableContainer } from '../../../components/table-container/TableContainer';
import {
  capitalizeEveryFirstLetter,
  flattenStudentPodRoster,
} from '../../../util/standardization';
import { returnMeetingSessionDocByUID } from '../../../firebase/configuration';
import {
  AvailabilityOption,
  sortAvailabilityOptionsByTime,
} from '../../Availability/AvailabilityPage';
import { miliToLocalTimeObject } from '../../../util/data';
import { cx } from 'classix';
import InputSubmit from '../../../features/AdminStudentDataViewPage/InputSubmit';
import { updateClassCredit } from '../../../firebase/subscription/classes/classes';
import TextInput from '../../../components/forms/textInput';
import { getUserDailyAttendanceHistory } from '../../../api/bookings';
import { returnLatestDateGivenArrayOfDateStrings } from '../../../util/dates';

const CourseGranularData = ({ pastPodRecordings, course, studentID }: any) => {
  const courseTitle = course.courseTitle;
  const [podMembers, setPodMembers] = useState<Array<any>>([]);

  useEffect(onLoad, []);

  function onLoad() {
    getPodMembers(course);
  }

  async function getPodMembers(course: any) {
    let podIndex = -1;
    if (course && course.podRoster) {
      for (let i = 0; i < course.podRoster.length; i++) {
        if (course.podRoster[i].includes(studentID)) {
          podIndex = i;
          break;
        }
      }
    }
    if (podIndex > -1) {
      const membersIDs = JSON.parse(course.podRoster[podIndex]);
      const newPodMembers = await Promise.all(
        membersIDs.map(async (id: string) => {
          const podMemberDoc = await returnUserByUID(id);
          return podMemberDoc;
        }),
      );
      setPodMembers(newPodMembers);
    }
  }

  function renderPodMembers() {
    return podMembers.map((member: any, index: number) => {
      return (
        <tr>
          <td className="mr-5" key={member.id + member.name}>
            {member.name}
          </td>
          <td className="mr-5" key={member.id + member.email}>
            {member.email}
          </td>
          <td className="mr-5" key={member.id + 'globalAtt'}>
            {member.globalAttendanceRate}
          </td>
          <td className="mr-5" key={member.id + 'age'}>
            {member.age}
          </td>
          <td className="mr-5" key={member.id + member.occupation}>
            {member.occupation}
          </td>
          <td className="mr-5" key={member.id + member.relationshipStatus}>
            {member.relationshipStatus}
          </td>
        </tr>
      );
    });
  }

  function renderPodAttendance(course: any) {
    const courseWeekLength = getCourseWeekLength(course);
    const courseAttendance = course.podAttendance
      ? course.podAttendance[studentID]
      : {};
    const attendenceComponents: any = [];
    for (let i = 0; i < courseWeekLength; i++) {
      const weekAttended =
        courseAttendance && courseAttendance[i] ? true : false;
      attendenceComponents.push(
        <tr>
          <td className="mr-5">{'Week ' + i}</td>
          <td>{weekAttended ? 'Attended' : 'Not Attended'}</td>
        </tr>,
      );
    }

    return attendenceComponents;
  }

  function renderWeeklyRecordingLinks(recordingMap: any) {
    const recordingComponents: any = [];
    for (let weekIndex in recordingMap) {
      const recordings = recordingMap[weekIndex].map(
        (recordingLink: string, index: number) => {
          return (
            <tr className="text-blue-immigo">
              <a href={recordingLink}>{'Recording ' + index}</a>
            </tr>
          );
        },
      );
      recordingComponents.push(
        <tr>
          <td className="mr-5">{'Week ' + weekIndex}</td>
          {recordings}
        </tr>,
      );
    }
    return recordingComponents;
  }

  return (
    <div>
      <div className="font-bold">{courseTitle}</div>
      <div className="text-blue-immigo">Pod Members</div>
      <TableContainer className="auto w-full">
        <tbody>{renderPodMembers()}</tbody>
      </TableContainer>
      <div className="text-blue-immigo">Attendance</div>
      <TableContainer className="auto w-full">
        <tbody>{renderPodAttendance(course)}</tbody>
      </TableContainer>
      <div className="text-blue-immigo">Recordings</div>
      <TableContainer className="auto w-full">
        {pastPodRecordings ? (
          <tbody>
            {renderWeeklyRecordingLinks(pastPodRecordings[course.id])}
          </tbody>
        ) : null}
      </TableContainer>
    </div>
  );
};

const StudentAdditionalInformationBox = ({
  pastPodRecordings,
  enrolledCourses,
  courses,
  studentID,
}: any) => {
  function renderInCourseInformation() {
    const courseInformationComponents = [];
    for (let i in enrolledCourses) {
      const courseID = enrolledCourses[i];
      courseInformationComponents.push(
        <CourseGranularData
          pastPodRecordings={pastPodRecordings}
          course={courses[courseID]}
          studentID={studentID}
        />,
      );
    }

    return courseInformationComponents;
  }

  return <div className="mb-20">{renderInCourseInformation()}</div>;
};

const StudentDemoInfoBox = ({ studentDocument }: any) => {
  return (
    <div>
      <div>
        <span className="text-blue-immigo">Age: </span>{' '}
        <span>{studentDocument.age}</span>{' '}
      </div>
      <div>
        <span className="text-blue-immigo">Occupation: </span>{' '}
        <span>{studentDocument.occupation}</span>{' '}
      </div>
      <div>
        <span className="text-blue-immigo">Life Stage: </span>{' '}
        <span>{studentDocument.relationshipStatus}</span>{' '}
      </div>
      <div>
        <span className="text-blue-immigo">Tried Speaking Partner: </span>{' '}
        <span>{studentDocument.triedSpeakingPartner ? 'Yes' : 'No'}</span>{' '}
      </div>
      {studentDocument.triedSpeakingPartner ? null : (
        <div>
          <span className="text-blue-immigo">Why Haven't Tried Partner: </span>{' '}
          <span>{studentDocument.whyNotTryFindPartner}</span>{' '}
        </div>
      )}
      {studentDocument.haveSpeakingPartner ? null : (
        <div>
          <span className="text-blue-immigo">Why Stopped Talking: </span>{' '}
          <span>{studentDocument.whyStopTalkingToPartner}</span>{' '}
        </div>
      )}
      <div>
        <span className="text-blue-immigo">Past Partner Exp: </span>{' '}
        <span>{studentDocument.partnerExperience}</span>{' '}
      </div>
      <div>
        <span className="text-blue-immigo">Learning Methods Tried: </span>{' '}
        <span>{studentDocument.learningOptionsTried}</span>{' '}
      </div>
      <div>
        <span className="text-blue-immigo">
          Reason for joining the course:{' '}
        </span>{' '}
        <span>{studentDocument.whyJoinedCourse}</span>
      </div>
    </div>
  );
};

type GetLastEnrolledCourseParams = {
  courses: any[];
  enrolledCourses: any[];
};
function getLastEnrolledCourse({
  courses,
  enrolledCourses,
}: GetLastEnrolledCourseParams) {
  return courses?.[enrolledCourses?.[enrolledCourses?.length - 1]]?.courseTitle;
}

type StudentDataBoxProps = {
  name: string;
  email: string;
  referMethod: string;
  referHandle: string;
  phone: string;
  age: number;
  availableClasses: number;
  nationality: string;
  basedCountry: string;
  currentlyHasTutor: boolean;
  exactOcc: string;
  haveKids: boolean | undefined;
  haveSpeakingPartner: boolean;
  timeZone: string;
  learningOptionsTried: string;
  currentlyHasSpeakingPartner: boolean;
  howLongLivedAbroad: string;
  kindOfWork: string;
  livingAbroad: boolean;
  cefrLevel: string;
  meetMoreThanOnce: boolean;
  perfectPodForMe: string;
  podImportance: string;
  planOnLivingAbroad: string;
  preparingForExam: string;
  timezoneDiff: string;
  relationshipStatus: string;
  usesEnglishAtWork: string;
  whenPlannedLivingAbroad: string;
  whichCountryPlanToMove: string;
  whyJoinedCourse: string;
  whyNotTryFindPartner: string;
  attendanceRate: string;
  attendancePerCourse: any;
  clicked: boolean;
  onClick: Function;
  enrolledCourses: any;
  pastPodRecordings: string;
  courses: any;
  studentID: string;
  studentDoc: string;
  courseID: string;
  podNumber?: number;
  meetingSessions?: any;
  availability?: Array<AvailabilityOption>;
  totalAttended?: number;
  totalBooked?: number;
  lastClassDate?: string;
};
const StudentDataBox = ({
  name,
  email,
  referMethod,
  referHandle,
  availableClasses,
  phone,
  age,
  nationality,
  basedCountry,
  currentlyHasTutor,
  exactOcc,
  haveKids,
  haveSpeakingPartner,
  timeZone,
  currentlyHasSpeakingPartner,
  howLongLivedAbroad,
  kindOfWork,
  livingAbroad,
  meetMoreThanOnce,
  perfectPodForMe,
  podImportance,
  planOnLivingAbroad,
  preparingForExam,
  cefrLevel,
  timezoneDiff,
  relationshipStatus,
  usesEnglishAtWork,
  whenPlannedLivingAbroad,
  whichCountryPlanToMove,
  whyJoinedCourse,
  whyNotTryFindPartner,
  learningOptionsTried,
  attendanceRate,
  attendancePerCourse,
  clicked,
  onClick,
  enrolledCourses,
  pastPodRecordings,
  courses,
  studentID,
  studentDoc,
  courseID,
  podNumber,
  meetingSessions,
  availability,
  totalAttended,
  totalBooked,
  lastClassDate,
}: StudentDataBoxProps) => {
  const courseIndex = enrolledCourses?.indexOf(courseID);
  const isIntImm =
    (cefrLevel === 'B1' || cefrLevel === 'B2') &&
    basedCountry !== nationality &&
    basedCountry &&
    nationality;
  const isIntImmToEng =
    (cefrLevel === 'B1' || cefrLevel === 'B2') &&
    (basedCountry === 'US' ||
      basedCountry === 'GB' ||
      basedCountry === 'NZ' ||
      basedCountry === 'AU' ||
      basedCountry === 'CA' ||
      basedCountry === 'IE');
  const isIntEslProf =
    (cefrLevel === 'B1' || cefrLevel === 'B2') &&
    (usesEnglishAtWork === 'Yes, frequently' ||
      usesEnglishAtWork === 'Yes, sometimes');

  function calculateTotalTime() {
    if (
      podNumber !== undefined &&
      meetingSessions &&
      meetingSessions[podNumber - 1]
    ) {
      let minutes = 0;
      const userMeetingSessions = meetingSessions[podNumber - 1];
      userMeetingSessions.forEach((session: any) => {
        if (
          session &&
          session.userTimeData &&
          Object.keys(session.userTimeData).includes(studentID)
        ) {
          minutes += session.userTimeData[studentID];
        }
      });
      return minutes;
    } else {
      return 0;
    }
  }

  function getTag() {
    const tags = [];
    if (isIntImm)
      tags.push(
        <div
          className="inline-flex items-center rounded bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-800"
          key={studentID + ' int im'}
        >
          Intermediate Immigrant
        </div>,
      );
    if (isIntImmToEng)
      tags.push(
        <div
          className="inline-flex items-center rounded bg-purple-100 px-2 py-0.5 text-xs font-medium text-purple-800"
          key={studentID + ' int im'}
        >
          Intermediate Immigrant to English Speaking country
        </div>,
      );
    if (isIntEslProf)
      tags.push(
        <div
          className="inline-flex items-center rounded bg-pink-100 px-2 py-0.5 text-xs font-medium text-pink-800"
          key={studentID + 'esl prof'}
        >
          Intermediate ESL Professional
        </div>,
      );
    if (currentlyHasTutor)
      tags.push(
        <div
          className="inline-flex items-center rounded bg-yellow-100 px-2 py-0.5 text-xs font-medium text-yellow-800"
          key={studentID + 'has tutor'}
        >
          Has tutor
        </div>,
      );
    if (courseID && enrolledCourses?.[enrolledCourses?.length - 1] !== courseID)
      tags.push(
        <div
          className="inline-flex items-center rounded bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800"
          key={studentID + 'returning to future course'}
        >
          Returning to future course
        </div>,
      );
    if (attendancePerCourse?.[courseID]?.attendanceRate > 75)
      tags.push(
        <div
          className="inline-flex items-center rounded bg-indigo-100 px-2 py-0.5 text-xs font-medium text-indigo-800"
          key={studentID + ' completed'}
        >
          Completed student
        </div>,
      );
    return tags;
  }

  return (
    <tbody>
      <tr className="cursor-pointer" onClick={() => onClick()}>
        {podNumber !== undefined ? (
          <td className={'border px-2 py-1'}>{podNumber}</td>
        ) : null}
        {meetingSessions !== undefined && podNumber !== undefined ? (
          <td className={'border px-2 py-1'}>
            {calculateTotalTime() + ' mins'}
          </td>
        ) : null}
        <td className={'border px-2 py-1'}>
          {capitalizeEveryFirstLetter(name)}
        </td>
        <td className={'border px-2 py-1'}>{email}</td>
        <td className={'border px-2 py-1'}>
          <InputSubmit
            type="number"
            placeholder="ex) 5"
            initialValue={availableClasses ?? 0}
            onSubmit={async (value: number) => {
              await updateClassCredit(studentID, value);
            }}
          />
        </td>
        <td className={'border px-2 py-1'}>{getTag()}</td>
        <td className={'border px-2 py-1'}>{referMethod}</td>
        <td className={'border px-2 py-1'}>{referHandle}</td>
        <td className={'border px-2 py-1'}>{totalAttended}</td>
        <td className={'border px-2 py-1'}>{totalBooked}</td>
        <td className={'border px-2 py-1'}>{lastClassDate}</td>
        <td className={'border px-2 py-1'}>{enrolledCourses?.length}</td>
        <td className={'border px-2 py-1'}>{phone}</td>
        <td className={'border px-2 py-1'}>{cefrLevel}</td>
        <td className={'border px-2 py-1'}>{age}</td>
        <td className={'border px-2 py-1'}>
          {countryCodeToName(((nationality || 'UN') as string).toUpperCase())}
        </td>
        <td className={'border px-2 py-1'}>
          {countryCodeToName(((basedCountry || 'UN') as string).toUpperCase())}
        </td>
        <td className={'border px-2 py-1'}>
          {currentlyHasTutor === undefined
            ? 'No Input'
            : currentlyHasTutor
            ? 'Yes'
            : 'No'}
        </td>
        <td className={'border px-2 py-1'}>{preparingForExam}</td>
        <td className={'border px-2 py-1'}>
          {currentlyHasSpeakingPartner === undefined
            ? ''
            : currentlyHasSpeakingPartner
            ? 'Yes'
            : 'No'}
        </td>
        <td className={'border px-2 py-1'}>{howLongLivedAbroad}</td>
        <td className={'border px-2 py-1'}>{kindOfWork}</td>
        <td className={'border px-2 py-1'}>{exactOcc}</td>
        <td className={'border px-2 py-1'}>
          {livingAbroad === undefined ||
          nationality === undefined ||
          basedCountry === undefined
            ? ''
            : livingAbroad || basedCountry !== nationality
            ? 'Yes'
            : 'No'}
        </td>
        <td className={'border px-2 py-1'}>
          {meetMoreThanOnce ? 'Yes' : 'No'}
        </td>
        <td className={'border px-2 py-1'}>
          {haveKids === undefined ? '' : haveKids ? 'Yes' : 'No'}
        </td>
        <td className={'border px-2 py-1'}>
          {haveSpeakingPartner === undefined
            ? ''
            : haveSpeakingPartner
            ? 'Yes'
            : 'No'}
        </td>
        <td className={'border px-2 py-1'}>
          {sortAvailabilityOptionsByTime(availability || []).map(
            (option, index) => {
              const startUTCObject = miliToLocalTimeObject(option.startMillis);
              const endUTCObject = miliToLocalTimeObject(option.endMillis);
              if (startUTCObject && endUTCObject) {
                return (
                  <li key={index} className={cx('min-h-[16px]')}>
                    <p>
                      {`${startUTCObject.day}, ${startUTCObject.hour}:${startUTCObject.minute} - ${endUTCObject.day}, ${endUTCObject.hour}:${endUTCObject.minute}`}
                    </p>
                  </li>
                );
              } else {
                return (
                  <li key={index} className={cx('min-h-[16px]')}>
                    <p>Undefined</p>
                  </li>
                );
              }
            },
          )}
        </td>
        <td className={'border px-2 py-1'}>{timeZone}</td>
        <td className={'border px-2 py-1'}>{learningOptionsTried}</td>
        <td className={'border px-2 py-1'}>{perfectPodForMe}</td>
        <td className={'border px-2 py-1'}>{podImportance}</td>
        <td className={'border px-2 py-1'}>{planOnLivingAbroad}</td>
        <td className={'border px-2 py-1'}>{timezoneDiff}</td>
        <td className={'border px-2 py-1'}>{relationshipStatus}</td>
        <td className={'border px-2 py-1'}>{usesEnglishAtWork}</td>
        <td className={'border px-2 py-1'}>{whenPlannedLivingAbroad}</td>
        <td className={'border px-2 py-1'}>{whichCountryPlanToMove}</td>
        <td className={'border px-2 py-1'}>{whyJoinedCourse}</td>
        <td className={'border px-2 py-1'}>{whyNotTryFindPartner}</td>
        <td className={'border px-2 py-1'}>
          {!!attendanceRate
            ? attendanceRate !== 'NaN'
              ? attendanceRate
              : '0.00'
            : '0.00'}
        </td>
        <td
          className={
            attendancePerCourse &&
            attendancePerCourse[courseID] &&
            attendancePerCourse[courseID].attendanceRate >= 75
              ? 'text-blue-immigo border px-2 py-1'
              : 'border px-2 py-1'
          }
        >
          {attendancePerCourse && attendancePerCourse[courseID]
            ? Number.isNaN(attendancePerCourse[courseID].attendanceRate)
              ? 0
              : attendancePerCourse[courseID].attendanceRate
            : 0}
        </td>
        <td className={'border px-2 py-1 min-w-[192px]'}>
          {getLastEnrolledCourse({ courses, enrolledCourses }) ?? 'N/A'}
        </td>
        <td className={'border px-2 py-1'}>{studentID}</td>
      </tr>
      {clicked ? (
        <td
          className="
                    w-full max-w-prose shadow-lg border-blue-immigo border-2 p-2
                    font-medium bg-white
                    rounded-md
                    mb-10
                    absolute
                    "
        >
          <StudentDemoInfoBox studentDocument={studentDoc} />
          <StudentAdditionalInformationBox
            pastPodRecordings={pastPodRecordings}
            enrolledCourses={enrolledCourses}
            courses={courses}
            studentID={studentID}
          />
        </td>
      ) : null}
    </tbody>
  );
};

export const AdminStudentDataView = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [students, setStudents] = useState<any>(null);
  const [masterStudentList, setMasterStudentList] = useState<any>(null);
  const [searchInput, setSearchInput] = useState<string>('');
  const [courses, setCourses] = useState<any>(null);
  const [meetingSessions, setMeetingSessions] = useState<any>(null);
  const [nations, setNations] = useState<Array<string>>([]);
  const [viewingIndex, setViewingIndex] = useState<number>(-1);
  const [granularUserData, setGranularUserData] = useState<any>([]);

  const courseID = window.location.pathname.split('/')[2];
  const urlParams = new URLSearchParams(window.location.search);
  const minCoursesTaken = parseInt(urlParams.get('minCourses') ?? '-1');
  const maxCoursesTaken = parseInt(urlParams.get('maxCourses') ?? '-1');
  const wasActive = parseInt(urlParams.get('wasActive') ?? '-1');

  const history = useHistory();

  useEffect(onLoad, []);
  useEffect(() => {
    setLoading(false);
  }, [granularUserData]);

  function onLoad() {
    updateStudents();
    updateCourses();
    // updateMeetingSessions();

    if (courseID === 'csv') {
      getGranularUsers();
    }
  }

  async function getGranularUsers() {
    setLoading(true);
    const granularUsers = await getGranularUserData();
    setGranularUserData(
      granularUsers.map((user: any) => {
        return {
          name: user.name,
          createdAt: moment(user.createAt).format(),
          email: user.email,
          globalAttendanceRate: user.globalAttendanceRate,
          numberOfcoursesTaken: user.numberOfcoursesTaken,
          nationality: user.nationality,
          basedCountry: user.basedCountry,
          lastTakenCourse: user.courses[user.courses.length - 1],
        };
      }),
    );
    setLoading(false);
  }

  async function updateMeetingSessions() {
    setLoading(true);
    if (courseID) {
      const courseData = await returnCourseByUID(courseID);
      const indexToMeetingSessionDocs: any = {};

      if (courseData && courseData.meetingSessionMap) {
        const indices = Object.keys(courseData.meetingSessionMap);

        await Promise.all(
          indices.map(async (podIndex: string) => {
            const meetingLists = courseData.meetingSessionMap[podIndex];
            const meetingDocList = await Promise.all(
              meetingLists.map(async (meetingUID: string) => {
                return await returnMeetingSessionDocByUID(meetingUID);
              }),
            );
            indexToMeetingSessionDocs[podIndex] = meetingDocList;
          }),
        );
      }

      setMeetingSessions(indexToMeetingSessionDocs);
    }
  }

  async function updateStudents() {
    function minCourseFilter(user: any) {
      return (
        (minCoursesTaken >= 0 && user.courses.length >= minCoursesTaken) ||
        minCoursesTaken < 0
      );
    }
    function maxCourseFilter(user: any) {
      return (
        (maxCoursesTaken >= 0 && user.courses.length <= maxCoursesTaken) ||
        maxCoursesTaken < 0
      );
    }
    function wasActiveFilter(user: any) {
      return user.courses.length > 0 || user.availableClasses > 0;
    }
    setLoading(true);
    const nationalityList: Array<string> = [];

    // Not given course ID
    if (!courseID) {
      let users = await returnAllUsers();
      console.log(users);

      users.forEach((user) => {
        if (
          user &&
          user.nationality &&
          !nationalityList.includes(user.nationality)
        ) {
          nationalityList.push(user.nationality);
        }
      });
      setNations(nationalityList);

      if (minCoursesTaken >= 0)
        users = users.filter((user) => {
          return minCourseFilter(user);
        });
      if (maxCoursesTaken >= 0)
        users = users.filter((user) => {
          return maxCourseFilter(user);
        });
      if (wasActive === 1)
        users = users.filter((user) => {
          return wasActiveFilter(user);
        });

      // Show highest engaged people at the top
      users.sort((user1: any, user2: any) => {
        let user1attdRate = parseFloat(user1.globalAttendanceRate);
        let user2attdRate = parseFloat(user2.globalAttendanceRate);
        if (!user1attdRate) user1attdRate = 0;
        if (!user2attdRate) user2attdRate = 0;
        return user2attdRate - user1attdRate;
      });
      setMasterStudentList(users);
    } else {
      const courseData = await returnCourseByUID(courseID);

      if (courseData) {
        const userIDsInRoster = courseData.podRoster.map((pod: string) => {
          return JSON.parse(pod);
        });
        const userDocsInRoster = await Promise.all(
          userIDsInRoster.map(async (userIDs: Array<string>) => {
            return await Promise.all(
              userIDs.map(async (userID: string) => {
                return await returnUserByUID(userID);
              }),
            );
          }),
        );

        const flattenedList = flattenStudentPodRoster(userDocsInRoster);
        flattenedList.forEach((user: any) => {
          if (
            user &&
            user.nationality &&
            !nationalityList.includes(user.nationality)
          ) {
            nationalityList.push(user.nationality);
          }
        });
        setNations(nationalityList);
        setMasterStudentList(flattenedList);
      }
    }
    setLoading(false);
  }

  async function updateCourses() {
    setLoading(true);
    const courses = await returnAllCourses();
    const courseMap: any = {};
    courses.forEach((course: any) => {
      courseMap[course.id] = course;
    });
    setLoading(false);
    setCourses(courseMap);
  }

  function onApplicationClick(id: string) {
    history.push('/applications/' + id);
  }

  async function updateStudentList() {
    const filtered = masterStudentList?.filter((student: any) => {
      return (
        student?.email?.includes(searchInput.toLowerCase()) ||
        student?.name?.includes(searchInput.toLowerCase()) ||
        student?.phoneNumber?.includes(searchInput.toLowerCase())
      );
    });

    await Promise.all(
      filtered.map(async (user: any) => {
        const bookingData = await getUserDailyAttendanceHistory(user.id);
        user.bookingData = bookingData;
      }),
    );

    setStudents(filtered);
  }

  useEffect(() => {
    if (searchInput.length > 2) updateStudentList();
  }, [searchInput]);

  return (
    <div className={'max-w-screen-lg mx-auto'}>
      <LoadingOverlay enabled={loading} />
      {/* {granularUserData && granularUserData.length > 0 ? (
        <CsvDownload data={granularUserData} />
      ) : null} */}
      <CsvDownload data={granularUserData} />
      <button
        className="border-2 rounded-md border-solid border-blue-immigo my-2 px-2 py-1 shadow-sm"
        onClick={() => {
          onApplicationClick(courseID);
        }}
      >
        View Applications
      </button>
      <div>{'Total nationality list: ' + nations.length}</div>
      <div>
        {nations.map((nation: string) => {
          return countryCodeToName(nation) + ', ';
        })}
      </div>
      <div className="my-6">
        <TextInput
          value={searchInput}
          onChange={setSearchInput}
          title={'Email/Name/Phonenumber'}
          placeholder={'ex) info@immigo.io, ryan jeon, 19493820780'}
        />
      </div>

      {searchInput.length > 0 ? (
        <TableContainer className="table-auto">
          <thead>
            <tr>
              {courseID ? (
                <th className={'border px-2 py-1 text-left'}>Pod #</th>
              ) : null}
              {meetingSessions ? (
                <th className={'border px-2 py-1 text-left'}>
                  Total Meeting Time (In own pod)
                </th>
              ) : null}
              <th className={'border px-2 py-1 text-left'}>Name</th>
              <th className={'border px-2 py-1 text-left'}>Email</th>
              <th className={'border px-2 py-1 text-left'}>Class Credits</th>
              <th className={'border px-2 py-1 text-left'}>Tag</th>
              <th className={'border px-2 py-1 text-left'}>Refer method</th>
              <th className={'border px-2 py-1 text-left'}>Handle</th>
              <th className={'border px-2 py-1 text-left'}>
                # of classes taken
              </th>
              <th className={'border px-2 py-1 text-left'}>
                # of classes booked
              </th>
              <th className={'border px-2 py-1 text-left'}>last class date</th>
              <th className={'border px-2 py-1 text-left'}>
                # of courses taken
              </th>
              <th className={'border px-2 py-1 text-left'}>Phone</th>
              <th className={'border px-2 py-1 text-left'}>CEFR</th>
              <th className={'border px-2 py-1 text-left'}>Age</th>
              <th className={'border px-2 py-1 text-left'}>Country</th>
              <th className={'border px-2 py-1 text-left'}>Based Country</th>
              <th className={'border px-2 py-1 text-left'}>
                Currently has tutor
              </th>
              <th className={'border px-2 py-1 text-left'}>
                Preparing for exam
              </th>
              <th className={'border px-2 py-1 text-left'}>
                Currently has speaking partner
              </th>
              <th className={'border px-2 py-1 text-left'}>
                How long live abroad
              </th>
              <th className={'border px-2 py-1 text-left'}>kindOfWork</th>
              <th className={'border px-2 py-1 text-left'}>exactOcc</th>
              <th className={'border px-2 py-1 text-left'}>livingAbroad</th>
              <th className={'border px-2 py-1 text-left'}>
                meetMoreThanOncefWork
              </th>
              <th className={'border px-2 py-1 text-left'}>haveKids</th>
              <th className={'border px-2 py-1 text-left'}>
                haveSpeakingPartner
              </th>
              <th className={'border px-2 py-1 text-left'}>Availability</th>
              <th className={'border px-2 py-1 text-left'}>timeZone</th>
              <th className={'border px-2 py-1 text-left'}>
                learningOptionsTried
              </th>
              <th className={'border px-2 py-1 text-left'}>perfectPodForMe</th>
              <th className={'border px-2 py-1 text-left'}>podImportance</th>
              <th className={'border px-2 py-1 text-left'}>
                planOnLivingAbroad
              </th>
              <th className={'border px-2 py-1 text-left'}>timezoneDiff</th>
              <th className={'border px-2 py-1 text-left'}>
                relationshipStatus
              </th>
              <th className={'border px-2 py-1 text-left'}>
                usesEnglishAtWork
              </th>
              <th className={'border px-2 py-1 text-left'}>
                whenPlannedLivingAbroad
              </th>
              <th className={'border px-2 py-1 text-left'}>
                whichCountryPlanToMove
              </th>
              <th className={'border px-2 py-1 text-left'}>whyJoinedCourse</th>
              <th className={'border px-2 py-1 text-left'}>
                whyNotTryFindPartner
              </th>
              <th className={'border px-2 py-1 text-left'}>Avg Attendance %</th>
              <th className={'border px-2 py-1 text-left'}>
                Current Course Attendance %
              </th>
              <th className={'border px-2 py-1 text-left min-w-[192px]'}>
                Latest Course
              </th>
              <th className={'border px-2 py-1 text-left min-w-[192px]'}>ID</th>
            </tr>
          </thead>
          {students?.map((student: any, index: number) => {
            return (
              <StudentDataBox
                name={student.name}
                email={student.email}
                referMethod={student.referMethod}
                referHandle={
                  student.instaHandle ??
                  student.youtubeHandle ??
                  student.tiktokHandle ??
                  student.couponCode
                }
                phone={student.phoneNumber}
                nationality={student.nationality}
                basedCountry={
                  student.basedCountry ? student.basedCountry : null
                }
                currentlyHasTutor={student.currentlyHasTutor}
                age={student.age}
                currentlyHasSpeakingPartner={
                  student.currentlyHasSpeakingPartner
                }
                howLongLivedAbroad={student.howLongLivedAbroad}
                kindOfWork={student.kindOfWork}
                cefrLevel={student.cefrLevel}
                livingAbroad={student.livingAbroad}
                meetMoreThanOnce={student.meetMoreThanOnce}
                exactOcc={student.exactOcc}
                availableClasses={student.availableClasses}
                lastClassDate={returnLatestDateGivenArrayOfDateStrings(
                  Object.keys(student?.bookingData?.attendance ?? {})?.filter(
                    (a: any) => student?.bookingData?.attendance?.[a]?.attended,
                  ),
                )}
                haveKids={student.haveKids}
                haveSpeakingPartner={student.haveSpeakingPartner}
                timeZone={student.timeZone}
                learningOptionsTried={student.learningOptionsTried}
                perfectPodForMe={student.perfectPodForMe}
                podImportance={student.podImportance}
                planOnLivingAbroad={student.planOnLivingAbroad}
                preparingForExam={student.preparingForExam}
                timezoneDiff={student.timezoneDiff}
                relationshipStatus={student.relationshipStatus}
                usesEnglishAtWork={student.usesEnglishAtWork}
                whenPlannedLivingAbroad={student.whenPlannedLivingAbroad}
                whichCountryPlanToMove={student.whichCountryPlanToMove}
                whyJoinedCourse={student.whyJoinedCourse}
                whyNotTryFindPartner={student.whyNotTryFindPartner}
                attendanceRate={student.globalAttendanceRate}
                attendancePerCourse={student.attendancePerCourse}
                clicked={viewingIndex === index}
                totalAttended={student.bookingData?.totalAttended ?? 0}
                totalBooked={student.bookingData?.totalBooked ?? 0}
                onClick={() => {
                  if (viewingIndex !== index) setViewingIndex(index);
                  else setViewingIndex(-1);
                }}
                pastPodRecordings={student.pastPodRecordings}
                courses={courses}
                enrolledCourses={student.courses}
                studentID={student.id}
                studentDoc={student}
                courseID={courseID}
                podNumber={student.podNumber}
                meetingSessions={meetingSessions}
                key={student.id}
                availability={student.availability}
              />
            );
          })}
        </TableContainer>
      ) : (
        <div className="w-full flex text-center justify-center">
          <div className="text-5xl">Start typing above to get result</div>
        </div>
      )}
    </div>
  );
};
