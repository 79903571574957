import { useState, useEffect } from 'react';
import {
  returnCourseByUID,
  returnAppByUID,
  enrollStudentInCourse,
  returnReferrerDataWithCode,
  returnUserByUID,
} from '../../../firebase/configuration';
import { LoadingOverlay } from '../../../components/loading';
import moment from 'moment';
import { TableContainer } from '../../../components/table-container/TableContainer';
import { Link, useHistory } from 'react-router-dom';
import { updateApplicationStatus } from '../../../firebase/applications/applications';
import { createCharge } from '../../../api/payment';
import { getEarliestApp } from '../../../util/data';

export const ApplicationView = ({ userData, onSetUserData }: any) => {
  const [applications, setApplications] = useState<any>(null);
  const [course, setCourse] = useState<any>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [totalCollectedAmount, setTotalCollectedAmount] = useState<number>(0);
  const [totalImmigoTrafficCount, setTotalImmigoTrafficCount] =
    useState<number>(0);
  const [totalInstructorTrafficCount, setTotalInstructorTrafficCount] =
    useState<number>(0);
  const [totalReferredTrafficCount, setTotalReferredTrafficCount] =
    useState<number>(0);

  // Trial Stats
  const [totalTrialSignedUpCount, setTotalTrialSignedUpCount] =
    useState<number>(0);
  const [totalTrialSignedUpFirstTime, setTotalTrialSignedUpFirstTime] =
    useState<number>(0);
  const [totalTrialConverted, setTotalTrialConverted] = useState<number>(0);
  const [
    totalTrialSignedUpFirstTimeConverted,
    setTotalTrialSignedUpFirstTimeConverted,
  ] = useState<number>(0);

  const [students, setStudents] = useState<Array<any>>([]);

  const history = useHistory();

  const courseID = window.location.pathname.split('/')[2];

  useEffect(onLoad, []);

  function onLoad() {
    updateApplications();
  }

  async function updateApplications() {
    setSubmitting(true);
    const courseData = await returnCourseByUID(courseID);
    setCourse(courseData);

    if (courseData && courseData.applications) {
      const applicationIDs: Array<string> = [];
      let totalCollected = 0;
      let immigoTrafficCount = 0;
      let instructorTrafficCount = 0;
      let referredCount = 0;

      //Trial stats
      let trialSignedUpCount = 0;
      let trialSignedUpFirstTime = 0;
      let trialConverted = 0;
      let trialFirstTimecConverted = 0;

      Object.keys(courseData.applications).forEach((userID: string) => {
        applicationIDs.push(courseData.applications[userID]);
      });

      const studentMap: any = {};

      const applications = await Promise.all(
        applicationIDs.map(async (appID: string, key: number) => {
          const application = await returnAppByUID(appID);
          const student = await returnUserByUID(application?.studentID);
          const earliestApp = await getEarliestApp(student?.applications);
          if (student) student.earliestApp = earliestApp;
          studentMap[application?.studentID] = student;

          // Trial Stuff
          if (earliestApp.id === appID && application)
            application.earliestApp = true;
          if (application?.trial) trialSignedUpCount += 1;
          if (application?.trial && application.earliestApp)
            trialSignedUpFirstTime += 1;
          if (application?.trial && application.paid) trialConverted += 1;
          if (application?.trial && application.paid && application.earliestApp)
            trialFirstTimecConverted += 1;

          if (application && application.paid) {
            if (application?.paidPrice) totalCollected += application.paidPrice;
            if (!courseData.students.includes(application.studentID)) {
              application.needToBeAdded = true;
            }
          }

          if (application && application.referralCode) referredCount++;
          else if (application && application.distributorType) {
            if (application.distributorType === 'immigo') immigoTrafficCount++;
            else if (application.distributorType === 'referral')
              referredCount++;
          } else if (application) {
            instructorTrafficCount++;
          }

          // If student was referred
          if (application && application.referralCode) {
            const referrerData: any = await returnReferrerDataWithCode(
              application.referralCode,
            );
            if (referrerData) {
              application.referrerName = referrerData.name;
              application.referrerEmail = referrerData.email;
            }
          }

          return application;
        }),
      );

      totalCollected = parseFloat(totalCollected.toFixed(2));

      if (totalCollected !== undefined) setTotalCollectedAmount(totalCollected);
      setTotalImmigoTrafficCount(immigoTrafficCount);
      setTotalInstructorTrafficCount(instructorTrafficCount);
      setTotalReferredTrafficCount(referredCount);

      //Trial stuff
      setTotalTrialSignedUpCount(trialSignedUpCount);
      setTotalTrialSignedUpFirstTime(trialSignedUpFirstTime);
      setTotalTrialConverted(trialConverted);
      setTotalTrialSignedUpFirstTimeConverted(trialFirstTimecConverted);

      applications.sort((app1: any, app2: any) => {
        if (app1.createdAt < app2.createdAt) return -1;
        else return 1;
      });
      applications.sort((app1: any, app2: any) => {
        if (app1.paid && app2.paid) {
          return 1;
        } else if (app1.paid) return -1;
        else if (app1.trial) return -1;
        else return 1;
      });
      setSubmitting(false);
      setStudents(studentMap);
      console.log(studentMap);
      setApplications(applications);
    }
  }

  async function markAppRefunded(id: string, refunded: boolean) {
    setSubmitting(true);
    await updateApplicationStatus(id, {
      refunded: refunded,
      paid: !refunded,
    });
    await updateApplications();
    setSubmitting(false);
  }

  async function chargeTrialUser(
    userId: string,
    email: string,
    appID: string,
    paidPrice: number,
    optionToPurchase: string,
    via?: string,
  ) {
    setSubmitting(true);

    const user = await returnUserByUID(userId);
    const customerID = user?.customerID;

    if (via) history.push(`?via=${via}`); // set up via token for rewardful
    await createCharge(
      paidPrice * 100,
      'usd',
      customerID,
      `
        ${course?.courseTitle} : ${optionToPurchase} x1, $${paidPrice}
      `,
    );
    await updateApplicationStatus(appID, {
      paid: true,
    });
    // @ts-ignore
    rewardful('convert', { email: email });

    await updateApplications();
    setSubmitting(false);
  }

  function renderApplications() {
    if (applications) {
      const appComponents = applications.map((app: any) => {
        return (
          <tbody>
            <tr>
              <Link
                key={app.id}
                to={'/profile/' + app.studentID}
                target="_blank"
              >
                <td className="mr-5">{app.name}</td>
              </Link>
              <td className="mr-5">
                {moment(app.createdAt).format('MM/DD/YYYY')}
              </td>
              {app.paid ? (
                <td className="mr-5 text-blue-immigo">Paid</td>
              ) : app.trial && !app.withdrawn ? (
                <td className="mr-5 text-yellow-500">Trial</td>
              ) : app.trial && app.withdrawn ? (
                <td className="mr-5 text-red-500">Trial Withdrawn</td>
              ) : (
                <td className="mr-5 text-red-warning">Unpaid</td>
              )}
              <td className="mr-5">{app.price}</td>
              <td className="mr-5">
                {app.paidPrice - (app.usedCredit ? app.usedCredit : 0)}
              </td>
              <td className="mr-5">{app.usedCredit}</td>
              <td className="mr-5">{app.email}</td>
              <td className="mr-5">
                {app.referralCode ? (
                  <td className="text-blue-immigo">
                    referred (Referred by {app.referrerName})
                  </td>
                ) : app.distributorType ? (
                  app.distributorType
                ) : (
                  'instructor'
                )}
              </td>
              <td className="mr-5">{app.via ? app.via : 'NA'}</td>
              <td>
                {app.refunded ? (
                  <td className="text-red-500">Refunded</td>
                ) : app.transferred ? (
                  <td className="text-yellow-500">Transferred</td>
                ) : app.trial && app.paid ? (
                  <td className="text-green-700">Trial converted</td>
                ) : app.withdrawn ? (
                  <td className="text-red-500">Withdrawn from Trial</td>
                ) : (
                  <td className="text-gray-300">NA</td>
                )}
              </td>
              <td
                className={`mr-5 ${
                  app.earliestApp ? 'text-green-500' : 'text-red-500'
                }`}
              >
                {app.earliestApp ? 'Yes' : 'No'}
              </td>
              <td className="mr-5">{app.studentID}</td>
              {app.needToBeAdded ? (
                <td>
                  <button
                    className="border-2 rounded-md border-solid border-blue-immigo"
                    onClick={async () => {
                      await enrollStudentInCourse(app.courseID, app.studentID);
                      await updateApplications();
                    }}
                  >
                    Add to course
                  </button>
                </td>
              ) : null}
              {app.trial && !app.paid ? (
                <td className="mx-12">
                  <button
                    onClick={() => {
                      chargeTrialUser(
                        app.studentID,
                        app.email,
                        app.id,
                        app.paidPrice,
                        app.optionToPurchase,
                        app.via,
                      );
                    }}
                    type="button"
                    className="text-yellow-500 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs 
                  font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:blue-immigo focus:ring-offset-2"
                  >
                    Charge Customer
                  </button>
                </td>
              ) : null}
              {!app?.refunded && app.paid ? (
                <td className="mx-12">
                  <button
                    onClick={() => {
                      markAppRefunded(app.id, true);
                    }}
                    type="button"
                    className="text-red-warning inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs 
                font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:blue-immigo focus:ring-offset-2"
                  >
                    Mark Refunded
                  </button>
                </td>
              ) : app.refunded !== undefined ? (
                <td className="mx-12">
                  <button
                    onClick={() => {
                      markAppRefunded(app.id, false);
                    }}
                    type="button"
                    className="text-blue-immigo inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs 
                font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:blue-immigo focus:ring-offset-2"
                  >
                    Unmark Refunded
                  </button>
                </td>
              ) : null}
            </tr>
          </tbody>
        );
      });
      return appComponents;
    } else {
      return null;
    }
  }

  return (
    <div>
      <LoadingOverlay enabled={submitting} />
      <div className="font-bold">
        <div>{'Total collected $ amount: $' + totalCollectedAmount}</div>
        <div>{'# of Immigo traffic: ' + totalImmigoTrafficCount}</div>
        <div>{'# of instructor traffic ' + totalInstructorTrafficCount}</div>
        <div>{'# of referred traffic ' + totalReferredTrafficCount}</div>
        <div className="text-blue-immigo mt-2">Trial Stats</div>
        <div>{`Total # of students signed up for trial: ${totalTrialSignedUpCount}`}</div>
        <div>{`Total # of trial students converted to paid: ${totalTrialConverted}`}</div>
        <div>{`Total # of students signed for the trial & this is their first ever course: ${totalTrialSignedUpFirstTime}`}</div>
        <div>{`Total # of students signed for the trial & this is their first ever course (converted): ${totalTrialSignedUpFirstTimeConverted}`}</div>
      </div>
      <TableContainer className="table-auto">
        <thead>
          <tr>
            <th>Name</th>
            <th>Created At</th>
            <th>Paid</th>
            <th>Course Price</th>
            <th>Paid Amount</th>
            <th>Used Credit</th>
            <th>Email</th>
            <th>Distributor</th>
            <th>Via</th>
            <th>Status</th>
            <th>FirstApp</th>
            <th>User ID</th>
          </tr>
        </thead>
        {renderApplications()}
      </TableContainer>
    </div>
  );
};
