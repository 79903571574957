import { useEffect, useState } from 'react';
import { getSessionBookers } from '../../../../../firebase/subscription/subscription';
import { BookerData } from '../../../../../types/bookings';
import { UserData } from '../../../../../types/user';
import { SimpleBackAndNext } from '../../../../../components/buttons/simpleBackAndNext/simpleBackAndNext';
import { WhoDidYouTalkTo } from './pages/whoDidYouTalkTo';
import { updateBookingDetail } from '../../../../../firebase/subscription/bookings/bookings';
import { returnUserByUID } from '../../../../../firebase/configuration';
import { TheirEnglishLevel } from './pages/theirEnglishLevel';
import {
  addEnglishRatingGivenToUser,
  addEnglishRatingToUser,
} from '../../../../../firebase/users/users';
import {
  EnglishLevelRating,
  EnglishLevelRatingGiven,
  EnglishLevelRatingMap,
  EnglishRatingComparativeType,
} from '../../../../../types/rating';
import { SurveyPageThankYou } from './pages/thankYouPage';
import { trackClassLinkRedirect } from '../../../analytics';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';
import { initializeFirebase } from '../../../../../firebase/configValues';
import { PeerReaction } from './pages/reactionPage';
import {
  updateClassRatingInClassSession,
  updatePeerReactionInClassSession,
} from '../../../../../firebase/subscription/classSessions/classSessions';
import { get2DInvertedMapGivenNestedValue } from '../../../../../util/data';
import { ClassRatingSurvey } from './pages/classRatingPage';
const { firestore } = initializeFirebase();

const useBooking = (bookingID: string) => {
  const [data, loading, error] = useDocumentData(
    doc(firestore, `bookings/${bookingID}`),
  );

  if (error) throw error;
  return {
    booking: data,
    bookingLoading: loading,
    bookingError: error,
  };
};

export const PostClassSurveyPage = (props: {
  classSessionID?: string;
  bookingID: string;
  userID?: string;
}) => {
  const { classSessionID, bookingID, userID } = props;
  const [bookers, setBookers] = useState<Array<BookerData>>([]);
  const [chosenMembers, setChosenMembers] = useState<Array<BookerData>>([]);
  const [ratingMap, setRatingMap] = useState<EnglishLevelRatingMap>({});
  const [reactionMap, setReactionMap] = useState<any>({});
  const [classRating, setClassRating] = useState<undefined | string>();
  const [openConfrimModal, setOpenConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [error, setError] = useState('');

  const { booking, bookingLoading, bookingError } = useBooking(bookingID);

  async function updateClassSessionData(id: string) {
    const sessionBookers = await getSessionBookers(id);
    if (sessionBookers) setBookers(sessionBookers); // Update all the bookers
  }

  async function updateBookingData() {
    // const booking = (await getBookingDocument(id)) as BookingData;
    if (booking) {
      if (booking?.englishLevelRatings && booking?.peerReaction) {
        setPage(3); // Already filled out
      } else {
        if (booking?.talkedTo?.length) {
          const userList = (await Promise.all(
            booking.talkedTo.map(async (id: string) => {
              const user = await returnUserByUID(id);
              return user;
            }),
          )) as Array<BookerData>;
          setChosenMembers(userList);
          setPage(1); // Already chosen members
        }

        if (booking?.englishLevelRatings) {
          setRatingMap(booking?.englishLevelRatings);
          setPage(2);
        }
        if (booking?.peerReactionMap) {
          const existingReactionMap = get2DInvertedMapGivenNestedValue(
            booking?.peerReactionMap ?? {},
            booking?.bookerID,
          );
          if (Object.keys(existingReactionMap).length > 0) {
            setReactionMap(existingReactionMap);
            setPage(3);
          }
        }
      }
    }
  }

  async function updateTalkedTo() {
    setLoading(true);
    if (bookingID)
      await updateBookingDetail(bookingID, {
        talkedTo: chosenMembers.map((m) => m.id),
      });
    setLoading(false);
  }

  async function updateLevelRating() {
    setLoading(true);
    if (ratingMap && userID && classSessionID && bookingID) {
      const userIDs = Object.keys(ratingMap);
      const raterData = (await returnUserByUID(userID)) as UserData;
      const { cefrLevel, cefrElo } = raterData;
      await Promise.all(
        userIDs.map(async (id: string) => {
          const ratee = (await returnUserByUID(id)) as UserData;
          console.log(ratee);
          const rateeCEFR = ratee.cefrLevel;
          const rateeElo = ratee.cefrElo;
          const rating = ratingMap[id] ?? 'NA';
          const ratingObj: EnglishLevelRating = {
            ratedAt: new Date().valueOf(),
            ratedBy: userID,
            raterCEFR: cefrLevel || 'NA',
            sessionID: classSessionID,
            bookingID,
            rating,
          };
          if (cefrElo) ratingObj.raterElo = cefrElo;
          await addEnglishRatingToUser(id, ratingObj);

          const ratingObjGiven: EnglishLevelRatingGiven = {
            ratedAt: new Date().valueOf(),
            ratee: id,
            rateeCEFR: rateeCEFR ?? 'NA',
            sessionID: classSessionID,
            bookingID,
            rating,
          };
          if (rateeElo) ratingObjGiven.rateeElo = rateeElo;
          await addEnglishRatingGivenToUser(userID, ratingObjGiven);
        }),
      );
      await updateBookingDetail(bookingID, {
        englishLevelRatings: ratingMap,
      });
    }
    setLoading(false);
  }

  async function updateReaction() {
    setLoading(true);
    if (reactionMap && userID && classSessionID && bookingID) {
      await updatePeerReactionInClassSession(
        classSessionID,
        userID,
        reactionMap,
      );
    }
    setLoading(false);
  }

  async function updateClassRating() {
    setLoading(true);
    console.log(classRating);
    if (classRating && userID && bookingID) {
      await updateClassRatingInClassSession(bookingID, classRating);
    }
    setLoading(false);
  }

  async function onDidNotTalkToAnyone() {
    setLoading(true);
    if (bookingID) {
      await updateBookingDetail(bookingID, {
        didNotTalkToAnyone: true,
        talkedTo: [],
      });
      trackClassLinkRedirect(
        "Clicked I didn't talk to anyone in survey page in the class redirect link",
        {
          bookingID: bookingID,
        },
      );
    }
    setPage(3);
    setLoading(false);
  }

  async function onNext() {
    trackClassLinkRedirect(
      'Clicked next in survey page in the class redirect link',
    );
    switch (page) {
      case 0:
        if (chosenMembers.length > 0) {
          await updateTalkedTo();
          trackClassLinkRedirect(
            'Submitted who I talked to in survey page in the class redirect link',
          );
          setPage(1);
        } else {
          setOpenConfirmModal(true);
        }
        break;
      case 1:
        if (Object.keys(ratingMap).length === chosenMembers.length) {
          await updateLevelRating();
          trackClassLinkRedirect(
            'Submitted their English level in survey page in the class redirect link',
          );
          setPage(2);
        } else {
          setError('Please provide all the input before submitting!');
        }
        break;
      case 2:
        await updateReaction();
        trackClassLinkRedirect('Submitted reaction in the survey page');
        setPage(3);
        break;
      case 3:
        console.log('yak');
        await updateClassRating();
        trackClassLinkRedirect('Submitted class rating in the survey page');
        setPage(4);
        break;
      default:
    }
  }

  function renderPage() {
    const pages = [
      <WhoDidYouTalkTo
        bookers={bookers.filter((b) => {
          return b.id !== userID;
        })}
        confirmModalOpen={openConfrimModal}
        onConfirmModalClose={() => {
          setOpenConfirmModal(false);
        }}
        chosenMembers={chosenMembers}
        onDidNotTalkToAnyone={onDidNotTalkToAnyone}
        onChosenChange={(chosen: Partial<UserData>) => {
          setChosenMembers(chosen as Array<BookerData>);
        }}
        confirmLoading={loading}
      />,
      <TheirEnglishLevel
        chosenMembers={chosenMembers}
        ratingMap={ratingMap}
        onRatingChange={(uid: string, rating: string) => {
          setError('');
          trackClassLinkRedirect('Chose rating in the survey page', {
            rating,
            ratee: uid,
          });
          const newRating: EnglishLevelRatingMap = { ...ratingMap };
          newRating[uid] = rating as EnglishRatingComparativeType;
          console.log(newRating);
          setRatingMap(newRating);
        }}
      />,
      <PeerReaction
        chosenMembers={chosenMembers}
        reactionMap={reactionMap}
        onReactionChange={(uid: string, reaction: string) => {
          setError('');
          trackClassLinkRedirect('Chose reaction in the survey page', {
            reaction,
            ratee: uid,
          });
          const newReaction: any = { ...reactionMap };
          newReaction[uid] = reaction;
          setReactionMap(newReaction);
        }}
      />,
      <ClassRatingSurvey
        onChosen={(id: string) => {
          setClassRating(id);
        }}
      />,
      <SurveyPageThankYou />,
    ];
    return pages[page];
  }

  useEffect(() => {
    if (classSessionID) {
      updateClassSessionData(classSessionID);
    }
  }, [classSessionID]);

  useEffect(() => {
    if (booking) {
      updateBookingData();
    }
  }, [booking]);

  useEffect(() => {
    trackClassLinkRedirect('Showed survey page in the class redirect link');
  }, []);

  return (
    <div className="max-w-screen max-h-screen px-6">
      {page < 4 ? <div className="text-center text-6xl my-12">📝</div> : null}
      <div className="max-w-[800px]">{renderPage()}</div>
      {error ? <div className="text-red-warning mt-2">{error}</div> : null}
      {page < 4 ? (
        <div className="flex justify-end">
          <SimpleBackAndNext
            onBack={
              page !== 0
                ? () => {
                    trackClassLinkRedirect(
                      'Clicked back in survey page in the class redirect link',
                    );
                    setPage(page - 1);
                  }
                : undefined
            }
            onNext={onNext}
            nextLoading={loading}
          />
        </div>
      ) : null}
    </div>
  );
};
