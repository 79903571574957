import { arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore';
import {
  UserData,
  UserPointHistory,
  UserPointHistoryReasonType,
} from '../../types/user';
import { initializeFirebase } from '../configValues';
import { addAvailableClassesToUser } from '../subscription/subscription';
const { firestore: db } = initializeFirebase();

// Can be +-
export const addPointHistoryToUser = async (
  userID: string,
  amount: number,
  reason: UserPointHistoryReasonType,
) => {
  const pointHistory: UserPointHistory = {
    createdAt: new Date().valueOf(),
    amount,
    reason,
  };
  const userDoc = doc(db, 'users', userID);
  const userData = (await getDoc(userDoc)).data() as UserData;
  const points = userData?.points ?? 0;

  // Update history
  await updateDoc(userDoc, {
    pointsHistory: arrayUnion(pointHistory),
  });
  // Update total points
  await updateDoc(userDoc, {
    points: points + amount,
  });

  return pointHistory;
};

export const redeemPointsToGetClass = async (userID: string) => {
  const userDoc = doc(db, 'users', userID);
  const userData = (await getDoc(userDoc)).data() as UserData;

  // Insufficient points
  if (!userData?.points || userData.points < 100) {
    return null;
  } else {
    const history = await addPointHistoryToUser(
      userID,
      -100,
      'claimed-free-class',
    );
    await addAvailableClassesToUser(userID, 1);
    return history;
  }
};
