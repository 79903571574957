import { useEffect, useState } from 'react';
import VerticalMessageModal from '../../modals/verticalMessageModal/verticalMessageModal';
import { SparkleButton } from '../sparkle/sparkleButton';
import { slackCustomerNotifier } from '../../../api/slack';
import { trackBuyMoreCreditButton } from './analytics';
import { BuyMoreCreditModal } from '../../modals/buyMoreCreditModal/buyMoreCreditModal';

export const BuyOneCreditButton = (props: {
  email: string;
  customerId: string;
  userId: string;
  number?: string;
}) => {
  const { email, number, userId, customerId } = props;

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openBuyModal, setOpenBuyModal] = useState(false);

  async function onButtonClick() {
    setOpenBuyModal(true);
    trackBuyMoreCreditButton('Buy more credit button clicked', {
      email,
    });
    // const res = await slackCustomerNotifier(
    //   `${email} wants: I want to buy one more credit-- WA Link : https://wa.me/${number}`,
    // );
  }

  useEffect(() => {
    trackBuyMoreCreditButton('Buy more credit button shown', {
      email,
    });
  }, []);

  return (
    <div>
      <VerticalMessageModal
        mainMessage="Success!"
        theme="success"
        subMessage="Your credit will be updated within 12 hours! This process will become instant in the future, so bear with us!"
        buttonText="Yay!"
        isOpen={openSuccessModal}
        onButtonClick={() => {
          setOpenSuccessModal(false);
        }}
        onCloseModal={() => {
          setOpenSuccessModal(false);
          trackBuyMoreCreditButton('Closed buy more credit button', { email });
        }}
      />
      <BuyMoreCreditModal
        userId={userId}
        isOpen={openBuyModal}
        customerId={customerId}
        userPhone={number}
        userEmail={email}
        onClose={() => {
          setOpenBuyModal(false);
        }}
      />
      <SparkleButton text={'Buy 1 credit for $4.99'} onClick={onButtonClick} />
    </div>
  );
};
