import { Menu } from '@headlessui/react';
import {
  CalendarIcon,
  ClockIcon,
  LinkIcon,
  TrashIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { cx } from 'classix';
import moment from 'moment';
import { useEffect, useState } from 'react';
import AvatarGroupStacked from '../../../../components/avatar/avatarGroupStacked/avatarGroupStacked';
import {
  BookingData,
  getSessionBookers,
} from '../../../../firebase/subscription/subscription';
import { UserData } from '../../../../types/user';
import { capitalizeEveryFirstLetter } from '../../../../util/standardization';
import { Avatar } from '../../../../components/avatar/avatar';

interface UpcomingClassContainerProps extends BookingData {
  onTrashClick?: Function;
  onClassLinkClick?: Function;
}

export const UpcomingClassContainer = ({
  id,
  startMili,
  title,
  recurring,
  classSessionID,
  instructorPhotoUrl,
  durationMili,
  onTrashClick,
  onClassLinkClick,
}: UpcomingClassContainerProps) => {
  const [timeLeftToStartMoment, setTimeLeftToStart] = useState<
    moment.Duration | undefined
  >();
  const [bookersList, setBookersList] = useState<Array<UserData>>([]);

  const startingSoon = startMili - new Date().valueOf() < 1000 * 60 * 60 * 24;
  const inProgress =
    startMili <= new Date().valueOf() &&
    startMili + durationMili >= new Date().valueOf();

  useEffect(() => {
    updateBookers();
    if (startingSoon) {
      setTimeLeftToStart(moment.duration(startMili - new Date().valueOf()));
      setInterval(() => {
        setTimeLeftToStart(moment.duration(startMili - new Date().valueOf()));
      }, 1000);
    }
  }, []);

  async function updateBookers() {
    if (!classSessionID) return;
    const bookers: Array<UserData> = (await getSessionBookers(
      classSessionID,
    )) as Array<UserData>;
    setBookersList(bookers);
  }

  return (
    <li className={'relative flex space-x-6 py-6 xl:static'}>
      <div className="flex-auto">
        <div className="flex items-center">
          {instructorPhotoUrl ? (
            <div className="mr-2 flex-shrink-0">
              <Avatar photoUrl={instructorPhotoUrl} />
            </div>
          ) : null}
          <h3
            className={cx(
              startingSoon ? 'text-blue-immigo' : 'text-gray-900',
              ' pr-10 font-semibold xl:pr-0',
            )}
          >
            {title}
          </h3>
        </div>
        <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
          <div className="flex items-start space-x-3">
            <dt className="mt-0.5">
              <span className="sr-only">Date</span>
              <CalendarIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </dt>
            <dd>
              <time dateTime={moment(startMili).format()}>
                {`${moment(startMili)
                  .local()
                  .format('MMMM Do, YYYY [at] hh:mm A')} - ${moment(startMili)
                  .add(durationMili)
                  .local()
                  .format('hh:mm A')}`}
              </time>
            </dd>
          </div>
          {recurring ? (
            <div className="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
              <dt className="mt-0.5">
                <span className="sr-only">Schedule</span>
                <ClockIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </dt>
              <dd>Booked every {moment(startMili).format('dddd')}</dd>
            </div>
          ) : null}
        </dl>
        <dl className="mt-2">
          <div className="flex items-start space-x-3 text-gray-500">
            <dt className="mt-0.5">
              <span className="sr-only">Date</span>
              <LinkIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd
              onClick={() => {
                onClassLinkClick?.();
              }}
            >
              {classSessionID ? (
                <a
                  className="cursor-pointer hover:text-blue-immigo break-all"
                  target={'_blank'}
                  href={`${window.location.origin}/class/${classSessionID}`}
                  rel="noreferrer"
                >{`${window.location.origin}/class/${classSessionID}`}</a>
              ) : (
                `Link coming soon! (Will be available before the class)`
              )}
            </dd>
          </div>
        </dl>
        {bookersList.length > 0 ? (
          <dl className="mt-2">
            <div className="flex items-center space-x-3 text-gray-500">
              <dt className="mt-0.5">
                <span className="sr-only">Date</span>
                <UserGroupIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </dt>
              <dd>
                <AvatarGroupStacked
                  key={`avatar-${id}-${startMili}`}
                  limit={6}
                  imageLinks={bookersList.map(
                    (booker: UserData) => booker.profilePictureLink,
                  )}
                  nationalities={bookersList.map(
                    (booker: UserData) => booker.nationality ?? '',
                  )}
                  names={bookersList.map((booker: UserData) => booker.name)}
                  ids={bookersList.map((booker: UserData) => booker.id)}
                />
              </dd>
            </div>
          </dl>
        ) : null}

        {startingSoon && timeLeftToStartMoment && !inProgress ? (
          <dl className="mt-2">
            <div className="flex items-start space-x-3 text-gray-500">
              <dt className="mt-0.5">
                <span className="sr-only">Date</span>
                <ClockIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </dt>
              <dd>
                {`Starting in ${
                  timeLeftToStartMoment.days()
                    ? timeLeftToStartMoment.days() + ' days '
                    : ''
                }
            ${
              timeLeftToStartMoment.hours()
                ? timeLeftToStartMoment.hours() + ' hours'
                : ''
            }
            ${
              timeLeftToStartMoment.minutes()
                ? timeLeftToStartMoment.minutes() + ' minutes'
                : ''
            } 
            ${
              timeLeftToStartMoment.seconds()
                ? timeLeftToStartMoment.seconds() + ' seconds'
                : ''
            }`}
              </dd>
            </div>
          </dl>
        ) : inProgress ? (
          <dl className="mt-2">
            <div className="flex items-start space-x-3 text-gray-500">
              <dd className="flex justify-center items-center">
                <span className="relative flex h-3 w-3 mr-5">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
                </span>
                <span>Class in progress!</span>
              </dd>
            </div>
          </dl>
        ) : null}
      </div>

      {onTrashClick ? (
        <Menu
          as="div"
          className="absolute right-0 top-6 xl:relative xl:right-auto xl:top-auto xl:self-center"
        >
          <div>
            <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
              <span className="sr-only">Cancle Booking</span>
              <TrashIcon
                onClick={() => {
                  onTrashClick?.();
                }}
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
        </Menu>
      ) : null}
    </li>
  );
};
