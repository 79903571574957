import cx from 'classix';

export const PrimaryButton = (props: {
  text: string;
  onClick: Function;
  loading?: boolean;
  loadingWithText?: boolean;
  className?: string;
  disabled?: boolean;
}) => {
  return (
    <button
      className={cx(
        props.disabled
          ? 'bg-gray-400'
          : 'bg-blue-immigo hover:bg-blue-immigo-lighter',
        'flex text-white text-sm font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full items-center justify-center',
        props.className,
      )}
      onClick={(e) => props.onClick(e)}
      disabled={props.disabled || props.loading}
    >
      {props.loading || props.loadingWithText ? (
        <div className="mx-2">
          <svg
            className="text-white animate-spin h-5 w-5 mx-auto"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      ) : null}
      {!props.loading ? props.text : null}
    </button>
  );
};
