import { CSSProperties, FC, memo, PropsWithChildren } from 'react';
import cx from 'classix';

import { FieldError } from '../field-error';

interface BaseProps {
  id?: string;
  name?: string;
  label: string;
  hideLabel?: boolean;
  touched?: boolean;
  error?: string | null;
  description?: string;

  containerClassName?: string;
  containerStyle?: CSSProperties;

  labelClassName?: string;
  labelStyle?: CSSProperties;

  descriptionClassName?: string;
  descriptionStyle?: CSSProperties;

  errorClassName?: string;
  errorStyle?: CSSProperties;

  hide?: boolean;
  required?: boolean;
  optional?: boolean;
}

const CustomFieldComponent: FC<PropsWithChildren<BaseProps>> = (props) => {
  const {
    id,
    name,
    label,
    hideLabel,
    description,
    touched,
    error,
    containerClassName,
    containerStyle,
    labelClassName,
    labelStyle,
    descriptionClassName,
    descriptionStyle,
    errorClassName,
    errorStyle,
    hide,
    required = true,
    optional = !required,
    ...otherProps
  } = props;

  return (
    <div
      className={cx(
        'flex flex-col items-start',
        containerClassName,
        hide && 'hidden select-none pointer-events-none',
      )}
      style={containerStyle}
    >
      <label
        htmlFor={id || name}
        className={cx(
          'block text-sm font-medium leading-6 text-gray-900',
          hideLabel && 'hidden select-none pointer-events-none',
          labelClassName,
        )}
        style={labelStyle}
      >
        {label}
        {!required || optional ? (
          <>
            {' '}
            <span className={cx('opacity-75 select-none')}>(optional)</span>
          </>
        ) : null}
      </label>
      {description ? (
        <p
          className={cx('opacity-70 text-sm', descriptionClassName)}
          style={descriptionStyle}
        >
          {description}
        </p>
      ) : null}
      <div className="mt-2">{otherProps.children}</div>
      <FieldError
        touched={touched}
        error={error}
        className={errorClassName}
        style={errorStyle}
      />
    </div>
  );
};

export const CustomField = memo(CustomFieldComponent);
