import { signInWithCustomToken } from 'firebase/auth';
import { authCreate } from '../../api/auth';
import { SimpleLoginForm } from '../../components/forms/simpleLoginForm/simpleLoginForm';
import { initializeFirebase } from '../../firebase/configValues';
import { useSelector } from 'react-redux';
import { UserData } from '../../types/user';
import { useEffect, useState } from 'react';
import { LoadingOverlay } from '../../components/loading';

const { auth } = initializeFirebase();

export const GuestPassCreateAccount = () => {
  const [loading, setLoading] = useState(false);

  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );

  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email') ?? undefined;
  const name = urlParams.get('name') ?? undefined;
  const uid = urlParams.get('uid') ?? undefined;
  const passId = urlParams.get('passId') ?? undefined;

  async function onSubmit(email: string, password: string, uid: string) {
    setLoading(true);
    const authToken = (await authCreate(email, password, uid)).data
      ?.firebaseToken;
    console.log(authToken);
    const authData = await signInWithCustomToken(auth, authToken);
    setLoading(false);
  }

  useEffect(() => {
    if (userData) {
      window.location.href = '/home';
    }
  }, [userData]);

  return (
    <div>
      <LoadingOverlay enabled={loading} />
      <img
        className="md:mx-auto h-10 mr-auto"
        src="https://immigoimages.s3.amazonaws.com/Immigo_new_logo.png"
        alt="Your Company"
      />
      <div className="text-2xl">
        Set your password to claim your guest pass!
      </div>
      <div className="bg-white px-6 py-6 shadow sm:rounded-lg sm:px-12 mt-5">
        <SimpleLoginForm
          disableOAuth
          defaultEmail={email}
          defaultName={name}
          defaultId={uid}
          onSubmit={async (email: string, password: string, uid: string) => {
            await onSubmit(email, password, uid);
          }}
        />
      </div>
    </div>
  );
};
