import mixpanel from '../../../../analytics/mixpanel';

type Events =
  | 'Generated referral code'
  | 'Copied referral code'
  | 'Clicked see more in referral container'
  | 'Clicked inside referral container';

type ReferralContainerData = {};

export const trackReferralContainer = (
  event: Events,
  data?: ReferralContainerData,
) => {
  mixpanel.track(event, data);
};
