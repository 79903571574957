import { Dialog, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ClockIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  FlagIcon,
  PencilIcon,
  PhoneIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import Modal from '../../modal';
import VerticalModalTemplate from '../templates/verticalModalTemplate/verticalModalTemplate';

type IconThemes =
  | 'no-icon'
  | 'check-mark'
  | 'clock'
  | 'exclamation'
  | 'exclamation-triangle'
  | 'flag'
  | 'phone'
  | 'pencil'
  | 'star';

type ModalThemes =
  | 'default'
  | 'warning-red'
  | 'info'
  | 'warning-yellow'
  | 'success';

interface VerticalMessageModalProps {
  mainMessage: string;
  subMessage: string;
  customWidthStyle?: string;
  isOpen?: boolean;
  onCloseModal?: Function;
  onModalOpen?: Function;
  buttonText?: string;
  secondaryButtonText?: string;
  onSecondaryButtonClick?: Function;
  sparkleButtonText?: string;
  sparkleButtonOnClick?: Function;
  stayOpenOnPrimaryClick?: boolean;
  onButtonClick?: Function;
  children?: React.ReactNode;
  icon?: IconThemes;
  noCloseOnSecondaryButtonClick?: boolean;
  theme: ModalThemes;
}

const themeToColorMap = {
  default: 'blue-immigo',
  success: 'green-600',
  'warning-red': 'red-600',
  'warning-yellow': 'yellow-600',
  info: 'blue-600',
};

const themeToLightColorMap = {
  default: 'blue-100',
  success: 'green-100',
  'warning-red': 'red-100',
  'warning-yellow': 'yellow-100',
  info: 'blue-100',
};

const VerticalMessageModal = ({
  mainMessage,
  subMessage,
  isOpen,
  onCloseModal,
  customWidthStyle,
  onModalOpen,
  buttonText,
  onButtonClick,
  secondaryButtonText,
  sparkleButtonText,
  sparkleButtonOnClick,
  stayOpenOnPrimaryClick,
  onSecondaryButtonClick,
  children,
  icon,
  noCloseOnSecondaryButtonClick,
  theme,
}: VerticalMessageModalProps) => {
  function renderIcon() {
    const cs = `h-6 w-6 text-${themeToColorMap[theme]}`;
    switch (icon) {
      case 'no-icon':
        return null;
      case 'check-mark':
        return <CheckIcon className={cs} aria-hidden="true" />;
      case 'clock':
        return <ClockIcon className={cs} aria-hidden="true" />;
      case 'exclamation':
        return <ExclamationCircleIcon className={cs} aria-hidden="true" />;
      case 'exclamation-triangle':
        return <ExclamationTriangleIcon className={cs} aria-hidden="true" />;
      case 'phone':
        return <PhoneIcon className={cs} aria-hidden="true" />;
      case 'flag':
        return <FlagIcon className={cs} aria-hidden="true" />;
      case 'pencil':
        return <PencilIcon className={cs} aria-hidden="true" />;
      case 'star':
        return <StarIcon className={cs} aria-hidden="true" />;
      default:
        return <CheckIcon className={cs} aria-hidden="true" />;
    }
  }
  return (
    <VerticalModalTemplate
      isOpen={isOpen}
      closeModal={onCloseModal}
      primaryButtonText={buttonText}
      primaryButtonOnClick={onButtonClick ?? (() => {})}
      secondaryButtonText={secondaryButtonText}
      secondaryButtonOnClick={onSecondaryButtonClick}
      sparkleButtonOnClick={sparkleButtonOnClick}
      sparkleButtonText={sparkleButtonText}
      theme={'default'}
      onModalOpen={onModalOpen}
      stayOpenOnPrimaryClick={stayOpenOnPrimaryClick}
      noCloseOnSecondaryButtonClick={noCloseOnSecondaryButtonClick}
      customWidthStyle={customWidthStyle}
    >
      <div>
        <div
          className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-${themeToLightColorMap[theme]}`}
        >
          {renderIcon()}
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            {mainMessage}
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">{subMessage}</p>
            {children}
          </div>
        </div>
      </div>
    </VerticalModalTemplate>
  );
};

export default VerticalMessageModal;
