import * as React from 'react';
import { connect } from 'react-redux';
import {
  getUserWholeProfileByUID,
  updateUserLoggedIn,
} from '../firebase/configuration';
import {
  analyticsIdentifyUser,
  analyticsSetUserEmail,
} from '../analytics/index';

import { initializeFirebase } from '../firebase/configValues';
import { onAuthStateChanged } from 'firebase/auth';

const { auth } = initializeFirebase();

interface InterfaceProps {
  authUser?: any;
}

interface InterfaceState {
  authUser?: any;
}

// Used to authenticate user & update userData on redux store.
export const withAuthentication = (Component: any) => {
  class WithAuthentication extends React.Component<
    InterfaceProps,
    InterfaceState
  > {
    public componentDidMount() {
      const { onSetAuthUser, onSetUserData }: any = this.props;

      onAuthStateChanged(auth, (authUser: any) => {
        if (authUser) {
          onSetAuthUser(authUser);
          if (authUser.email && authUser.uid) {
            const uid = authUser.uid;
            analyticsIdentifyUser(authUser.email);
            analyticsSetUserEmail(authUser.email);
            getUserWholeProfileByUID(uid, async (user: any) => {
              // Fetch userData and set the store userData
              user.id = uid;
              onSetUserData(user);
              await updateUserLoggedIn(uid);
            });
          }
        } else {
          onSetAuthUser(null);
        }
      });
    }

    public render() {
      return <Component />;
    }
  }

  const mapDispatchToProps = (dispatch: any) => ({
    onSetAuthUser: (authUser: any) =>
      dispatch({ type: 'AUTH_USER_SET', authUser }),
    onSetUserData: (userData: any) =>
      dispatch({ type: 'USER_DATA_SET', userData }),
  });

  return connect(null, mapDispatchToProps)(WithAuthentication);
};
