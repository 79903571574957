import { Avatar } from '../../components/avatar/avatar';
import { getObscuredName } from '../../util/standardization';

export const PeerReactionContainer = (props: {
  reactorPictureUrl?: string;
  reactorName: string;
  reaction: string;
}) => {
  const { reactorPictureUrl, reactorName, reaction } = props;
  return (
    <div className="flex items-center" key={reactorName + reaction}>
      <Avatar photoUrl={reactorPictureUrl} />
      <div className="ml-2 text-gray-600 flex items-center">
        {`${getObscuredName(reactorName)} sent you a reaction:`}
        <span className="text-4xl ml-2">{reaction}</span>
      </div>
    </div>
  );
};
