import { UserGroupIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropDownFancy from '../../../components/forms/dropDownFancy/dropDownFancy';
import StackedCardRadio from '../../../components/forms/stackedCardRadio/stackedCardRadio';
import HorizontalMessageModal from '../../../components/modals/horizontalMessageModal/horizontalMessageModal';
import { updateUserData } from '../../../firebase/users/users';
import { UserData } from '../../../types/user';
import {
  countryNameToCode,
  nationalities,
  nationalitiesOptions,
} from '../../../util/forms';
import { trackPricingPageEvents } from '../../../features/PricingPage/analytics';
import { stringsPricingPage } from '../../../util/localization';

export const Nationality = (props: {
  onNextClick: Function;
  setValue: Function;
}) => {
  const { onNextClick, setValue } = props;
  const [openNationalityModal, setOpenNationalityModal] = useState(false);

  const nationalityChoices = [
    {
      name: '🇨🇴 Colombia',
      onClick: () => {
        setValue('CO');
        onNextClick();
      },
      userPhotos: [
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FDRn1RbRjOLhk5xaWZ5VjOTSmQn93_profile_picture?alt=media&token=ca0b3ada-fa97-4326-9eda-2ce1000df3fa',
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FlifP27lx6ifLdtkBHxgwUvCGTR62_profile_picture?alt=media&token=8b430c89-8012-46ff-afec-ab496a6514b4',
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FhS8RNZjzVgNmfrJWKO4IIyFSkUD2_profile_picture?alt=media&token=cafe4fdb-67b2-4c50-be22-642faf248863',
      ],
    },
    {
      name: '🇲🇽 Mexico',
      onClick: () => {
        setValue('MX');
        console.log('mx');
        onNextClick();
      },
      userPhotos: [
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FkXLWHNlfwTRFLSfPhSsaeiemvrI2_profile_picture?alt=media&token=e526f2b7-7937-46f3-9ba7-024a0ebab2d7',
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2Fa7VyTG0cLbUfX9QZEKlKSDllp4A3_profile_picture?alt=media&token=2b7d0264-d6bb-4991-ae50-0ca279fc09be',
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FMeyCPU3IMuSCrmPSmHK6ySFUo8h1_profile_picture?alt=media&token=4c0a4dba-43be-45fc-b718-39744ec89639',
      ],
    },
    {
      name: '🇻🇪 Venezuela',
      onClick: () => {
        setValue('VE');
        onNextClick();
      },
      userPhotos: [
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FPM7Y3DBazlfAA7ATh1xRhB0NR3F2_profile_picture?alt=media&token=0444be0a-ae2b-4315-8285-180c601fc2b7',
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FAqR6CFu9FKNk7AtmWyUdPfCD5kp2_profile_picture?alt=media&token=50545393-d595-45e5-8d3f-f85db22f029b',
        'https://firebasestorage.googleapis.com/v0/b/immigo-application-8ba25.appspot.com/o/profile_pictures%2FfySKq4neJmaGTxVjt8SgQnXDyvl2_profile_picture?alt=media&token=eaa10006-4c10-445b-9f07-154ee6e2d50c',
      ],
    },
    {
      name: `💭 ${stringsPricingPage.other}`,
      onClick: () => {
        trackPricingPageEvents('Opened nationality modal on pricing page');
        setOpenNationalityModal(true);
      },
    },
  ];
  return (
    <div>
      <HorizontalMessageModal
        mainMessage={stringsPricingPage.letsFindYourCountry}
        buttonText={stringsPricingPage.submit}
        subMessage={stringsPricingPage.chooseYourCountryPressSubmit}
        onButtonClick={() => {
          setOpenNationalityModal(false);
          onNextClick();
        }}
        onCloseModal={() => {
          trackPricingPageEvents('Closed nationality modal on pricing page');
          setOpenNationalityModal(false);
        }}
        theme="default"
        icon="flag"
        isOpen={openNationalityModal}
      >
        <div className="mt-6">
          <DropDownFancy
            title=""
            onOptionSelected={(selected: any) => {
              const code = countryNameToCode(selected?.name);
              setValue(code);
            }}
            options={nationalities.map((n: string) => {
              return { name: n, id: n };
            })}
          />
        </div>
      </HorizontalMessageModal>
      <div className="text-2xl text-center">
        {stringsPricingPage.whatIsYourNationality}
      </div>
      <div className="mt-6 text-center max-w-[500px]">
        {stringsPricingPage.learnersFrom120Countries}
      </div>
      <div className="mt-6">
        <StackedCardRadio choices={nationalityChoices} />
      </div>
    </div>
  );
};
