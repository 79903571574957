import mixpanel from '../../analytics/mixpanel';

type Events = 'Profile page: user followed' | 'Profile page: user unfollowed';

type ProfilePageData = {
  userID?: string;
  profileID?: string;
};

export const trackProfilePageAnalytics = (
  event: Events,
  data: ProfilePageData = {},
) => {
  mixpanel.track(event, data);
};
