import { useEffect } from 'react';
import { PrimaryButton } from '../../../../components/buttons/primary';
import DropDownFancy, {
  DropDownFancyOption,
} from '../../../../components/forms/dropDownFancy/dropDownFancy';
import { AudioDevice } from '../classSetUpContainer';
import { MicrophoneIcon } from '@heroicons/react/20/solid';

export const ClassPrepHeadPhoneOnPage = (props: {
  onClick: Function;
  audioDevices: Array<AudioDevice>;
  audioOutputDevices: Array<AudioDevice>;
  defaultAudio?: AudioDevice;
  defaultAudioOutput?: AudioDevice;
  onDeviceChange?: Function;
  onDeviceOutputChange?: Function;
}) => {
  const {
    onClick,
    audioDevices,
    defaultAudio,
    defaultAudioOutput,
    audioOutputDevices,
    onDeviceChange,
    onDeviceOutputChange,
  } = props;

  return (
    <div className="justify-center items-center flex flex-col max-w-[500px] text-center">
      <div className="md:text-3xl text-xl">Are you using your headphones?</div>
      <div className="text-gray-500 md:text-xl text-sm mt-4">
        Make sure to use your headphone for both speaker and microphone for more
        accurate AI feedback.
      </div>
      <div className="my-12 text-8xl">🎧🎤️</div>
      <div className="mb-8">
        {defaultAudio ? (
          <div className="flex items-center">
            <MicrophoneIcon className="w-5 h-5 text-gray-500 mr-4" />
            <DropDownFancy
              title=""
              options={audioDevices}
              defaultIndex={
                defaultAudio
                  ? audioDevices.findIndex(
                      (audio) => audio.id === defaultAudio.id,
                    )
                  : undefined
              }
              onOptionSelected={(option: DropDownFancyOption) => {
                onDeviceChange?.(option.id);
              }}
            />
          </div>
        ) : null}
        {defaultAudioOutput ? (
          <div className="flex items-center">
            <MicrophoneIcon className="w-5 h-5 text-gray-500 mr-4" />
            <DropDownFancy
              title=""
              options={audioOutputDevices}
              defaultIndex={
                defaultAudioOutput
                  ? audioOutputDevices.findIndex(
                      (audio) => audio.id === defaultAudioOutput.id,
                    )
                  : undefined
              }
              onOptionSelected={(option: DropDownFancyOption) => {
                onDeviceOutputChange?.(option.id);
              }}
            />
          </div>
        ) : null}
      </div>

      <div className="max-w-[350px]">
        <PrimaryButton text="I have my headphones on!" onClick={onClick} />
      </div>
    </div>
  );
};
