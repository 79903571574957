import { connect } from 'react-redux';
import { initializeFirebase } from '../../firebase/configValues';
import { FirstOnBoardingPage } from './pages/firstPage';
import { ProgressBarSteps } from '../../components/steps/progressBarSteps';
import { UserData } from '../../types/user';
import { useEffect, useState } from 'react';
import { CountryDetail } from './pages/countryDetail/countryDetail';
import { EnglishInformation } from './pages/englishInformation/englishInformation';
import { AdditionalInformation } from './pages/additionalInformation/additionalInformation';
import { OnboardingFinishedPage } from './pages/finished/Finished';
import { trackOnBoardingPage } from '../../features/OnBoard/analytics';
import { OnBoardingStartPage } from './pages/start/Start';
import { OnBoardPageLoader } from './loader';
import { HowDidYouHearGetStarted } from './pages/lead/howDidYouHear';

export interface OnBoardingPageProps {
  userID: string;
  onNextClick: Function;
  onBackClick?: Function;
}

const OnBoardComponent = (props: {
  userData: UserData;
  onSetUserData: Function;
}) => {
  const { userData, onSetUserData } = props;

  const [page, setPage] = useState(0);

  useEffect(() => {
    trackOnBoardingPage('Visited get started page');
  }, []);

  const steps = [
    { name: 'first-step', page: 0 },
    { name: 'second-step', page: 1 },
    { name: 'third-step', page: 2 },
    { name: 'fourth-step', page: 3 },
    { name: 'fifth-step', page: 4 },
  ];
  function renderPage() {
    const pages = [
      <OnBoardingStartPage onNextClick={() => setPage(1)} />,
      <HowDidYouHearGetStarted
        userID={userData.id}
        onNextClick={(referMethod: string, referredByHandle: string) => {
          trackOnBoardingPage('Submitted lead information on boarding page', {
            referMethod,
            referredByHandle,
          });
          setPage(2);
        }}
        onBackClick={() => {
          setPage(0);
        }}
      />,
      <CountryDetail
        userID={userData.id}
        onNextClick={() => {
          setPage(3);
          trackOnBoardingPage(
            'Submitted nationality information on boarding page',
          );
        }}
        onBackClick={() => {
          setPage(1);
        }}
      />,
      <EnglishInformation
        userID={userData.id}
        onNextClick={() => {
          setPage(4);
          trackOnBoardingPage(
            'Submitted English journey information on boarding page',
          );
        }}
        onBackClick={() => {
          setPage(2);
        }}
      />,
      <AdditionalInformation
        userID={userData.id}
        onNextClick={() => {
          setPage(5);
          trackOnBoardingPage(
            'Submitted addtional information on boarding page',
          );
        }}
        onBackClick={() => {
          setPage(3);
        }}
      />,
      <OnboardingFinishedPage />,
    ];
    return pages[page];
  }

  return (
    <div className="flex w-full justify-center flex-col">
      <div className="max-w-[600px] mx-auto">
        <div className="mx-auto mb-10">
          <ProgressBarSteps steps={steps} currentStep={page} hideLabels />
        </div>
        {userData ? (
          renderPage()
        ) : (
          <div className="min-w-[400px] min-h-[400px]">
            <OnBoardPageLoader />
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  onSetUserData: (userData: any) =>
    dispatch({ type: 'USER_DATA_SET', userData }),
});

const mapStateToProps = (state: any) => ({
  userData: state.sessionState.userData,
});

export const OnBoard = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OnBoardComponent);
