import mixpanel from '../../../analytics/mixpanel';

type Events =
  | 'Clicked see my mistake on next class tip'
  | 'Clicked yes on next class tip modal'
  | 'Clicked no on next class tip modal';

type RankingContainerData = {
  clickedTab?: string;
};

export const trackNextClassTip = (
  event: Events,
  data: RankingContainerData,
) => {
  mixpanel.track(event, data);
};
