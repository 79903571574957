import { UserData } from '../../../types/user';
import { useHistory, useParams } from 'react-router-dom';
import { ReportCardIntro } from '../ReportCardIntro';
import { useEffect, useState } from 'react';
import { CourseData, getAllCoursesAttendanceForUser } from '../firebase';
import { trackReportCardEvent } from '../analytics';

interface ReportCardPageProps {
  user: UserData;
}

export function ReportCardPageIndexPage({ user }: ReportCardPageProps) {
  const history = useHistory();
  const { userId } = useParams<{
    userId: string;
  }>();
  const [userCourses, setUserCourses] = useState<CourseData[]>([]);

  useEffect(() => {
    if (!userId) {
      return;
    }

    trackReportCardEvent('Visit all courses Report Card page', {
      userID: userId,
    });
  }, [userId]);

  useEffect(() => {
    if (!userId) {
      return;
    }

    const getData = async () => {
      const result = await getAllCoursesAttendanceForUser(userId);
      setUserCourses(result);
    };

    void getData();
  }, [userId]);

  return (
    <div className="max-w-4xl m-auto">
      <ReportCardIntro name={user.name} />
      <div className="mt-3">
        <label
          htmlFor="course"
          className="block text-sm font-medium text-gray-700"
        >
          Select a course
        </label>
        <select
          id="course"
          name="course"
          className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          onChange={(e) => history.push(`/reports/${userId}/${e.target.value}`)}
        >
          <option>Select a course</option>
          {userCourses.map((course) => (
            <option key={course.id} value={course.id}>
              {course.courseTitle}
            </option>
          ))}
        </select>
      </div>{' '}
    </div>
  );
}
