import { CSSProperties, FC, useMemo, memo } from 'react';
import cx from 'classix';

import './radio.css';
import { FieldError } from '../field-error';

interface RadioOption {
  value: string;
  text: string;
}

interface RadioProps {
  id: string;
  label: string;
  name?: string;
  hideLabel?: boolean;
  touched?: boolean;
  error?: string | null;
  description?: string;

  containerClassName?: string;
  containerStyle?: CSSProperties;

  labelClassName?: string;
  labelStyle?: CSSProperties;

  descriptionClassName?: string;
  descriptionStyle?: CSSProperties;

  errorClassName?: string;
  errorStyle?: CSSProperties;

  hide?: boolean;
  disabled?: boolean;
  options?: Array<RadioOption>;
  value?: string | null;
  onChange?: (newValue?: string) => void;
  required?: boolean;
  optional?: boolean;
}

const RadioComponent: FC<RadioProps> = (props) => {
  const {
    options = [],
    hideLabel,
    labelClassName,
    labelStyle,
    description,
    descriptionClassName,
    descriptionStyle,
    touched,
    error,
    errorClassName,
    errorStyle,
    hide,
    disabled,
    value,
    required = true,
    optional = !required,
  } = props;

  const validValue = useMemo<boolean>(() => {
    return typeof value !== 'undefined' && value !== null;
  }, [value]);

  if (!options || !options.length) return null;
  return (
    <div
      className={cx(
        'flex flex-col',
        props.containerClassName,
        hide && 'hidden select-none pointer-events-none',
      )}
      style={props.containerStyle}
    >
      <p
        id={props.id || props.name}
        className={cx(
          'block text-sm font-medium leading-6 text-gray-900',
          hideLabel && 'hidden select-none pointer-events-none',
          labelClassName,
        )}
        style={labelStyle}
      >
        {props.label}
        {!required || optional ? (
          <>
            {' '}
            <span className={cx('opacity-75 select-none')}>(optional)</span>
          </>
        ) : null}
      </p>
      {description ? (
        <p
          className={cx('opacity-70 text-sm', descriptionClassName)}
          style={descriptionStyle}
        >
          {description}
        </p>
      ) : null}
      <div
        role="group"
        aria-labelledby={props.id || props.name}
        className={cx('flex items-center gap-1')}
      >
        {(options || []).map((option) => {
          const checked = validValue && value === option.value;
          return (
            <label
              key={option.value}
              className={cx(
                'radio-option',
                'text-sm border mt-2 px-2 py-2 cursor-pointer rounded',
                checked && 'checked',
              )}
            >
              <input
                type="radio"
                name={props.name}
                value={option.value || ''}
                disabled={disabled || hide}
                className={'hidden'}
                {...(validValue ? { checked } : {})}
                onChange={(e) => {
                  props.onChange?.(option.value);
                }}
              />
              <span className={'font-medium'}>{option.text}</span>
            </label>
          );
        })}
      </div>
      <FieldError
        touched={touched || !validValue}
        error={error}
        className={errorClassName}
        style={errorStyle}
      />
    </div>
  );
};

export const Radio = memo(RadioComponent);
