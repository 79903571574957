import axios from 'axios';

const endPoint =
  process.env.REACT_APP_ENV === 'PROD'
    ? 'https://immigo-api.herokuapp.com/auth'
    : 'http://localhost:8080/auth'; // Dev env

export async function authKakao(code: string) {
  const data = await axios.post(endPoint + '/kakao', {
    code,
  });
  return data?.data;
}

export async function authCreate(email: string, password: string, uid: string) {
  const data = await axios.post(endPoint + '/create', {
    email,
    password,
    uid,
  });
  return data?.data;
}
