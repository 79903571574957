import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getUserByUID,
  returnAllCourses,
  returnAllUsers,
  returnUserByUID,
} from '../../firebase/configuration';
import moment from 'moment';
import { getCourseEngagementData } from '../../api';
import { checkIsAdmin } from '../../util/admin';
import ReactCohortGraph from 'react-cohort-graph';
import { monthlyMapToCohortAnalysis } from '../../util/data';
import { PrimaryButton } from '../../components/buttons/primary';
import { onAuthStateChanged } from 'firebase/auth';
import { initializeFirebase } from '../../firebase/configValues';
import { LoadingOverlay } from '../../components/loading';

const { auth } = initializeFirebase();

const AdminDataViewComponent = ({ userData, onSetUserData }: any) => {
  const [courses, setCourses] = useState<Array<any>>([]);
  const [courseMap, setCourseMap] = useState<any>(undefined);
  const [cohortReturneeArray, setCohortReturneeArray] = useState<any>([]);
  const [uniqueStudentList, setUniqueStudentList] = useState<Array<string>>([]);
  const [momCohortMap, setMomCohortMap] = useState<any>(null);
  const [showCohortAnalysis, setShowCohortAnalysis] = useState<boolean>(false);
  const [userMap, setUserMap] = useState<any>(undefined);
  const [intermediateImmigrant, setIntermediateImmigrant] =
    useState<any>(undefined);
  const [intermediateEslProfessional, setIntermediateEslProfessional] =
    useState<any>(undefined);
  const [intermediateHasTutor, setIntermediateHasTutor] =
    useState<any>(undefined);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const isAdmin = checkIsAdmin(userData?.email);
  const ongoingCourses: Array<any> = [];
  const pastCourses: Array<any> = [];

  useEffect(onLoad, []);
  useEffect(() => {
    userDataLoaded(userData);
  }, [userData]);

  courses.map((course: any) => {
    if (course.coursePeriod.end > new Date().valueOf()) {
      ongoingCourses.push(course);
      return course;
    } else {
      pastCourses.push(course);
      return course;
    }
  });

  function onLoad() {
    if (userData && onSetUserData) {
      // Update with new user data
      getUserByUID(userData.id, (data: any) => {
        onSetUserData(data);
        userDataLoaded(data);
      });
    }

    onAuthStateChanged(auth, (user) => {
      if (!user) history.push('/login');
    });
  }

  async function userDataLoaded(user: any) {
    setLoading(true);
    if (user && user.courses && user.courses.length > 0) {
      await updateCourses();
    } else {
      console.log('User is missing!');
    }
    setLoading(false);
  }

  async function updateUserMap() {
    const allUsers = await returnAllUsers();
    const newUserMap: any = {};
    const newImmigrantMap: any = {};
    const newEslProfMap: any = {};
    const newHasTutorMap: any = {};
    allUsers.forEach((user: any) => {
      newUserMap[user.id] = user;
      // Immigrant
      if (
        (user?.basedCountry === 'US' ||
          user?.basedCountry === 'GB' ||
          user?.basedCountry === 'NZ' ||
          user?.basedCountry === 'AU' ||
          user?.basedCountry === 'CA' ||
          user?.basedCountry === 'IE') &&
        (user.cefrLevel === 'B1' || user.cefrLevel === 'B2')
      ) {
        newImmigrantMap[user.id] = user;
      }

      if (
        (user.usesEnglishAtWork === 'Yes, frequently' ||
          user.usesEnglishAtWork === 'Yes, sometimes') &&
        (user.cefrLevel === 'B1' || user.cefrLevel === 'B2')
      ) {
        newEslProfMap[user.id] = user;
      }

      if (
        user.currentlyHasTutor &&
        (user.cefrLevel === 'B1' || user.cefrLevel === 'B2')
      ) {
        newHasTutorMap[user.id] = user;
      }
    });
    setUserMap(newUserMap);
    setIntermediateImmigrant(newImmigrantMap);
    setIntermediateEslProfessional(newEslProfMap);
    setIntermediateHasTutor(newHasTutorMap);
  }

  function updateUniqueStudentList(courseList: Array<string>) {
    const newList: Array<string> = JSON.parse(
      JSON.stringify(uniqueStudentList),
    );
    courseList.forEach((course: any) => {
      if (course.students) {
        course.students.forEach((studentID: string) => {
          if (!newList.includes(studentID)) newList.push(studentID);
        });
      }
    });
    setUniqueStudentList(newList);
  }

  // This will break once we have a lot of courses
  async function updateCourses() {
    const cohortMonthMap: any = {};
    const allCourses: Array<any> = await returnAllCourses();
    const newCourseMap: any = {};
    const courseList: Array<any> = await Promise.all(
      allCourses
        .filter((course: any) => {
          return (
            course.id !== 'lzppdmtTwcoL88IgGtNX' &&
            course.id !== 'sKxX2A70dgWhXQi9gUo4'
          );
        })
        .map(async (course: any, courseIndex: number) => {
          const courseEngagementData = await getCourseEngagementData(course.id);
          newCourseMap[course.id] = course;
          return { ...course, ...courseEngagementData };
        }),
    );
    courseList.sort((a: any, b: any) => {
      return a.coursePeriod.start - b.coursePeriod.start;
    }); // sort the course in chrono order by the start date

    courseList.forEach((course: any, index: number) => {
      course.courseIndex = index;
      course.students = course.students.filter(
        (val: string) => val !== '55GfqR1eKVQmzqe11XFdYvpjWyg2',
      );
      course.startMonth =
        moment(course.coursePeriod.start).month() +
        1 +
        '/' +
        moment(course.coursePeriod.start).year();
      course.endMonth =
        moment(course.coursePeriod.end).month() +
        1 +
        '/' +
        moment(course.coursePeriod.end).year();

      cohortMonthMap[course.startMonth] = cohortMonthMap[course.startMonth]
        ? Array.from(
            new Set<string>([
              ...cohortMonthMap[course.startMonth],
              ...course.students,
            ]),
          )
        : course.students;

      cohortMonthMap[course.endMonth] = cohortMonthMap[course.endMonth]
        ? Array.from(
            new Set<string>([
              ...cohortMonthMap[course.endMonth],
              ...course.students,
            ]),
          )
        : course.students;

      newCourseMap[course.id].courseIndex = index;
    }); // Map order for chronological reference

    const { cohortMap, cohortUserMap } =
      monthlyMapToCohortAnalysis(cohortMonthMap);
    setMomCohortMap(cohortMap);
    updateUserCohortData(cohortUserMap);
    updateUniqueStudentList(courseList);
    setCourseMap(newCourseMap);
    setCourses(courseList); // Flatten 2 dimensional array before updating the student list
  }

  async function updateUserCohortData(cohortUserMap: any) {
    const dates = Object.keys(cohortUserMap);

    const ls = await Promise.all(
      dates.map(async (date: string) => {
        const deds = cohortUserMap[date][4] ? cohortUserMap[date][2] : []; // who stayed until 5
        const stdList = await Promise.all(
          deds.map(async (ded: string) => {
            const dedDoc = await returnUserByUID(ded);
            return (
              dedDoc?.name +
              ' ' +
              dedDoc?.cefrLevel +
              ' ' +
              dedDoc?.globalAttendanceRate +
              dedDoc?.basedCountry
            );
          }),
        );
        return [date, ...stdList];
      }),
    );

    console.log(ls);
    setCohortReturneeArray(ls);
  }

  function onCourseClick(id: string) {
    history.push('/learners/' + id);
  }

  function findStudentOverlap(studentList: Array<string>, courseID: string) {
    let overlapList: Array<string> = [];
    if (
      studentList &&
      courseMap &&
      courseMap[courseID] &&
      courseMap[courseID].students
    ) {
      const otherCourseStudentList = courseMap[courseID].students;
      overlapList = studentList.filter((value: string) =>
        otherCourseStudentList.includes(value),
      ); // Find the overlapping studentID between ohter and cur course
    }
    return overlapList;
  }

  function filterComingBackToFutureCohort(
    studentList: Array<string>,
    courseIndex: number,
  ) {
    let overlapList: Array<string> = [];
    let futureStudentList: Array<string> = [];
    // let courseIndex = courseMap[courseID].courseIndex;
    for (let i = courseIndex + 1; i < courses.length; i++) {
      futureStudentList = [...futureStudentList, ...courses[i].students];
    }
    if (studentList) {
      overlapList = studentList.filter((value: string) =>
        futureStudentList.includes(value),
      ); // Find the overlapping studentID between ohter and cur course
    }
    return overlapList;
  }

  function renderCourseList(courses: Array<any>) {
    const courseList: Array<any> = courses.map((course: any) => {
      return (
        <div
          onClick={() => onCourseClick(course.id)}
          className="cursor-pointer p-5 shadow-md bg-white rounded-md mb-10 border w-full"
        >
          <div className="font-bold">{course.courseTitle}</div>
          <div>{`Course Code: ${course.courseCode}`}</div>
          <div>
            {'Start Date: ' +
              moment(course.coursePeriod.start).format('MM/DD/YYYY')}
          </div>
          <div>
            {'End Date: ' +
              moment(course.coursePeriod.end).format('MM/DD/YYYY')}
          </div>
          <div>{'Total students: ' + course.totalStudentCount}</div>
          <div>
            {'Students who completed all weeks: ' +
              course.studentsWhoCompletedAllWeeks}
          </div>
          <div>
            {'Total engaged student count (Projected): ' +
              course.engagedStudentsCount}
          </div>
          <div>{'Completion rate: ' + course.completionRate}</div>
          <div>
            {'Engaged student percentage (Projected): ' +
              course.engagedStudentPercentage}
          </div>
          <div className="font-bold mt-2">Retention Data</div>
          {course.previousCourseID ? (
            <div>
              <div>
                {'# of students from last cohort : ' +
                  findStudentOverlap(course.students, course.previousCourseID)
                    .length}
              </div>
              {course.nextCourseID ? (
                <div>
                  <div>
                    {'# of completed students retained to next cohort: ' +
                      findStudentOverlap(
                        course.completedStudentList,
                        course.nextCourseID,
                      ).length}
                  </div>
                  <div>
                    {'# of engaged students retained to next cohort: ' +
                      findStudentOverlap(
                        course.engagedStudentList,
                        course.nextCourseID,
                      ).length}
                  </div>
                  <div>
                    {'# of students retained to next cohort: ' +
                      findStudentOverlap(course.students, course.nextCourseID)
                        .length}
                  </div>
                  <div>
                    {'completed students retention rate: ' +
                      (
                        (findStudentOverlap(
                          course.completedStudentList,
                          course.nextCourseID,
                        ).length /
                          course.studentsWhoCompletedAllWeeks) *
                        100
                      ).toFixed(2) +
                      '%'}
                  </div>
                  <div>
                    {'engaged students retention rate: ' +
                      (
                        (findStudentOverlap(
                          course.engagedStudentList,
                          course.nextCourseID,
                        ).length /
                          course.engagedStudentsCount) *
                        100
                      ).toFixed(2) +
                      '%'}
                  </div>
                  <div>
                    {'course retention rate: ' +
                      (
                        (findStudentOverlap(
                          course.students,
                          course.nextCourseID,
                        ).length /
                          course.students.length) *
                        100
                      ).toFixed(2) +
                      '%'}
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
          {intermediateImmigrant ? (
            <div>
              <div className="text-blue-immigo">
                {'# intermediate immigrants to English speaking countries : ' +
                  course.students.filter((id: string) => {
                    return intermediateImmigrant[id] !== undefined;
                  }).length}
              </div>
              <div className="text-blue-immigo">
                {'# intermediate immigrants to English speaking countries coming back to any future cohort : ' +
                  filterComingBackToFutureCohort(
                    course.students.filter((id: string) => {
                      return intermediateImmigrant[id] !== undefined;
                    }),
                    course.courseIndex,
                  ).length}
              </div>
              <div className="text-blue-immigo">
                {'# intermediate immigrants to English speaking countries coming back to any future cohort %: ' +
                  (
                    (filterComingBackToFutureCohort(
                      course.students.filter((id: string) => {
                        return intermediateImmigrant[id] !== undefined;
                      }),
                      course.courseIndex,
                    ).length /
                      course.students.filter((id: string) => {
                        return intermediateImmigrant[id] !== undefined;
                      }).length) *
                    100
                  ).toFixed(2) +
                  '%'}
              </div>
              <div className="text-green-700">
                {'# completed intermediate immigrants to English speaking countries: ' +
                  course.completedStudentList.filter((id: string) => {
                    return intermediateImmigrant[id] !== undefined;
                  }).length}
              </div>
              <div className="text-green-700">
                {'# completed intermediate immigrants to English speaking countries coming back to any future cohort : ' +
                  filterComingBackToFutureCohort(
                    course.completedStudentList.filter((id: string) => {
                      return intermediateImmigrant[id] !== undefined;
                    }),
                    course.courseIndex,
                  ).length}
              </div>
              <div className="text-green-700">
                {'# completed intermediate immigrants coming back to any future cohort %: ' +
                  (
                    (filterComingBackToFutureCohort(
                      course.completedStudentList.filter((id: string) => {
                        return intermediateImmigrant[id] !== undefined;
                      }),
                      course.courseIndex,
                    ).length /
                      course.completedStudentList.filter((id: string) => {
                        return intermediateImmigrant[id] !== undefined;
                      }).length) *
                    100
                  ).toFixed(2) +
                  '%'}
              </div>
            </div>
          ) : null}
          {intermediateEslProfessional ? (
            <div>
              <div className="text-blue-immigo">
                {'# intermediate esl professional : ' +
                  course.students.filter((id: string) => {
                    return intermediateEslProfessional[id] !== undefined;
                  }).length}
              </div>
              <div className="text-blue-immigo">
                {'# intermediate esl professional coming back to any future cohort : ' +
                  filterComingBackToFutureCohort(
                    course.students.filter((id: string) => {
                      return intermediateEslProfessional[id] !== undefined;
                    }),
                    course.courseIndex,
                  ).length}
              </div>
              <div className="text-blue-immigo">
                {'# intermediate esl professional coming back to any future cohort %: ' +
                  (
                    (filterComingBackToFutureCohort(
                      course.students.filter((id: string) => {
                        return intermediateEslProfessional[id] !== undefined;
                      }),
                      course.courseIndex,
                    ).length /
                      course.students.filter((id: string) => {
                        return intermediateEslProfessional[id] !== undefined;
                      }).length) *
                    100
                  ).toFixed(2) +
                  '%'}
              </div>
              <div className="text-green-700">
                {'# completed intermediate esl professional : ' +
                  course.completedStudentList.filter((id: string) => {
                    return intermediateEslProfessional[id] !== undefined;
                  }).length}
              </div>
              <div className="text-green-700">
                {'# completed intermediate esl professional coming back to any future cohort : ' +
                  filterComingBackToFutureCohort(
                    course.completedStudentList.filter((id: string) => {
                      return intermediateEslProfessional[id] !== undefined;
                    }),
                    course.courseIndex,
                  ).length}
              </div>
              <div className="text-green-700">
                {'# completed intermediate esl professional coming back to any future cohort %: ' +
                  (
                    (filterComingBackToFutureCohort(
                      course.completedStudentList.filter((id: string) => {
                        return intermediateEslProfessional[id] !== undefined;
                      }),
                      course.courseIndex,
                    ).length /
                      course.completedStudentList.filter((id: string) => {
                        return intermediateEslProfessional[id] !== undefined;
                      }).length) *
                    100
                  ).toFixed(2) +
                  '%'}
              </div>
            </div>
          ) : null}
          {intermediateHasTutor ? (
            <div>
              <div className="text-blue-immigo">
                {'# has tutor intermediate : ' +
                  course.students.filter((id: string) => {
                    return intermediateHasTutor[id] !== undefined;
                  }).length}
              </div>
              <div className="text-blue-immigo">
                {'# has tutor intermediate coming back to any future cohort : ' +
                  filterComingBackToFutureCohort(
                    course.students.filter((id: string) => {
                      return intermediateHasTutor[id] !== undefined;
                    }),
                    course.courseIndex,
                  ).length}
              </div>
              <div className="text-blue-immigo">
                {'# has tutor intermediate coming back to any future cohort %: ' +
                  (
                    (filterComingBackToFutureCohort(
                      course.students.filter((id: string) => {
                        return intermediateHasTutor[id] !== undefined;
                      }),
                      course.courseIndex,
                    ).length /
                      course.students.filter((id: string) => {
                        return intermediateHasTutor[id] !== undefined;
                      }).length) *
                    100
                  ).toFixed(2) +
                  '%'}
              </div>
            </div>
          ) : null}
          <div>
            {'# people coming back to any future cohort : ' +
              filterComingBackToFutureCohort(
                course.students,
                course.courseIndex,
              ).length}
          </div>
          <div>
            {'^ Percentage over total students: ' +
              (
                (filterComingBackToFutureCohort(
                  course.students,
                  course.courseIndex,
                ).length /
                  course.students.length) *
                100
              ).toFixed(2) +
              '%'}
          </div>
          <div>
            {'# completed students coming back to any future cohort : ' +
              filterComingBackToFutureCohort(
                course.completedStudentList,
                course.courseIndex,
              ).length}
          </div>
          <div>
            {'^ Percentage over total completed students: ' +
              (
                (filterComingBackToFutureCohort(
                  course.completedStudentList,
                  course.courseIndex,
                ).length /
                  course.completedStudentList.length) *
                100
              ).toFixed(2) +
              '%'}
          </div>
          <div>
            {'# engaged students coming back to any future cohort : ' +
              filterComingBackToFutureCohort(
                course.engagedStudentList,
                course.courseIndex,
              ).length}
          </div>
          <div>
            {'^ Percentage over total engaged students: ' +
              (
                (filterComingBackToFutureCohort(
                  course.engagedStudentList,
                  course.courseIndex,
                ).length /
                  course.engagedStudentList.length) *
                100
              ).toFixed(2) +
              '%'}
          </div>
          {course.weeklyAttendancePercentage ? (
            <div>
              <div className="font-bold mt-2">Weekly Data</div>
              <div>
                {'Week 1: ' +
                  (course.weeklyAttendancePercentage[0] * 100).toFixed(2) +
                  '%'}
              </div>
              <div>
                {'Week 2: ' +
                  (course.weeklyAttendancePercentage[1] * 100).toFixed(2) +
                  '%'}
              </div>
              <div>
                {'Week 3: ' +
                  (course.weeklyAttendancePercentage[2] * 100).toFixed(2) +
                  '%'}
              </div>
              <div>
                {'Week 4: ' +
                  (course.weeklyAttendancePercentage[3] * 100).toFixed(2) +
                  '%'}
              </div>
            </div>
          ) : null}
        </div>
      );
    });
    return courseList;
  }

  return (
    <div className="flex w-full justify-center">
      <LoadingOverlay enabled={loading} />
      {isAdmin ? (
        <div className="max-w-7xl w-full">
          {momCohortMap && showCohortAnalysis ? (
            <div className="mx-auto max-w-6xl w-full">
              <h3 className="text-xl font-bold mb-5">
                New user cohort analysis
              </h3>
              <ReactCohortGraph data={{ months: momCohortMap }} />
            </div>
          ) : null}

          <div className="justify-center items-start flex flex-col max-w-prose mx-auto pb-24">
            <div className="w-64 my-12">
              <PrimaryButton
                text={
                  !showCohortAnalysis
                    ? 'Show cohort analysis'
                    : 'Hide cohort analysis'
                }
                onClick={() => {
                  setShowCohortAnalysis(!showCohortAnalysis);
                }}
              />
              <div className="my-2">
                <PrimaryButton
                  text={'Populate user map'}
                  onClick={async () => {
                    setLoading(true);
                    await updateUserMap();
                    setLoading(false);
                  }}
                />
              </div>
            </div>
            <h2 className="text-xl font-bold mb-5">Courses</h2>
            <div>
              {
                'Total accumulated unique students #: ' +
                  (uniqueStudentList.length +
                    119) /* 41 students not in platform from vero cohort 1 / 15 in Keith / 63 Holberton */
              }
            </div>
            <h3 className="font-bold my-4 underline">Ongoing courses</h3>
            {renderCourseList(
              ongoingCourses.sort(
                (a, b) => b.coursePeriod.end - a.coursePeriod.end,
              ),
            )}
            <h3 className="font-bold mb-4 underline">Past courses</h3>
            {renderCourseList(
              pastCourses.sort(
                (a, b) => b.coursePeriod.end - a.coursePeriod.end,
              ),
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  onSetUserData: (userData: any) =>
    dispatch({ type: 'USER_DATA_SET', userData }),
});

const mapStateToProps = (state: any) => ({
  userData: state.sessionState.userData,
});

export const AdminDataView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminDataViewComponent);
