import { FC, useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  assignUserReferralCode,
  getPaymentIntent,
  sendApplicationConfirmationEmail,
} from '../../../api';
import {
  addCourseOnUser,
  createApplicationDocument,
  returnAppByUID,
  trackUserReferral,
  updateApplicationDocument,
  updateCreditToUser,
} from '../../../firebase/configuration';
import { analyticsReferralCodeUsed } from '../../../analytics/index';

import { BundleBox } from '../bundlebox/index';
import { PrimaryButton } from '../../buttons/primary/index';
import { TotalTable, TotalTableItem } from './totaltable';
import { PaymentMethods, PaymentMethod } from './payment-methods';
import { BundleDeal } from '../../../types/bundle';
import { useReferralCode } from '../../../providers/referral-provider';
import { useAffiliateDiscountFromFirestore } from '../../../firebase/referralCode/referralCode';
import { capitalizeEveryFirstLetter } from '../../../util/standardization';
import cx from 'classix';
import { Input } from '../../forms/input';
import { trackRegistrationEvent } from '../../../features/CourseRegistration/analytics';
import { getFirstNameFromFullName } from '../../../util/helper';
import { TrialInfo } from '../trialinfo/TrialInfo';

const optionsToPurchase = [
  'BundlePlus',
  'BundlePremium',
  'SingleCourse',
  'None',
] as const;
type OptionToPurchase = typeof optionsToPurchase[number];

const defaultBundles: Record<OptionToPurchase, BundleDeal> = {
  BundlePremium: {
    bestDeal: false,
    numberOfCohorts: 6,
    discount: 0.49,
    name: '6 course bundle',
    priceBefore: 234,
  },
  BundlePlus: {
    bestDeal: true,
    numberOfCohorts: 3.06,
    discount: 0.25,
    name: '3 course bundle',
    priceBefore: 117,
  },
  SingleCourse: {
    bestDeal: false,
    numberOfCohorts: 1,
    discount: 0,
    name: '1 course',
  },
  None: {
    bestDeal: false,
    numberOfCohorts: 0,
    discount: 0,
    name: 'Please choose an option',
  },
};

const returneeBundles: Record<OptionToPurchase, BundleDeal> = {
  BundlePremium: {
    bestDeal: false,
    numberOfCohorts: 6,
    discount: 0.28,
    name: '6 course bundle',
    priceBefore: 234,
    returneeDiscount: 0.54,
  },
  BundlePlus: {
    bestDeal: true,
    numberOfCohorts: 3,
    discount: 0.2,
    name: '3 course bundle',
    priceBefore: 117,
    returneeDiscount: 0.5,
  },
  SingleCourse: {
    bestDeal: false,
    numberOfCohorts: 1,
    discount: 0,
    name: '1 course',
    returneeDiscount: 0.35,
  },
  None: {
    bestDeal: false,
    numberOfCohorts: 0,
    discount: 0,
    name: 'Please choose an option',
  },
};

export type DistributorType = 'returnee' | 'referral' | undefined;

interface PaymentPageProps {
  userData?: any;
  course?: any;
  courseTitle: string;
  courseID: string;
  price: number;
  onNext?: Function;
  onBack?: Function;
  onSubmit?: Function;
  onSuccess?: Function;
  onFailure?: Function;
  distributorType?: DistributorType;
  referralCode?: string;
  trial?: boolean;
}

const RETURNEE_PRICE_LIMIT_DATE = new Date('2023-01-18');

export const PaymentPage: FC<PaymentPageProps> = (props) => {
  const {
    userData,
    course,
    courseID,
    price,
    onBack,
    onNext,
    onSubmit,
    onSuccess,
    onFailure,
    distributorType,
    referralCode,
    trial,
  } = props;

  // Referral code from affiliate links, renamed as viaToken
  const { referralCode: viaToken, setReferralCode } = useReferralCode();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const [activePaymentMethod, setActivePaymentMethod] =
    useState<PaymentMethod>('card');

  const [optionToPurchase, setOptionToPurchase] =
    useState<OptionToPurchase>('None');

  const [application, setApplication] = useState<any>();

  const {
    discount: affiliateDiscount,
    loading: loadingDiscount,
    additionalInfo: additionalDiscountData,
    registerDiscountUsageByUser,
  } = useAffiliateDiscountFromFirestore(viaToken, userData?.id);
  const [manualDiscountCode, setManualDiscountCode] = useState<
    string | undefined
  >(viaToken);

  const canUseReturneePrice = useMemo<boolean>(() => {
    if (distributorType !== 'returnee') return false;
    const now = new Date();
    return now.getTime() < RETURNEE_PRICE_LIMIT_DATE.getTime();
  }, [distributorType]);

  const bundles = useMemo(() => {
    return canUseReturneePrice ? returneeBundles : defaultBundles;
  }, [canUseReturneePrice]);

  const coursePrice = useMemo<number>(() => {
    return canUseReturneePrice ? price : price; // This is to disable returnee price.
  }, [price, canUseReturneePrice]);

  const optionPrice = useMemo<number>(() => {
    if (!optionToPurchase) return 0;
    return Math.floor(
      (bundles[optionToPurchase]?.numberOfCohorts || 1) *
        coursePrice *
        (1 - (bundles[optionToPurchase]?.discount || 0)),
    );
  }, [bundles, optionToPurchase, coursePrice]);

  const optionName = useMemo<string>(() => {
    if (!optionToPurchase) return 'Please choose an option…';
    if (optionToPurchase !== 'SingleCourse')
      return capitalizeEveryFirstLetter(
        bundles[optionToPurchase]?.name || 'Bundle',
      );
    return 'Single Course';
  }, [bundles, optionToPurchase]);

  useEffect(() => {
    // @ts-ignore
    rewardful('ready', function () {
      console.log('Rewardful Ready!');
    });
    console.log(trial);
  }, []);

  const finalAffiliateDiscountValue = useMemo<number>(() => {
    if (!affiliateDiscount) return 0;
    if (!affiliateDiscount.isPercentage) return affiliateDiscount.value;
    return (affiliateDiscount.value * optionPrice) / 100;
  }, [affiliateDiscount, optionPrice]);

  const itemsToBePaid = useMemo<Array<TotalTableItem | null>>(() => {
    return [
      {
        name:
          optionName +
          (trial
            ? ' (You will be charged 24 hours after your first workshop, cancel anytime on the course dashboard)'
            : ''),
        price: optionPrice,
        isDiscount: false,
      },
      optionToPurchase === 'SingleCourse' && !trial
        ? {
            name: 'Immigo Credit',
            price: Math.min(userData?.credit || 0, coursePrice),
            isDiscount: true,
          }
        : null,
      affiliateDiscount
        ? {
            name: `Promotion Discount [${viaToken}] ${
              affiliateDiscount.isPercentage
                ? ` (${affiliateDiscount.value}%)`
                : ''
            }`,
            price: finalAffiliateDiscountValue,
            isDiscount: true,
          }
        : null,
      trial
        ? {
            name: 'Free trial - 1 workshop on us',
            price: optionPrice,
            isDiscount: true,
          }
        : null,
    ];
  }, [
    optionName,
    optionPrice,
    optionToPurchase,
    coursePrice,
    userData?.credit,
    affiliateDiscount,
    viaToken,
    finalAffiliateDiscountValue,
  ]);

  const finalPriceToBePaid: number = itemsToBePaid.reduce(
    (prev: number, item: TotalTableItem | null) =>
      prev + (item?.price || 0) * (item?.isDiscount ? -1 : 1),
    0,
  );

  useEffect(() => {
    const payment_intent = params.get('payment_intent');
    if (payment_intent) confirmPaymentIntent();
    console.log(payment_intent);

    if (userData?.credit > 0) {
      setOptionToPurchase('SingleCourse');
    }
    // TODO: Double check
    // eslint-disable-next-line react-hooks/exhaustive-deps
    updateApplication();
  }, [userData]);

  useEffect(() => {
    if (application?.paid && onNext) onNext(); // Proceed to finish if the person paid
  }, [application]);

  async function updateApplication() {
    if (userData?.applications?.[courseID]) {
      const application = await returnAppByUID(userData.applications[courseID]);
      console.log(application);
      setApplication(application);
    }
  }

  // This is needed for alipay
  async function confirmPaymentIntent() {
    const payment_intent = params.get('payment_intent');
    const redirect_status = params.get('redirect_status');
    if (
      payment_intent &&
      redirect_status === 'succeeded' &&
      userData &&
      userData.applications &&
      userData.applications[courseID]
    ) {
      const paymentIntent = await getPaymentIntent(payment_intent);
      const application = await returnAppByUID(userData.applications[courseID]);
      if (
        paymentIntent.data &&
        paymentIntent.data.paymentIntent &&
        paymentIntent.data.paymentIntent.status === 'succeeded' &&
        application &&
        !application.paid
      ) {
        onPaymentSuccess();
      }
    } else {
      console.log(redirect_status);
    }
  }

  async function onPaymentSuccess() {
    // Tricky implementation to make coursePrice (in this function) the same as default price (from props), even if user is returnee;
    const coursePrice = price;
    let appID;

    if (userData) {
      console.log(userData);
      if (!userData.applications?.[courseID]) {
        console.log('Creating app');
        appID = await createApplicationDocument(userData.id, courseID, true, {
          name: userData.name,
          email: userData.email,
        }); // Create app data if doesn't exit
      } else {
        appID = userData.applications[courseID];
        console.log(userData.applications?.[courseID]);
      }
      const subOptionField =
        window.location.pathname.split('/')[
          window.location.pathname.split('/').length - 1
        ];
      const appUpdateData: any = {
        paid: !trial, // Not paid if it's trial
        price: coursePrice,
        paidPrice: optionPrice,
        trial: trial,
      };

      if (viaToken) appUpdateData.via = viaToken;
      if (optionToPurchase) appUpdateData.optionToPurchase = optionToPurchase;
      if (distributorType) appUpdateData.distributorType = distributorType;

      appUpdateData.createdAt = new Date().valueOf();

      if (finalPriceToBePaid !== coursePrice && !trial) {
        appUpdateData.usedCredit = optionPrice - finalPriceToBePaid; // Log how much credit was used in the transaction
        if (optionPrice - finalPriceToBePaid < coursePrice)
          await updateCreditToUser(
            userData.id,
            -(optionPrice - finalPriceToBePaid),
          );
      }

      if (
        optionToPurchase !== 'SingleCourse' ||
        subOptionField === '1' ||
        subOptionField === '0'
      ) {
        appUpdateData.boughtBundle = true;
        appUpdateData.bundlePlan = optionName; // Only 2 for now
        appUpdateData.boughtCredit =
          Math.floor(bundles[optionToPurchase].numberOfCohorts) * coursePrice;
        appUpdateData.paidPrice = optionPrice;
        await updateCreditToUser(
          userData.id,
          Math.floor(bundles[optionToPurchase].numberOfCohorts) * coursePrice -
            coursePrice,
        ); // give credit for bundle
      }

      await updateApplicationDocument(
        userData.id,
        appID,
        appUpdateData,
        viaToken,
      ); // update the payment status

      if (onSuccess) {
        await sendApplicationConfirmationEmail(
          userData.email,
          course.courseTitle,
          capitalizeEveryFirstLetter(getFirstNameFromFullName(userData.name)),
        );
        if (referralCode) {
          analyticsReferralCodeUsed(userData.id, referralCode, courseID); // Analytics
          await trackUserReferral(referralCode, userData.id, courseID); // Track user referral
        }
        if (!userData.referralCode) await assignUserReferralCode(userData.id); // assign referral code to the user

        try {
          if (userData) {
            console.log('converting');
            await sendApplicationConfirmationEmail(
              'ryan@immigo.io',
              `${userData.email} converting in rewardful`,
              capitalizeEveryFirstLetter(
                getFirstNameFromFullName(userData.name),
              ),
            );
            // @ts-ignore
            rewardful('convert', { email: userData?.email }); // Only convert if the user is a first timer
          } else {
            console.log('returning user');
          }
        } catch (e) {
          console.log(e);
        }

        if (registerDiscountUsageByUser)
          await registerDiscountUsageByUser?.(courseID);

        onSuccess?.();
        if (!trial)
          trackRegistrationEvent('Paid for the course', {
            courseID: courseID,
            courseTitle: course.courseTitle,
          });
      } else {
        onFailure?.();
      }
      await addCourseOnUser(courseID, userData.id); // Add course under userData
    } else {
      console.log({ userData });
      onFailure?.();
    }
  }

  return (
    <div className="">
      {userData ? (
        <>
          <div className="mb-15">
            <div className="text-3xl md:text-4xl mb-4 text-center font-semibold">
              Choose your learning options
            </div>
            {!trial ? (
              <div className="m-auto text-center">
                Your payment will be refunded within 2 weeks, if your
                application is rejected\
              </div>
            ) : (
              <TrialInfo />
            )}
          </div>
          <div className="mt-10 flex flex-col gap-4">
            {optionToPurchase === 'None' && (
              <p
                className={cx(
                  'text-lightBlue-900 font-medium text-lg mb-8 text-center',
                )}
              >
                Please choose a learning option from below
              </p>
            )}
            <div
              className={
                'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 md:gap-4'
              }
            >
              {Object.keys(bundles).map((key: string, index: number) => {
                if (key === 'None') return null;
                const bundle = bundles[key as OptionToPurchase];
                return (
                  <BundleBox
                    key={key}
                    onClick={() => setOptionToPurchase(key as OptionToPurchase)}
                    numberOfCohorts={bundle.numberOfCohorts}
                    discount={bundle.discount}
                    chosen={optionToPurchase === key}
                    name={bundle.name}
                    coursePrice={coursePrice}
                    originalPrice={course.price}
                    bestDeal={bundle.bestDeal}
                    priceBefore={bundle.priceBefore}
                    isReturneePrice={canUseReturneePrice}
                    returneeDiscount={bundle.returneeDiscount}
                  />
                );
              })}
            </div>
            {canUseReturneePrice && (
              <small className={'font-semibold text-center self-center'}>
                * Prices available until:{' '}
                {RETURNEE_PRICE_LIMIT_DATE.toLocaleDateString()}
              </small>
            )}
          </div>
          {/* {!additionalDiscountData || affiliateDiscount?.value !== 0 ? (
            <div className={cx('flex flex-col gap-4 my-8 items-start')}>
              <Input
                id={'promo-code'}
                label={'Promotion Code'}
                required={false}
                value={manualDiscountCode}
                onChange={(e: any) => {
                  setManualDiscountCode(e.target.value);
                }}
                containerClassName={cx('w-full')}
                disabled={loadingDiscount}
                error={
                  additionalDiscountData?.invalid
                    ? 'This code is not valid or active'
                    : additionalDiscountData?.usedByUser
                    ? 'You already claimed this code'
                    : undefined
                }
                touched={Boolean(manualDiscountCode)}
              />
              <button
                className={cx(
                  'border border-blue-immigo font-semibold rounded-md px-6',
                  'min-h-[48px]',
                )}
                onClick={() => {
                  setReferralCode?.(manualDiscountCode);
                }}
              >
                Set
              </button>
            </div>
          ) : null} */}
          {optionToPurchase !== 'None' ? (
            <TotalTable
              currency="usd"
              items={itemsToBePaid}
              totalPrice={finalPriceToBePaid}
              trial={trial}
              credit={userData?.credit}
            />
          ) : (
            <p
              className={cx(
                'text-red-warning font-semibold text-lg mb-8 text-center my-4',
              )}
            >
              Please choose your learning option before continuing.
            </p>
          )}
          {finalPriceToBePaid === 0 &&
          optionToPurchase === 'SingleCourse' &&
          !trial ? (
            <div className="flex md:justify-start justify-center w-36 md:mx-0 mx-auto">
              <PrimaryButton
                text="Use Credit"
                onClick={async () => {
                  onSubmit?.();
                  await updateCreditToUser(userData.id, -coursePrice); // Substract credit
                  onPaymentSuccess();
                }}
              />
            </div>
          ) : (
            <div
              className={cx(
                optionToPurchase === 'None' &&
                  'pointer-events-none cursor-not-allowed select-none opacity-75',
              )}
            >
              <PaymentMethods
                trial={trial}
                price={finalPriceToBePaid}
                activePaymentMethod={activePaymentMethod}
                isSubscribing={false}
                subOption={optionsToPurchase.indexOf(optionToPurchase)}
                userData={userData}
                onPaymentMethodSelected={(selectedMethod: PaymentMethod) => {
                  setActivePaymentMethod(selectedMethod);
                }}
                onSubmit={async () => {
                  // if (subOption !== 2 && !course.bundleDeal)
                  //   await updateCreditToUser(userData.id, -prices[2]); // pre-subtracked credit on sub
                  onSubmit?.();
                }}
                onFailure={async () => {
                  // if (subOption !== 2 && !course.bundleDeal)
                  //   await updateCreditToUser(userData.id, prices[2]); // add back credit on fail
                  onFailure?.();
                }}
                onSuccess={onPaymentSuccess}
                disabled={optionToPurchase === 'None'}
              />
            </div>
          )}
          <div className="flex md:justify-start justify-center w-36 md:mx-0 mx-auto mt-4 mb-8">
            <button
              className={'border font-semibold rounded-md px-6 py-3 w-full'}
              onClick={() => {
                onBack?.();
              }}
            >
              Back
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
};
