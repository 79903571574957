import mixpanel from '../../analytics/mixpanel';

export type Event =
  | 'Visited platform homepage'
  | 'Clicked on profile on homepage activity feed'
  | 'Clicked on the ranking tab in homepage'
  | 'Clicked on the feed tab in homepage'
  | 'Clicked on the refer tab in homepage';

type HomepageData = {
  profileID?: string;
};

export const trackHomePageData = (event: Event, data?: HomepageData) => {
  mixpanel.track(event, data);
};
