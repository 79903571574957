import bookOneMedal from '../graphics/book-1-medal.svg';
import wingedOneMedal from '../graphics/winged-1-medal.svg';
import fire from '../graphics/fire.svg';

export type AcheivementTypes =
  | 'first-ever-course'
  | 'first-ever-pod-meeting'
  | 'wildfire';

export type AchievementDetail = {
  id: AcheivementTypes;
  badge: string;
};

export type AchievementDetailMap = {
  achievements: {
    [key in AcheivementTypes]: AchievementDetail;
  };
};

export const AchievementMap: AchievementDetailMap = {
  achievements: {
    'first-ever-course': {
      id: 'first-ever-course',
      badge: bookOneMedal,
    },
    'first-ever-pod-meeting': {
      id: 'first-ever-pod-meeting',
      badge: wingedOneMedal,
    },
    wildfire: {
      id: 'wildfire',
      badge: fire,
    },
  },
};
