import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import cx from 'classix';
import { Fragment, useEffect, useRef, useState } from 'react';
import { LoadingCircle } from '../../../icons/loadingCircle';

type ModalThemes =
  | 'default'
  | 'warning-red'
  | 'info'
  | 'warning-yellow'
  | 'success';

interface HorizontalTemplateModalProps {
  children: React.ReactNode;
  theme: ModalThemes;
  primaryButtonText: string;
  primaryButtonOnClick: Function;
  secondaryButtonText?: string;
  secondaryButtonOnClick?: Function;
  isOpen?: boolean;
  closeModal?: Function;
  loading?: boolean;
}

const themeToColorMap = {
  default: 'blue-immigo',
  success: 'blue-immigo',
  'warning-red': 'red-600',
  'warning-yellow': 'yellow-600',
  info: 'blue-immigo',
};

const themeToColorMapLight = {
  default: 'blue-immigo-lighter',
  success: 'blue-immigo-lighter',
  'warning-red': 'red-500',
  'warning-yellow': 'yellow-500',
  info: 'blue-500',
};

const HorizontalModalTemplate = ({
  isOpen,
  closeModal,
  children,
  primaryButtonText,
  primaryButtonOnClick,
  secondaryButtonText,
  secondaryButtonOnClick,
  theme,
  loading,
}: HorizontalTemplateModalProps) => {
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const [open, setOpen] = useState(isOpen);
  const cancelButtonRef = useRef<any>(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
          closeModal?.();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-immigo focus:ring-offset-2"
                    onClick={() => {
                      setOpen(false);
                      closeModal?.();
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className={cx('max-w-full')}>{children}</div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className={cx(
                      'inline-flex w-full justify-center rounded-md px-3',
                      `bg-${themeToColorMap[theme]}`,
                      `hover:bg-${themeToColorMapLight[theme]}`,
                      'py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto',
                    )}
                    disabled={loading}
                    onClick={() => {
                      primaryButtonOnClick();
                    }}
                  >
                    {loading ? <LoadingCircle className="mr-2" /> : null}
                    {primaryButtonText}
                  </button>
                  {secondaryButtonOnClick && secondaryButtonText ? (
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      disabled={loading}
                      onClick={() => {
                        secondaryButtonOnClick();
                      }}
                    >
                      {secondaryButtonText}
                    </button>
                  ) : null}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default HorizontalModalTemplate;
