import { useEffect } from 'react';

declare global {
  interface Window {
    Kakao: any;
  }
}

const Login = () => {
  if (!window.Kakao.isInitialized()) {
    window.Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
  }
};

export const KakaoAuthPage = () => {
  const onLoginWithKakao = () => {
    const redirectUri = `${window.location.origin}/auth/callback/kakao`;
    const scope = ['account_email', 'profile_nickname'].join(',');

    window.Kakao.Auth.authorize({
      redirectUri,
      scope,
    });
  };

  useEffect(() => {
    Login();
    onLoginWithKakao();
  });

  // https://developers.kakao.com/docs/latest/ko/kakaologin/design-guide
  return <></>;
};
