import { ArrowDownOnSquareIcon } from '@heroicons/react/24/outline';
import { takeComponentScreenShotAndOpen } from '../../../util/canvas';

export const ScreenShotComponentButton = (props: {
  componentID: string;
  onClick?: Function;
}) => {
  const { onClick, componentID } = props;
  function onClickHandler() {
    takeComponentScreenShotAndOpen(componentID);
    onClick?.();
  }
  return (
    <div
      className="flex items-center justify-center cursor-pointer text-md hover:text-blue-immigo"
      onClick={onClickHandler}
      data-html2canvas-ignore="true"
    >
      <ArrowDownOnSquareIcon className="w-5 h-5 mr-1" /> Share
    </div>
  );
};
