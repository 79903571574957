import { createContext, FC, useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useSessionStorage } from 'react-use';

export interface ReferralContextValue {
  referralCode?: string;
  setReferralCode?: (code?: string) => void;
}

const defaultContextState: ReferralContextValue = {};

const ReferralContext =
  createContext<ReferralContextValue>(defaultContextState);

export const REFERRAL_CODE_KEY = 'via';
export const ReferralProvider: FC = (props) => {
  const history = useHistory();
  const { search } = useLocation();
  const [codeFromLocalStorage, setReferralCode] =
    useSessionStorage<string>(REFERRAL_CODE_KEY);

  const referralCode = useMemo<string | undefined>(() => {
    const searchParams = new URLSearchParams(search);
    const ref = searchParams?.get(REFERRAL_CODE_KEY);
    if (!ref) return codeFromLocalStorage;
    const codeToSave: string = Array.isArray(ref) ? ref.join('') : ref;
    if (codeToSave === codeFromLocalStorage) return codeFromLocalStorage;
    setReferralCode(codeToSave);
    return codeToSave;
  }, [search, codeFromLocalStorage, setReferralCode]);

  const internalSetReferralCode = (code?: string) => {
    try {
      if (!code) return;
      const searchParams = new URLSearchParams(search);
      searchParams?.set(REFERRAL_CODE_KEY, code);
      history.replace({ search: searchParams.toString() });
      window.sessionStorage.setItem(REFERRAL_CODE_KEY, code);
      setReferralCode(code);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ReferralContext.Provider
      value={{ referralCode, setReferralCode: internalSetReferralCode }}
    >
      {props.children}
    </ReferralContext.Provider>
  );
};

export const useReferralCode = (): ReferralContextValue => {
  return useContext(ReferralContext) || defaultContextState;
};
