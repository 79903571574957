import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import cx from 'classix';
import { Fragment, useEffect, useState } from 'react';
import { SparkleButton } from '../../../buttons/sparkle/sparkleButton';

type ModalThemes =
  | 'default'
  | 'warning-red'
  | 'info'
  | 'warning-yellow'
  | 'success';

interface VerticalModalTemplateProps {
  children: React.ReactNode;
  theme: ModalThemes;
  customWidthStyle?: string;
  primaryButtonText?: string;
  primaryButtonOnClick?: Function;
  secondaryButtonText?: string;
  secondaryButtonOnClick?: Function;
  sparkleButtonText?: string;
  sparkleButtonOnClick?: Function;
  isOpen?: boolean;
  closeModal?: Function;
  onModalOpen?: Function;
  stayOpenOnPrimaryClick?: boolean;
  noCloseOnSecondaryButtonClick?: boolean;
}

const themeToColorMap = {
  default: 'blue-immigo',
  success: 'blue-immigo',
  'warning-red': 'red-600',
  'warning-yellow': 'yellow-600',
  info: 'blue-600',
};

const themeToColorMapLight = {
  default: 'blue-immigo-lighter',
  success: 'blue-immigo-lighter',
  'warning-red': 'red-500',
  'warning-yellow': 'yellow-500',
  info: 'blue-500',
};

const VerticalModalTemplate = ({
  isOpen,
  closeModal,
  onModalOpen,
  children,
  customWidthStyle,
  primaryButtonText,
  primaryButtonOnClick,
  secondaryButtonText,
  secondaryButtonOnClick,
  sparkleButtonText,
  sparkleButtonOnClick,
  stayOpenOnPrimaryClick,
  noCloseOnSecondaryButtonClick,
}: VerticalModalTemplateProps) => {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (open) onModalOpen?.();
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
          closeModal?.();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center text-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={cx(
                  customWidthStyle
                    ? customWidthStyle
                    : 'sm:max-w-2xl md:w-full w-screen md:h-full h-full',
                  'relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6 ',
                )}
              >
                <div className="absolute top-0 right-0 pt-4 pr-4 block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-immigo focus:ring-offset-2"
                    onClick={() => {
                      setOpen(false);
                      closeModal?.();
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className={cx('max-w-full')}>{children}</div>
                {primaryButtonText && primaryButtonOnClick ? (
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-immigo px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-immigo-lighter focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-immigo"
                      onClick={() => {
                        primaryButtonOnClick();
                        if (!stayOpenOnPrimaryClick) setOpen(false);
                      }}
                    >
                      {primaryButtonText}
                    </button>
                  </div>
                ) : null}
                {sparkleButtonOnClick && sparkleButtonText ? (
                  <div className="mt-2">
                    <SparkleButton
                      text={sparkleButtonText}
                      onClick={sparkleButtonOnClick}
                    />
                  </div>
                ) : null}
                {secondaryButtonText ? (
                  <div className="mt-2">
                    <button
                      onClick={() => {
                        secondaryButtonOnClick?.();
                        if (!noCloseOnSecondaryButtonClick) setOpen(false);
                      }}
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent
                    px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 sm:text-sm"
                    >
                      {secondaryButtonText}
                    </button>
                  </div>
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default VerticalModalTemplate;
