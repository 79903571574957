import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import emptyAvatar from '../../../images/empty-avatar.webp';
import { countryCodeToSVG } from '../../../util/flagLoader';

export default function AvatarGroupStackedBigger(props: {
  imageLinks: Array<string | undefined>;
  names: Array<string>;
  ids: Array<string>;
  limit: number;
  nationalities?: Array<string>;
}) {
  const { imageLinks, limit, names, nationalities, ids } = props;

  return (
    <>
      <div className="flex flex-shrink-0 -space-x-2 p-1">
        {imageLinks
          .slice(0, limit)
          .map((imageLink: string | undefined, index: number) => {
            return (
              <div
                className="relative"
                key={ids[index] + ''}
                id={ids[index] + ''}
              >
                <img
                  key={(imageLink ?? 'avatar') + index}
                  className="inline-block h-12 w-12 md:h-24 md:w-24 rounded-full ring-1 ring-gray-200 object-cover object-center bg-white"
                  src={imageLink ?? emptyAvatar}
                  alt={(imageLink ?? 'avatar') + index}
                />
                {nationalities?.[index] ? (
                  <img
                    className="absolute -bottom-2 -right-1 w-5 h-5 md:w-10 md:h-10"
                    src={countryCodeToSVG(nationalities?.[index])}
                  />
                ) : null}
              </div>
            );
          })}
      </div>
    </>
  );
}
