import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/20/solid';
import { ReactChild, useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

export const SimpleCarouselWithBottomArrows = (props: {
  items: Array<ReactChild>;
  selectedItem?: number;
  onItemChanged?: Function;
}) => {
  const { items, selectedItem, onItemChanged } = props;
  const [selected, setSelected] = useState(selectedItem ?? 0);

  useEffect(() => {
    setSelected(selectedItem ?? 0);
  }, [selectedItem]);

  return (
    <div>
      <Carousel
        emulateTouch
        infiniteLoop
        dynamicHeight
        autoFocus
        showThumbs={false}
        showArrows={false}
        showIndicators={false}
        selectedItem={selected}
      >
        {items}
      </Carousel>
      <div className="flex justify-center mt-2">
        <div>
          <ArrowLeftCircleIcon
            className="w-10 h-10 mr-2 text-blue-immigo hover:text-blue-immigo-lighter cursor-pointer"
            onClick={() => {
              setSelected(selected - 1 < 0 ? items.length - 1 : selected - 1);
            }}
          />
        </div>
        <div>
          <ArrowRightCircleIcon
            className="w-10 h-10 ml-2 text-blue-immigo hover:text-blue-immigo-lighter cursor-pointer"
            onClick={() => {
              setSelected(selected + 1 >= items.length ? 0 : selected + 1);
            }}
          />
        </div>
      </div>
    </div>
  );
};
