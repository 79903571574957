import { Correction } from '../../types/feedback';
import { CorrectionContainer } from '../FeedbackListener/Corrections/CorrectionContainer';

export const ClassInsightCorrectionsContainer = (props: {
  corrections: Array<Correction>;
  audioRecordingURL?: string;
  speaker?: Array<string>;
  onRecordingPlayClicked?: Function;
  onCorrectionRating?: Function;
}) => {
  const {
    corrections,
    audioRecordingURL,
    onRecordingPlayClicked,
    speaker,
    onCorrectionRating,
  } = props;

  const filteredCorrections = speaker
    ? corrections.filter(
        (c) =>
          speaker.includes(c.speakerTag ?? '') || c.speakerTag === undefined,
      )
    : corrections;

  console.log(corrections);
  console.log(filteredCorrections);

  return (
    <div>
      {filteredCorrections.map((correction: Correction) => {
        return (
          <CorrectionContainer
            key={correction.correctedAt + correction.original}
            correction={correction}
            audioRecordingURL={audioRecordingURL}
            onRecordingPlayClicked={onRecordingPlayClicked}
            onCorrectionRating={onCorrectionRating}
          />
        );
      })}
    </div>
  );
};
