import { LightBulbIcon } from '@heroicons/react/20/solid';
import { cx } from 'classix';

export const TextInsightItem = (props: {
  text: string;
  firstEntry?: boolean;
}) => {
  const { text, firstEntry } = props;
  return (
    <div
      className={cx(
        firstEntry ? '' : 'border-t border-gray-300',
        'flex py-2 whitespace-pre-line',
      )}
    >
      <LightBulbIcon className="w-5 h-5 text-yellow-300 mr-4 flex-shrink-0 mt-1" />
      {text}
    </div>
  );
};
