import { useEffect, useState } from 'react';
import { getBookingDocument } from '../../firebase/subscription/bookings/bookings';
import { IdiomAndExplanationContainerProp } from '../../features/ClassPreview/Idioms/idiomContainer';
import { BookingData } from '../../firebase/subscription/subscription';
import {
  getMostRecentAttendedPreviousBooking,
  getOrGeneratePreviewActivityForBooking,
  updateUserPreviewActivityProgress,
} from '../../api/bookings';
import PanelSteps from '../../components/steps/panelSteps';
import { ClassPreviewReviewPage } from '../../features/ClassPreview/pages/review';
import { ClassPreviewPreviewPage } from '../../features/ClassPreview/pages/preview';
import { ClassPreviewWarmUpPage } from '../../features/ClassPreview/pages/warmup';
import VerticalMessageModal from '../../components/modals/verticalMessageModal/verticalMessageModal';
import ReactConfetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import { trackClassPreviewEvent } from '../../features/ClassPreview/analytics';
import { LoadingOverlay } from '../../components/loading';
import { ClassInfoTable } from '../../components/tables/classInfoTable';

export const ClassPreview = () => {
  const [idioms, setIdioms] = useState<Array<IdiomAndExplanationContainerProp>>(
    [],
  );
  const [previousIdioms, setPreviousIdioms] = useState<
    Array<IdiomAndExplanationContainerProp>
  >([]);
  const [howToAnswerWithPrevious, setHowToAnswerWithPrevious] = useState<
    Array<any>
  >([]);
  const [questionToAnswerMap, setQuestionToAnswerMap] = useState<any>();
  const [booking, setBooking] = useState<BookingData | undefined>();
  const [previousBooking, setPreviousBooking] = useState<
    BookingData | undefined
  >();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [didItModalOpen, setDidItModalOpen] = useState(false);
  const { width, height } = useWindowSize();

  const urlParams = new URLSearchParams(window.location.search);
  const bookingID = urlParams.get('bid');
  const step = urlParams.get('step');

  const canHavePreview =
    booking?.tag === 'idiom' ||
    booking?.tag === 'vocab' ||
    booking?.tag === 'free-talking' ||
    booking?.tag === undefined;

  async function stepToPage() {
    switch (step) {
      case 'preview':
        setPage(0);
        break;
      case 'review':
        setPage(1);
        break;
      case 'warmup':
        setPage(2);
        break;
      default:
        setPage(0);
    }
  }

  async function updateDefinitionAndExample() {
    setLoading(true);
    stepToPage(); // Set which page we are on first
    if (!bookingID) return;
    const bookingDoc = (await getBookingDocument(bookingID)) as BookingData;
    const mostRecentPreviousBooking =
      (await getMostRecentAttendedPreviousBooking(bookingID)) as BookingData;
    const previewActivity = canHavePreview
      ? await getOrGeneratePreviewActivityForBooking(bookingID)
      : null;

    if (previewActivity) {
      setIdioms(
        Object.keys(previewActivity?.idiomToDefinitionExampleMap || {}).map(
          (phrase: string) => {
            const phraseObj = (previewActivity?.idiomToDefinitionExampleMap ||
              {})[phrase];
            return {
              phrase: phrase,
              definition: phraseObj.definition,
              example: phraseObj.example,
            };
          },
        ),
      );

      setQuestionToAnswerMap(previewActivity?.questionToAnswerMap);

      setPreviousIdioms(
        Object.keys(
          previewActivity?.mostRecentIdiomToDefinitionExampleMap || {},
        ).map((phrase: string) => {
          const phraseObj =
            (previewActivity?.mostRecentIdiomToDefinitionExampleMap || {})[
              phrase
            ];
          return {
            phrase: phrase,
            definition: phraseObj.definition,
            example: phraseObj.example,
          };
        }),
      );

      setHowToAnswerWithPrevious(
        Object.keys(previewActivity?.howToAnswerPreviousIdioms || {}).map(
          (phrase: string) => {
            const qaObj = (previewActivity?.howToAnswerPreviousIdioms || {})[
              phrase
            ];
            return {
              phrase: phrase,
              index: qaObj.questionIndex,
              question: qaObj.question,
              answer: qaObj.answer,
            };
          },
        ),
      );
    }

    setPreviousBooking(mostRecentPreviousBooking);
    setBooking(bookingDoc);
    setLoading(false);
  }

  console.log(canHavePreview);

  function renderPage() {
    const pages = [
      <ClassPreviewPreviewPage
        booking={booking}
        idioms={idioms}
        questionToAnswerMap={questionToAnswerMap}
        onlyPreview={previousIdioms.length === 0}
        // onBackClick={() => {
        //   trackClassPreviewEvent('Clicked back button in preview section', {});
        //   window.location.href = `/preview?bid=${bookingID}&step=review`;
        // }}
        onNextClick={async () => {
          setLoading(true);
          trackClassPreviewEvent('Clicked next button in preview section', {});
          if (bookingID)
            await updateUserPreviewActivityProgress(bookingID, 'preview');
          setLoading(false);
          if (previousIdioms.length > 0)
            window.location.href = `/preview?bid=${bookingID}&step=review`;
          else setDidItModalOpen(true);
        }}
      />,
      <ClassPreviewReviewPage
        booking={previousBooking}
        idioms={previousIdioms}
        onBackClick={() => {
          trackClassPreviewEvent('Clicked back button in review section', {});
          window.location.href = `/preview?bid=${bookingID}&step=preview`;
        }}
        onNextClick={async () => {
          setLoading(true);
          trackClassPreviewEvent(
            'Visited review section in class preview page',
            {},
          );
          if (bookingID)
            await updateUserPreviewActivityProgress(bookingID, 'review');
          setLoading(false);
          window.location.href = `/preview?bid=${bookingID}&step=warmup`;
        }}
      />,
      <ClassPreviewWarmUpPage
        booking={booking}
        qaList={howToAnswerWithPrevious}
        onNextClick={async () => {
          setLoading(true);
          trackClassPreviewEvent('Clicked next button in warmup section', {});
          if (bookingID)
            await updateUserPreviewActivityProgress(bookingID, 'warmup');
          setLoading(false);
          setDidItModalOpen(true);
        }}
        onBackClick={() => {
          trackClassPreviewEvent('Clicked back button in warmup section', {});
          window.location.href = `/preview?bid=${bookingID}&step=review`;
        }}
      />,
    ];
    return pages[page];
  }

  useEffect(() => {
    updateDefinitionAndExample();
  }, []);

  useEffect(() => {
    if (page === 0) {
      trackClassPreviewEvent(
        'Visited review section in class preview page',
        {},
      );
    } else if (page === 1) {
      trackClassPreviewEvent(
        'Visited preview section in class preview page',
        {},
      );
    } else if (page === 2) {
      trackClassPreviewEvent(
        'Visited warmup section in class preview page',
        {},
      );
    }
  }, [page]);

  function status(ownPage: number) {
    if (page === ownPage) return 'current';
    else if (page < ownPage) return 'upcoming';
    else return 'complete';
  }

  return (
    <div>
      <LoadingOverlay
        enabled={loading}
        loadingMessage="Customizing your experience. Give us a minute!"
      />
      {didItModalOpen ? (
        <ReactConfetti
          width={width}
          height={height}
          numberOfPieces={320}
          recycle={false}
        />
      ) : null}
      <VerticalMessageModal
        mainMessage="You did it!"
        subMessage="Know that you are one of the top 5% students who take the time to review & preview your class :)"
        buttonText="Got it!"
        onButtonClick={() => {
          window.location.href = '/';
        }}
        onCloseModal={() => {
          window.location.href = '/';
        }}
        theme="success"
        isOpen={didItModalOpen}
      />
      {canHavePreview ? (
        <PanelSteps
          disabledClick
          steps={
            previousIdioms.length > 0
              ? [
                  {
                    name: 'Preview',
                    href: `/preview?bid=${bookingID}&step=preview`,
                    status: status(0),
                    id: '01',
                  },
                  {
                    name: 'Review',
                    href: `/preview?bid=${bookingID}&step=review`,
                    status: status(1),
                    id: '02',
                  },
                  {
                    name: 'Warm Up',
                    href: `/preview?bid=${bookingID}&step=warmup`,
                    status: status(2),
                    id: '03',
                  },
                ]
              : [
                  {
                    name: 'Preview',
                    href: `/preview?bid=${bookingID}&step=preview`,
                    status: status(0),
                    id: '01',
                  },
                ]
          }
        />
      ) : null}
      {canHavePreview ? (
        renderPage()
      ) : (
        <div className="flex flex-col justify-center items-center h-screen">
          <div className="text-8xl mb-8">🐹</div>
          <div className="text-4xl text-blue-immigo">Preview coming soon</div>
          <div className="text-gray-600 max-w-[500px] text-center mt-4 mb-6">{`Preview activity is not available for ${booking.tag} classes at the moment. Meanwhile you can still prepare for the class using the slide below!`}</div>
          {booking?.cleanSlide && booking?.startMili ? (
            <ClassInfoTable
              slideUrl={booking.cleanSlide}
              startMili={booking.startMili}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};
