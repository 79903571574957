import { useState } from 'react';

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export default function InputSubmit(props: {
  type: string;
  placeholder: string;
  initialValue: number;
  onSubmit: Function;
}) {
  const { type, placeholder, initialValue, onSubmit } = props;
  const [value, setValue] = useState<number>(initialValue);
  return (
    <div>
      <input
        type={type}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          setValue(parseInt(e.target.value));
        }}
      />
      <button
        type="button"
        className="w-full rounded bg-blue-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        onClick={() => {
          onSubmit(value);
        }}
      >
        Submit
      </button>
    </div>
  );
}
