import html2canvas from 'html2canvas';

export async function takeScreenShot() {
  const element: HTMLElement | null = document.querySelector('#root');
  if (element) {
    const canvas = await html2canvas(element);
    var base64URL = canvas
      .toDataURL('image/jpeg')
      .replace('image/jpeg', 'image/octet-stream');
    return base64URL;
  }
}

export async function takeComponentScreenShot(componentID: string) {
  const element: HTMLElement | null = document.querySelector(`#${componentID}`);
  if (element) {
    const canvas = await html2canvas(element);
    var base64URL = canvas
      .toDataURL('image/jpeg')
      .replace('image/jpeg', 'image/octet-stream');
    return base64URL;
  }
}

function b64toBlob(dataURI: string) {
  var byteString = atob(dataURI.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
}

export async function takeScreenShotAndOpen() {
  const b64 = await takeScreenShot();
  if (b64) {
    const blob = b64toBlob(b64);
    const converted = new Blob([blob], { type: 'image/png' });
    const url = URL.createObjectURL(converted);
    window.open(url, '_blank');
    return b64;
  }
}

export async function takeComponentScreenShotAndOpen(componentID: string) {
  const b64 = await takeComponentScreenShot(componentID);
  if (b64) {
    const blob = b64toBlob(b64);
    const converted = new Blob([blob], { type: 'image/png' });
    const url = URL.createObjectURL(converted);
    window.open(url, '_blank');
    return b64;
  }
}
