import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import { initializeFirebase } from '../configValues';
const { firestore: db } = initializeFirebase();

export const updateAchievementData = async (
  userID: string,
  achievementID: string,
  updateMap: any,
) => {
  try {
    const achReference = await doc(
      db,
      `users/${userID}/achievements/${achievementID}`,
    );
    await updateDoc(achReference, updateMap);
  } catch (err) {
    console.log(err);
  }
};

export const setAchievementNotified = async (
  userID: string,
  achievementID: string,
) => {
  try {
    await updateAchievementData(userID, achievementID, {
      notified: true,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getUserAchievements = async (userID: string) => {
  const achReference = await collection(db, 'users', userID, 'achievements');
  const achs = await getDocs(achReference);
  const achData: any[] = [];
  achs.forEach((ach: any) => {
    const data = ach.data();
    achData.push(data);
  });
  return achData;
};

export const getUserSubAchievements = async (
  userID: string,
  parentAchievementID: string,
) => {
  const achReference = await collection(
    db,
    'users',
    userID,
    'achievements',
    parentAchievementID,
    'subAchievements',
  );
  const achs = await getDocs(achReference);
  const achData: any[] = [];
  achs.forEach((ach: any) => {
    const data = ach.data();
    achData.push(data);
  });
  return achData;
};
