import mixpanel from '../../analytics/mixpanel';

type Events =
  | 'Visited class preview page'
  | 'Visited review section in class preview page'
  | 'Visited preview section in class preview page'
  | 'Visited warmup section in class preview page'
  | 'Clicked next button in review section'
  | 'Clicked next button in preview section'
  | 'Clicked next button in warmup section'
  | 'Clicked back button in preview section'
  | 'Clicked back button in review section'
  | 'Clicked back button in warmup section';

type ClassInsightData = {
  bookingID?: string;
};

export const trackClassPreviewEvent = (
  event: Events,
  data: ClassInsightData,
) => {
  mixpanel.track(event, data);
};
