import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import cx from 'classix';

interface SmallCardRadioOption {
  name: string;
  tag: string;
  checked?: boolean;
  disabled?: boolean;
}

const memoryOptions = [
  { name: '4 GB', inStock: true },
  { name: '8 GB', inStock: true },
  { name: '16 GB', inStock: true },
  { name: '32 GB', inStock: true },
  { name: '64 GB', inStock: true },
  { name: '128 GB', inStock: false },
];

export default function SmallCardsRadio(props: {
  title?: string;
  options: Array<SmallCardRadioOption>;
  chosenTag?: string;
  onChosen: Function;
}) {
  const { title, options, onChosen, chosenTag } = props;
  const [mem, setMem] = useState();

  return (
    <fieldset aria-label="Choose a memory option">
      <div className="flex items-center justify-between">
        <div className="text-sm font-medium leading-6 text-gray-900">
          {title}
        </div>
      </div>

      <RadioGroup
        value={mem}
        onChange={setMem}
        className="mt-2 grid grid-cols-3 gap-3 sm:grid-cols-3"
      >
        {options.map((option) => (
          <RadioGroup.Option
            key={option.name}
            value={option}
            className={({ focus, checked }: any) =>
              cx(
                !option.disabled
                  ? 'cursor-pointer focus:outline-none'
                  : 'cursor-not-allowed opacity-25',
                focus ? 'ring-2 ring-blue-600 ring-offset-2' : '',
                checked || option.checked || chosenTag === option.tag
                  ? 'bg-blue-immigo text-white hover:bg-blue-immigo-lighter'
                  : 'bg-white text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
                'flex items-center text-center justify-center rounded-md px-3 py-3 text-sm font-semibold sm:flex-1',
              )
            }
            onClick={async () => {
              await onChosen(option.tag);
            }}
            disabled={option.disabled}
          >
            {option.name}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
    </fieldset>
  );
}
