import ad from '../images/countries/ad.svg';
import ae from '../images/countries/ae.svg';
import af from '../images/countries/af.svg';
import ag from '../images/countries/ag.svg';
import ai from '../images/countries/ai.svg';
import al from '../images/countries/al.svg';
import am from '../images/countries/am.svg';
import ao from '../images/countries/ao.svg';
import ar from '../images/countries/ar.svg';
import as from '../images/countries/as.svg';
import at from '../images/countries/at.svg';
import au from '../images/countries/au.svg';
import aw from '../images/countries/aw.svg';
import az from '../images/countries/az.svg';
import ba from '../images/countries/ba.svg';
import bb from '../images/countries/bb.svg';
import bd from '../images/countries/bd.svg';
import be from '../images/countries/be.svg';
import bf from '../images/countries/bf.svg';
import bg from '../images/countries/bg.svg';
import bh from '../images/countries/bh.svg';
import bi from '../images/countries/bi.svg';
import bj from '../images/countries/bj.svg';
import bm from '../images/countries/bm.svg';
import bo from '../images/countries/bo.svg';
import br from '../images/countries/br.svg';
import bs from '../images/countries/bs.svg';
import bt from '../images/countries/bt.svg';
import bw from '../images/countries/bw.svg';
import by from '../images/countries/by.svg';
import bz from '../images/countries/bz.svg';
import ca from '../images/countries/ca.svg';
import cd from '../images/countries/cd.svg';
import cf from '../images/countries/cf.svg';
import cg from '../images/countries/cg.svg';
import ch from '../images/countries/ch.svg';
import ci from '../images/countries/ci.svg';
import ck from '../images/countries/ck.svg';
import cl from '../images/countries/cl.svg';
import cm from '../images/countries/cm.svg';
import cn from '../images/countries/cn.svg';
import co from '../images/countries/co.svg';
import cr from '../images/countries/cr.svg';
import cu from '../images/countries/cu.svg';
import cv from '../images/countries/cv.svg';
import cw from '../images/countries/cw.svg';
import cy from '../images/countries/cy.svg';
import cz from '../images/countries/cz.svg';
import de from '../images/countries/de.svg';
import dj from '../images/countries/dj.svg';
import dk from '../images/countries/dk.svg';
import DO from '../images/countries/dma.svg';
import dm from '../images/countries/dm.svg';
import dz from '../images/countries/dz.svg';
import ec from '../images/countries/ec.svg';
import ee from '../images/countries/ee.svg';
import eg from '../images/countries/eg.svg';
import er from '../images/countries/er.svg';
import es from '../images/countries/es.svg';
import et from '../images/countries/et.svg';
import fi from '../images/countries/fi.svg';
import fj from '../images/countries/fj.svg';
import fk from '../images/countries/fk.svg';
import fm from '../images/countries/fm.svg';
import fo from '../images/countries/fo.svg';
import fr from '../images/countries/fr.svg';
import ga from '../images/countries/ga.svg';
import gb from '../images/countries/gb.svg';
import gd from '../images/countries/gd.svg';
import ge from '../images/countries/ge.svg';
import gg from '../images/countries/gg.svg';
import gh from '../images/countries/gh.svg';
import gi from '../images/countries/gi.svg';
import gl from '../images/countries/gl.svg';
import gm from '../images/countries/gm.svg';
import gn from '../images/countries/gn.svg';
import gq from '../images/countries/gq.svg';
import gr from '../images/countries/gr.svg';
import gt from '../images/countries/gt.svg';
import gu from '../images/countries/gu.svg';
import gw from '../images/countries/gw.svg';
import hk from '../images/countries/hk.svg';
import hn from '../images/countries/hn.svg';
import hr from '../images/countries/hr.svg';
import ht from '../images/countries/ht.svg';
import hu from '../images/countries/hu.svg';
import id from '../images/countries/id.svg';
import ie from '../images/countries/ie.svg';
import il from '../images/countries/il.svg';
import im from '../images/countries/im.svg';
import india from '../images/countries/in.svg';
import io from '../images/countries/io.svg';
import iq from '../images/countries/iq.svg';
import ir from '../images/countries/ir.svg';
import is from '../images/countries/is.svg';
import it from '../images/countries/it.svg';
import je from '../images/countries/je.svg';
import jm from '../images/countries/jm.svg';
import jo from '../images/countries/jo.svg';
import jp from '../images/countries/jp.svg';
import ke from '../images/countries/ke.svg';
import kg from '../images/countries/kg.svg';
import kh from '../images/countries/kh.svg';
import ki from '../images/countries/ki.svg';
import km from '../images/countries/km.svg';
import kn from '../images/countries/kn.svg';
import kp from '../images/countries/kp.svg';
import kr from '../images/countries/kr.svg';
import kw from '../images/countries/kw.svg';
import ky from '../images/countries/ky.svg';
import kz from '../images/countries/kz.svg';
import la from '../images/countries/la.svg';
import lb from '../images/countries/lb.svg';
import li from '../images/countries/li.svg';
import lk from '../images/countries/lk.svg';
import lr from '../images/countries/lr.svg';
import ls from '../images/countries/ls.svg';
import lt from '../images/countries/lt.svg';
import lu from '../images/countries/lu.svg';
import lv from '../images/countries/lv.svg';
import ly from '../images/countries/ly.svg';
import ma from '../images/countries/ma.svg';
import mc from '../images/countries/mc.svg';
import md from '../images/countries/md.svg';
import me from '../images/countries/me.svg';
import mg from '../images/countries/mg.svg';
import mh from '../images/countries/mh.svg';
import mk from '../images/countries/mk.svg';
import ml from '../images/countries/ml.svg';
import mm from '../images/countries/mm.svg';
import mn from '../images/countries/mn.svg';
import mo from '../images/countries/mo.svg';
import mp from '../images/countries/mp.svg';
import mq from '../images/countries/mq.svg';
import mr from '../images/countries/mr.svg';
import ms from '../images/countries/ms.svg';
import mt from '../images/countries/mt.svg';
import mu from '../images/countries/mu.svg';
import mv from '../images/countries/mv.svg';
import mw from '../images/countries/mw.svg';
import mx from '../images/countries/mx.svg';
import my from '../images/countries/my.svg';
import mz from '../images/countries/mz.svg';
import na from '../images/countries/na.svg';
import nato from '../images/countries/nato.svg';
import ne from '../images/countries/ne.svg';
import nf from '../images/countries/nf.svg';
import ng from '../images/countries/ng.svg';
import ni from '../images/countries/ni.svg';
import nl from '../images/countries/nl.svg';
import no from '../images/countries/no.svg';
import np from '../images/countries/np.svg';
import nr from '../images/countries/nr.svg';
import nu from '../images/countries/nu.svg';
import nz from '../images/countries/nz.svg';
import om from '../images/countries/om.svg';
import pa from '../images/countries/pa.svg';
import pe from '../images/countries/pe.svg';
import pf from '../images/countries/pf.svg';
import pg from '../images/countries/pg.svg';
import ph from '../images/countries/ph.svg';
import pk from '../images/countries/pk.svg';
import pl from '../images/countries/pl.svg';
import pn from '../images/countries/pn.svg';
import pr from '../images/countries/pr.svg';
import ps from '../images/countries/ps.svg';
import pt from '../images/countries/pt.svg';
import pw from '../images/countries/pw.svg';
import py from '../images/countries/py.svg';
import qa from '../images/countries/qa.svg';
import ro from '../images/countries/ro.svg';
import rs from '../images/countries/rs.svg';
import ru from '../images/countries/ru.svg';
import rw from '../images/countries/rw.svg';
import sa from '../images/countries/sa.svg';
import sb from '../images/countries/sb.svg';
import sc from '../images/countries/sc.svg';
import sd from '../images/countries/sd.svg';
import se from '../images/countries/se.svg';
import sg from '../images/countries/sg.svg';
import si from '../images/countries/si.svg';
import sk from '../images/countries/sk.svg';
import sl from '../images/countries/sl.svg';
import sm from '../images/countries/sm.svg';
import sn from '../images/countries/sn.svg';
import so from '../images/countries/so.svg';
import sr from '../images/countries/sr.svg';
import ss from '../images/countries/ss.svg';
import st from '../images/countries/st.svg';
import sv from '../images/countries/sv.svg';
import sx from '../images/countries/sx.svg';
import sy from '../images/countries/sy.svg';
import sz from '../images/countries/sz.svg';
import tc from '../images/countries/tc.svg';
import td from '../images/countries/td.svg';
import tg from '../images/countries/tg.svg';
import th from '../images/countries/th.svg';
import tibet from '../images/countries/tibet.svg';
import tj from '../images/countries/tj.svg';
import tk from '../images/countries/tk.svg';
import tm from '../images/countries/tm.svg';
import tn from '../images/countries/tn.svg';
import to from '../images/countries/to.svg';
import tr from '../images/countries/tr.svg';
import tt from '../images/countries/tt.svg';
import tv from '../images/countries/tv.svg';
import tw from '../images/countries/tw.svg';
import tz from '../images/countries/tz.svg';
import ua from '../images/countries/ua.svg';
import ug from '../images/countries/ug.svg';
import us from '../images/countries/us.svg';
import uy from '../images/countries/uy.svg';
import un from '../images/countries/un.svg';
import uz from '../images/countries/uz.svg';
import ve from '../images/countries/ve.svg';
import vi from '../images/countries/vi.svg';
import vn from '../images/countries/vn.svg';
import vu from '../images/countries/vu.svg';
import ws from '../images/countries/ws.svg';
import ye from '../images/countries/ye.svg';
import za from '../images/countries/za.svg';
import zm from '../images/countries/zm.svg';
import zw from '../images/countries/zw.svg';

const flagMap: any = {
  ad: ad,
  ae: ae,
  af: af,
  ag: ag,
  ai: ai,
  al: al,
  am: am,
  ao: ao,
  ar: ar,
  as: as,
  at: at,
  au: au,
  aw: aw,
  az: az,
  ba: ba,
  bb: bb,
  bd: bd,
  be: be,
  bf: bf,
  bg: bg,
  bh: bh,
  bi: bi,
  bj: bj,
  bm: bm,
  bo: bo,
  br: br,
  bs: bs,
  bt: bt,
  bw: bw,
  by: by,
  bz: bz,
  ca: ca,
  cd: cd,
  cf: cf,
  cg: cg,
  ch: ch,
  ci: ci,
  ck: ck,
  cl: cl,
  cm: cm,
  cn: cn,
  co: co,
  cr: cr,
  cu: cu,
  cv: cv,
  cw: cw,
  cy: cy,
  cz: cz,
  de: de,
  dj: dj,
  dk: dk,
  dm: dm,
  do: DO,
  dz: dz,
  ec: ec,
  ee: ee,
  eg: eg,
  er: er,
  es: es,
  et: et,
  fi: fi,
  fj: fj,
  fk: fk,
  fm: fm,
  fo: fo,
  fr: fr,
  ga: ga,
  gb: gb,
  gd: gd,
  ge: ge,
  gg: gg,
  gh: gh,
  gi: gi,
  gl: gl,
  gm: gm,
  gn: gn,
  gq: gq,
  gr: gr,
  gt: gt,
  gu: gu,
  gw: gw,
  hk: hk,
  hn: hn,
  hr: hr,
  ht: ht,
  hu: hu,
  id: id,
  ie: ie,
  il: il,
  im: im,
  in: india,
  io: io,
  iq: iq,
  ir: ir,
  is: is,
  it: it,
  je: je,
  jm: jm,
  jo: jo,
  jp: jp,
  ke: ke,
  kg: kg,
  kh: kh,
  ki: ki,
  km: km,
  kn: kn,
  kp: kp,
  kr: kr,
  kw: kw,
  ky: ky,
  kz: kz,
  la: la,
  lb: lb,
  li: li,
  lk: lk,
  lr: lr,
  ls: ls,
  lt: lt,
  lu: lu,
  lv: lv,
  ly: ly,
  ma: ma,
  mc: mc,
  md: md,
  me: me,
  mg: mg,
  mh: mh,
  mk: mk,
  ml: ml,
  mm: mm,
  mn: mn,
  mo: mo,
  mp: mp,
  mq: mq,
  mr: mr,
  ms: ms,
  mt: mt,
  mu: mu,
  mv: mv,
  mw: mw,
  mx: mx,
  my: my,
  mz: mz,
  na: na,
  nato: nato,
  ne: ne,
  nf: nf,
  ng: ng,
  ni: ni,
  nl: nl,
  no: no,
  np: np,
  nr: nr,
  nu: nu,
  nz: nz,
  om: om,
  pa: pa,
  pe: pe,
  pf: pf,
  pg: pg,
  ph: ph,
  pk: pk,
  pl: pl,
  pn: pn,
  pr: pr,
  ps: ps,
  pt: pt,
  pw: pw,
  py: py,
  qa: qa,
  ro: ro,
  rs: rs,
  ru: ru,
  rw: rw,
  sa: sa,
  sb: sb,
  sc: sc,
  sd: sd,
  se: se,
  sg: sg,
  si: si,
  sk: sk,
  sl: sl,
  sm: sm,
  sn: sn,
  so: so,
  sr: sr,
  ss: ss,
  st: st,
  sv: sv,
  sx: sx,
  sy: sy,
  sz: sz,
  tc: tc,
  td: td,
  tg: tg,
  th: th,
  tibet: tibet,
  tj: tj,
  tk: tk,
  tm: tm,
  tn: tn,
  to: to,
  tr: tr,
  tt: tt,
  tv: tv,
  tw: tw,
  tz: tz,
  ua: ua,
  ug: ug,
  us: us,
  uy: uy,
  uz: uz,
  ve: ve,
  vi: vi,
  vn: vn,
  vu: vu,
  ws: ws,
  ye: ye,
  za: za,
  zm: zm,
  zw: zw,
};

export function countryCodeToSVG(code?: string): any {
  if (!code) return un;
  return flagMap[code.toLowerCase()] ? flagMap[code.toLowerCase()] : un;
}
