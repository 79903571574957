import { UserGroupIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SimpleBackAndNext } from '../../../../components/buttons/simpleBackAndNext/simpleBackAndNext';
import { useForm } from '../../../../components/forms/hooks';
import { Radio } from '../../../../components/forms/radio';
import { Select } from '../../../../components/forms/select';
import StackedCardRadio from '../../../../components/forms/stackedCardRadio/stackedCardRadio';
import HorizontalMessageModal from '../../../../components/modals/horizontalMessageModal/horizontalMessageModal';
import { updateUserData } from '../../../../firebase/users/users';
import { UserData } from '../../../../types/user';
import { removeUndefinedProperties } from '../../../../util/data';
import {
  arrayAsSelectOptions,
  countryCodeToName,
  countryNameToCode,
  nationalitiesOptions,
  yesNoOptions,
} from '../../../../util/forms';
import { OnBoardingPageProps } from '../../OnBoard';
import {
  CountryDetailInformationData,
  livingAbroadDurationOptions,
  validationSchema,
  whenToMoveOptions,
} from './data';

export const CountryDetail = ({
  userID,
  onNextClick,
  onBackClick,
}: OnBoardingPageProps) => {
  const [openNationalityModal, setOpenNationalityModal] = useState(false);
  const [openBasedModal, setOpenBasedModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const userData = useSelector(
    (state: any) => state.sessionState.userData as UserData,
  );
  const dispatch = useDispatch();

  const formik = useForm<CountryDetailInformationData>({
    initialValues: {
      ...userData,
      nationality: countryCodeToName(userData?.nationality),
      basedCountry: countryCodeToName(userData?.basedCountry),
    },
    validationSchema,
    onSubmit: async (values) => {
      if (userData && userData.id) {
        if (values['nationality'])
          values['nationality'] = countryNameToCode(values['nationality']);
        if (values['basedCountry']) {
          values['basedCountry'] = countryNameToCode(values['basedCountry']);
        }
        if (!values['livingAbroad']) {
          values['basedCountry'] = countryNameToCode(values['nationality']);
          values['howLongLivedAbroad'] = undefined;
        }
        try {
          await updateUserData(
            userID,
            removeUndefinedProperties({
              ...values,
            }),
          );
        } catch (err) {
          console.log(err);
        }
        const newUserValue = { ...userData, ...values };
        dispatch({ type: 'USER_DATA_SET', userData: newUserValue });
        onNextClick();
      }
    },
    enableReinitialize: true,
  });
  const nationalityChoices = [
    {
      name: '🇨🇴 Colombia',
      chosen:
        formik.getSelectProps('nationality', undefined, true).value ===
        '🇨🇴 Colombia',
      onClick: () => {
        formik.setFieldValue('nationality', '🇨🇴 Colombia');
      },
    },
    {
      name: '🇲🇽 Mexico',
      chosen:
        formik.getSelectProps('nationality', undefined, true).value ===
        '🇲🇽 Mexico',
      onClick: () => {
        formik.setFieldValue('nationality', '🇲🇽 Mexico');
      },
    },
    {
      name: '🇻🇪 Venezuela',
      chosen:
        formik.getSelectProps('nationality', undefined, true).value ===
        '🇻🇪 Venezuela',
      onClick: () => {
        formik.setFieldValue('nationality', '🇻🇪 Venezuela');
      },
    },
    {
      name:
        formik.getSelectProps('nationality', undefined, true).value !==
          '🇨🇴 Colombia' &&
        formik.getSelectProps('nationality', undefined, true).value !==
          '🇲🇽 Mexico' &&
        formik.getSelectProps('nationality', undefined, true).value !==
          '🇻🇪 Venezuela'
          ? formik.getSelectProps('nationality', undefined, true).value
          : undefined,
      chosen: true,
    },
    {
      name: '💭    Other',
      onClick: () => {
        setOpenNationalityModal(true);
      },
    },
  ];

  const basedChoices = [
    {
      name: '🇺🇸 United States',
      chosen:
        formik.getSelectProps('basedCountry', undefined, true).value ===
        '🇺🇸 United States',
      onClick: () => {
        formik.setFieldValue('basedCountry', '🇺🇸 United States');
      },
    },
    {
      name: '🇨🇦 Canada',
      chosen:
        formik.getSelectProps('basedCountry', undefined, true).value ===
        '🇨🇦 Canada',
      onClick: () => {
        formik.setFieldValue('basedCountry', '🇨🇦 Canada');
      },
    },
    {
      name: '🇬🇧 United Kingdom',
      chosen:
        formik.getSelectProps('basedCountry', undefined, true).value ===
        '🇬🇧 United Kingdom',
      onClick: () => {
        formik.setFieldValue('basedCountry', '🇬🇧 United Kingdom');
      },
    },
    {
      name:
        formik.getSelectProps('basedCountry', undefined, true).value !==
          '🇺🇸 United States' &&
        formik.getSelectProps('basedCountry', undefined, true).value !==
          '🇨🇦 Canada' &&
        formik.getSelectProps('basedCountry', undefined, true).value !==
          '🇬🇧 United Kingdom'
          ? formik.getSelectProps('basedCountry', undefined, true).value
          : undefined,
      chosen: true,
    },
    {
      name: '💭 Other',
      onClick: () => {
        setOpenBasedModal(true);
      },
    },
  ];

  return (
    <div>
      <HorizontalMessageModal
        mainMessage="Let's find your country!"
        buttonText="Submit"
        subMessage='Please choose your country and press "Submit"'
        onButtonClick={() => {
          setOpenNationalityModal(false);
        }}
        onCloseModal={() => {
          setOpenNationalityModal(false);
        }}
        theme="default"
        icon="flag"
        isOpen={openNationalityModal}
      >
        <Select
          {...formik.getSelectProps('nationality', undefined, true)}
          options={nationalitiesOptions}
        />
      </HorizontalMessageModal>
      <HorizontalMessageModal
        mainMessage="Let's find the country you currently reside in."
        buttonText="Submit"
        subMessage='Please choose the country you live in and press "Submit"'
        onButtonClick={() => {
          setOpenBasedModal(false);
        }}
        onCloseModal={() => {
          setOpenBasedModal(false);
        }}
        theme="default"
        icon="flag"
        isOpen={openBasedModal}
      >
        <Select
          label={'Where are you currently living?'}
          {...formik.getSelectProps('basedCountry', undefined, true)}
          options={nationalitiesOptions}
        />
      </HorizontalMessageModal>
      <div className="text-2xl text-center">Tell us about your nationality</div>
      <div className="mt-6 text-center text-sm text-gray-500">
        Our community has members from over 120 countries, where many of them
        are immigrants living in various English speaking countries. Knowing
        your nationality & immigration background will help us find you classes
        and other students that best fit your learning goals.
      </div>
      <div className="mt-6">
        <div className="block text-sm font-medium leading-6 text-gray-900 mb-2">
          Where are you from?
        </div>
        <StackedCardRadio
          choices={nationalityChoices}
          {...formik.getSelectProps('nationality')}
        />
      </div>
      <div className="mt-6">
        <Radio
          label={'Are you living abroad?'}
          {...formik.getRadioProps(
            'livingAbroad',
            (value) => (value ? 'yes' : 'no'),
            (newValue) => {
              formik.setFieldValue('livingAbroad', newValue === 'yes');
            },
          )}
          options={yesNoOptions}
        />
      </div>
      <div className="mt-6">
        {formik.values.livingAbroad === true ? (
          <div>
            <div className="mt-6">
              <div className="block text-sm font-medium leading-6 text-gray-900 mb-2">
                Where are you currently living?
              </div>
              <StackedCardRadio
                choices={basedChoices}
                {...formik.getSelectProps('basedCountry')}
              />
            </div>
            <div className="mt-6">
              <Select
                label={'How long have you lived in this foreign country?'}
                {...formik.getSelectProps('howLongLivedAbroad')}
                options={arrayAsSelectOptions(livingAbroadDurationOptions)}
              />
            </div>
          </div>
        ) : (
          <Select
            label={'Are you planning to move abroad?'}
            {...formik.getSelectProps('whenToMove')}
            options={arrayAsSelectOptions(whenToMoveOptions)}
          />
        )}
      </div>
      <SimpleBackAndNext
        onBack={() => {
          onBackClick?.();
        }}
        onNext={async () => {
          setLoading(true);
          await formik.submitForm();
          setLoading(false);
        }}
        nextLoading={loading}
      />
    </div>
  );
};
