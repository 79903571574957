import mixpanel from '../../analytics/mixpanel';

type Events = 'Showed meeting summary to user' | 'Closed meeting summary';

type UserData = {
  userID?: string;
  userName?: string;
  sessionID?: string;
};

export const trackMeetingNotifierEvent = (
  event: Events,
  data: UserData = {},
) => {
  mixpanel.track(event, data);
};
