import { SimpleBackAndNext } from '../../../components/buttons/simpleBackAndNext/simpleBackAndNext';
import { Fade, Slide } from 'react-awesome-reveal';
import { ImmigoWhiteLogo } from '../../../components/icons/immigoWhite';
import { useEffect } from 'react';
import { trackYearlyInsight } from '../../../features/YearlyInsight/analytics';
import ReactConfetti from 'react-confetti';
import { useWindowSize } from 'react-use';

export const YearlyInsightIntro = (props: { onNext: Function }) => {
  const { onNext } = props;
  const { width, height } = useWindowSize();

  useEffect(() => {
    trackYearlyInsight('Visited wrapped intro page');
  });
  return (
    <div className="text-center flex flex-col items-center justify-center py-24 bg-gradient-to-r from-green-400 to-blue-500 h-full">
      <ReactConfetti
        width={width}
        height={height}
        numberOfPieces={160}
        recycle={true}
      />
      <Fade delay={500}>
        <div className="flex flex-shrink-0 items-center md:px-4">
          <div className="w-48 flex">
            <ImmigoWhiteLogo />
          </div>
        </div>
      </Fade>

      <Fade delay={1000}>
        <div className="font-bold text-6xl mb-10 text-white">2023 Wrapped</div>
      </Fade>
      <Fade delay={1500}>
        <div className="font-bold text-3xl text-white">#IMMIGOWRAPPED</div>
      </Fade>
      <div>
        <SimpleBackAndNext color="text-white" onNext={onNext} />
      </div>
    </div>
  );
};
