import { useEffect, useState } from 'react';
import { BookingData } from '../../firebase/subscription/subscription';
import {
  Correction,
  CorrectionDocument,
  CorrectionRatingType,
} from '../../types/feedback';
import { getBookingDocument } from '../../firebase/subscription/bookings/bookings';
import {
  getCorrectionDocument,
  updateCorrectionDoc,
  updateCorrectionRating,
} from '../../firebase/feedback/correction';
import { getAndCreateClassInsight } from '../../api/insight';
import { ListStatBox } from '../../components/stats/listStatBox/listStatBox';
import { TextInsightBox } from '../../components/stats/textInsightBox/textInsightBox';
import { trackClassInsightEvent } from '../../features/ClassInsight/analytics';
import { ClassInsightLoader } from '../../features/ClassInsight/loader';
import { BasicStatBox } from '../../components/stats/basicStatBox/basicStatBox';
import { returnUserByUID } from '../../firebase/configuration';
import { BarStatBox } from '../../components/stats/barStatBox/barStatBox';
import { cefrToLevelMap, getCEFRWithWordCount } from '../../util/fluency';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import ReactTooltip from 'react-tooltip';
import { DotDotLoader } from '../../components/loaders/dotdotloader';
import { ClassInsightWordUsageList } from '../../features/ClassInsight/wordUsageList';
import BlueAlert from '../../components/alerts/blueAlert';
import { ClassInsightCorrectionsContainer } from '../../features/ClassInsight/correctionsContainer';
import { InsightHeaderContainer } from '../../features/ClassInsight/insightHeaderContainer';
import { SpeakerChoiceModal } from '../../features/ClassInsight/speakerChoiceModal';
import { getListOfSpeakers } from '../../util/speechmaticDataHandler';
import { PeerReactionCollectionContainer } from '../../features/ClassInsight/peerReactionCollectionContainer';

export const ClassInsight = (props: {}) => {
  const [bookingDoc, setBookingDoc] = useState<
    BookingData | undefined | null
  >();
  const [correctionDoc, setCorrectionDoc] = useState<
    CorrectionDocument | undefined | null
  >();
  const [fillerMap, setFillerMap] = useState<any>();
  const [wordMap, setWordMap] = useState<any>();
  const [topWordMap, setTopWordMap] = useState<any>();
  const [newWordsUsed, setNewWordsUsed] = useState<Array<string>>([]);
  const [tips, setTips] = useState<Array<string>>([]);
  const [fillerPercentage, setFillerPercentage] = useState<
    undefined | number
  >();
  const [loading, setLoading] = useState(false);
  const [booker, setBooker] = useState<any>();
  const [totalWordMap, setTotalWordMap] = useState<any>();
  const [totalWordList, setTotalWordList] = useState<Array<string>>([]);
  const [wordToSentenceMap, setWordToSentenceMap] = useState<any>();
  const [openSpeakerChoiceModal, setOpenSpeakerChoiceModal] = useState(false);
  const [speakerTags, setSpeakerTags] = useState<Array<string>>([]);

  const urlParams = new URLSearchParams(window.location.search);
  const bookingID = urlParams.get('bid');

  async function updateInfo() {
    if (!bookingID) return;
    setLoading(true);
    const booking = (await getBookingDocument(bookingID)) as BookingData;
    const booker = await returnUserByUID(booking?.bookerID);
    const bookerCorrectionDoc = booking?.correctionID
      ? ((await getCorrectionDocument(
          booking?.correctionID,
        )) as CorrectionDocument)
      : null;
    setCorrectionDoc(bookerCorrectionDoc);
    setBookingDoc(booking);

    let listOfSpeakers = getListOfSpeakers(
      bookerCorrectionDoc?.formattedTranscriptArray ?? [],
    );

    console.log(listOfSpeakers);

    // If there's more than 1 speakers && speaker tag is missing
    if (
      listOfSpeakers.length > 1 &&
      !bookerCorrectionDoc?.userSpeakerTags?.length
    ) {
      console.log('Missing speak');
      trackClassInsightEvent('Shown speaker tag chooser', {
        bookingID: bookingID ?? '',
      });
      setOpenSpeakerChoiceModal(true);
    } else {
      console.log('Yaps');
      if (listOfSpeakers.length === 0) listOfSpeakers = ['S1'];
      if (bookerCorrectionDoc && booking.correctionID) {
        setCorrectionDoc({
          ...bookerCorrectionDoc,
          userSpeakerTags: listOfSpeakers,
        });
        if (!bookerCorrectionDoc?.userSpeakerTags)
          await updateCorrectionDoc(booking.correctionID, {
            userSpeakerTags: listOfSpeakers,
          });
      }

      const res = await getAndCreateClassInsight(bookingID);
      console.log(res);
      setBooker(booker);
      setTotalWordMap(booker?.wordMap);
      setTotalWordList(Object.keys(booker?.wordMap ?? {}));
      setFillerMap(res?.fillerMap);
      setWordMap(res?.wordMap);
      setFillerPercentage(res?.fillerPercentage);
      setWordToSentenceMap(res?.topThreeOccurenceSentenceMap);

      if (res?.topThreeOccuredWords && res?.wordMap) {
        const frequentMap: any = {};
        res.topThreeOccuredWords.map((word: string) => {
          frequentMap[word] = res.wordMap[word];
        });
        setTopWordMap(frequentMap);
      }

      if (res?.alternateWordReplacementAdvices) {
        setTips(res.alternateWordReplacementAdvices);
      }

      if (res?.newWordsUsed) {
        setNewWordsUsed(res.newWordsUsed);
      }

      trackClassInsightEvent('Visited class insight page', {
        bookingID: bookingID,
        fillerUsagePercentage: res?.fillerPercentage,
      }); // Track visit

      setLoading(false);
    }
  }

  function fillerTextGenerator() {
    if (fillerPercentage === undefined) return undefined;

    if (fillerPercentage <= 0.06) {
      return (
        <div className="flex justify-evenly my-4">
          <ReactTooltip
            className="font-bold rounded-md max-w-[300px]"
            backgroundColor="#009eff"
          />
          <div className="text-center">
            <div className="text-6xl">🙃</div>
            <div className="text-xl font-bold">Native speakers</div>
            <div className="flex justify-center">
              <div className="text-blue-immigo font-bold">6%</div>
              <InformationCircleIcon
                className="w-5 h-5 text-gray-300 my-auto ml-2 cursor-pointer"
                data-tip={`Average native English speakers' speech usually consists of 6% filler words.`}
              />
            </div>
          </div>
          <div className="text-center">
            <div className="text-6xl">🤓</div>
            <div className="text-xl font-bold">You</div>
            <div className="flex">
              <div className="text-green-700 font-bold">
                🎉 {(fillerPercentage * 100).toFixed(2)}%
              </div>
              <InformationCircleIcon
                className="w-5 h-5 text-gray-300 my-auto ml-2 cursor-pointer"
                data-tip={`Out of all the words you used for this class, ${(
                  fillerPercentage * 100
                ).toFixed(
                  2,
                )}% of them were filler words, which is less than native English speakers. Keep it up!`}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex justify-evenly">
          <ReactTooltip
            className="font-bold rounded-md max-w-[300px]"
            backgroundColor="#009eff"
          />
          <div className="text-center">
            <div className="text-6xl">😀</div>
            <div className="text-xl font-bold">Native speakers</div>
            <div className="flex">
              <div className="text-blue-immigo font-bold">6%</div>
              <InformationCircleIcon
                className="w-5 h-5 text-gray-300 my-auto ml-2 cursor-pointer"
                data-tip={`Average native English speakers' speech usually consists of 6% filler words.`}
              />
            </div>
          </div>
          <div className="text-center">
            <div className="text-6xl">🤓</div>
            <div className="text-xl font-bold">You</div>
            <div className="flex justify-center">
              <div className="text-green-700 font-bold">
                {(fillerPercentage * 100).toFixed(2)}%
              </div>
              <InformationCircleIcon
                className="w-5 h-5 text-gray-300 my-auto ml-2 cursor-pointer"
                data-tip={`Out of all the words you used for this class, ${(
                  fillerPercentage * 100
                ).toFixed(
                  2,
                )}% of them were filler words! Let's try to keep this below 6% next time!`}
              />
            </div>
          </div>
        </div>
      );
    }
  }

  async function onSpeakerSubmit() {
    setLoading(true);
    trackClassInsightEvent('Submitted speaker tag chooser', {
      bookingID: bookingID ?? '',
    });
    if (bookingDoc?.correctionID) {
      await updateCorrectionDoc(bookingDoc?.correctionID, {
        userSpeakerTags: speakerTags,
      });
      setOpenSpeakerChoiceModal(false);
      window.location.reload();
    }
    setLoading(false);
  }

  async function onSpeakerChosen(
    content: string,
    startSeconds: number,
    endSeconds: number,
    speaker: string,
  ) {
    const newSpeakerTags = [...speakerTags];
    if (!newSpeakerTags.includes(speaker)) newSpeakerTags.push(speaker);
    else newSpeakerTags.splice(newSpeakerTags.indexOf(speaker), 1);
    setSpeakerTags(newSpeakerTags);
  }

  useEffect(() => {
    updateInfo(); // Update booking & correction document on load
  }, []);

  useEffect(() => {
    if (bookingDoc?.peerReactionMap?.[bookingDoc?.bookerID]) {
      trackClassInsightEvent('Shown peer reaction in insight page', {
        bookingID: bookingID ?? '',
      });
    }
  }, [bookingDoc]);

  console.log(correctionDoc);

  return (
    <div>
      <SpeakerChoiceModal
        formattedTranscriptArray={correctionDoc?.formattedTranscriptArray ?? []}
        classVoiceRecordingURL={bookingDoc?.classVoiceRecordingURL}
        isOpen={openSpeakerChoiceModal}
        chosenSpeakerList={speakerTags}
        onSpeakerChosen={onSpeakerChosen}
        onSubmit={onSpeakerSubmit}
      />
      {!loading ? (
        <div>
          {bookingDoc ? <InsightHeaderContainer booking={bookingDoc} /> : null}
          {correctionDoc?.id && correctionDoc.transcript?.length > 10 ? (
            <>
              <div className="pb-2">
                <BlueAlert text="Note that this report will only be accurate if you used your headphones. Our system can pick up other voices if you do not use your headphones." />
              </div>
              {bookingDoc?.peerReactionMap?.[bookingDoc?.bookerID] ? (
                <div className="pb-2">
                  <PeerReactionCollectionContainer
                    reactionMap={
                      bookingDoc?.peerReactionMap?.[bookingDoc?.bookerID]
                    }
                  />
                </div>
              ) : null}
              <div className="md:grid md:grid-cols-2 gap-2">
                <div className="my-2 md:my-1">
                  {fillerMap ? (
                    <ListStatBox
                      title="Your filler usage"
                      customDescription={
                        fillerPercentage !== undefined
                          ? fillerTextGenerator()
                          : undefined
                      }
                      items={Object.keys(fillerMap).map((filler) => {
                        return { name: filler, number: fillerMap[filler] };
                      })}
                    />
                  ) : null}
                  <div className="my-2 md:my-1">
                    {totalWordList.length > 0 && newWordsUsed ? (
                      <BasicStatBox
                        statArray={[
                          {
                            name: 'Your total words used so far',
                            unit: 'words',
                            stat: `${totalWordList.length}`,
                            change: `${newWordsUsed.length}`,
                            changeType: 'increase',
                            subText: `${newWordsUsed.length}`,
                          },
                        ]}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="">
                  <div className="my-2 md:my-1">
                    {topWordMap ? (
                      <ListStatBox
                        title="Your most frequent words"
                        items={Object.keys(topWordMap).map((word) => {
                          return {
                            name: word,
                            number: topWordMap[word],
                            customDescription: wordToSentenceMap?.[word] ? (
                              <ClassInsightWordUsageList
                                word={word}
                                sentences={wordToSentenceMap[word]}
                                onExpandCollapseClick={(expanded: boolean) => {
                                  if (!expanded)
                                    trackClassInsightEvent(
                                      'Clicked expand on the word usage in insight page',
                                      {},
                                    );
                                  else
                                    trackClassInsightEvent(
                                      'Clicked collapse on the word usage in insight page',
                                      {},
                                    );
                                }}
                              />
                            ) : undefined,
                          };
                        })}
                      />
                    ) : null}
                  </div>

                  <div>
                    {totalWordList.length > 0 ? (
                      <div className="my-2">
                        <BarStatBox
                          title="Your word range so far"
                          completed={
                            (totalWordList.length /
                              (getCEFRWithWordCount(totalWordList.length)
                                .nextLevelNumber ?? 0)) *
                              100 ?? 0
                          }
                          description="Your word range will go up as you use different words in your classes."
                          customBottomChild={
                            <div className="flex justify-between">
                              <div className="text-2xl font-bold text-blue-immigo">
                                {cefrToLevelMap(
                                  getCEFRWithWordCount(totalWordList.length)
                                    .level,
                                )}
                              </div>
                              {getCEFRWithWordCount(totalWordList.length)
                                .nextLevel ? (
                                <div className="flex items-center">
                                  <div className="text-gray-500 mr-2">
                                    {`${
                                      (getCEFRWithWordCount(
                                        totalWordList.length,
                                      ).nextLevelNumber ?? 0) -
                                      totalWordList.length
                                    } words to `}
                                  </div>
                                  <div className="text-md font-semibold text-blue-immigo">
                                    {cefrToLevelMap(
                                      getCEFRWithWordCount(totalWordList.length)
                                        .nextLevel ?? 'A1',
                                    )}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          }
                          // sideText={`${totalWordList.length}/${getCEFRWithWordCount(totalWordList.length).nextLevelNumber}`}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-span-2 max-h-[400px] overflow-scroll mb-2">
                  <ClassInsightCorrectionsContainer
                    audioRecordingURL={bookingDoc?.classVoiceRecordingURL}
                    corrections={correctionDoc.corrections}
                    speaker={correctionDoc.userSpeakerTags}
                    onRecordingPlayClicked={(
                      correction: Correction,
                      playing: boolean,
                    ) => {
                      trackClassInsightEvent(
                        'Clicked speaker icon in correction container',
                        {
                          correctionOriginalText: correction?.original,
                          correction: correction?.fixed,
                          explanation: correction?.explanation,
                          status: playing ? 'paused' : 'play',
                        },
                      );
                    }}
                    onCorrectionRating={async (
                      rating: CorrectionRatingType,
                      correction: Correction,
                    ) => {
                      if (bookingDoc?.correctionID) {
                        await updateCorrectionRating(
                          bookingDoc?.correctionID,
                          rating,
                          correction,
                        );
                        trackClassInsightEvent(
                          'Rated a correction in insight page',
                          {
                            bookingID: bookingID ?? '',
                            correctionOriginalText: correction.original,
                            correction: correction?.fixed,
                            explanation: correction?.explanation,
                            rating: rating,
                          },
                        );
                      }
                      setCorrectionDoc({ ...correctionDoc }); // Trigger update
                    }}
                  />
                </div>
                {tips?.length > 0 ? (
                  <div className="col-span-2 row-span-1">
                    <TextInsightBox
                      title={'Tips for your  next class'}
                      texts={tips}
                    />
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            <div className="w-full h-full justify-center items-center flex">
              <div className="text-center pt-8">
                <div className="text-4xl py-8 text-blue-immigo">
                  Your class insight is missing
                </div>
                <div className="text-8xl py-8">🙀</div>
                <div className="py-8 text-2xl text-gray-500 max-w-[500px] px-2">
                  We need you to follow these steps every class for us to
                  generate an accurate class insight to help you:
                </div>
                <div>
                  <div className="text-blue-immigo max-w-[500px] px-2 py-1">
                    1. 💻 Attend your class using your laptop or desktop (📵 not
                    mobile)
                  </div>
                  <div className="text-blue-immigo max-w-[500px] px-2 py-1">
                    2. 🎧 Put on your headphones with a mic before the class
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="text-gray-500 mb-4 flex items-center">
            <span className="mr-2">⌛ Loading your class report</span>
            <DotDotLoader />
          </div>
          <ClassInsightLoader />
        </div>
      )}
    </div>
  );
};
