import mixpanel from '../../../analytics/mixpanel';

type Events =
  | 'Clicked what are immigots profile overview'
  | 'Clicked claim free class button profile overview';

type NextClassContainerData = {};

export const trackProfileOverviewEventts = (
  event: Events,
  data?: NextClassContainerData,
) => {
  mixpanel.track(event, data);
};
