import { ArrowRightIcon, CheckIcon } from '@heroicons/react/24/outline';
import { cx } from 'classix';
import { SubTier } from '../../config/stripe';
import { stringsPricingPage } from '../../util/localization';
import { currencySymbols } from '../../util/currency';
import { grayscale } from 'pdf-lib';

interface PricingOptionBoxProps {
  name?: string;
  id: string;
  classesCount: number;
  price: number;
  description: string;
  features: Array<string>;
  currency?: string;
  mostPopular?: boolean;
  current?: boolean;
  onSelect?: Function;
  hideSelect?: boolean;
  discount?: number;
  hideCurrency?: boolean;
}

export function PricingOptionBox({
  name,
  id,
  price,
  description,
  features,
  mostPopular,
  current,
  onSelect,
  classesCount,
  currency,
  hideSelect,
  discount,
  hideCurrency,
}: PricingOptionBoxProps) {
  const currencyString = (currency ?? 'usd').toLocaleUpperCase();
  const currencySymbol = currencySymbols[currency ?? 'usd'] ?? '$';

  return (
    <div
      key={id}
      className={cx(
        mostPopular ? 'ring-2 ring-blue-immigo' : 'ring-1 ring-gray-200',
        'md:rounded-3xl rounded-xl md:p-8 p-4 xl:p-10 bg-white md:block flex min-w-[350px]',
        current ? 'grayscale' : '',
      )}
      onClick={() => {
        if (onSelect && !current) onSelect();
      }}
    >
      <div>
        <div className="flex justify-between md:gap-x-4">
          <h3
            id={id}
            className={cx(
              mostPopular ? 'text-blue-immigo' : 'text-gray-900',
              'text-lg font-semibold leading-8',
            )}
          >
            {/* <p className="flex items-baseline gap-x-1 md:hidden block">
              <span className="md:text-4xl text-2xl font-bold tracking-tight text-gray-900">
                {!hideCurrency ? currencyString : ''} {currencySymbol}
                {(price / classesCount).toFixed(1)}
              </span>
              <span className="text-sm font-semibold leading-6 text-gray-600 mb-2">
                {`/${stringsPricingPage.class}`}
              </span>
            </p> */}
            <p className="flex items-baseline gap-x-1 md:hidden block">
              <span className="md:text-4xl text-lg font-bold tracking-tight text-gray-900">
                {!hideCurrency ? currencyString : ''} {currencySymbol}
                {price.toFixed(2)}
              </span>
              <span className="text-xs font-semibold leading-6 text-gray-600 mb-2">
                {classesCount > 16
                  ? `${stringsPricingPage.annually}`
                  : `/4 ${stringsPricingPage.weeks}`}
              </span>
            </p>
            {name
              ? name
              : `${Math.floor(classesCount / (classesCount > 16 ? 13 : 1))} ${
                  stringsPricingPage.classes
                }`}
            <span
              className={cx(
                'mx-1 text-sm font-semibold leading-6 text-gray-600',
                mostPopular ? 'text-blue-immigo-darker' : 'text-gray-600',
              )}
            >
              {name ? '' : `/4 ${stringsPricingPage.weeks}`}
            </span>
          </h3>
          <div>
            <p className="mb-1 md:block hidden rounded-full bg-green-100 px-2.5 py-1 text-xs text-center min-w-[100px] font-semibold leading-5 text-green-700 ml-auto">
              {`${Math.round(
                ((price + (discount ?? 20) - price) /
                  (price + (discount ?? 20))) *
                  100,
              ).toFixed(0)}% ${stringsPricingPage.off}`}
            </p>
            {mostPopular ? (
              <p className="md:block hidden rounded-full bg-blue-immigo px-2.5 py-1 text-xs text-center min-w-[100px] font-semibold leading-5 text-white">
                {stringsPricingPage.mostPopular}
              </p>
            ) : null}
          </div>
        </div>
        {/* <h3
          id={id}
          className={'text-gray-900 md:text-md text-sm md:leading-8 leading-4'}
        >
          Total: {!hideCurrency ? currencyString : ''} {currencySymbol}
          {price.toFixed(2)}{' '}
          <span className="line-through text-gray-400">
            {!hideCurrency ? currencyString : ''} {currencySymbol}
            {(price + (discount ?? 20)).toFixed(2)}
          </span>
        </h3> */}
        <span className="text-md tracking-tight text-gray-900">
          {!hideCurrency ? currencyString : ''} {currencySymbol}
          {(price / classesCount) % 1 <= 0.5
            ? (price / classesCount).toFixed(0)
            : (price / classesCount).toFixed(1)}
        </span>
        <span className="ml-1 text-xs font-semibold leading-6 text-gray-600">
          {`/${stringsPricingPage.class}`}
        </span>
      </div>
      <p className="mt-4 text-sm leading-6 text-gray-600 hidden md:block">
        {/*@ts-ignore*/}
        {stringsPricingPage[description]}
      </p>
      <p className="mt-6 flex items-baseline gap-x-1 md:block hidden">
        {/* <span className="md:text-4xl text-2xl font-bold tracking-tight text-gray-900">
          {!hideCurrency ? currencyString : ''} {currencySymbol}
          {(price / classesCount) % 1 <= 0.5
            ? (price / classesCount).toFixed(0)
            : (price / classesCount).toFixed(1)}
        </span>
        <span className="text-sm font-semibold leading-6 text-gray-600">
          {`/${stringsPricingPage.class}`}
        </span> */}
        <h3
          id={id}
          className={
            'text-gray-900 md:text-md text-2xl font-bold md:leading-8 leading-4 flex flex-col'
          }
        >
          <span className="line-through text-gray-400">
            {!hideCurrency ? currencyString : ''} {currencySymbol}
            {(price + (discount ?? 20)).toFixed(2)}
          </span>
          <span>
            {!hideCurrency ? currencyString : ''} <span> </span>
            {currencySymbol}
            {price.toFixed(2)}{' '}
            <span
              className={cx(
                'text-sm font-semibold leading-6 text-gray-600',
                mostPopular ? 'text-blue-immigo-darker' : 'text-gray-600',
              )}
            >
              {classesCount > 16
                ? `${stringsPricingPage.annually}`
                : `/4 ${stringsPricingPage.weeks}`}
            </span>
          </span>
        </h3>
      </p>
      {!hideSelect ? (
        <div
          className={cx(
            mostPopular
              ? 'bg-blue-immigo text-white shadow-sm hover:bg-blue-immigo-lighter'
              : current
              ? 'text-gray-500 ring-1 ring-inset ring-gray-500'
              : 'text-blue-immigo ring-1 ring-inset ring-blue-immigo hover:ring-blue-immigo-lighter',
            current
              ? ''
              : 'cursor-pointer leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-immigo',
            'md:mt-6 hidden md:block rounded-md py-2 px-3 text-center text-sm font-semibold',
          )}
          onClick={() => {
            // setClicked(true);
            // setSubOption(index);
            // setTimeout(() => {
            //   setPage(1);
            // }, animationDuration);
            if (onSelect && !current) onSelect();
          }}
        >
          {current ? 'Current plan' : stringsPricingPage.selectLearningPlan}
        </div>
      ) : null}
      <div className="ml-auto flex flex-col justify-between">
        <ArrowRightIcon
          onClick={() => {
            // setClicked(true);
            // setSubOption(index);
            // setTimeout(() => {
            //   setPage(1);
            // }, animationDuration);
            if (onSelect) onSelect();
          }}
          className={cx(
            'w-5 h-5 md:hidden block ml-auto',
            mostPopular
              ? 'text-blue-immigo hover:text-blue-immigo-lighter'
              : 'text-gray-600 hover:text-blue-immigo',
          )}
        />
        {mostPopular ? (
          <p className=" md:hidden block rounded-full bg-blue-immigo px-2.5 py-1 text-xs text-center min-w-[100px] font-semibold leading-5 text-white ml-auto">
            {stringsPricingPage.mostPopular}
          </p>
        ) : null}
        <p className="mt-1 md:hidden block rounded-full bg-green-100 px-2.5 py-1 text-xs text-center min-w-[100px] font-semibold leading-5 text-green-700 ml-auto">
          {`${Math.round(
            ((price + (discount ?? 20) - price) / (price + (discount ?? 20))) *
              100,
          ).toFixed(0)}% off`}
        </p>
      </div>
      <ul
        role="list"
        className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10 md:block hidden"
      >
        {features.map((feature: string) => (
          <li key={feature} className="flex gap-x-3">
            <CheckIcon
              className="h-6 w-5 flex-none text-blue-immigo"
              aria-hidden="true"
            />
            {/*@ts-ignore*/}
            {stringsPricingPage[feature]}
          </li>
        ))}
      </ul>
    </div>
  );
}
