import { useEffect, useState } from 'react';
import StackedCardRadio from '../../forms/stackedCardRadio/stackedCardRadio';
import VerticalMessageModal from '../verticalMessageModal/verticalMessageModal';
import HorizontalMessageModal from '../horizontalMessageModal/horizontalMessageModal';
import TextInput from '../../forms/textInput';
import TextBox from '../../forms/textbox';
import { indexToReasonMap } from './indexToCancelReason';

export const CancelSubscriptionQuestionModal = (props: {
  isOpen: boolean;
  onClose: Function;
  onOpen: Function;
  onCancel: Function;
  onBack: Function;
}) => {
  const { isOpen, onCancel, onClose, onOpen, onBack } = props;
  const [chosenIndices, setChosenIndices] = useState<Array<number>>([]);
  const [openModal, setOpenModal] = useState(false);
  const [additionalExplanation, setAdditionalExplanation] = useState<
    string | undefined
  >();
  const [reason, setReason] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const choices = [
    {
      name: 'Material level is too low',
      icon: <span>📉</span>,
      chosen: chosenIndices.includes(0),
      onClick: () => {
        if (!chosenIndices.includes(0)) setChosenIndices([...chosenIndices, 0]);
        else {
          chosenIndices.splice(0, 1);
          setChosenIndices([...chosenIndices]);
        }
      },
    },
    {
      name: 'Material level is too high',
      icon: <span>📈</span>,
      chosen: chosenIndices.includes(1),
      onClick: () => {
        if (!chosenIndices.includes(1)) setChosenIndices([...chosenIndices, 1]);
        else {
          chosenIndices.splice(chosenIndices.indexOf(1), 1);
          setChosenIndices([...chosenIndices]);
        }
      },
    },
    {
      name: 'Student levels are too low',
      icon: <span>👇</span>,
      chosen: chosenIndices.includes(2),
      onClick: () => {
        if (!chosenIndices.includes(2)) setChosenIndices([...chosenIndices, 2]);
        else {
          chosenIndices.splice(chosenIndices.indexOf(2), 2);
          setChosenIndices([...chosenIndices]);
        }
      },
    },
    {
      name: 'Student levels are too high',
      icon: <span>☝️</span>,
      chosen: chosenIndices.includes(3),
      onClick: () => {
        if (!chosenIndices.includes(3)) setChosenIndices([...chosenIndices, 3]);
        else {
          chosenIndices.splice(chosenIndices.indexOf(3), 1);
          setChosenIndices([...chosenIndices]);
        }
      },
    },
    {
      name: 'Found another English learning resource/method that suits me better',
      icon: <span>🎒</span>,
      chosen: chosenIndices.includes(4),
      onClick: () => {
        if (!chosenIndices.includes(4)) setChosenIndices([...chosenIndices, 4]);
        else {
          chosenIndices.splice(chosenIndices.indexOf(4), 1);
          setChosenIndices([...chosenIndices]);
        }
      },
    },
    {
      name: "Don't need to learn English right now",
      icon: <span>🏫</span>,
      chosen: chosenIndices.includes(5),
      onClick: () => {
        if (!chosenIndices.includes(5)) setChosenIndices([...chosenIndices, 5]);
        else {
          chosenIndices.splice(chosenIndices.indexOf(5), 1);
          setChosenIndices([...chosenIndices]);
        }
      },
    },
    {
      name: "Class materials don't meet my needs",
      icon: <span>📝</span>,
      chosen: chosenIndices.includes(6),
      onClick: () => {
        if (!chosenIndices.includes(6)) setChosenIndices([...chosenIndices, 6]);
        else {
          chosenIndices.splice(chosenIndices.indexOf(6), 1);
          setChosenIndices([...chosenIndices]);
        }
      },
    },
    {
      name: "Immigo coaches don't meet my needs",
      icon: <span>🧑‍🏫️</span>,
      chosen: chosenIndices.includes(7),
      onClick: () => {
        if (!chosenIndices.includes(7)) setChosenIndices([...chosenIndices, 7]);
        else {
          chosenIndices.splice(chosenIndices.indexOf(7), 1);
          setChosenIndices([...chosenIndices]);
        }
      },
    },
    {
      name: 'Class times are not ideal for my schedule',
      icon: <span>⏰️</span>,
      chosen: chosenIndices.includes(8),
      onClick: () => {
        if (!chosenIndices.includes(8)) setChosenIndices([...chosenIndices, 8]);
        else {
          chosenIndices.splice(chosenIndices.indexOf(8), 1);
          setChosenIndices([...chosenIndices]);
        }
      },
    },
    {
      name: reason ? reason : 'Other reason',
      icon: <span>💬</span>,
      chosen: reason !== undefined && reason.length > 0,
      onClick: () => {
        setOpenModal(true);
      },
    },
  ];

  useEffect(() => {
    setError(false);
  }, [chosenIndices]);

  function getReasons() {
    const reasons = chosenIndices.map((i) => indexToReasonMap[i]);
    if (reason && reason.length > 3) reasons.push(reason.toLowerCase());
    return reasons;
  }

  return (
    <div>
      <HorizontalMessageModal
        mainMessage="Tell us your reason"
        subMessage="Please type below the reason for cancelation if you didn't find the option you were looking for."
        theme="default"
        icon="check-mark"
        buttonText="Submit"
        onButtonClick={async () => {
          setOpenModal(false);
        }}
        onCloseModal={() => {
          setOpenModal(false);
        }}
        isOpen={openModal}
        loading={loading}
      >
        <div className="mt-6">
          <TextInput
            placeholder="ex) Vacation"
            value={reason}
            onChange={(t: string) => {
              setReason(t);
            }}
          />
        </div>
      </HorizontalMessageModal>
      <VerticalMessageModal
        mainMessage="We want to hear from you"
        subMessage="We are sad to see you go and hope you will return soon! Before you go, please help us out by telling us your reason for cancelation. You can choose multiple."
        theme={'info'}
        buttonText="Cancel subscription"
        secondaryButtonText="Back"
        onSecondaryButtonClick={onClose}
        onButtonClick={async () => {
          setLoading(true);
          const reasons = getReasons();
          if (
            reasons.length > 0 ||
            (reason !== undefined && reason.length > 0)
          ) {
            console.log(reasons);
            console.log(reason);
            await onCancel(reasons, additionalExplanation);
            onClose();
          } else {
            setError(true);
          }
          setLoading(false);
        }}
        stayOpenOnPrimaryClick
        onCloseModal={onClose}
        isOpen={isOpen}
      >
        <div className="mt-5 text-left">
          <StackedCardRadio choices={choices} />
          <div className="text-left text-sm mt-4 text-gray-500">
            (Optional) Elaborate below:
          </div>
          <TextBox
            value={additionalExplanation}
            onChange={setAdditionalExplanation}
          />
          {error ? (
            <div className="text-sm text-red-warning">
              Please select at least one reason!
            </div>
          ) : null}
        </div>
      </VerticalMessageModal>
    </div>
  );
};
