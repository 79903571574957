import * as yup from 'yup';

import { FormData, SchemaFor } from '../../../components/forms/hooks/useForm';
import { verifyReferralCode } from '../../../firebase/configuration';
import { nationalities, Nationality } from '../../../util/forms';

export const cefrLevels = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
type CEFRLevel = typeof cefrLevels[number];

export const planOnMovingOptions = [
  'No',
  'Yes, sometime in the future',
  "Yes, in the near future but I don't have a plan yet",
  'Yes, I already have a confirmed plan to move',
];
type PlanOnMovingOption = typeof planOnMovingOptions[number];

export const whenToMoveOptions = [
  'In less than 3 months from now',
  'In less than 6 months from now',
  'In less than a year from now',
  'In more than a year from now',
];
type WhenToMoveOption = typeof whenToMoveOptions[number];

export const livingAbroadDurationOptions = [
  'Less than 3 months',
  'Less than 6 months',
  'Less than a year',
  'More than a year',
];
export type LivingAbroadDurationOption =
  typeof livingAbroadDurationOptions[number];

export const preparingForExamOptions = [
  'No',
  'Yes, IELTS',
  'Yes, TOEFL',
  'Yes, EF',
  'Yes, but some other test',
];
type PreparingForExamOption = typeof preparingForExamOptions[number];

export interface ApplicationQuestionData extends FormData {
  disclaimerAgree?: boolean;
  cefrLevel?: CEFRLevel;
  nationality?: Nationality;
  livingAbroad?: boolean;
  planOnMoving?: PlanOnMovingOption;
  based?: Nationality;
  howLongLivedAbroad?: LivingAbroadDurationOption;
  whenToMove?: WhenToMoveOption;
  countryToMoveTo?: Nationality;
  whyLearnEnglish?: string;
  preparingForExam?: PreparingForExamOption;
  referralCode?: string;
  personalRestrictions?: string;
}

const nationalityValidationSchema = (errorMessage?: string): yup.StringSchema =>
  yup
    .string()
    .min(2, 'This is not a valid option')
    .oneOf(nationalities, 'This is not a valid option')
    .strict()
    .required(errorMessage || 'This field is required.');

export const validationSchema: SchemaFor<ApplicationQuestionData> = {
  disclaimerAgree: yup
    .boolean()
    .oneOf([true], 'We cannot proceed to the course without your agreement!')
    .required('This field is required'),
  cefrLevel: yup
    .string()
    .min(1, 'This is not a valid option')
    .oneOf(
      cefrLevels.slice(1),
      'Unfortunately, this course is not meant for A1 level students.',
    )
    .required('This field is required, please choose on the CEFR levels')
    .uppercase(),
  nationality: nationalityValidationSchema(
    'This field is required, please choose a nationality',
  ),
  livingAbroad: yup.boolean().required('Please select an option'),
  based: yup.string().when('livingAbroad', {
    is: (value: boolean) => value === true,
    then: nationalityValidationSchema(
      'This field is required, please choose the country you are based in!',
    ),
  }),
  howLongLivedAbroad: yup.string().when('livingAbroad', {
    is: (value: boolean) => value === true,
    then: yup
      .string()
      .min(1, 'This is not a valid option')
      .oneOf(livingAbroadDurationOptions, 'This is not a valid option')
      .required('This field is required, please choose an option'),
  }),
  planOnMoving: yup.string().when('livingAbroad', {
    is: (value: boolean) => value === false,
    then: yup
      .string()
      .min(1, 'This is not a valid option')
      .oneOf(planOnMovingOptions, 'This is not a valid option')
      .required('This field is required, please select an option'),
  }),
  whenToMove: yup.string().when('planOnMoving', {
    is: (value?: string) => value && value !== planOnMovingOptions[0],
    then: yup
      .string()
      .min(1, 'This is not a valid option')
      .oneOf(whenToMoveOptions, 'This is not a valid option')
      .required('This field is required, please choose an option'),
  }),
  countryToMoveTo: yup.string().when('planOnMoving', {
    is: (value?: string) => value && value !== planOnMovingOptions[0],
    then: nationalityValidationSchema(
      'This field is required, please choose an option',
    ),
  }),
  whyLearnEnglish: yup
    .string()
    .min(20, 'Please elaborate or write a longer text'),
  preparingForExam: yup
    .string()
    .min(1, 'This is not a valid option')
    .oneOf(preparingForExamOptions, 'This is not a valid option')
    .required('This field is required, please select an option'),
  referralCode: yup
    .string()
    .uppercase()
    .test({
      name: 'is-valid',
      message: 'This is not a valid referral code. Please try again.',
      test: async (value, ctx) => {
        if (!value) return true;
        const isValid = await verifyReferralCode(value);
        if (isValid) return true;
        return ctx.createError({
          message: `This is not a valid referral code. Please try again.`,
        });
      },
    }),
  personalRestrictions: yup.string(),
};
