import { groupBy, over } from 'lodash';

import {
  days,
  type Day,
  type AvailabilityOption,
} from './../pages/Availability/AvailabilityPage';
import { unique } from './unique';

interface SimpleStudentData {
  name: string;
  email: string;
  podNumber: number;
}

interface SimpleOverlapStudent extends SimpleStudentData {
  availability?: Array<AvailabilityOption>;
}

interface OverlapUser {
  availability?: AvailabilityOption;
  student: SimpleStudentData;
}

type DayAvalabilities = Record<Day, Array<OverlapUser>>;
type PodsWithAvailability = Record<number, DayAvalabilities>;

interface OverlapOption {
  overlap: { start: number; end: number };
  students: Array<SimpleStudentData>;
}

interface DayOverlapData {
  overlaps: Array<OverlapOption>;
  studentsWithoutOverlaps: Array<SimpleStudentData>;
}

type DayOverlaps = Record<Day, DayOverlapData>;
export type PodsOverlaps = Record<number, DayOverlaps>;

const sortWeekOverlaps = (overlaps: DayOverlaps): DayOverlaps => {
  const daysInObject = Object.keys(overlaps);
  daysInObject.sort((a, b) => days.indexOf(a as Day) - days.indexOf(b as Day));
  return daysInObject.reduce((obj: DayOverlaps, key) => {
    obj[key as Day] = overlaps[key as Day];
    return obj;
  }, {} as DayOverlaps);
};

const millisInAnHour = 1000 * 60 * 60;

const checkDuplicateStudent = (
  candidate: SimpleOverlapStudent,
  studentArray: Array<SimpleStudentData>,
) => {
  for (let i = 0; i < studentArray.length; i++) {
    if (studentArray[i].email === candidate.email) {
      return true;
    }
  }
  return false;
};

export const findAvailabilityOverlaps = (
  users?: Array<SimpleOverlapStudent>,
): PodsOverlaps => {
  if (!users || !users.length) return {};
  const studentsWithoutAvailability: Array<SimpleStudentData> = users
    .filter(
      (user) => !Boolean(user && user.availability && user.availability.length),
    )
    .map((user) => {
      return {
        name: user.name || 'Unknown',
        email: user.email,
        podNumber: user.podNumber,
      } as SimpleStudentData;
    });

  const usersByGroup = groupBy(users, (item) => item.podNumber || -1);
  const pods = {} as PodsWithAvailability;
  Object.keys(usersByGroup).forEach((pod) => {
    const podNumber = Number(pod);
    usersByGroup[pod].forEach((user) => {
      const daysAvailabilities = (pods?.[podNumber] || {}) as DayAvalabilities;
      user.availability?.forEach((availabilityOption) => {
        const dayAvailabilities: Array<OverlapUser> = [
          ...(daysAvailabilities[availabilityOption.day] || []),
          {
            availability: availabilityOption,
            student: {
              name: user.name || 'Unknown',
              email: user.email,
              podNumber: user.podNumber,
            },
          },
        ];
        dayAvailabilities.sort(
          (a, b) =>
            (a.availability?.startMillis || 0) -
            (b.availability?.startMillis || 0),
        );
        daysAvailabilities[availabilityOption.day] = dayAvailabilities;
      });
      pods[podNumber] = { ...daysAvailabilities };
    });
  });

  const podsOverlaps: PodsOverlaps = {} as PodsOverlaps;
  Object.keys(pods).forEach((pod) => {
    // const podStudentsCount = usersByGroup[pod].length;
    const daysOverlaps: DayOverlaps = {} as DayOverlaps;
    Object.keys(pods[Number(pod)]).forEach((day) => {
      const availabilities = pods[Number(pod)]?.[day as Day];
      const overlaps: Array<OverlapOption> = [];
      let overlapStudents: Array<SimpleStudentData> = [];
      let startOverlap = 0;
      let endOverlap = 0;

      for (const option of availabilities) {
        const { availability, student } = option;
        const { startMillis = 0, endMillis = 0 } = availability || {};
        if (startMillis >= endOverlap && endOverlap !== 0) {
          if (
            overlapStudents.length > 1 &&
            endOverlap - startOverlap >= millisInAnHour
          ) {
            overlaps.push({
              overlap: { start: startOverlap, end: endOverlap },
              students: overlapStudents,
            });
          }
          startOverlap = 0;
          endOverlap = 0;
          overlapStudents = [];
        }
        if (startOverlap === 0 || startMillis >= startOverlap)
          startOverlap = startMillis;
        if (endOverlap === 0 || endMillis <= endOverlap) endOverlap = endMillis;
        if (!checkDuplicateStudent(student, overlapStudents))
          overlapStudents.push(student);
      }
      if (
        overlapStudents.length > 1 &&
        endOverlap - startOverlap >= millisInAnHour
      ) {
        overlaps.push({
          overlap: { start: startOverlap, end: endOverlap },
          students: overlapStudents,
        });
      }

      if (overlaps.length > 1)
        overlaps.sort((a, b) => b.students.length - a.students.length);

      const uniqueStudentsWithoutOverlaps = unique(
        [
          ...studentsWithoutAvailability.filter(
            (student) => student.podNumber === Number(pod),
          ),
          ...availabilities
            .filter(
              ({ student }) =>
                student.podNumber === Number(pod) &&
                !overlaps.some((overlap) =>
                  overlap.students.some(
                    (overlapStudent) => overlapStudent.email === student.email,
                  ),
                ),
            )
            .map((it) => it.student),
        ].flat(),
        'email',
      );
      if (overlaps.length > 0 || uniqueStudentsWithoutOverlaps.length > 1)
        daysOverlaps[day as Day] = {
          overlaps,
          studentsWithoutOverlaps: uniqueStudentsWithoutOverlaps,
        };
    });
    podsOverlaps[Number(pod)] = sortWeekOverlaps(daysOverlaps);
  });

  return podsOverlaps;
};

export const hasEnoughAvailabilitiesSet = (
  availabilities?: Array<AvailabilityOption>,
): 'missing' | 'insufficient' | 'ok' => {
  if (!availabilities || !availabilities.length) return 'missing';
  // @ts-ignore
  const daysCount = [...new Set(availabilities.map((it) => it.day))].length;
  if (daysCount < 2) return 'insufficient';

  const totalMillisInAvailabilities = availabilities.reduce((prev, curr) => {
    return prev + ((curr.endMillis || 0) - (curr.startMillis || 0));
  }, 0);
  if (totalMillisInAvailabilities < millisInAnHour * 4) return 'insufficient';

  return 'ok';
};

export const filterFullOverlaps = (
  podsOverlaps: PodsOverlaps,
  podNumber: number,
  studentsCount: number = 0,
): PodsOverlaps => {
  const overlaps = podsOverlaps[podNumber];
  if (!overlaps) return podsOverlaps;
  Object.keys(overlaps).forEach((day) => {
    const dayOverlaps = overlaps[day as Day];
    dayOverlaps.studentsWithoutOverlaps = [];
    dayOverlaps.overlaps = dayOverlaps.overlaps.filter((option) => {
      return option.students.length >= studentsCount;
    });
    if (dayOverlaps.overlaps.length) overlaps[day as Day] = { ...dayOverlaps };
    else delete overlaps[day as Day];
  });
  return podsOverlaps;
};
