import mixpanel from '../../../analytics/mixpanel';

type Events =
  | 'Guest booking invite modal shown'
  | 'Guest booking invite modal pressed submit';

type TrackGuestBookingInviteModal = {};

export const trackGuestBookingInviteModal = (
  event: Events,
  data?: TrackGuestBookingInviteModal,
) => {
  mixpanel.track(event, data);
};
