import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { returnCourseByUID } from '../../firebase/configuration';

export const PodHistory = ({ userData }: any) => {
  const [courses, setCourses] = useState<Array<any>>([]);
  const [detailsShown, setDetailsShown] = useState<any>([]);

  const toggleShown = (courseTitle: any) => {
    const shownState = detailsShown.slice();
    const index = shownState.indexOf(courseTitle);

    if (index >= 0) {
      shownState.splice(index, 1);
      setDetailsShown(shownState);
    } else {
      shownState.push(courseTitle);
      setDetailsShown(shownState);
    }
  };

  useEffect(() => {
    userDataLoaded(userData);
  }, userData);

  function userDataLoaded(user: any) {
    if (user && user.courses && user.courses.length > 0) {
      updateCourses(user.courses);
    }
  }

  async function updateCourses(courses: Array<string>) {
    const courseList: Array<any> = await Promise.all(
      courses.map(async (courseID: string, courseIndex: number) => {
        const course: any = await returnCourseByUID(courseID);
        return course;
      }),
    );

    setCourses(courseList); // Flatten 2 dimensional array before updating the student list
  }

  function createPodRecordingsRows(podWeek: any, numberOfRecordings: number) {
    if (numberOfRecordings > 0) {
      const podRecordingLinks = [];

      for (const week in podWeek) {
        podRecordingLinks.push(podWeek[week]);
      }

      return podRecordingLinks.map((link, index) => {
        return (
          <tr>
            <td>
              <a
                className="text-blue-immigo pl-9"
                href={link}
                target="_blank"
                rel="noreferrer"
              >
                • POD recording {index + 1}
              </a>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr className="text-sm text-gray-400">
          <td className="pl-9">
            There is no recording available for this course.
          </td>
        </tr>
      );
    }
  }

  function createPodHistoryDataTable() {
    return courses.map((course, index) => {
      const chevronUpIcon = (
        <svg
          className="w-4 h-4 mr-1"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5l7 7-7 7"
          ></path>
        </svg>
      );
      const chevronDownIcon = (
        <svg
          className="w-4 h-6 mr-1"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      );
      const numberOfRecordings =
        userData.pastPodRecordings && userData.pastPodRecordings[course.id]
          ? Object.keys(userData.pastPodRecordings[course.id]).length
          : 0;
      const podWeek = userData.pastPodRecordings
        ? userData.pastPodRecordings[course.id]
        : undefined;
      const podRecordingsRows = createPodRecordingsRows(
        podWeek,
        numberOfRecordings,
      );

      return (
        <React.Fragment key={course.courseTitle}>
          <tr className="flex justify-between font-bold">
            <td
              className="p-4 flex items-center cursor-pointer"
              onClick={() => toggleShown(course.courseTitle)}
            >
              {!detailsShown.includes(course.courseTitle) && chevronUpIcon}
              {detailsShown.includes(course.courseTitle) && chevronDownIcon}
              {course.courseTitle}
            </td>
            <td className="p-4 pr-7 text-center">
              {moment(course.coursePeriod.start).format('MMM YYYY')}
            </td>
          </tr>
          {detailsShown.includes(course.courseTitle) && podRecordingsRows}
        </React.Fragment>
      );
    });
  }

  return (
    <div
      className="
      shadow-lg w-full lg:mr-2 border-blue-immigo border-2 p-2
      font-medium bg-white
      rounded-lg
      mb-10"
    >
      <div className="overflow-auto">
        <table className="w-full">
          <thead className="bg-black flex text-black w-full border-blue-immigo border-b-2">
            <tr className="flex w-full mb-2">
              <th className="p-4 w-1/4 text-2xl">Pod history</th>
            </tr>
          </thead>
          <tbody className="bg-grey-light flex flex-col overflow-y-auto scrollbar w-full h-52">
            {createPodHistoryDataTable()}
          </tbody>
        </table>
      </div>
    </div>
  );
};
